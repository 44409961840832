import { Button, Grid, IconButton, TextField } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Autocomplete } from '@material-ui/lab';
import { ArrayHelpers, Field, FieldArray, FieldProps, Form, Formik, FormikConfig, FormikProps } from 'formik';
import moment, { Moment } from 'moment';
import React from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { connect } from 'react-redux';
import { muiResponsive } from 'src/appConfig/muiTheme';
import { isEmpty as _isEmpty } from 'lodash';
import { MuiDatePickerFormikField, MuiDatePickerMomentUtilsProvider } from 'src/components/common/KeyboardDatePicker';
import { MuiTimePickerFormikField } from 'src/components/common/MuiTimePickers';
import CustomPopperAutocomplete from 'src/components/CustomPopperAutocomplete';
import SearchDropdownBox from 'src/components/SearchDropdownBox';
import { getLocationOptionLabel } from 'src/containers/SchedulerContainers/helper';
import { IRootState } from 'src/redux/rootReducer';
import { ServiceLocation } from 'src/redux/schedulerRedux/types';
import {
  getContactTypesOptionsSLAsync,
  getSessionTypesOptionsSLAsync,
  getDeliveryTypesOptionsSLAsync,
  getPracticeElementOptionsSLAsync,
  getTargetOptionsSLAsync,
  getBehaviorCategoryOptionsSLAsync,
  getBehaviorMeasureOptionsSLAsync,
  searchLocationAddSLAsync,
  getDirectNonDirectOptionsSLAsync,
} from 'src/redux/sessionLogsRedux/actions';
import {
  AddSessionTemplateType,
  BehaviorCategoryType,
  BehaviorMeasureType,
  ConcernTreatmentType,
  OptionSelectSLType,
  PracticeElementType,
  sessionOptionsSelectSLType,
  TargetAreaType,
} from 'src/redux/sessionLogsRedux/types';
import { filterDisabledBMOptions, filterDisabledPMOCOptions, filterDisabledPMOptions, filterDisabledPracticeOptions, filterOptionsMatchAllKeywords } from 'src/utils';
import * as yup from 'yup';
import { CONSTANT_ADD_LOG, isCrisisOrConsultation } from '../../AddSessionLog/helper';
import './styles.scss';

const clsPrefix = 'session-log-template-form';
const MAX_TARGETS = 3;
const MAX_PROGRESS_MEASURES = 5;

type Props = FormikProps<AddSessionTemplateType> & ReturnType<typeof mapState> & typeof mapDispatch;

export const isTargetTypeOther = (target: TargetAreaType | null) => target?.otherFlag;
export const isConcernTreatmentTypeOther = (c: ConcernTreatmentType | null) => c?.otherFlag;

export const isPracticeTypeOther = (p: PracticeElementType | null) => p?.otherFlag;

export const getPracticeElementTitle = (p: PracticeElementType, isFormVersion2?: boolean) => isFormVersion2 ? p.practiceElement : p.practiceFullName;

export const getProgressMeasureTitle = (p?: BehaviorCategoryType) => {
      return p && `${p.measureCategory}`;
};

export const getBehaviorMeasureTitle = (p?: BehaviorMeasureType) => {
  return p && `${p.measure}`;
};

export const getTargetTitle = (t?: TargetAreaType, isFormVersion2?: boolean) => {
  if (isFormVersion2) 
    return t.target;
  return t && `${t.targetArea}${t.target ? `: ${t.target}` : ''}`;
}
export const getConcernTreatmentTitle = (c?: ConcernTreatmentType) => c.concernTreatment;

export const getTargetTitleNull = (t?: TargetAreaType) => {
  if(!t.disabledFlag) {
    return t && `${t.targetArea}${t.target ? `: ${t.target}` : ''}`;
  }
  else return 'Not Available';
};

export const AddSessionLogTemplateForm = ({
  values,
  provider,
  searchLocations,
  searchSLLocation,
  clearSearchLocation,
  targets,
  getTargetOptions,
  practices,
  getPracticeOptions,
  contact,
  sessionTypes,
  progressMeasures,
  behaviorMeasures,
  getContactOptions,
  getSessionTypeOptions,
  getBehaviorCategoryOptions,
  getBehaviorMeasureOptions,
  deliveryMode,
  directNonDirect,
  getDeliveryModeOptions,
  getDirectNonDirectOptions,
}: Props) => {
  const isMobile = useMediaQuery(muiResponsive.MOBILE);
  const isCrisisOrConsult = isCrisisOrConsultation(values.primaryMethodOfContact, values.sessionType);
  React.useEffect(() => {
    if (targets.data.length === 0) {
      getTargetOptions();
    }
    if (practices.data.length === 0) {
      getPracticeOptions();
    }
    if (contact.data.length === 0) {
      getContactOptions();
    }
    if (sessionTypes.data.length === 0) {
      getSessionTypeOptions();
    }
    if (deliveryMode.data.length === 0) {
      getDeliveryModeOptions();
    }
    if (directNonDirect.data.length === 0) {
      getDirectNonDirectOptions();
    }
    if (progressMeasures.data.length === 0) {
      getBehaviorCategoryOptions();
    }
    if (behaviorMeasures.data.length === 0) {
      getBehaviorMeasureOptions();
    }
  }, []);

  const renderDynamicFields = () => (
    <FieldArray
      name="targets"
      render={({ push: pushTarget, remove: removeTarget }: ArrayHelpers) => (
        <FieldArray
          name="practiceElements"
          render={({ push: pushPracticeElement, remove: removePracticeElement }: ArrayHelpers) => {
            const showDeleteButton = values.targets?.length > 1;
            const handleAddTarget = () => {
              pushTarget(null);
              pushPracticeElement(null);
            };

            const handleRemoveTarget = (index: number) => {
              removeTarget(index);
              removePracticeElement(index);
            };

            return (
              <>
                {Array.from(
                  { length: Math.max(values.targets?.length || 1, values.practiceElements?.length || 1) },
                  (_, index) => (
                    <Grid
                      key={`target-and-practice-${index}`}
                      container
                      item
                      spacing={3}
                      xs={12}
                      lg={12}
                      justifyContent="center"
                      className={`${clsPrefix}-dynamic-group`}>
                      <Grid container item xs={showDeleteButton ? 11 : 12} spacing={2}>
                        <Grid item container xs={12} md={6}>
                          <Grid item xs={12} style={{marginBottom: '1rem'}} >
                            <Field name={`targets.${index}.id`}>
                              {({
                                field: { name, value },
                                form: { errors, setFieldValue, setFieldTouched, setFieldError, setErrors },
                                meta: { touched },
                              }: FieldProps) => {
                                return (
                                  <Autocomplete
                                    key={`targetId-${value}`}
                                    options={targets.data}
                                    getOptionLabel={(t) => getTargetTitle(t, !_isEmpty(values.formVersion))}
                                    PopperComponent={CustomPopperAutocomplete}
                                    renderInput={params => (
                                      <TextField
                                        {...params}
                                        label="Target"
                                        variant="outlined"
                                        error={touched && !!errors.targets?.[index]?.id}
                                        helperText={touched && errors.targets?.[index]?.id}
                                      />
                                    )}
                                    value={targets.data.find(t => t.targetId === value)}
                                    onChange={(_e, target: TargetAreaType) => {
                                      setFieldValue(`targets.${index}.id`, target?.targetId);
                                      setFieldValue(`targets.${index}.needDescription`, isTargetTypeOther(target));
                                    }}
                                    filterOptions={filterOptionsMatchAllKeywords<TargetAreaType>(getTargetTitle)}
                                    // disableClearable
                                    onBlur={() => setFieldTouched(name, true)}
                                  />
                                );
                              }}
                            </Field>
                          </Grid>
                        {values.targets?.[index]?.needDescription && (
                          <Grid item xs={12} className={`${clsPrefix}-dynamic-group-briefDescription`}>
                            <Field name={`targets.${index}.description`}>
                              {({ field, form, meta }: FieldProps) => {
                                return (
                                  <TextField
                                    label="Other Targeted Concern Description"
                                    variant="outlined"
                                    error={meta.touched && !!meta.error}
                                    helperText={meta.touched && meta.error ? meta.error : ' '}
                                    fullWidth
                                    onBlur={() => form.setFieldTouched(field.name, true)}
                                    inputProps={{
                                      autoComplete: 'off',
                                    }}
                                    {...field}
                                  />
                                );
                              }}
                            </Field>
                          </Grid>
                      )}
                      </Grid>
                      <Grid item container xs={12} md={6}>
                        <Grid item xs={12} style={{marginBottom: '1rem'}}>
                          <Field name={`practiceElements.${index}.id`}>
                            {({
                              field: { name, value },
                              form: { errors, setFieldValue, setFieldTouched },
                              meta: { touched },
                            }: FieldProps) => {
                              return (
                              <Autocomplete
                                key={`practiceId-${value}`}
                                options={filterDisabledPracticeOptions(practices.data)}
                                getOptionLabel={(p) => getPracticeElementTitle(p, !_isEmpty(values.formVersion))}
                                PopperComponent={CustomPopperAutocomplete}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label="Practice Element"
                                    variant="outlined"
                                    error={touched && !!errors.practiceElements?.[index]?.id}
                                    helperText={touched && errors.practiceElements?.[index]?.id}
                                  />
                                )}
                                value={practices.data.find(p => p.practiceId === value)}
                                onChange={(_e, p: PracticeElementType) => {
                                  setFieldValue(`practiceElements.${index}.id`, p?.practiceId);
                                  setFieldValue(`practiceElements.${index}.needDescription`, isPracticeTypeOther(p));
                                }}
                                filterOptions={filterOptionsMatchAllKeywords<PracticeElementType>(
                                  getPracticeElementTitle
                                )}
                                onBlur={() => setFieldTouched(name, true)}
                                // disableClearable
                              />
                              );
                            }}
                          </Field>
                        </Grid>
                        {values.practiceElements?.[index]?.needDescription && (
                                  <Grid item xs={12} className={`${clsPrefix}-dynamic-group-briefDescription`}>
                                      <Field name={`practiceElements.${index}.description`}>
                                        {({ field, form, meta }: FieldProps) => {
                                          return (
                                            <TextField
                                              label="Other Practice Element Description"
                                              variant="outlined"
                                              error={meta.touched && !!meta.error}
                                              helperText={meta.touched && meta.error ? meta.error : ' '}
                                              fullWidth
                                              onBlur={() => form.setFieldTouched(field.name, true)}
                                              inputProps={{
                                                autoComplete: 'off',
                                              }}
                                              {...field}
                                            />
                                          );
                                        }}
                                      </Field>
                                  </Grid>
                                )}
                      </Grid>
                    </Grid>
                        {showDeleteButton && (
                          <Grid item container xs={1} justifyContent="center">
                            <IconButton 
                              onClick={() => handleRemoveTarget(index)}
                              style={{
                                transform: isMobile ? 'translateX(25px)' : 'translateX(0px)',
                                marginTop: '3rem',
                                marginBottom: '3rem',
                              }}>
                              <FaTrash />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                  ),
                )}
                <Grid container item spacing={3} className={`${clsPrefix}-the-rest`}>
                  <Grid item xs={12} md={12}>
                    <Button
                      startIcon={<FaPlus />}
                      variant="outlined"
                      color="primary"
                      onClick={handleAddTarget}
                      disabled={values.targets?.length === MAX_TARGETS}>
                      Add Target
                    </Button>
                  </Grid>
                </Grid>
              </>
            );
          }}
        />
      )}
    />
  );

  const renderBimasFields = () => (
    <FieldArray name="progressMeasures">
      {({ push: pushProgress, remove: removeProgress }: ArrayHelpers) => (
        <FieldArray name="behaviorMeasures">
          {({ push: pushMeasure, remove: removeMeasure }: ArrayHelpers) => (
            <FieldArray name="score">
              {({ push: pushScore, remove: removeScore }: ArrayHelpers) => {
                    const showDeleteButton = values.progressMeasures?.length > 1;

                    const handleAddBimas = () => {
                      pushProgress({ measureCategoryCode: null, measureId: null, measureOther: null, score: '' });
                      pushMeasure(null);
                      pushScore(null);
                    };

                    const handleRemoveBimas = (index: number) => {
                      removeProgress(index);
                      removeMeasure(index);
                      removeScore(index);
                    };

                    return (
                      <>
                        {Array.from(
                          {
                            length: Math.max(values?.progressMeasures?.length || 1),
                          },
                          (_, index) => (
                            <Grid
                              key={`behavior-measure-${index}`}
                              container
                              item
                              spacing={3}
                              alignItems="flex-end"
                              className={`${clsPrefix}-dynamic-group`}>
                              <Grid container item xs={showDeleteButton ? 11 : 12} spacing={2}>
                              <Grid item xs={12} md={6} className={`${clsPrefix}-dynamic-group-briefDescription`}>
                                    <Field name={`progressMeasures.${index}.measureCategoryCode`}>
                                      {({
                                        field: { name, value },
                                        form: { errors, setFieldValue, setFieldTouched, setFieldError, setErrors },
                                        meta: { touched },
                                      }: FieldProps) => {
                                        return (
                                          <Autocomplete
                                            key={`measureCategoryCode-${value}`}
                                            options={filterDisabledPMOptions(progressMeasures.data)}
                                            getOptionLabel={getProgressMeasureTitle}
                                            getOptionDisabled={(options) => {
                                              if (options.deletedFlag) {
                                                return true;
                                              }
                                            }}
                                            PopperComponent={CustomPopperAutocomplete}
                                            renderInput={params => (
                                              <TextField
                                                {...params}
                                                label="Progress Measure"
                                                variant="outlined"
                                                error={touched && !!errors.progressMeasures?.[index]?.measureCategoryCode}
                                              />
                                            )}
                                            value={progressMeasures.data.find(t => t.measureCategoryCode === value)}
                                            onChange={(_e, progressMeasures: BehaviorCategoryType) => {
                                              setFieldValue(`progressMeasures.${index}.measureCategoryCode`, progressMeasures?.measureCategoryCode);
                                            }}
                                            filterOptions={filterOptionsMatchAllKeywords<BehaviorCategoryType>(
                                              getProgressMeasureTitle,
                                            )}
                                            onBlur={() => setFieldTouched(name, true)}
                                          />
                                        );
                                      }}
                                    </Field>
                                  </Grid>
                                {values.progressMeasures?.[index]?.measureCategoryCode === 'other' && (
                                  <Grid item xs={12} md={6} className={`${clsPrefix}-dynamic-group-briefDescription`}>
                                      <Field name={`progressMeasures.${index}.measureOther`}>
                                        {({ field, form, meta }: FieldProps) => {
                                          return (
                                            <TextField
                                              label="Measure Description"
                                              variant="outlined"
                                              error={meta.touched && !!meta.error}
                                              helperText={meta.touched && meta.error ? meta.error : ' '}
                                              fullWidth
                                              disabled={!values.progressMeasures?.[index]?.measureCategoryCode }
                                              onBlur={() => form.setFieldTouched(field.name, true)}
                                              inputProps={{
                                                autoComplete: 'off',
                                              }}
                                              {...field}
                                            />
                                          );
                                        }}
                                      </Field>
                                  </Grid>
                                )}
                                  {values.progressMeasures?.[index]?.measureCategoryCode !== 'other' && (
                                  <Grid item xs={12} md={6} className={`${clsPrefix}-dynamic-group-briefDescription`}>
                                  <Field name={`progressMeasures.${index}.measureId`}>
                                    {({
                                      field: { name, value },
                                      form: { errors, setFieldValue, setFieldTouched },
                                      meta: { touched },
                                    }: FieldProps) => (
                                      <Autocomplete
                                        key={`measureId-${value}`}
                                        options={filterDisabledBMOptions(behaviorMeasures.data, values.progressMeasures?.[index]?.measureCategoryCode)}
                                        getOptionLabel={getBehaviorMeasureTitle}
                                        PopperComponent={CustomPopperAutocomplete}
                                        renderInput={params => (
                                          <TextField
                                            {...params}
                                            label="Measure Description"
                                            variant="outlined"
                                            error={touched && !!errors.progressMeasures?.[index].measureId}
                                            helperText={
                                              touched && errors.progressMeasures?.[index].measureId ? errors.progressMeasures?.[index].measureId : ' '
                                            }
                                          />
                                        )}
                                        value={behaviorMeasures.data.find(t => t.measureId === value)}
                                        onChange={(_e, progressMeasures: BehaviorMeasureType) => {
                                          setFieldValue(`progressMeasures.${index}.measureId`, progressMeasures?.measureId);
                                        }}
                                        onBlur={() => setFieldTouched(name, true)}
                                        disabled={!values.progressMeasures?.[index]?.measureCategoryCode}
                                        disableClearable
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                )}
                              </Grid>
                              {showDeleteButton && (
                                <Grid item container xs={1} justifyContent="flex-end">
                                  <IconButton
                                    onClick={() => { 
                                      handleRemoveBimas(index);
                                      }
                                    }
                                    style={{
                                      transform: isMobile ? 'translateX(25px)' : 'translateX(0px)',
                                    }}>
                                    <FaTrash />
                                  </IconButton>
                                </Grid>
                              )}
                            </Grid>
                          ),
                        )}
                        <Grid container item spacing={3} className={`${clsPrefix}-the-rest`}>
                          <Grid item xs={12}>
                            <Button
                              startIcon={<FaPlus />}
                              variant="outlined"
                              color="primary"
                              onClick={handleAddBimas}
                              disabled={values?.progressMeasures?.length === MAX_PROGRESS_MEASURES}>
                              Add Progress Measure
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    );
                  }}
                </FieldArray>
          )}
        </FieldArray>
      )}
    </FieldArray>
  );

  return (
    <Form className={clsPrefix}>
      <MuiDatePickerMomentUtilsProvider>
        <Grid container>
          <Grid container item spacing={3} alignItems="flex-end">
            <Grid item xs={12} sm={3}>
              <Field name="provider">
                {(fieldProps: FieldProps) => (
                  <TextField
                    label="Provider"
                    variant="outlined"
                    fullWidth
                    value={provider ? provider.firstName + ' ' + provider.lastName : ''}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Field name="credentialName">
                {(fieldProps: FieldProps) => (
                  <TextField
                    label="Provider Credentials"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    value={provider ? provider.credentialName : ''}
                  />
                )}
              </Field>
            </Grid>
            {!isMobile && (
              <>
                <Grid item xs={12} sm={6}>
                  <Field name="primaryMethodOfContact">
                    {({
                      field: { name, value },
                      form: { errors, setFieldValue, setFieldTouched },
                      meta: { touched, error },
                    }: FieldProps) => {
                      const getOptionTitle = (o: sessionOptionsSelectSLType) => o?.name || '';
                      return (
                        <Autocomplete
                          key={`primaryMethodOfContact`}
                          options={filterDisabledPMOCOptions(contact.data) || []}
                          getOptionLabel={getOptionTitle}
                          PopperComponent={CustomPopperAutocomplete}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Service Delivered"
                              variant="outlined"
                              error={touched && !!error}
                              helperText={touched && error}
                            />
                          )}
                          value={contact.data.find(t => t.value === value) || ''}
                          onChange={(_e, o: sessionOptionsSelectSLType) => {
                            setFieldValue(name, o?.value || '');
                            // clear group size, and BIMAS fields if service delivered changes to crisis or consultation
                            if(isCrisisOrConsultation(o?.value, values.sessionType)) {
                              setFieldValue('groupSize', '');
                              values.progressMeasures.forEach((_, index) => {
                                values.progressMeasures[index].measureCategoryCode = null;
                                values.progressMeasures[index].measureId = null;
                                values.progressMeasures[index].measureOther = null;
                              });
                            }
                          }}
                          filterOptions={filterOptionsMatchAllKeywords<sessionOptionsSelectSLType>(getOptionTitle)}
                          onBlur={() => setFieldTouched(name, true)}
                          // disableClearable
                        />
                      );
                    }}
                  </Field>
                </Grid>

                <Grid item container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Field disabled={true} name="sessionDate">
                      {(renderProps: FieldProps) => (
                        <MuiDatePickerFormikField
                          label="Session Date"
                          disabled={true}
                          disabledHelperText={isMobile ? true : false}
                          {...renderProps}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                  <Field name="sessionType">
                    {({
                      field: { name, value },
                      form: { errors, setFieldValue, setFieldTouched },
                      meta: { touched, error },
                    }: FieldProps) => {
                      const getOptionTitle = (o: sessionOptionsSelectSLType) => o?.name || '';
                      return (
                        <Autocomplete
                          key={`sessionType`}
                          options={filterDisabledPMOCOptions(sessionTypes.data) || []}
                          getOptionLabel={getOptionTitle}
                          PopperComponent={CustomPopperAutocomplete}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Session Type"
                              variant="outlined"
                              error={touched && !!error}
                              helperText={touched && error}
                            />
                          )}
                          value={sessionTypes.data.find(t => t.value === value) || ''}
                          onChange={(_e, o: sessionOptionsSelectSLType) => {
                            setFieldValue(name, o?.value || '');

                            // clear group size, and BIMAS fields if service delivered changes to crisis or consultation
                            if(isCrisisOrConsultation(values.primaryMethodOfContact, o?.value)) {
                              setFieldValue('groupSize', '');
                              values.progressMeasures.forEach((_, index) => {
                                values.progressMeasures[index].measureCategoryCode = null;
                                values.progressMeasures[index].measureId = null;
                                values.progressMeasures[index].measureOther = null;
                              });
                            }
                          }}
                          filterOptions={filterOptionsMatchAllKeywords<sessionOptionsSelectSLType>(getOptionTitle)}
                          onBlur={() => setFieldTouched(name, true)}
                        />
                      );
                    }}
                  </Field>
                </Grid>
                </Grid>
              </>
            )}
            {isMobile && (
              <>
                <Grid item container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Field disabled={true} name="sessionDate">
                      {(renderProps: FieldProps) => (
                        <MuiDatePickerFormikField label="Session Date" disabled={true} {...renderProps} />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="sessionType">
                    {({
                      field: { name, value },
                      form: { errors, setFieldValue, setFieldTouched },
                      meta: { touched, error },
                    }: FieldProps) => {
                      const getOptionTitle = (o: sessionOptionsSelectSLType) => o?.name || '';
                      return (
                        <Autocomplete
                          key={`sessionType`}
                          options={filterDisabledPMOCOptions(sessionTypes.data) || []}
                          getOptionLabel={getOptionTitle}
                          PopperComponent={CustomPopperAutocomplete}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Session Type"
                              variant="outlined"
                              error={touched && !!error}
                              helperText={touched && error}
                            />
                          )}
                          value={sessionTypes.data.find(t => t.value === value) || ''}
                          onChange={(_e, o: sessionOptionsSelectSLType) => {
                            setFieldValue(name, o?.value || '');
                          }}
                          filterOptions={filterOptionsMatchAllKeywords<sessionOptionsSelectSLType>(getOptionTitle)}
                          onBlur={() => setFieldTouched(name, true)}
                        />
                      );
                    }}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="primaryMethodOfContact">
                    {({
                      field: { name, value },
                      form: { errors, setFieldValue, setFieldTouched },
                      meta: { touched, error },
                    }: FieldProps) => {
                      const getOptionTitle = (o: OptionSelectSLType) => o?.name || '';
                      return (
                        <Autocomplete
                          key={`primaryMethodOfContact`}
                          options={filterDisabledPMOCOptions(contact.data) || []}
                          getOptionLabel={getOptionTitle}
                          PopperComponent={CustomPopperAutocomplete}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Service Delivered"
                              variant="outlined"
                              error={touched && !!error}
                              helperText={touched && error}
                            />
                          )}
                          value={contact.data.find(t => t.value === value) || ''}
                          onChange={(_e, o: OptionSelectSLType) => {
                            setFieldValue(name, o?.value || '');
                            if(o?.value === 'CONSUL' || o?.value === 'CRISIS') setFieldValue('groupSize', '');
                          }}
                          filterOptions={filterOptionsMatchAllKeywords<OptionSelectSLType>(getOptionTitle)}
                          onBlur={() => setFieldTouched(name, true)}
                          // disableClearable
                        />
                      );
                    }}
                  </Field>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={3}>
              <Field name="startTime">
                {(props: FieldProps) => (
                  <MuiTimePickerFormikField
                    {...props}
                    ampm={true}
                    label="Start time"
                    onChange={(time: Moment) => {
                      props.form.setFieldValue(props.field.name, time);
                      if (values.endTime) {
                        const diffTimeInMs = moment(time).diff(moment(values.endTime));
                        const duration = Math.floor(moment.duration(diffTimeInMs).asMinutes());
                        props.form.setFieldValue('sessionLength', duration ? Math.abs(duration) : '');
                      }
                    }}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Field name="endTime">
                {(props: FieldProps) => (
                  <MuiTimePickerFormikField
                    {...props}
                    ampm={true}
                    label="End time"
                    onChange={(time: Moment) => {
                      props.form.setFieldValue(props.field.name, time);
                      if (values.startTime) {
                        const diffTimeInMs = moment(time).diff(moment(values.startTime));
                        const duration = Math.floor(moment.duration(diffTimeInMs).asMinutes());
                        props.form.setFieldValue('sessionLength', duration ? Math.abs(duration) : '');
                      }
                    }}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Field name="sessionLength">
                {({ field, form, meta }: FieldProps) => {
                  return (
                    <TextField
                      {...field}
                      label="Session Length (mins)"
                      variant="outlined"
                      fullWidth
                      type="number"
                      InputLabelProps={{ shrink: field.value || field.value === 0 ? true : false }}
                      error={meta.touched && !!meta.error}
                      helperText={(meta.touched && meta.error) || ' '}
                      onChange={e => {
                        let input = e.target.value;
                        form.setFieldValue(field.name, input.replace(/[^0-9]/g, ''));
                      }}
                      onBlur={() => {
                        if (values.startTime) {
                          let newEndTime = moment(values.startTime).add(values.sessionLength, 'minutes').toISOString();
                          values.sessionLength && form.setFieldValue('endTime', newEndTime);
                        }
                        form.setFieldTouched(field.name, true);
                      }}
                    />
                  );
                }}
              </Field>
            </Grid>
            { !isCrisisOrConsult &&
            <Grid item xs={12} sm={3}>
              <Field name="groupSize">
                {({ field, form, meta }: FieldProps) => (
                  <TextField
                    {...field}
                    label="Group Size"
                    variant="outlined"
                    error={meta.touched && !!meta.error}
                    disabled={!values?.primaryMethodOfContact}
                    helperText={(meta.touched && meta.error) || ' '}
                    fullWidth
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                    type="number"
                    onChange={e => {
                      let input = e.target.value;
                      form.setFieldValue(field.name, input.replace(/[^0-9]/g, ''));
                    }}
                    onBlur={() => form.setFieldTouched(field.name, true)}
                  />
                )}
              </Field>
            </Grid>
            }
            <Grid item xs={12} sm={6}>
              <Field name="location">
                {({ field, form }: FieldProps) => (
                  <SearchDropdownBox<ServiceLocation>
                    placeholder="Location"
                    value={field.value}
                    onChange={v => {
                      form.setFieldValue('location', v);
                    }}
                    loading={searchLocations.loading}
                    options={searchLocations.data || []}
                    onSearch={searchSLLocation}
                    getOptionLabel={getLocationOptionLabel}
                    onClear={clearSearchLocation}
                    noOptionsText="No locations found"
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field name="locationDetails">
                {({ field, form, meta }: FieldProps) => (
                  <TextField
                    {...field}
                    label="Location Details"
                    variant="outlined"
                    error={meta.touched && !!meta.error}
                    helperText={(meta.touched && meta.error) || ''}
                    fullWidth
                    onBlur={() => form.setFieldTouched(field.name, true)}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={values.deliveryMode === CONSTANT_ADD_LOG.IN_PERSON ? 6 : 12} md={6}>
              <Field name="deliveryMode">
                {({
                  field: { name, value },
                  form: { errors, setFieldValue, setFieldTouched },
                  meta: { touched, error },
                }: FieldProps) => {
                  const getOptionTitle = (o: OptionSelectSLType) => o?.name || '';
                  return (
                    <Autocomplete
                      key={`deliveryMode`}
                      options={deliveryMode.data || []}
                      getOptionLabel={getOptionTitle}
                      PopperComponent={CustomPopperAutocomplete}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Delivery Mode"
                          variant="outlined"
                          error={touched && !!error}
                          helperText={touched && error}
                        />
                      )}
                      value={deliveryMode.data.find(t => t.value === value) || ''}
                      onChange={(_e, o: OptionSelectSLType) => {
                        setFieldValue(name, o?.value || '');
                      }}
                      filterOptions={filterOptionsMatchAllKeywords<OptionSelectSLType>(getOptionTitle)}
                      onBlur={() => setFieldTouched(name, true)}
                      // disableClearable
                    />
                  );
                }}
              </Field>
            </Grid>
            {values.deliveryMode === CONSTANT_ADD_LOG.IN_PERSON && (
              <Grid item xs={12} sm={6}>
                <Field name="roomName">
                  {({ field, form, meta }: FieldProps) => (
                    <TextField
                      {...field}
                      label="Classroom Number"
                      variant="outlined"
                      fullWidth
                      error={meta.touched && !!meta.error}
                      helperText={(meta.touched && meta.error) || ''}
                    />
                  )}
                </Field>
              </Grid>
            )}
          </Grid>
          {renderDynamicFields()}
          {!isCrisisOrConsult && renderBimasFields()}
          <Grid container item spacing={3} className={`${clsPrefix}-the-rest`}>
            <Grid item xs={12} md={12}>
              <Field name="comments">
                {({ field, form }: FieldProps) => (
                  <>
                    <TextField
                      label="Comments"
                      variant="outlined"
                      fullWidth
                      multiline
                      minRows={3}
                      maxRows={5}
                      {...field}
                    />
                  </>
                )}
              </Field>
            </Grid>
          </Grid>
        </Grid>
      </MuiDatePickerMomentUtilsProvider>
    </Form>
  );
};

const AddSessionLogTemplateFormik: React.FC<FormikConfig<AddSessionTemplateType> & ReturnType<typeof mapState>> = ({
  children,
  initialValues,
  provider,
  sessionDate,
  ...formProps
}) => {
  const formRef = React.useRef(null);

  return (
    <Formik<AddSessionTemplateType>
      validationSchema={AddSessionLogFormTemplateSchema}
      initialValues={{
        provider: provider,
        credentialName: provider ? provider.credentialName : '',
        primaryMethodOfContact: '',
        sessionType: '',
        sessionDate: sessionDate || '',
        startTime: '',
        endTime: '',
        sessionLength: '',
        groupSize: '',
        location: null,
        locationDetails: '',
        deliveryMode: '',
        directNonDirect: '',
        roomName: '',
        targets: [{ tpoId: '', id: '', needDescription: false, description: '', target: '' }],
        concernTreatments: [{ id: '', needDescription: false, description: '', concernTreatment: ''}],
        focusAreaAsPlan: [{ id: '', focusAreaAsPlan: ''}],
        practiceElements: [{ id: '', needDescription: false, description: '', practiceElement: '' }],
        progressMeasures: [{ id: '', measureCategoryCode: '', measure: '', measureCategory: '', measureId: null, measureOther: '', score: '' }],
        behaviorMeasures: [{ id: '', measureId: null, measure: '', measureCategoryCode: '' }],
        comments: '',
        ...initialValues,
      }}
      enableReinitialize
      innerRef={formRef}
      {...formProps}>
      {children}
    </Formik>
  );
};

const AddSessionLogFormTemplateSchema = yup.object().shape({
  provider: yup.object().required().nullable(),
  credentialName: yup.string().nullable(),
  primaryMethodOfContact: yup.string().nullable(),
  sessionType: yup.string().nullable(),
  sessionDate: yup.date().nullable().typeError(`Please enter a valid time (hh:mm  tt)`),
  startTime: yup.date().nullable().typeError(`Please enter a valid time (hh:mm  tt)`),
  endTime: yup
    .date()
    .min(yup.ref('startTime'), "End time can't be before Start time")
    .nullable()
    .typeError(`Please enter a valid time (hh:mm  tt)`),
  sessionLength: yup
    .number()
    .min(1, 'Session Length must be greater than 0.')
    .nullable()
    .typeError(`Session Length is required.`)
    .test({
      name: 'test',
      exclusive: true,
      params: { },
      message: 'Session must occur on a single day',
      test: function (value) {
          if(this.parent.startTime && value > 0){
          var length = moment().endOf('day').diff(this.parent.startTime, 'minutes')
          return value <= length
          }
          else return true;
      },
    }),
  groupSize: yup.number().nullable().min(2, 'Group Size must be greater than 1.'),
  location: yup.object().nullable(),
  locationDetails: yup.string().max(50, `Max Location Details is 50 characters`).nullable(),
  deliveryMode: yup.string().nullable(),
  directNonDirect: yup.string().nullable(),
  roomName: yup.string().max(30, `Max Classroom Number is 30 characters`).nullable(),
  targets: yup
    .array(
      yup
        .object({
          id: yup.number().nullable(),
          needDescription: yup.boolean(),
          description: yup.string().max(50, `Max Description is 50 characters`).nullable().optional(),
        })
        .nullable(),
    )
    .nullable(),
  concernTreatments: yup
    .array(
      yup
        .object({
          id: yup.number().nullable(),
          needDescription: yup.boolean(),
          description: yup.string().max(50, `Max Description is 50 characters`).nullable().optional(),
        })
        .nullable(),
    )
    .nullable(),
  focusAreaAsPlan: yup
    .array(
      yup
        .object({
          id: yup.number().nullable(),
        })
        .nullable(),
    )
    .nullable(),
  practiceElements: yup
    .array(
      yup
        .object({
          id: yup.number().nullable(),
          needDescription: yup.boolean(),
          description: yup.string().max(50, `Max Description is 50 characters`).nullable().optional(),
        })
      .nullable(),
  )
  .nullable(),
  progressMeasures: yup
    .array(
      yup
        .object({
          id: yup.number().nullable(),
          measureId: yup.number().nullable().optional(),
          measureOther: yup.string().max(30, 'Max Measure Description is 30 characters').nullable().optional(),
        })
        .nullable(),
    )
    .nullable().optional(),
  comments: yup.string().nullable(),
});

const mapState = (state: IRootState) => {
  const { provider, sessionDate } = state.sessionLogs.addSession;
  const { searchLocations, targets, practices, progressMeasures, behaviorMeasures, contact, sessionTypes, deliveryMode, directNonDirect } = state.sessionLogs.addSession.search;
  return {
    provider,
    sessionDate,
    searchLocations,
    targets,
    progressMeasures,
    behaviorMeasures,
    practices,
    contact,
    sessionTypes,
    deliveryMode,
    directNonDirect
  };
};

const mapDispatch = {
  searchSLLocation: searchLocationAddSLAsync.request,
  clearSearchLocation: searchLocationAddSLAsync.cancel,
  getTargetOptions: getTargetOptionsSLAsync.request,
  getBehaviorCategoryOptions: getBehaviorCategoryOptionsSLAsync.request,
  getBehaviorMeasureOptions: getBehaviorMeasureOptionsSLAsync.request,
  getPracticeOptions: getPracticeElementOptionsSLAsync.request,
  getContactOptions: getContactTypesOptionsSLAsync.request,
  getSessionTypeOptions: getSessionTypesOptionsSLAsync.request,
  getDeliveryModeOptions: getDeliveryTypesOptionsSLAsync.request,
  getDirectNonDirectOptions: getDirectNonDirectOptionsSLAsync.request,
};

export const ConnectedAddSessionLogTemplateForm = connect(mapState, mapDispatch)(AddSessionLogTemplateForm);

export default connect(mapState)(AddSessionLogTemplateFormik);
