import { Button, Grid, Switch } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { connect } from 'react-redux';
import { muiResponsive } from 'src/appConfig/muiTheme';
import { Loading } from 'src/components/common';
import Dialog from 'src/components/common/Dialog';
import { UpdateUserPreferencesDefaultMenuAsync, UpdateUserPreferencesTooltipAsync } from 'src/redux/authRedux/actions';
import {
  selectDefaultMenu,
  selectUser,
  selectUserPermissions,
  selectUserPreferencesTooltip,
} from 'src/redux/authRedux/selectors';
import { PreferenceKeyValue } from 'src/redux/authRedux/types';
import { IRootState } from 'src/redux/rootReducer';
import { DefaultRoute, Toastify } from 'src/services';
import {
  checkedValue,
  convertCheckedValueToRoute,
  convertDefaultMenuToCheckedValue,
  initialState,
  preferenceOptions,
  reducer,
} from './helper';
import './styles.scss';

const clsPrefix = 'user-preferences';

const UserPreferencesDialog: React.VFC<Props> = ({
  onClose,
  stateTooltip,

  updateUserPreferenceTooltip,
  updatePreferenceDefaultRoute,
  user,
  loadingUserPreferenceTooltip,
  loadingUserPreferenceDefaultMenu,
  permissions,
}) => {
  const isMobile = useMediaQuery(muiResponsive.MOBILE);
  const stateDefaultMenu = DefaultRoute.getLocalDefaultRoute();

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [allOptionsIsFalse, setAllOptionsIsFalse] = React.useState<boolean>(false);

  const handleOnChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name: string = event.target.name;
    dispatch({ type: name });
  };

  React.useEffect(() => {
    // Check if 3 state are false at same time
    if (!state.scheduler && !state.tieredServices && !state.sessionLogs) {
      setAllOptionsIsFalse(true);
    } else {
      setAllOptionsIsFalse(false);
    }
  }, [state]);

  React.useEffect(() => {
    if (stateTooltip !== state.tooltip) {
      dispatch({ type: 'tooltip' });
    }
  }, [stateTooltip]);

  const handleOnChangeDefaultMenu = () => {
    const type = convertDefaultMenuToCheckedValue(stateDefaultMenu);
    if (!state[type]) {
      dispatch({ type: type });
    }
  };

  React.useEffect(() => {
    handleOnChangeDefaultMenu();
  }, [stateDefaultMenu]);

  const handleApplyButton = () => {
    // get default route  of user select
    const userDefaultRoute = {
      ...Object.entries(state)
        .slice(1, 4)
        .filter(([, value]) => value === true)
        .shift(),
    }[0];

    if (stateTooltip !== state.tooltip) {
      // localStorage.setItem('toolTipPreferences', state.tooltip);
      updateUserPreferenceTooltip({
        userId: user.userId,
        preferenceKey: PreferenceKeyValue.SBBH_HELP,
        value: state.tooltip === true ? 'enabled' : 'disabled',
      });
    } else if (stateDefaultMenu !== convertCheckedValueToRoute(userDefaultRoute)) {
      updatePreferenceDefaultRoute({
        userId: user.userId,
        preferenceKey: PreferenceKeyValue.SBBH_MENU_DEFAULT,
        value: convertCheckedValueToRoute(userDefaultRoute),
      });
    } else {
      Toastify.warning('There is nothing to update');
    }
  };

  return (
    <Dialog
      open={true}
      title="User Preferences"
      onClose={onClose}
      maxWidth={isMobile ? 'xs' : 'sm'}
      fullWidth
      dialogActions={
        <>
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleApplyButton}
            disabled={loadingUserPreferenceTooltip || allOptionsIsFalse}
            startIcon={
              loadingUserPreferenceTooltip || loadingUserPreferenceDefaultMenu ? (
                <Loading size="small" loadingStyle={5} className="cmp-button__loading" position="absolute" />
              ) : null
            }>
            Apply
          </Button>
        </>
      }>
      <div className={`${clsPrefix}`}>
        {preferenceOptions.map((option, index) => {
          if (
            (!permissions.isScheduler && option.label === checkedValue.SCHEDULER) ||
            (!permissions.isTS && option.label === checkedValue.TIERED_SERVICES) ||
            (!permissions.isSL && option.label === checkedValue.SESSION_LOGS)
          ) {
            return null;
          }
          return (
            <Grid container spacing={2} key={option.label}>
              <Grid item container justifyContent="space-between" alignItems="center">
                <Grid item md={11} xs={10}>
                  <p className={`${clsPrefix}__title`}>{option.title}</p>
                  <h5 className={`${clsPrefix}__subTitle`}>{option.subTitle}</h5>
                </Grid>
                <Grid item md={1} xs={2}>
                  <Switch onChange={handleOnChangeSwitch} checked={state[option.label]} name={option.label} />
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </div>
    </Dialog>
  );
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & { onClose: () => void };

const mapStateToProps = (state: IRootState) => ({
  stateTooltip: selectUserPreferencesTooltip(state),
  stateDefaultMenu: selectDefaultMenu(state),
  user: selectUser(state),
  loadingUserPreferenceTooltip: state.auth.tooltip.loading,
  loadingUserPreferenceDefaultMenu: state.auth.defaultMenu.loading,
  permissions: selectUserPermissions(state),
});

const mapDispatchToProps = {
  updateUserPreferenceTooltip: UpdateUserPreferencesTooltipAsync.request,
  updatePreferenceDefaultRoute: UpdateUserPreferencesDefaultMenuAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPreferencesDialog);
