import { ApiResponse } from './../types.d';
export type Permissions = {
  isAdmin: boolean;
  isScheduler: boolean;
  isSL: boolean;
  isTS: boolean;
  hasTieredEditRights: boolean;
  hasTieredViewRights: boolean;
  hasFullRights: boolean;
  hasSessionEditRights: boolean;
  hasSessionViewRights: boolean;
};

export type AuthPermission = {
  id: number;
  name: string;
};

export type AgencyContact = {
  agencyId: number;
  agencyName: string;
  proxy: boolean;
  scheduler: boolean;
};

export type AgencyProvider = {
  agencyId: number;
  agencyName: string;
};

export type SelectedAgencyContact = {
  agencyId: number;
  agencyName: string;
  proxy: boolean;
  scheduler: boolean;
};

export type User = {
  userId: number;
  hUserId: number;
  username: string;
  fullName: string;
  email: string;
  position: string;
  activeFlag: boolean;
  credentialName: string;
  credentialType: string;
  hasSessionEditRights: boolean;
  hasSessionViewRights: boolean;
  hasTieredEditRights: boolean;
  hasTieredViewRights: boolean;
  isAdmin: boolean;
  isScheduler: boolean;
  selectedAgencyContact: SelectedAgencyContact | {};
  agencyContacts: AgencyContact[] | [];
  agencyProviders: AgencyProvider[] | [];
  preferences: [];
  app: {
    name: string;
    value: string;
  };
};

export type SignInPayload = {
  username: string;
  password: string;
};

export type Token = string;

export type AuthPayload = {
  user?: User | null;
  token?: string | null;
};

export type AuthResponse = ApiResponse<AuthPayload>;

export enum PreferenceKeyValue {
  SBBH_HELP = 'sbbh_help',
  SBBH_MENU_DEFAULT = 'sbbh_menu_default',
}

export enum MenuDefaultValue {
  SCHEDULER = 'scheduler',
  SESSION_LOGS = 'sessionLogs',
  TIERED_SERVICES = 'tieredService',
}

export type UserPreferencesType = {
  userId: number;
  preferenceKey: string;
  value: string;
};

export type GetUserPreferencesParams = {
  userId: number;
  preferenceKey?: string;
};
