import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route } from 'react-router-dom';

import * as workbox from './workbox';
import MainAppNavigator from './containers';
import createStore from './redux/store';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { theme } from './appConfig/muiTheme';
import './scss/styles.scss';

const { store, history } = createStore();

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <ConnectedRouter history={history}>
        <Route component={MainAppNavigator} />
      </ConnectedRouter>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
workbox.register();
