import cn from 'classnames';
import TextArea from '../TextArea';
import './styles.scss';

const MuiTextarea = ({
  value,
  onChange,
  placeholder,
  name = undefined,
  rows = 3,
  cols = 50,
  className = '',
  ...otherProps
}) => {
  const handleChange = e => onChange((e.target as any).value);

  return (
    <TextArea
      name={name}
      className={cn('cmp-mui-textarea', className)}
      rows={rows}
      cols={cols}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      {...otherProps}
    />
  );
};

export default MuiTextarea;
