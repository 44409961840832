import { MenuItem, Select, makeStyles, Theme, withStyles, createStyles, InputBase } from '@material-ui/core';
import React from 'react';
import './styles.scss';
interface PageSizeSelectProps {
  value: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, size: number) => void;
  sizes?: number[];
  className?: string;
}

const defaultSizes = [10, 25, 50, 100];

export const useStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    backgroundColor: 'white !important',
    '&:hover': {
      color: 'white !important',
      backgroundColor: 'rgba(103,58,183, 1) !important',
    },
    '&.Mui-selected': {
      color: 'white !important',
      backgroundColor: 'rgba(103,58,183, 0.8) !important',
    },
  },
}));

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontFamily: ['Roboto'].join(','),
      '&:focus': {
        borderRadius: 4,
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
      },
    },
  }),
)(InputBase);

const PageSizeSelect: React.FC<PageSizeSelectProps> = ({ value, onChange, sizes = defaultSizes, ...otherProps }) => {
  const handleChange = e => onChange(e, parseInt(e.target.value));
  const classes = useStyles();
  return (
    <div className="cmp-page-size-select" {...otherProps}>
      <p className="mr-12">Items per page</p>
      <Select variant="outlined" value={value} onChange={handleChange} input={<BootstrapInput />}>
        {sizes.map(size => (
          <MenuItem key={size} value={size} classes={{ root: classes.menuItem }}>
            {size}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default PageSizeSelect;
