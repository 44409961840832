import { SearchName } from 'src/containers/TieredServicesContainers/AddProviderAndStudentsDialog/AddStudentsDialog';
import { TieredServiceFormValue } from 'src/containers/TieredServicesContainers/Components/TieredServiceForm';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { ProviderName, SchoolName, StudentName } from '../schedulerRedux/types';
import { getProviderHUserIdType, ProviderHUserId } from '../sessionLogsRedux/types';
import { TieredServiceDetail } from './TieredServiceDetail';
import {
  EditableStudentName,
  Grade,
  Practice,
  Target,
  TieredServiceDataWithPagination,
  // TieredServiceSessionLog,
} from './types';

export type GetTieredServiceParams = {
  schoolid?: number;
  grade?: number;
  /** The providers user id. */
  providerid?: number;
  /** The students id. */
  studentid?: number;
  /** The location id. */
  locationid?: number;
  /** The current page number to retrieve. Defaults to page 1. */
  pagenumber: number;
  /** The number of items per page. Default to 50 items. */
  itemsperpage: number;
  includeinactive: boolean;
};

export const getTieredServicesAsync = createAsyncAction(
  'tieredServices/GET_TIERED_SERVICES_REQUEST',
  'tieredServices/GET_TIERED_SERVICES_SUCCESS',
  'tieredServices/GET_TIERED_SERVICES_FAILURE',
)<GetTieredServiceParams, TieredServiceDataWithPagination, Error>();

export const clearTieredServiceSearchResults = createAction('tieredServices/CLEAR_TS_SEARCH_RESULTS')();

//#region Search actions

const createSearchAsync = <TReturn extends unknown, TRequest = string>(type: string) =>
  createAsyncAction(
    `tieredServices/SEARCH_${type}_REQUEST`,
    `tieredServices/SEARCH_${type}_SUCCESS`,
    `tieredServices/SEARCH_${type}_FAILURE`,
    `tieredServices/SEARCH_${type}_CANCEL`,
  )<TRequest, TReturn, Error, void>();

export const searchProvidersAsync = createSearchAsync<ProviderName[]>('PROVIDERS');
export const searchStudentsAsync = createSearchAsync<StudentName[]>('STUDENTS');
export const searchSchoolsAsync = createSearchAsync<SchoolName[]>('SCHOOLS');

//#endregion search actions

export const editTieredService = createAction('tieredServices/EDIT')<{ reason: string; planId: number }>();
export const editHasEndTieredService =
  createAction('tieredServices/EDIT_HAS_END')<{ reason: string; planId: number }>();
export const cloneTieredService = createAction('tieredServices/CLONE')<number>();
export const endTieredService = createAction('tieredServices/END')<number>();
export const removeTieredServiceAsync = createAsyncAction(
  'tieredServices/REMOVE_TIERED_SERVICE_REQUEST',
  'tieredServices/REMOVE_TIERED_SERVICE_SUCCESS',
  'tieredServices/REMOVE_TIERED_SERVICE_CANCEL',
)<{ planId: number }, { planId: number }, Error>();

export type TieredServiceFormProgress = {
  studentId: string | number;
  formValues: TieredServiceFormValue;
  completed?: boolean;
};

export const saveEditServiceFormProgress = createAction(
  'tieredServices/edit/SAVE_FORM_PROGRESS',
)<TieredServiceFormProgress>();
export const cancelEditOrCloneService = createAction('tieredServices/edit/CANCEL_EDITING_OR_CLONE_SERVICE')();

const createGetTieredServiceByIdAction = (purpose: string) =>
  createAsyncAction(
    `tieredServices/${purpose}/GET_TIERED_SERVICE_BY_ID_REQUEST`,
    `tieredServices/${purpose}/GET_TIERED_SERVICE_BY_ID_SUCCESS`,
    `tieredServices/${purpose}/GET_TIERED_SERVICE_BY_ID_FAILURE`,
  )<number, TieredServiceDetail, Error>();

export const getTieredServiceByIdAsync = createGetTieredServiceByIdAction('detail');
export const getTieredServiceByIdForEdittingOrCloningAsync = createGetTieredServiceByIdAction('editOrClone');

// export const getTieredServiceSessionLogsAsync = createAsyncAction(
//   'tieredServices/detail/GET_TS_SESSION_LOGS_REQUEST',
//   'tieredServices/detail/GET_TS_SESSION_LOGS_SUCCESS',
//   'tieredServices/detail/GET_TS_SESSION_LOGS_FAILURE',
// )<number, TieredServiceSessionLog[], Error>();

export const clearTieredServiceDetail = createAction('tieredService/detail/CLEAR_TS_DETAIL')<void>();

// ADD TIERED SERVICE

export const getStudentsAsync = createAsyncAction(
  'tieredServices/add/SEARCH_TIERED_SERVICES_REQUEST',
  'tieredServices/add/SEARCH_TIERED_SERVICES_SUCCESS',
  'tieredServices/add/SEARCH_TIERED_SERVICES_FAILURE',
)<SearchName, StudentName[], Error>();

export const setSelectedStudents = createAction('tieredServices/add/SET_SELECTED_STUDENTS')<EditableStudentName[]>();

export const getProviderHUserTSAsync = createAsyncAction(
  `tieredServices/add/GET_PROVIDER_H_USER_ID_REQUEST`,
  `tieredServices/add/GET_PROVIDER_H_USER_ID_SUCCESS`,
  `tieredServices/add/GET_PROVIDER_H_USER_ID_FAILURE`,
)<getProviderHUserIdType, ProviderHUserId[], Error>();

export const searchSchoolsForFilterAsync = createSearchAsync<SchoolName[]>('SCHOOLS_FILTER');

export const searchProvidersToAddTSAsync = createSearchAsync<ProviderName[]>('PROVIDERS_ADD_TS');
export const setSelectedProviderToAddTS = createAction('tieredService/add/SET_SELECTED_PROVIDER')<ProviderName>();
export const searchAlternativeProvidersAsync = createSearchAsync<
  ProviderName[],
  {
    search: string;
    studentId: number;
  }
>('ALTERNATIVE_PROVIDERS');

export const searchStudentsToAddServiceAsync = createAsyncAction(
  'tieredServices/SEARCH_STUDENTS_TO_ADD_SERVICE_REQUEST',
  'tieredServices/SEARCH_STUDENTS_TO_ADD_SERVICE_SUCCESS',
  'tieredServices/SEARCH_STUDENTS_TO_ADD_SERVICE_FAILURE',
  'tieredServices/SEARCH_STUDENTS_TO_ADD_SERVICE_CANCEL',
)<
  {
    firstname: string;
    lastname: string;
    includeinactive: number;
    schoolid: number;
    pagenumber: number;
    itemsperpage: number;
    provideruserid: number;
  },
  EditableStudentName[],
  Error,
  void
>();

export const saveAddTieredServiceFormProgress = createAction(
  'tieredServices/add/SAVE_ADD_TIERED_SERVICE_PROGRESS',
)<TieredServiceFormProgress>();
export const cancelAddingService = createAction('tieredServices/add/CANCEL_ADDING_SERVICE')();
export const cancelRemainingStudentsAddingService = createAction('tieredServices/add/CANCEL_REMAINING_STUDENTS')();

export type CreateTieredServiceLogPayload = {
  studentId: number;
  hStudentId: number;
  providerUserId: number;
  hProviderUserId: number;
  altProviderUserId: number;
  hAltProviderUserId: number;
  /**<Date (YYYY-MM-DD)> */
  startDate: string;
  /**<Date (YYYY-MM-DD)> */
  endDate?: string;
  /**<Date (YYYY-MM-DD)> */
  projectedEndDate: string;
  tier: number;
  frequencyMinute: number;
  frequencyTimes: number;
  frequencyInterval: string;
  outcomeId: number;
  notes: string;
  target: {
    targetId: number;
    targetOther: string;
    practiceId: number;
    practiceOther: string;
  }[];
  createdLogId: null;
  updatedLogId: null;
  logs?: [
    {
      comment: string;
    },
  ];
};

export type UpdateTieredServiceLogPayload = {
  planId?: number;
  studentId: number;
  hStudentId: number;
  providerUserId: number;
  hProviderUserId: number;
  altProviderUserId: number;
  hAltProviderUserId: number;
  /**<Date (YYYY-MM-DD)> */
  startDate: string;
  /**<Date (YYYY-MM-DD)> */
  endDate?: string;
  /**<Date (YYYY-MM-DD)> */
  projectedEndDate: string;
  tier: number;
  frequencyMinute: number;
  frequencyTimes: number;
  frequencyInterval: string;
  outcomeId: number;
  notes: string;
  target: {
    targetId: number;
    targetOther: string;
    practiceId: number;
    practiceOther: string;
  }[];
  createdLogId: null;
  updatedLogId: null;
  logs?: [
    {
      comment: string;
    },
  ];
};

export const createTieredServiceLogsAsync = createAsyncAction(
  'tieredService/add/CREATE_TIERED_SERVICE_LOGS_SUBMIT_REQUEST',
  'tieredService/add/CREATE_TIERED_SERVICE_LOGS_SUBMIT_SUCCESS',
  'tieredService/add/CREATE_TIERED_SERVICE_LOGS_SUBMIT_FAILURE',
)<
  {
    payloads: CreateTieredServiceLogPayload[];
    final: boolean;
  },
  {
    records: TieredServiceDetail[];
    final: boolean;
  },
  Error
>();

export const updateTieredServiceLogsAsync = createAsyncAction(
  'tieredService/add/UPDATE_TIERED_SERVICE_LOGS_SUBMIT_REQUEST',
  'tieredService/add/UPDATE_TIERED_SERVICE_LOGS_SUBMIT_SUCCESS',
  'tieredService/add/UPDATE_TIERED_SERVICE_LOGS_SUBMIT_FAILURE',
)<
  UpdateTieredServiceLogPayload,
  {
    records: TieredServiceDetail[];
    final: boolean;
  },
  Error
>();

export const getTieredServiceTargetsAndPracticesAsync = createAsyncAction(
  'tieredService/add/GET_TARGETS_AND_PRACTICES_REQUEST',
  'tieredService/add/GET_TARGETS_AND_PRACTICES_SUCCESS',
  'tieredService/add/GET_TARGETS_AND_PRACTICES_FAILURE',
)<
  void,
  {
    targets: Target[];
    practices: Practice[];
  },
  Error
>();

export const getGradeTSAsync = createAsyncAction(
  'tieredService/search/GET_GRADE_REQUEST',
  'tieredService/search/GET_GRADE_SUCCESS',
  'tieredService/search/GET_GRADE_FAILURE',
  'tieredService/search/GET_GRADE_CANCEL',
)<void, Grade[], Error, void>();

export const clearSubmissionHistory = createAction('tieredService/add/CLEAR_SUBMISSION_HISTORY')<void>();
export const clearCompletedStudentTS = createAction(`tieredService/add/CLEAR_COMPLETED_STUDENT`)<{
  studentId: number;
}>();
