import { Toastify } from 'src/services';

export const SOMETHING_WENT_WRONG = 'Something went wrong!';

// eslint-disable-next-line require-yield
export function* toastifyErrorSaga(response: any, context?: string) {
  if(context.includes('GET LOG BY ID: User does not have rights to view this record')) {
    Toastify.error('Error: User does not have rights to view this record.');
  }
  else {
    !context.includes('undefined') && !context.includes('jwt') && Toastify.error(`${context ? `Error: ${context} ` : `${SOMETHING_WENT_WRONG}`}`);
  }
}
