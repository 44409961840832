import { ApiResponse } from '../types';

export enum SelectedTabValue {
  Provider = 'provider',
  Student = 'student',
}

export enum SchedulerView {
  AllService = 'All Services',
  UnAssigned = 'Unassigned',
}

export type GetServiceListByProviderParams = {
  providerId: string | number;
  schoolId: string | number;
  unassignedOnly: boolean;
  provider: ProviderName;
  school: SchoolName;
};
export type GetServiceListByStudentParams = {
  studentId: number;
  student: StudentName;
};
export type AddProviderToServiceParams = {
  serviceId: number;
  providerUserId: number;
};

export type RemoveProviderFromServiceParams = {
  providerId: number;
};

export type SearchProviderByStudentsRequest = {
  serviceId: number;
  search: string;
};

// ------- Search TextField--------
export interface ProviderName {
  userId: number;
  hUserId?: number;
  username: string;
  firstName: string;
  lastName: string;
  position: string;
  sortOrder?: number;
  agencyId?: number;
  agencyName?: string;
  credentialType?: string;
  credentialName?: string;
}

// ------- Search TextField--------
export interface ProviderNameEditClone {
  providerId: number;
  userId: number;
  hUserId?: number;
  username: string;
  firstName: string;
  lastName: string;
  position: string;
  sortOrder?: number;
  agencyId?: number;
  agencyName?: string;
  credentialType?: string;
  credentialName?: string;
}

export interface SchoolName {
  schoolId: number;
  schoolName: string;
  schoolCode: string;
}

export interface ServiceLocation {
  locationCode: string;
  locationId: number;
  locationName: string;
  sortOrder?: number;
}

export interface StudentName {
  studentId: number;
  hStudentId?: number;
  studentExtId: string;
  lastName: string;
  middleInitial: string;
  studentName?: string;
  firstName: string;
  schoolId?: number;
  schoolCode: string;
  schoolName: string;
  birthDate: string;
  gender: string;
  grade: string;
}

// ------- End Search TextField--------

// -------  Get Student Services List (By Providers) -------
export interface Service {
  serviceId: number;
  providerId?: any;
  studentId: number;
  lastName: string;
  firstName: string;
  studentExtId: string;
  grade: string;
  gender: string;
  schoolId: number;
  schoolName: string;
  schoolCode: string;
  planTypeName: string;
  serviceType: string;
  startDate: string;
  endDate: string;
  frequency: string;
  esy?: any;
  active: boolean;
  providerUserId: number;
  isProvider: boolean;
  addType: string;
  removeType: string;
}

export interface Provider {
  firstName: string;
  lastName: string;
  grade: string;
  gender: string;
  schoolId: number;
  schoolCode: string;
  schoolName: string;
  studentExtId: string;
  studentId: number;
  services: Service[];
}

// ------- End Get Student Services List (By Providers) -------

/// ------- Get Student Services List (By Student): -------
export interface Providers {
  providerId: number;
  providerUserId: number;
  position: string;
  credentialType: string;
  addType: string;
  removeType: string;
  fullName: string;
  credentialName: string;
}

export interface ServicesByStudent {
  serviceId: number;
  providerId?: number;
  studentId: number;
  lastName: string;
  firstName: string;
  studentExtId: string;
  grade: string;
  gender: string;
  schoolId: number;
  schoolName: string;
  schoolCode: string;
  planTypeName: string;
  serviceType: string;
  startDate: string;
  endDate: string;
  frequency: string;
  esy?: any;
  active: true;
  providerUserId?: number;
  providers: Providers[];
}
export type ServicesByStudentResponse = ApiResponse<ServicesByStudent[]>;

/// ------- End Get Student Services List (By Student): -------
