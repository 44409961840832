import { getPage } from 'src/utils/paginationUtils';
import { IRootState } from '../rootReducer';
import { Pagination } from '../types';

export const selectSchedulerProvidersPagination = (state: IRootState): Pagination =>
  state.schedulers.servicesByProvider.pagination;

export const selectServicesByProvider = (state: IRootState) => {
  const pagination = selectSchedulerProvidersPagination(state);
  const data = state.schedulers.servicesByProvider.data || [];
  return getPage(data, pagination);
};

export const selectServicesDataByProvider = (state: IRootState) => {
  return state.schedulers.servicesByProvider.data || [];
};

export const selectServicesByStudent = (state: IRootState) => {
  return state.schedulers.servicesByStudent.data || [];
};

export const selectProviderInfoServiceByProvider = (state: IRootState) => {
  return state.schedulers.servicesByProvider.provider || {};
};
export const selectSchoolInfoServiceByProvider = (state: IRootState) => {
  return state.schedulers.servicesByProvider.school || {};
};

export const selectStudentInfoServiceByStudent = (state: IRootState) => {
  return state.schedulers.servicesByStudent.student || {};
};

export const selectProviderName = (state: IRootState) => {
  return state.schedulers.searchProviders.selected || {};
};
export const selectSchoolName = (state: IRootState) => {
  return state.schedulers.searchSchools.selected || {};
};
export const selectStudentName = (state: IRootState) => {
  return state.schedulers.searchStudents.selected || {};
};

export const selectSelectedTab = (state: IRootState) => {
  return state.schedulers.uiState.selectedTab || '';
};

export const selectSchedulerView = (state: IRootState) => {
  return state.schedulers.uiState.schedulerView || '';
};

export const selectServiceId = (state: IRootState) => {
  return state.schedulers.servicesByStudent.serviceIdSelected || null;
};
