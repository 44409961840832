import {
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectProps,
} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import CloseIcon from '@material-ui/icons/Close';
import cn from 'classnames';
import { FieldProps } from 'formik';
import React from 'react';
import './styles.scss';

type MuiSelectProps<TData, TValue extends string | number> = {
  value: TValue | TValue[];
  onChange: (v: TValue | TValue[]) => void;
  options?: TData[];
  getOptionTitle: (o: TData) => string;
  getOptionValue: (o: TData) => TValue;
  getOptionKey?: (o: TData) => string | number;
  label: string;
  color?: 'primary' | 'secondary';
  name?: string;
  multiple?: boolean;
  emptyText?: string;
  loading?: boolean;
  disabled?: boolean;
  showClear?: boolean;
  errorMessage?: string;
} & Omit<SelectProps, 'onChange' | 'value' | 'options'>;

const MuiSelect = <TData extends unknown, TValue extends string | number = string>({
  value,
  onChange,
  options,
  getOptionTitle,
  getOptionValue,
  getOptionKey = getOptionTitle,
  label,
  color = 'primary',
  name,
  multiple = false,
  emptyText,
  loading,
  disabled,
  showClear,
  errorMessage,
  readOnly,
  ...otherProps
}: MuiSelectProps<TData, TValue>) => {
  const handleChange = e => onChange(e.target.value);

  const renderMultiselectValue = (selected: TValue[]) => {
    if (!selected?.length) {
      return <span>{emptyText}</span>;
    }

    return (
      <div className="cmp-select-multiselect-value-container">
        {selected.map(value => (
          <Chip
            className="cmp-select-multiselect-chip"
            key={value}
            label={getOptionTitle(options.find(o => getOptionValue(o) === value))}
          />
        ))}
      </div>
    );
  };

  const handleDeselectAll = () => {
    onChange(Array.isArray(value) ? [] : null);
  };

  const shouldShrinkLabelInMultiple = multiple && !(value as TValue[])?.length && !!emptyText;
  const shrink = shouldShrinkLabelInMultiple || !!value;
  return (
    <FormControl
      fullWidth
      variant="outlined"
      classes={{
        root: `cmp-select-root${!shrink ? '-no-shrink' : ''}`,
      }}>
      <InputLabel
        error={!!errorMessage}
        shrink={shrink}
        classes={{
          shrink: 'cmp-select-label-shrink',
        }}
        className={cn(shouldShrinkLabelInMultiple && 'cmp-select-label')}>
        {label}
      </InputLabel>
      <Select
        error={!!errorMessage}
        displayEmpty
        multiple={multiple}
        {...(multiple ? { renderValue: renderMultiselectValue } : undefined)}
        name={name}
        variant="outlined"
        className="cmp-select"
        value={value}
        onChange={handleChange}
        color={color}
        fullWidth
        label={label}
        disabled={disabled || loading}
        classes={{
          icon: (readOnly && 'cmp-select-dropdown-icon-hidden') || '',
        }}
        MenuProps={{
          classes: {
            paper: 'cmp-select-menu',
          },
        }}
        endAdornment={
          (showClear && !!value) || (multiple && !!(value as TValue[])?.length) ? (
            <InputAdornment position="end" className="cmp-select-multiselect-clear-button">
              {!loading && ((showClear && !!value) || (multiple && !!(value as TValue[])?.length)) && (
                <IconButton onClick={handleDeselectAll} size="small">
                  <CloseIcon />
                </IconButton>
              )}
              {loading && <CircularProgress color={color} size={20} className="mr-2" />}
            </InputAdornment>
          ) : null
        }
        readOnly={readOnly}
        {...otherProps}>
        {options?.map(o => {
          const optionValue = getOptionValue(o);
          return (
            <MenuItem key={getOptionKey(o)} value={optionValue}>
              {multiple && <Checkbox checked={((value || []) as TValue[]).indexOf(optionValue) !== -1} />}
              {getOptionTitle(o)}
            </MenuItem>
          );
        })}
      </Select>
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

export default MuiSelect;

type MuiSelectFormikFieldProps<TData extends unknown, TValue extends string | number = string> = FieldProps &
  Omit<MuiSelectProps<TData, TValue>, 'onChange' | 'value'>;

export const MuiSelectFormikField = <TData extends unknown, TValue extends string | number = string>({
  field: { name, value },
  form: { setFieldValue },
  onOptionSelected,
  ...otherProps
}: MuiSelectFormikFieldProps<TData, TValue> & {
  onOptionSelected?: (o: TData) => void;
}) => {
  const handleChange = (v: TValue) => {
    setFieldValue(name, v);
    onOptionSelected?.(otherProps?.options?.find(o => otherProps.getOptionValue(o) === v));
  };

  React.useEffect(() => {
    handleChange(value);
  }, [value]);

  return <MuiSelect<TData, TValue> name={name} value={value || ''} onChange={handleChange} {...otherProps} />;
};
