import { Location } from 'history';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps, Switch, useHistory } from 'react-router-dom';
import { PATHS } from 'src/appConfig/paths';
import { Screen } from 'src/components/common';
import WithSideBarLayout from 'src/layout/LayoutWithSideBar';
import { exchangeTokenAsync, setToken } from 'src/redux/authRedux/actions';
import { IRootState } from 'src/redux/rootReducer';
import { DefaultRoute, Navigator, TokenService } from 'src/services';
import SchedulerContainer from './SchedulerContainers';
import SessionLogsContainers from './SessionLogsContainers';
import AddSessionLog from './SessionLogsContainers/AddSessionLog';
import EditSessionLog from './SessionLogsContainers/EditAndCloneSessionLog';
import AddSessionLogTemplate from './SessionLogsContainers/AddSessionLog/Template';
import SearchSessionLogs from './SessionLogsContainers/SearchSessionLogs';
import SubmissionHistorySessionLogs from './SessionLogsContainers/SubmissionHistory';
import LoadingContainer from './StartupContainers/LoadingContainer';
import NotFound from './StartupContainers/NotFound';
import SplashScreen from './StartupContainers/SplashScreen';
import ToastContainer from './StartupContainers/ToastContainer';
import TieredServicesContainer from './TieredServicesContainers';
import AddTieredService from './TieredServicesContainers/AddTieredService';
import EditTieredService from './TieredServicesContainers/EditAndCloneTieredService';
import SubmissionHistory from './TieredServicesContainers/SubmissionHistory';
import Signin from './UAMContainer/Signin';
import Version from './Version';

const Routing: React.FC<{ location: Location }> = ({ location }) => {
  Navigator.setTopHistory(useHistory());
  const userRootRoute = DefaultRoute.getLocalDefaultRoute();
  return (
    <Screen>
      <Switch location={location}>
        <Route
          path={PATHS.root}
          render={() => <Redirect to={userRootRoute ? `${userRootRoute}` : PATHS.signIn} />}
          exact
        />

        {/* handle defaultRoute is /schedule || /session from API response */}
        <Route path={'/schedule'} render={() => <Redirect to={PATHS.scheduler} />} />
        <Route path={'/session'} render={() => <Redirect to={PATHS.sessionLogs} />} />
        <Route path={'/null'} render={() => <Redirect to={PATHS.signIn} />} />

        <Route path={PATHS.signIn} component={Signin} />

        <AuthenticatedLayoutRoute path={PATHS.scheduler} pageRequiredAuth component={SchedulerContainer} exact />

        <AuthenticatedLayoutRoute path={PATHS.sessionLogs} pageRequiredAuth component={SessionLogsContainers} exact />
        <AuthenticatedLayoutRoute path={PATHS.searchSessionLogs} pageRequiredAuth component={SearchSessionLogs} exact />
        <AuthenticatedLayoutRoute
          path={PATHS.addSessionLogTemplate}
          defaultHideSideBar
          pageRequiredAuth
          component={AddSessionLogTemplate}
          exact
        />
        <AuthenticatedLayoutRoute
          path={PATHS.addSessionLog}
          defaultHideSideBar
          pageRequiredAuth
          component={AddSessionLog}
          exact
        />
        <AuthenticatedLayoutRoute
          path={PATHS.editSessionLog}
          component={EditSessionLog}
          pageRequiredAuth
          defaultHideSideBar
          exact
        />
        <AuthenticatedLayoutRoute
          path={PATHS.copySessionLog}
          component={EditSessionLog}
          pageRequiredAuth
          defaultHideSideBar
          exact
        />
        <AuthenticatedLayoutRoute
          path={PATHS.submissionHistorySessionLog}
          pageRequiredAuth
          component={SubmissionHistorySessionLogs}
          exact
        />

        <AuthenticatedLayoutRoute
          path={PATHS.tieredService}
          pageRequiredAuth
          component={TieredServicesContainer}
          exact
        />
        <AuthenticatedLayoutRoute
          path={PATHS.editTieredService}
          component={EditTieredService}
          pageRequiredAuth
          defaultHideSideBar
          exact
        />
        <AuthenticatedLayoutRoute
          path={PATHS.copyTieredService}
          component={EditTieredService}
          pageRequiredAuth
          defaultHideSideBar
          exact
        />
        <AuthenticatedLayoutRoute
          path={PATHS.addTieredService}
          component={AddTieredService}
          pageRequiredAuth
          defaultHideSideBar
          exact
        />
        <AuthenticatedLayoutRoute
          path={PATHS.version}
          component={Version}
          pageRequiredAuth
          defaultHideSideBar
          exact
        />
        <AuthenticatedLayoutRoute path={PATHS.submissionHistory} component={SubmissionHistory} pageRequiredAuth exact />
        <Route component={NotFound} />
      </Switch>
      <LoadingContainer />
      <ToastContainer />
    </Screen>
  );
};

export default Routing;

const CRouting: React.FC<Props> = ({
  isAuthenticated,
  user,
  token,
  checkValidToken,
  pathname,
  pageRequiredAuth,
  component,
  layout: Layout = ({ children }) => <>{children}</>,
  ...rest
}) => {
  const renderRoute = (Component: any) => (props: RouteProps) => {
    if (pathname !== PATHS.signIn) {
      if (user === null) {
        const token = TokenService.getExchangedToken();
        checkValidToken({ token });
      }
    }

    if (isAuthenticated === null) return <SplashScreen />;

    if ((isAuthenticated && pageRequiredAuth) || (!isAuthenticated && !pageRequiredAuth)) {
      // Before render component, check permission first
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }

    const redirectPath = user !== null ? PATHS.scheduler : PATHS.signIn;
    const redirectProps = {
      to: {
        pathname: redirectPath,
        state: { from: props.location },
      },
    };
    return <Redirect {...redirectProps} />;
  };

  return <Route {...rest} render={renderRoute(component)} />;
};

type CustomRouteProps = RouteProps & { pageRequiredAuth?: boolean; layout?: React.FC };
type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & CustomRouteProps;

const mapStateToProps = (state: IRootState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  token: state.auth.token,
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = {
  setToken: setToken,
  checkValidToken: exchangeTokenAsync.request,
};

const CustomRoute = connect(mapStateToProps, mapDispatchToProps)(CRouting);

const AuthenticatedLayoutRoute: React.VFC<CustomRouteProps & { defaultHideSideBar?: boolean }> = React.memo(
  ({ defaultHideSideBar, ...otherProps }) => {
    const Layout = props => <WithSideBarLayout {...props} defaultHideSideBar={defaultHideSideBar} />;
    return <CustomRoute {...otherProps} layout={Layout} />;
  },
);
