import { IRootState } from '../rootReducer';
import { GetTieredServiceParams } from './action';
import { TieredServiceDetail } from './TieredServiceDetail';

export const selectSubmissionRecordsTS = (
  state: IRootState,
): {
  records: TieredServiceDetail[];
  final: boolean;
} => {
  return state.tieredServices.addTieredService.submissions;
};

export const selectCurrentSearchParams = (state: IRootState): GetTieredServiceParams => {
  return state.tieredServices.services.currentSearchParams;
};

export const selectUpdateTSSuccess = (state: IRootState): boolean => {
  return state.tieredServices.editAndClone.updateSuccess;
};
