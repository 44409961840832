import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { muiResponsive } from 'src/appConfig/muiTheme';
import './styles.scss';

const Dialog: React.FC<
  DialogProps & {
    title: string;
    dialogActions?: React.ReactNode;
    dialogContentClasses?: DialogContentProps['classes'];
    fullScreen?: boolean;
  }
> = ({ children, title, dialogActions, onClose, dialogContentClasses, fullScreen, ...dialogProps }) => {
  const handleCloseClicked = () => onClose({}, 'backdropClick');

  const isMobile = useMediaQuery(muiResponsive.MOBILE);

  return (
    <MuiDialog onClose={onClose} {...dialogProps} fullScreen={fullScreen && isMobile}>
      <DialogTitle className="cmp-dialog-title" disableTypography>
        <h6>{title}</h6>
        <IconButton className="cmp-dialog-close-icon" onClick={handleCloseClicked}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={dialogContentClasses}>{children}</DialogContent>
      <DialogActions className="cmp-dialog-footer">{dialogActions}</DialogActions>
    </MuiDialog>
  );
};

export default Dialog;
