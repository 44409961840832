export type checkedType = {
  tooltip: boolean;
  sessionLogs: boolean;
  tieredServices: boolean;
  scheduler: boolean;
};

export enum checkedValue {
  TOOLTIP = 'tooltip',
  SESSION_LOGS = 'sessionLogs',
  TIERED_SERVICES = 'tieredServices',
  SCHEDULER = 'scheduler',
}

export enum routeValue {
  SESSION_LOGS = 'session-logs',
  TIERED_SERVICES = 'tiered-service',
  SCHEDULER = 'scheduler',
}

export const initialState: checkedType = {
  tooltip: true,
  sessionLogs: false,
  tieredServices: false,
  scheduler: false,
};

export function reducer(state: checkedType, action) {
  switch (action.type) {
    case checkedValue.TOOLTIP:
      return { ...state, tooltip: !state.tooltip };
    case checkedValue.SESSION_LOGS:
      return { ...state, sessionLogs: !state.sessionLogs, tieredServices: false, scheduler: false };
    case checkedValue.TIERED_SERVICES:
      return { ...state, tieredServices: !state.tieredServices, sessionLogs: false, scheduler: false };
    case checkedValue.SCHEDULER:
      return { ...state, scheduler: !state.scheduler, tieredServices: false, sessionLogs: false };
    case 'cancel':
      return action.payload;
    default:
      return { ...state, sessionLogs: !state.sessionLogs, tieredServices: false, scheduler: false };
  }
}

export const convertCheckedValueToRoute = (type: string) => {
  switch (type) {
    case checkedValue.TOOLTIP:
      return 'tooltip';
    case checkedValue.SESSION_LOGS:
      return 'session-logs';
    case checkedValue.TIERED_SERVICES:
      return 'tiered-service';
    case checkedValue.SCHEDULER:
      return 'scheduler';
    default:
      return 'session-logs';
  }
};

export const convertDefaultMenuToCheckedValue = (type: string) => {
  const removeSlashType = type.replace('/', '');
  switch (removeSlashType) {
    case 'tooltip':
      return checkedValue.TOOLTIP;
    case 'session-logs':
      return checkedValue.SESSION_LOGS;
    case 'tiered-service':
      return checkedValue.TIERED_SERVICES;
    case 'scheduler':
      return checkedValue.SCHEDULER;
    default:
      return checkedValue.SESSION_LOGS;
  }
};

export const preferenceOptions = [
  {
    label: checkedValue.TOOLTIP,
    title: 'Tooltips: Helpful hints throughout the app',
    subTitle: 'Click the toggle button to turn tooltips OFF and On',
  },
  {
    label: checkedValue.SESSION_LOGS,
    title: 'Session Logs',
    subTitle: 'Select this to have Session Logs page as your default view on login',
  },
  {
    label: checkedValue.TIERED_SERVICES,
    title: 'Tiered Services',
    subTitle: 'Select this to have Tiered Services page as your default view on login',
  },
  {
    label: checkedValue.SCHEDULER,
    title: 'Lōkahi Scheduler',
    subTitle: 'Select this to have Scheduler as your default view on login',
  },
];
