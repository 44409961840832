import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  FormControlLabel,
  makeStyles,
  Popover,
  Theme,
  Typography,
} from '@material-ui/core';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import React from 'react';
import { FaFilter } from 'react-icons/fa';
import { connect } from 'react-redux';
import SearchDropdownBox from 'src/components/SearchDropdownBox';
import { IRootState } from 'src/redux/rootReducer';
import { SchoolName } from 'src/redux/schedulerRedux/types';
import { searchSchoolsForFilterAsync } from 'src/redux/tieredServices/action';

export type StudentFilters = {
  schools: SchoolName;
  studentTypes: number[];
};

type Props = {
  value: StudentFilters;
  onChange: (filters: StudentFilters) => void;
} & ReturnType<typeof mapState> &
  typeof mapDispatch;

export type FilterFormValue = {
  schools: SchoolName;
  studentTypes: number[];
};

const StudentFiltersView = ({ searchSchoolState, value, onChange, searchSchools, clearSearchSchools }: Props) => {
  const [filtersPopoverAnchor, setFiltersPopoverAnchor] = React.useState<HTMLButtonElement>(null);
  const [initialFormValue, setInitialFormValue] = React.useState<FilterFormValue>();

  React.useEffect(() => {
    setInitialFormValue(value);
  }, [value]);

  const classes = useStyles();

  const onClose = () => {
    setFiltersPopoverAnchor(null);
  };

  const handleReset = () => {
    onChange({
      schools: null,
      studentTypes: [],
    });

    onClose();
  };

  const handleApply = ({ schools, studentTypes }: FilterFormValue) => {
    onChange({
      schools,
      studentTypes: studentTypes.length === 1 ? studentTypes : [],
    });
    onClose();
  };

  const removeFilter = (filter: keyof StudentFilters, id: string | number) => {
    if (filter === 'schools') {
      onChange({
        ...value,
        schools: null,
      });
    } else {
      onChange({
        ...value,
        studentTypes: value.studentTypes.filter(v => v !== id),
      });
    }
  };

  return (
    <div className={classes.container}>
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<FaFilter />}
        onClick={({ currentTarget }) => setFiltersPopoverAnchor(currentTarget)}>
        FILTER
      </Button>
      <Popover
        open={!!filtersPopoverAnchor}
        anchorEl={filtersPopoverAnchor}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <Formik<FilterFormValue> initialValues={initialFormValue} onSubmit={handleApply}>
          {(formProps: FormikProps<FilterFormValue>) => (
            <Form>
              <Card>
                <CardHeader
                  classes={{ root: classes.popoverHeader }}
                  title={<Typography component="h6">Filter By</Typography>}
                />
                <CardContent
                  classes={{
                    root: classes.popoverContent,
                  }}>
                  <Field
                    name="schools"
                    render={({ field, form }: FieldProps) => (
                      <SearchDropdownBox<SchoolName>
                        placeholder="School"
                        value={field.value}
                        onChange={v => form.setFieldValue('schools', v)}
                        getOptionLabel={o => o.schoolName}
                        getOptionValue={o => o}
                        options={searchSchoolState.data}
                        loading={searchSchoolState.loading}
                        onSearch={searchSchools}
                        onClear={clearSearchSchools}
                      />
                    )}
                  />
                  <Field
                    name="studentTypes"
                    render={(props: FieldProps) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={props.field.value?.length > 0}
                            onChange={e => props.form.setFieldValue('studentTypes', e.target.checked ? [1] : [])}
                          />
                        }
                        label="All Students (Incl Inactive)"
                      />
                    )}
                  />
                </CardContent>
                <CardActions classes={{ root: classes.popoverActions }}>
                  <Button color="secondary" onClick={handleReset}>
                    RESET
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => formProps.handleSubmit()}
                    disabled={!formProps.dirty}>
                    APPLY
                  </Button>
                </CardActions>
              </Card>
            </Form>
          )}
        </Formik>
      </Popover>
      {(!!value.schools || !!value.studentTypes?.length) && (
        <div className={classes.chipContainer}>
          {value.schools && (
            <Chip
              key={value.schools.schoolId}
              className={classes.chip}
              label={value.schools.schoolName}
              onDelete={() => removeFilter('schools', value.schools.schoolId)}
            />
          )}
          {!!value.studentTypes?.length && (
            <Chip
              className={classes.chip}
              label="All Students (Incl Inactive)"
              onDelete={() => removeFilter('studentTypes', 1)}
            />
          )}
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const isMobile = theme.breakpoints.down(600);
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      marginBottom: 16,
      [isMobile]: {
        alignItems: 'flex-start',
        flexDirection: 'column',
      },
    },
    chipContainer: {
      marginLeft: 18,
      [isMobile]: {
        marginLeft: 0,
      },
    },
    chip: {
      borderRadius: '4px !important',
      '&:not(:last-child)': {
        marginRight: 8,
      },
      marginTop: 0,
      [isMobile]: {
        marginTop: 8,
      },
    },
    popoverContent: {
      padding: 24,
      width: 350,
      '& > *:not(:last-child)': {
        marginBottom: 24,
      },
    },
    popoverHeader: {
      borderBottom: '1px solid #ebebeb',
    },
    popoverActions: {
      borderTop: '1px solid #ebebeb',
      justifyContent: 'flex-end',
      paddingRight: 24,
    },
  };
});
const mapState = (state: IRootState) => {
  return {
    searchSchoolState: state.tieredServices.addTieredService.searchSchools,
  };
};

const mapDispatch = {
  searchSchools: searchSchoolsForFilterAsync.request,
  clearSearchSchools: searchSchoolsForFilterAsync.cancel,
};

export default connect(mapState, mapDispatch)(StudentFiltersView);
