import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { connect } from 'react-redux';
import Nav from 'src/components/Nav';
import SideMenu from 'src/components/SideMenu';
import DrawerNavbar from 'src/components/SideMenu/SidebarMobile';
import { IRootState } from 'src/redux/rootReducer';
import cn from 'classnames';
import './styles.scss';
import { muiResponsive } from 'src/appConfig/muiTheme';

type Props = { defaultHideSideBar?: boolean } & ReturnType<typeof mapState>;

const WithSideBarLayout: React.FC<Props> = ({ children, defaultHideSideBar, isMenuCollapsed }) => {
  const isMobile = useMediaQuery(muiResponsive.MOBILE);
  return (
    <div className="layout-screen">
      <Nav />
      {isMobile ? <DrawerNavbar defaultHide={defaultHideSideBar} /> : <SideMenu defaultHide={defaultHideSideBar} />}
      <div className={cn('layout-content', !isMenuCollapsed && 'menu-show')}>
        <div className={cn('layout-main', isMenuCollapsed && 'menu-collapsed')}>{children}</div>
      </div>
    </div>
  );
};

const mapState = (state: IRootState) => ({
  isMenuCollapsed: state.common.collapseSidebar,
});
export default connect(mapState)(WithSideBarLayout);
