import React from 'react';

import './styles.scss';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Button, Divider, Grid, IconButton, Switch, Tooltip } from '@material-ui/core';
import { Alert, Pagination } from '@material-ui/lab';

import { EnhancedEncryption, LockOpen } from '@material-ui/icons';
import { IoPersonAddSharp, IoPersonRemoveSharp } from 'react-icons/io5';

import Empty from 'src/components/Empty';
import PageSizeSelect from 'src/components/ItemsPerPage';
import SearchDropdownBox from 'src/components/SearchDropdownBox';
import { getDateDisplay } from 'src/utils/momentUtils';
import { SchoolInfo, ServiceInfo, UserInfo } from 'src/utils/nameUtils';
import { getCorrectPagination } from 'src/utils/paginationUtils';
import { getProviderOptionLabel, getSchoolOptionLabel } from './helper';

import { connect } from 'react-redux';
import { IRootState } from 'src/redux/rootReducer';
import {
  addProviderToServiceByProviderAsync,
  getServiceListByProvidersAsync,
  removeProviderFromServiceByProviderAsync,
  searchProvidersAsync,
  searchSchoolsAsync,
  setSchedulerProviderName,
  setSchedulerProvidersPagination,
  setSchedulerSchoolName,
  setSchedulerView,
} from 'src/redux/schedulerRedux/actions';
import { selectSchedulerView, selectServicesByProvider } from 'src/redux/schedulerRedux/selectors';
import { ProviderName, SchedulerView, SchoolName } from 'src/redux/schedulerRedux/types';

import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import { muiResponsive } from 'src/appConfig/muiTheme';

const clsPrefix = 'scheduler-provider';
const toolTipPreferences = localStorage.getItem('toolTipPreferences') === 'true' ? true : false;

type SearchSchedulerProviderFormValues = {
  provider?: ProviderName;
  school?: SchoolName;
};

const formSchema = yup.object({
  provider: yup.object().nullable().required(),
  school: yup.object().nullable().required(),
});

const ProvidersList: React.FC<Props> = ({
  getServiceListByProvider,
  data,
  totalCount,
  pagination,
  setPagination,
  searchSchedulerProvider,
  searchProvider,
  clearSearchProvider,
  searchSchool,
  clearSearchSchool,
  searchSchedulerSchool,
  addProviderToService,
  removeProviderFromService,
  setProviderName,
  setSchoolName,
  schedulerView,
  setSchedulerView,
  providerOfService,
  schoolOfService,
}) => {
  const isMobile = useMediaQuery(muiResponsive.MOBILE);
  const isMediumScreen = useMediaQuery(muiResponsive.MEDIUM_SCREEN);
  const [searched, setSearched] = React.useState<boolean>(false);

  const getServiceList = (provider: ProviderName, school: SchoolName) => {
    getServiceListByProvider({
      providerId: provider?.userId,
      schoolId: school?.schoolId,
      unassignedOnly: schedulerView === SchedulerView.UnAssigned,
      provider: provider,
      school: school,
    });
  };

  React.useEffect(() => {
    if (providerOfService && schoolOfService) {
      getServiceList(providerOfService, schoolOfService);
      setSearched(true);
    }
  }, [schedulerView]);

  const handleProviderSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSchedulerView(event.target.checked ? SchedulerView.AllService : SchedulerView.UnAssigned);
  };

  const handlePageChanged = (_event, page: number) => {
    setPagination(
      getCorrectPagination(
        {
          ...pagination,
          currentPage: page,
        },
        totalCount,
      ),
    );
  };

  const handlePageSizeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pageSize = parseInt(event.target.value);
    setPagination(
      getCorrectPagination(
        {
          ...pagination,
          pageSize,
        },
        totalCount,
      ),
    );
  };

  const handleRetrieveButtonClicked = (formValues: SearchSchedulerProviderFormValues, { setStatus }) => {
    getServiceList(formValues.provider, formValues.school);
    setProviderName(formValues.provider);
    setSchoolName(formValues.school);
    setSearched(true);
  };

  const handleAddProviderButton = (serviceId?: number, providerUserId?: number) => {
    addProviderToService({ serviceId, providerUserId });
  };

  const handleRemoveProviderButton = (providerId: number) => {
    removeProviderFromService({ providerId });
  };

  return (
    <>
      <Formik<SearchSchedulerProviderFormValues>
        initialValues={{
          provider: null,
          school: null,
        }}
        onSubmit={handleRetrieveButtonClicked}
        validationSchema={formSchema}>
        {(formProps: FormikProps<SearchSchedulerProviderFormValues>) => {
          return (
            <Form>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={4}>
                  <Field
                    name="provider"
                    render={({ field, form }: FieldProps) => {
                      return (
                        <SearchDropdownBox<ProviderName>
                          {...field}
                          value={field.value}
                          onChange={v => {
                            form.setFieldValue('provider', v);
                          }}
                          loading={searchProvider.loading}
                          placeholder="Provider Name/Credentials"
                          options={searchProvider.data || []}
                          getOptionLabel={getProviderOptionLabel}
                          onSearch={searchSchedulerProvider}
                          onClear={clearSearchProvider}
                          noOptionsText="No providers found"
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Field
                    name="school"
                    render={({ field, form }: FieldProps) => (
                      <SearchDropdownBox<SchoolName>
                        {...field}
                        value={field.value}
                        onChange={v => {
                          form.setFieldValue('school', v);
                        }}
                        loading={searchSchool.loading}
                        placeholder="Student’s School"
                        options={searchSchool.data || []}
                        onSearch={searchSchedulerSchool}
                        onClear={clearSearchSchool}
                        getOptionLabel={getSchoolOptionLabel}
                        noOptionsText="No schools found"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    type="submit"
                    onClick={formProps.handleSubmit as any}
                    variant="contained"
                    color="secondary"
                    fullWidth
                    className={`search-full-height-button`}
                    disabled={!(formProps.touched && formProps.isValid && formProps.dirty)}>
                    Retrieve students
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <div className={`${clsPrefix}__body mt-32`}>
        <div className={`${clsPrefix}__body-header`}>
          <div>
            <div className="">
              {providerOfService && (
                <Tooltip title={ toolTipPreferences ? "Provider Name" : ''}>
                  <h6>{getProviderOptionLabel(providerOfService)}</h6>
                </Tooltip>
              )}
              {schoolOfService && (
                <Tooltip title={ toolTipPreferences ? "Student's School" : ''}>
                  <p>{getSchoolOptionLabel(schoolOfService)}</p>
                </Tooltip>
              )}
            </div>
            <div className={`${clsPrefix}__switch`}>
              <p
                className="ml-12"
                style={{ color: schedulerView === SchedulerView.AllService ? 'rgba(0,0,0,0.6)' : 'inherit' }}>
                {SchedulerView.UnAssigned}
              </p>
              <Tooltip title={ toolTipPreferences ? "Toggle to view All Services or only services where provider is not assigned" : ''}>
                <Switch
                  checked={schedulerView === SchedulerView.AllService}
                  onChange={handleProviderSwitchChange}
                  name={SchedulerView.UnAssigned}
                  color="primary"
                />
              </Tooltip>
              <p
                className="mr-12"
                style={{ color: schedulerView === SchedulerView.UnAssigned ? 'rgba(0,0,0,0.6)' : 'inherit' }}>
                {SchedulerView.AllService}
              </p>
            </div>
          </div>
        </div>
        {totalCount === 0 && searched && <Empty description={`No students found.`} />}
        {!!data.length && (
          <div>
            <div className={`${clsPrefix}__numberItem my-12`}>
              <p>
                {totalCount} result{totalCount > 1 ? 's' : ''}
              </p>
              <PageSizeSelect value={pagination.pageSize} onChange={handlePageSizeChanged} />
            </div>
            <Divider />
            <div className={`${clsPrefix}__services my-8`}>
              {data.map((student, indexStudent) => (
                <div className={`${clsPrefix}__serviceStudent my-8`} key={student.studentId}>
                  <Grid container spacing={1} direction="row" className={`${clsPrefix}__studentInfo`}>
                    <Grid item xs={12} sm={3}>
                      <UserInfo
                        firstName={student.firstName}
                        lastName={student.lastName}
                        studentExtId={student.studentExtId}
                        className={`${clsPrefix}__studentInfo-userInfo`}
                        component="h5"
                      />
                    </Grid>
                    <Grid item container xs={12} sm={6} spacing={1} direction="row-reverse" justifyContent="flex-end">
                      <Grid item>
                        <Tooltip title={ toolTipPreferences ? "Gender" : '' }>
                          <h5 className={`${clsPrefix}__studentInfo-gender`}>{student.gender}</h5>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Tooltip title={ toolTipPreferences ? "Grade" : '' }>
                          <h5 className={`${clsPrefix}__studentInfo-grade`}>GR: {student.grade}</h5>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <SchoolInfo
                          schoolName={student.schoolName}
                          schoolCode={student.schoolCode}
                          className={`${clsPrefix}__studentInfo-schoolInfo`}
                          component="h5"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {student.services.length === 0 ? (
                    <div className={`${clsPrefix}__service`}>
                      <Alert severity="error">No services found!</Alert>
                    </div>
                  ) : (
                    student.services.map((service, indexService) => {
                      return (
                        <Grid
                          container
                          alignItems={isMobile ? 'flex-start' : 'center'}
                          justifyContent={isMobile ? 'space-between' : 'flex-start'}
                          className={`${clsPrefix}__service`}
                          key={service.serviceId}>
                          <Grid item xs={8} sm={9} alignItems={isMobile ? 'flex-start' : 'center'}>
                            {isMobile ? (
                              <Grid item container direction="row" alignItems="center">
                                <ServiceInfo
                                  planTypeName={service.planTypeName}
                                  serviceType={service.serviceType}
                                  className={`${clsPrefix}__service-name `}
                                  component="p"
                                />
                                {isMobile &&
                                  (service.active === true ? null : (
                                    <h5 className="serviceInactive ml-16 mb-8">Inactive</h5>
                                  ))}
                              </Grid>
                            ) : (
                              <ServiceInfo
                                planTypeName={service.planTypeName}
                                serviceType={service.serviceType}
                                className={`${clsPrefix}__service-name `}
                                component="p"
                              />
                            )}
                            <Tooltip title={ toolTipPreferences ? "Projected Start/End Dates" : ''}>
                              <p className={`${clsPrefix}__service-date`}>
                                {getDateDisplay(service.startDate)} - {getDateDisplay(service.endDate)}
                              </p>
                            </Tooltip>
                            {isMobile ? (
                              <Grid item container direction="row" alignItems="center">
                                <Tooltip title={ toolTipPreferences ? "Service Frequency" : ''}>
                                  <p className={`${clsPrefix}__service-frequency`}>{service.frequency}</p>
                                </Tooltip>
                                <p className="ml-16">{service.esy === true ? '(ESY)' : null}</p>
                              </Grid>
                            ) : (
                              <>
                                <Tooltip title={ toolTipPreferences ? "Service Frequency" : ''}>
                                  <p className={`${clsPrefix}__service-frequency`}>{service.frequency}</p>
                                </Tooltip>
                                <p className="">{service.esy === true ? '(ESY)' : null}</p>
                              </>
                            )}
                            {!isMobile &&
                              (service.active === true ? null : <h5 className="serviceInactive">Inactive</h5>)}
                          </Grid>
                          <Grid item xs={4} sm={3} container spacing={isMobile ? 2 : 1} justifyContent="flex-end">
                            {service.addType === 'PROVIDER' ? (
                              isMediumScreen ? (
                                <Tooltip title={ toolTipPreferences ? "Add Provider" : ''}>
                                  <IconButton
                                    className="button__iconButton-padding-8"
                                    color="secondary"
                                    onClick={() => handleAddProviderButton(service.serviceId, service.providerUserId)}>
                                    <IoPersonAddSharp size="24" />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Button
                                  type="button"
                                  variant="contained"
                                  color="secondary"
                                  startIcon={<IoPersonAddSharp />}
                                  fullWidth
                                  className={`${clsPrefix}__service-button`}
                                  onClick={() => handleAddProviderButton(service.serviceId, service.providerUserId)}>
                                  ADD PROVIDER
                                </Button>
                              )
                            ) : service.addType === 'TEAM' ? (
                              isMediumScreen ? (
                                <Tooltip title={ toolTipPreferences ? "Add Team" : ''}>
                                  <IconButton
                                    className="button__iconButton-padding-8"
                                    color="secondary"
                                    onClick={() => handleAddProviderButton(service.serviceId, service.providerUserId)}>
                                    <EnhancedEncryption style={{ fontSize: 28 }} />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Grid item xs={12}>
                                  <Button
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<EnhancedEncryption />}
                                    fullWidth
                                    className={`${clsPrefix}__service-button button__add__team`}
                                    onClick={() => handleAddProviderButton(service.serviceId, service.providerUserId)}>
                                    ADD TEAM
                                  </Button>
                                </Grid>
                              )
                            ) : null}
                            {service.removeType === 'PROVIDER' ? (
                              isMediumScreen ? (
                                <Tooltip title={ toolTipPreferences ? "Remove Provider" : ''}>
                                  <IconButton
                                    className="button__iconButton-padding-8"
                                    onClick={() => handleRemoveProviderButton(service.providerId)}>
                                    <IoPersonRemoveSharp size="24" color="#e31b0c" />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Grid item xs={12}>
                                  <Button
                                    type="button"
                                    variant="outlined"
                                    color="secondary"
                                    className={`${clsPrefix}__service-button`}
                                    startIcon={<IoPersonRemoveSharp />}
                                    fullWidth
                                    classes={{ outlinedSecondary: 'button__outlined__danger' }}
                                    onClick={() => handleRemoveProviderButton(service.providerId)}>
                                    REMOVE PROVIDER
                                  </Button>
                                </Grid>
                              )
                            ) : service.removeType === 'TEAM' ? (
                              isMediumScreen ? (
                                <Tooltip title={ toolTipPreferences ? "Remove from Team" : ''}>
                                  <IconButton
                                    className="button__iconButton-padding-8"
                                    size="medium"
                                    onClick={() => handleRemoveProviderButton(service.providerId)}>
                                    <LockOpen style={{ fontSize: 24, color: '#e31b0c' }} />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Grid item xs={12}>
                                  <Button
                                    type="button"
                                    variant="outlined"
                                    color="secondary"
                                    className={`${clsPrefix}__service-button`}
                                    startIcon={<LockOpen />}
                                    fullWidth
                                    classes={{ outlinedSecondary: 'button__outlined__danger' }}
                                    onClick={() => handleRemoveProviderButton(service.providerId)}>
                                    REMOVE from TEAM
                                  </Button>
                                </Grid>
                              )
                            ) : null}
                          </Grid>
                        </Grid>
                      );
                    })
                  )}
                </div>
              ))}
            </div>
            <div className={`${clsPrefix}__pagination`}>
              <Pagination count={pagination.totalPages} page={pagination.currentPage} onChange={handlePageChanged} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const mapStateToProps = (state: IRootState) => ({
  totalCount: state.schedulers.servicesByProvider.data?.length || 0,
  data: selectServicesByProvider(state),
  pagination: state.schedulers.servicesByProvider.pagination,
  error: state.schedulers.servicesByProvider.error,
  searchProvider: state.schedulers.searchProviders,
  searchSchool: state.schedulers.searchSchools,
  schedulerView: selectSchedulerView(state),
  providerOfService: state.schedulers.servicesByProvider.provider,
  schoolOfService: state.schedulers.servicesByProvider.school,
});

const mapDispatchToProps = {
  getServiceListByProvider: getServiceListByProvidersAsync.request,
  setPagination: setSchedulerProvidersPagination,
  searchSchedulerProvider: searchProvidersAsync.request,
  searchSchedulerSchool: searchSchoolsAsync.request,
  addProviderToService: addProviderToServiceByProviderAsync.request,
  removeProviderFromService: removeProviderFromServiceByProviderAsync.request,
  setProviderName: setSchedulerProviderName,
  setSchoolName: setSchedulerSchoolName,
  setSchedulerView: setSchedulerView,
  clearSearchProvider: searchProvidersAsync.cancel,
  clearSearchSchool: searchSchoolsAsync.cancel,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProvidersList);
