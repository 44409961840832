import moment, { Moment } from 'moment';

export const DateFormat = 'MM/DD/YYYY';
export const DateFormatDisplay = 'MMMM DD, YYYY';
export const DateFormatDisplayShort = 'MMM DD, YYYY';

export const TimeFormat = 'HH:mm';
export const TimeFormat12 = 'hh:mm A';

const ApiDateFormat = 'YYYY-MM-DD';
const ApiTimeFormat = 'HH:mm:ss';

/**
 * Get date display
 * @param {string|date|moment} value
 * @param {string} languageCode
 */
export const getDateDisplay = (
  value?: string,
  formatter: (dateString) => string = dateString => dateString,
): string | undefined => {
  return value && formatter(moment(value).format(DateFormat));
};

/**
 * Get date display
 * @param {string|date|moment} value
 * @param {string} languageCode
 */
export const getTimeDisplay = (value: string) => {
  return moment(value).format(TimeFormat);
};

export const getTimeDislay12H = (value: Date | string) => (value ? moment(value).format(TimeFormat12) : null);

export const toApiDateOrNull = (date: string | Moment | Date) => (date ? moment(date).format(ApiDateFormat) : null);

export const toApiTimeOrNull = (time: string | Moment | Date) => (time ? moment(time).format(ApiTimeFormat) : null);
