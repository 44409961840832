import React from 'react';

import './styles.scss';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Button, Divider, Grid, IconButton, Tooltip } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { ExpandMore, RemoveCircleOutline, EnhancedEncryption, LockOpen } from '@material-ui/icons';
import { IoPersonAddSharp, IoPersonRemoveSharp } from 'react-icons/io5';

import Empty from 'src/components/Empty';
import SearchDropdownBox from 'src/components/SearchDropdownBox';
import { getDateDisplay } from 'src/utils/momentUtils';
import { SchoolInfo, ServiceInfo, UserInfo } from 'src/utils/nameUtils';
import { Accordion, AccordionDetails, AccordionSummary } from './CustomAccordion';
import { getProviderOptionLabel, getStudentOptionLabel } from './helper';

import { connect } from 'react-redux';
import { IRootState } from 'src/redux/rootReducer';
import {
  addProviderToServiceByStudentAsync,
  getStudentServicesByStudentAsync,
  removeProviderFromServiceByStudentAsync,
  searchProviderByStudentAsync,
  searchStudentsAsync,
  setSchedulerProviderName,
  setSchedulerStudentName,
  setServiceId,
} from 'src/redux/schedulerRedux/actions';
import { selectServicesByStudent } from 'src/redux/schedulerRedux/selectors';
import { ProviderName, StudentName } from 'src/redux/schedulerRedux/types';

import * as yup from 'yup';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import { muiResponsive } from 'src/appConfig/muiTheme';
const toolTipPreferences = localStorage.getItem('toolTipPreferences') === 'true' ? true : false;

type SearchSchedulerStudentFormValues = {
  student?: StudentName;
  studentInput?: string;
};

type AddProviderFormValues = {
  provider?: ProviderName;
  providerInput?: string;
};

const formSearchSchedulerStudentSchema = yup.object({
  student: yup.object().nullable().required(),
  studentInput: yup.string().nullable(),
});

const AddProviderSchema = yup.object({
  provider: yup.object().nullable().required(),
  providerInput: yup.string().nullable(),
});

const Student: React.FC<Props> = ({
  data,
  getStudentServicesByStudent,
  searchStudent,
  clearSearchStudent,
  searchSchedulerStudent,
  searchProvider,
  clearSearchProvider,
  searchSchedulerProvider,
  addProviderToService,
  removeProviderFromService,
  setProviderName,
  setStudentName,
  studentService,
  setServiceId,
}) => {
  const isMobile = useMediaQuery(muiResponsive.MOBILE);
  const [searched, setSearched] = React.useState<boolean>(false);

  const handleRetrieveButtonClicked = (formValues: SearchSchedulerStudentFormValues, { resetForm }) => {
    getStudentServicesByStudent({ studentId: formValues.student?.studentId, student: formValues.student });
    setStudentName(formValues.student);
    resetForm();
    setSearched(true);
  };

  const handleAddProviderButton = (serviceId, formValues: AddProviderFormValues, { resetForm }) => {
    addProviderToService({ serviceId, providerUserId: formValues.provider.userId });
    setProviderName(formValues.provider);
    resetForm();
  };

  const handleAddTeamButton = (serviceId?: number, providerUserId?: number) => {
    addProviderToService({ serviceId, providerUserId });
  };

  const handleRemoveProviderButton = (providerId: number) => {
    removeProviderFromService({ providerId });
    setProviderName(null);
  };

  return (
    <>
      <Formik<SearchSchedulerStudentFormValues>
        initialValues={{
          student: null,
          studentInput: null,
        }}
        onSubmit={handleRetrieveButtonClicked}
        validationSchema={formSearchSchedulerStudentSchema}>
        {(formProps: FormikProps<SearchSchedulerStudentFormValues>) => (
          <Form>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={8}>
                <Field
                  name="student"
                  render={({ field, form }: FieldProps) => (
                    <SearchDropdownBox<StudentName>
                      {...field}
                      value={field.value}
                      onChange={v => form.setFieldValue('student', v)}
                      inputValue={form.values['studentInput']}
                      onInputChange={v => form.setFieldValue('studentInput', v)}
                      loading={searchStudent.loading}
                      placeholder="Student Name"
                      options={searchStudent.data || []}
                      getOptionLabel={getStudentOptionLabel}
                      onSearch={searchSchedulerStudent}
                      onClear={clearSearchStudent}
                      noOptionsText="No students found"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Button
                  onClick={formProps.handleSubmit as any}
                  variant="contained"
                  color="secondary"
                  fullWidth
                  className={`search-full-height-button`}
                  disabled={!(formProps.isValid && formProps.dirty)}>
                  Retrieve student
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <div className="mt-32">
        {data.length === 0 && searched && <Empty description={`No student found.`} />}
        {!!data.length && (
          <>
            {studentService && (
              <div className="student__studentInfo">
                <UserInfo
                  firstName={studentService.firstName}
                  lastName={studentService.lastName}
                  studentExtId={studentService.studentExtId}
                  className=""
                  component="h6"
                />
                <div>
                  <Tooltip title={toolTipPreferences ? "Gender" : ''}>
                    <h5>{studentService.gender}</h5>
                  </Tooltip>
                  <Tooltip title={toolTipPreferences ? "Grade" : ''}>
                    <h5>GR: {studentService.grade}</h5>
                  </Tooltip>
                  <SchoolInfo
                    schoolName={studentService.schoolName}
                    schoolCode={studentService.schoolCode}
                    className=""
                    component="h5"
                  />
                </div>
              </div>
            )}
            <Divider />
            <div className="student__servicesList mt-16">
              {data.map((service, indexService) => (
                <div className="mb-16" key={service.serviceId}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Grid container spacing={1} alignItems="center" className="student__accordionSummary">
                        <Grid item xs={6} sm={5}>
                          <ServiceInfo
                            planTypeName={service.planTypeName}
                            serviceType={service.serviceType}
                            className=""
                            component="h5"
                          />
                        </Grid>
                        <Grid item xs={6} sm={5}>
                          <div className="student__serviceEsy">
                            <h5 className="">ESY: {service.esy === true ? 'Yes' : 'No'}</h5>
                            {service.active === true ? null : <h5 className="ml-16 serviceInactive">Inactive</h5>}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <Tooltip title={toolTipPreferences ? "Projected Start/End Dates" : ''} placement="bottom-start">
                            <p className="">
                              {getDateDisplay(service.startDate)} - {getDateDisplay(service.endDate)}
                            </p>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <Tooltip title={toolTipPreferences ? "Service Frequency" : ''} placement="bottom-start">
                            <p>{service.frequency}</p>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="student__accordionDetails">
                        <Formik<AddProviderFormValues>
                          initialValues={{
                            provider: null,
                            providerInput: null,
                          }}
                          onSubmit={(formValues, { resetForm }) =>
                            handleAddProviderButton(service.serviceId, formValues, { resetForm })
                          }
                          validationSchema={AddProviderSchema}>
                          {(formProps: FormikProps<AddProviderFormValues>) => (
                            <Form>
                              <Grid
                                container
                                spacing={isMobile ? 1 : 2}
                                alignItems="center"
                                justifyContent={isMobile ? 'space-between' : 'flex-start'}>
                                <Grid item xs={10} sm={6}>
                                  <Field
                                    name="provider"
                                    render={({ field, form }: FieldProps) => (
                                      <SearchDropdownBox<ProviderName>
                                        {...field}
                                        value={field.value}
                                        onChange={v => form.setFieldValue('provider', v)}
                                        inputValue={form.values['providerInput']}
                                        onInputChange={v => {
                                          form.setFieldValue('providerInput', v);
                                          setServiceId(service.serviceId);
                                        }}
                                        loading={searchProvider.loading}
                                        placeholder="Provider Name/Credentials"
                                        options={searchProvider.data || []}
                                        getOptionLabel={getProviderOptionLabel}
                                        onSearch={searchSchedulerProvider}
                                        onClear={clearSearchProvider}
                                        noOptionsText="No Providers found"
                                        size={isMobile ? 'small' : 'medium'}
                                      />
                                    )}
                                  />
                                </Grid>

                                <Grid item container xs={2} sm={3} justifyContent="flex-end">
                                  {isMobile ? (
                                    <Tooltip title={toolTipPreferences ? "Add Provider" : ''}>
                                      <IconButton
                                        className="button__iconButton-disable-padding"
                                        color="secondary"
                                        onClick={formProps.handleSubmit as any}>
                                        <IoPersonAddSharp size="32" />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <Button
                                      onClick={formProps.handleSubmit as any}
                                      variant="contained"
                                      color="secondary"
                                      fullWidth
                                      startIcon={<IoPersonAddSharp />}
                                      disabled={!formProps.isValid}
                                      className={isMobile ? '' : 'search-full-height-button'}>
                                      Add Provider
                                    </Button>
                                  )}
                                </Grid>
                              </Grid>
                            </Form>
                          )}
                        </Formik>
                        {service.providers.length === 0 ? (
                          <div className="mt-16">
                            <Alert severity="warning">No providers associated with this service</Alert>
                          </div>
                        ) : (
                          <div className="mt-16">
                            <h5>Manage Providers</h5>
                            <div className="student__providersList mt-8 ">
                              {service.providers.map((provider, indexProvider) => {
                                return (
                                  <div className="student__provider" key={provider.providerId}>
                                    <Grid container className="" justifyContent="space-between" alignItems="center">
                                      <Grid item xs={8} sm={9}>
                                        <p>
                                          {provider.fullName}{' '}
                                          {provider.credentialType &&
                                            (isMobile
                                              ? `/ ${provider.credentialType}`
                                              : `/ ${provider.credentialName}`)}
                                        </p>
                                      </Grid>
                                        <Grid
                                          item
                                          container
                                          spacing={1}
                                          xs={4}
                                          sm={3}
                                          justifyContent={isMobile ? 'flex-end' : 'space-between'}
                                          direction={isMobile ? 'row' : 'column'}>
                                          {provider.addType === "TEAM" ?
                                            isMobile ? (
                                              <Tooltip title={toolTipPreferences ? "Add Team" : ''}>
                                                <IconButton
                                                  className="button__iconButton-padding-8"
                                                  color="secondary"
                                                  onClick={() =>
                                                    handleAddTeamButton(service.serviceId, provider.providerUserId)
                                                  }>
                                                  <EnhancedEncryption style={{ fontSize: 28 }} />
                                                </IconButton>
                                              </Tooltip>
                                            ) : (
                                              <Grid item xs={12}>
                                                <Button
                                                  type="button"
                                                  variant="contained"
                                                  color="secondary"
                                                  startIcon={<EnhancedEncryption />}
                                                  fullWidth
                                                  className={``}
                                                  onClick={() =>
                                                    handleAddTeamButton(service.serviceId, provider.providerUserId)
                                                  }>
                                                  ADD TEAM
                                                </Button>
                                              </Grid>
                                            ) : ""}
                                          {provider.removeType === "PROVIDER" ?
                                            (isMobile ? (
                                              <Tooltip title={toolTipPreferences ? "Remove Provider" : ''}>
                                                <IconButton
                                                  className="button__iconButton-padding-8"
                                                  color="secondary"
                                                  onClick={() => handleRemoveProviderButton(provider.providerId)}>
                                                  <IoPersonRemoveSharp size="24" color="#e31b0c" />
                                                </IconButton>
                                              </Tooltip>
                                            ) : (
                                              <Grid item xs={12}>
                                                <Button
                                                  type="submit"
                                                  variant="outlined"
                                                  color="secondary"
                                                  startIcon={<RemoveCircleOutline />}
                                                  fullWidth
                                                  classes={{ outlinedSecondary: 'button__outlined__danger' }}
                                                  onClick={() =>
                                                    handleRemoveProviderButton(provider.providerId)
                                                  }>
                                                  Remove Provider
                                                </Button>
                                              </Grid>
                                            )) : ""}
                                          {provider.removeType === "TEAM" ?
                                            (isMobile ? (
                                              <Tooltip title={toolTipPreferences ? "Remove from Team" : ''}>
                                              <IconButton
                                                className="button__iconButton-padding-8"
                                                color="secondary"
                                                onClick={() => handleRemoveProviderButton(provider.providerId)}>
                                                <LockOpen style={{ fontSize: 28, color: '#e31b0c' }} />
                                              </IconButton>
                                            </Tooltip>
                                            ) : (
                                              <Grid item xs={12}>
                                                <Button
                                                  type="submit"
                                                  variant="outlined"
                                                  color="secondary"
                                                  startIcon={<RemoveCircleOutline />}
                                                  fullWidth
                                                  classes={{ outlinedSecondary: 'button__outlined__danger' }}
                                                  onClick={() =>
                                                    handleRemoveProviderButton(provider.providerId)
                                                  }>
                                                  REMOVE TEAM
                                                </Button>
                                              </Grid>
                                            )) : "" }
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const mapStateToProps = (state: IRootState) => ({
  data: selectServicesByStudent(state),
  searchStudent: state.schedulers.searchStudents,
  searchProvider: state.schedulers.searchProvidersByStudent,
  studentService: state.schedulers.servicesByStudent.student,
});

const mapDispatchToProps = {
  getStudentServicesByStudent: getStudentServicesByStudentAsync.request,
  searchSchedulerStudent: searchStudentsAsync.request,
  searchSchedulerProvider: searchProviderByStudentAsync.request,
  addProviderToService: addProviderToServiceByStudentAsync.request,
  removeProviderFromService: removeProviderFromServiceByStudentAsync.request,
  setProviderName: setSchedulerProviderName,
  setStudentName: setSchedulerStudentName,
  clearSearchProvider: searchProviderByStudentAsync.cancel,
  clearSearchStudent: searchStudentsAsync.cancel,
  setServiceId: setServiceId,
};

export default connect(mapStateToProps, mapDispatchToProps)(Student);
