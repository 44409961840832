/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, InputAdornment, TextField, Tooltip, useMediaQuery } from '@material-ui/core';
import { Field, Formik, FormikProps } from 'formik';
import { History } from 'history';
import React, { useRef, useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { connect } from 'react-redux';
import { IMAGES } from 'src/appConfig/images';
import { Button, Form, Image, Text } from 'src/components/common';
import { signInAsync } from 'src/redux/authRedux/actions';
import { IRootState } from 'src/redux/rootReducer';
import { DefaultRoute, TokenService } from 'src/services';
import './styles.scss';
import * as Yup from 'yup';
import { muiResponsive } from 'src/appConfig/muiTheme';
import { useKeyPress } from 'src/hooks';

type FormValue = {
  username: string;
  password: string;
};

const INTIAL: FormValue = { username: '', password: '' };

const signInFormSchema = Yup.object().shape({
  username: Yup.string().required(),
  password: Yup.string().required(),
});

const Signin: React.FC<Props> = ({
  error,
  loading,
  isSigningIn,
  onSignIn,
  loadingUserPreferenceTooltip,
  loadingUserPreferenceDefaultMenu,
}) => {
  const formRef = useRef<FormikProps<FormValue>>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const isMobile = useMediaQuery(muiResponsive.MOBILE);

  React.useEffect(() => {
    TokenService.clearToken();
    DefaultRoute.clearLocalDefaultRoute();
  }, []);

  const handleLogin = (values: FormValue) => {
    const { username, password } = values;
    onSignIn({ username, password });
  };

  React.useEffect(() => {
    formRef.current?.setErrors({
      username: error,
      password: error,
    });
  }, [error]);

  useKeyPress('Enter', () => {
    formRef.current?.handleSubmit();
  });

  return (
    <div className="ctn-uam">
      <div className="ctn-uam__header">
        <Image className="ctn-uam__logoImage mb-16" alt="logoSBBH" src={IMAGES.logoSBBH} />
        <h2 className="">Lōkahi</h2>
        <p className="">Well-being Supports and Services Application</p>
      </div>
      <div className="ctn-uam__body">
        <h3 className="mb-48">Log in</h3>

        <Formik initialValues={INTIAL} onSubmit={handleLogin} validationSchema={signInFormSchema} innerRef={formRef}>
          {({ dirty, touched, isValid, handleSubmit }) => (
            <Form onSubmit={handleSubmit} autoComplete="off" className="ctn-uam__form">
              <Field name="username">
                {({ field, meta }) => (
                  <TextField
                    label="Username"
                    variant="outlined"
                    error={meta.touched && meta.error ? true : false}
                    {...field}
                  />
                )}
              </Field>

              <Field name="password">
                {({ field, meta }) => (
                  <TextField
                    label="Password"
                    variant="outlined"
                    type={showPassword ? 'text' : 'password'}
                    error={meta.touched && meta.error ? true : false}
                    helperText={(meta.touched && meta.error) || ''}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(prevState => !prevState)}
                            edge="end">
                            {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    {...field}
                  />
                )}
              </Field>

              <Button
                type="submit"
                variant="secondary"
                className="my-48"
                isLoading={loading || isSigningIn || loadingUserPreferenceTooltip || loadingUserPreferenceDefaultMenu}
                disabled={!dirty || (touched && !isValid)}>
                LOG IN
              </Button>

              <Text size={14}>
                Forgot your password? {isMobile && <br />}Please go to{' '}
                <Tooltip title="http://help.hidoe.org/">
                  <a
                    className="cmp-nav-link"
                    href="http://help.hidoe.org/"
                    target="__blank"
                    rel="noopener noreferrer">
                    help.hidoe.org
                  </a>
                </Tooltip>
                .
              </Text>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & { history: History };

const mapStateToProps = (state: IRootState) => ({
  isSigningIn: state.auth.isSigningIn,
  loading: state.auth.loading,
  error: state.auth.error?.message || '',
  loadingUserPreferenceTooltip: state.auth.tooltip.loading,
  loadingUserPreferenceDefaultMenu: state.auth.defaultMenu.loading,
});

const mapDispatchToProps = {
  onSignIn: signInAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
