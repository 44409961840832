import reduceReducers from 'reduce-reducers';
import { AnyAction, combineReducers } from 'redux';
import { AsyncState, commonAsyncReducer } from 'src/utils/commonAsyncReducer';
import { Action, createReducer, getType } from 'typesafe-actions';
import { signOutAsync } from '../authRedux/actions';
import { Pagination } from '../types';
import {
  addProviderToServiceByProviderAsync,
  addProviderToServiceByStudentAsync,
  getServiceListByProvidersAsync,
  getStudentServicesByStudentAsync,
  removeProviderFromServiceByProviderAsync,
  removeProviderFromServiceByStudentAsync,
  searchProvidersAsync,
  searchProviderByStudentAsync,
  searchSchoolsAsync,
  searchStudentsAsync,
  setSchedulerProviderName,
  setSchedulerProvidersInitialValue,
  setSchedulerProvidersPagination,
  setSchedulerSchoolName,
  setSchedulerStudentName,
  setSchedulerStudentsInitialValue,
  setSchedulerView,
  setSelectedTab,
  setServiceId,
} from './actions';
import {
  Provider,
  ProviderName,
  SchedulerView,
  SchoolName,
  SelectedTabValue,
  ServicesByStudent,
  StudentName,
} from './types';

export type SchedulersByProviderState = AsyncState<Provider[]> & {
  pagination: Pagination;
  provider: ProviderName;
  school: SchoolName;
};
export type GetStudentServicesByStudent = AsyncState<ServicesByStudent[]> & {
  student: StudentName;
  serviceIdSelected: number;
};

export interface SchedulersState {
  servicesByProvider: SchedulersByProviderState;
  servicesByStudent: GetStudentServicesByStudent;
  searchProviders: AsyncState<ProviderName[]> & { selected: ProviderName };
  searchProvidersByStudent: AsyncState<ProviderName[]> & { selected: ProviderName };
  searchSchools: AsyncState<SchoolName[]> & { selected: SchoolName };
  searchStudents: AsyncState<StudentName[]> & { selected: StudentName };
  uiState: {
    selectedTab: SelectedTabValue;
    schedulerView: SchedulerView;
  };
}

const initialState: SchedulersState = {
  servicesByProvider: {
    data: null,
    loading: false,
    error: null,
    pagination: {
      pageSize: 10,
      totalPages: 0,
      currentPage: 1,
    },
    provider: null,
    school: null,
  },
  servicesByStudent: {
    data: null,
    loading: false,
    error: null,
    student: null,
    serviceIdSelected: null,
  },
  searchProviders: {
    data: [],
    loading: false,
    error: null,
    selected: null,
  },
  searchProvidersByStudent: {
    data: [],
    loading: false,
    error: null,
    selected: null,
  },
  searchSchools: {
    data: [],
    loading: false,
    error: null,
    selected: null,
  },
  searchStudents: {
    data: [],
    loading: false,
    error: null,
    selected: null,
  },
  uiState: {
    selectedTab: SelectedTabValue.Provider,
    schedulerView: SchedulerView.UnAssigned,
  },
};

const servicesByProviderReducer = createReducer(initialState.servicesByProvider)
  .handleAction(
    [getServiceListByProvidersAsync.success, getServiceListByProvidersAsync.failure],
    commonAsyncReducer(getServiceListByProvidersAsync),
  )
  .handleAction([getServiceListByProvidersAsync.request], (state, action) => ({
    ...state,
    loading: true,
    error: null,
    provider: action.payload.provider,
    school: action.payload.school,
  }))
  .handleAction(setSchedulerProvidersPagination, (state, action) => ({
    ...state,
    pagination: action.payload,
  }))
  .handleAction(addProviderToServiceByProviderAsync.request, (state, action) => ({
    ...state,
    loading: true,
  }))
  .handleAction(addProviderToServiceByProviderAsync.success, (state, action) => ({
    ...state,
  }))
  .handleAction(addProviderToServiceByProviderAsync.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(removeProviderFromServiceByProviderAsync.request, (state, action) => ({
    ...state,
    loading: true,
  }))
  .handleAction(removeProviderFromServiceByProviderAsync.success, (state, action) => ({
    ...state,
  }))
  .handleAction(removeProviderFromServiceByProviderAsync.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }));

const servicesByStudentReducer = createReducer(initialState.servicesByStudent)
  .handleAction(
    [getStudentServicesByStudentAsync.success, getStudentServicesByStudentAsync.failure],
    commonAsyncReducer<ServicesByStudent[], GetStudentServicesByStudent>(getStudentServicesByStudentAsync),
  )
  .handleAction([getStudentServicesByStudentAsync.request], (state, action) => ({
    ...state,
    loading: true,
    error: null,
    student: action.payload.student,
  }))
  .handleAction(setServiceId, (state, action) => ({
    ...state,
    serviceIdSelected: action.payload,
  }))
  .handleAction(addProviderToServiceByStudentAsync.request, (state, action) => ({
    ...state,
    loading: true,
  }))
  .handleAction(addProviderToServiceByStudentAsync.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(removeProviderFromServiceByStudentAsync.request, (state, action) => ({
    ...state,
    loading: true,
  }))
  .handleAction(removeProviderFromServiceByStudentAsync.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }));

const searchProvidersByStudentReducer = createReducer(initialState.searchProvidersByStudent)
  .handleAction(
    [
      searchProviderByStudentAsync.request,
      searchProviderByStudentAsync.success,
      searchProviderByStudentAsync.failure,
      searchProviderByStudentAsync.cancel,
    ],
    commonAsyncReducer(searchProviderByStudentAsync),
  )
  .handleAction(addProviderToServiceByStudentAsync.success, (state, action) => ({
    ...state,
    data: [],
  }));

const searchProvidersReducer = createReducer(initialState.searchProviders)
  .handleAction(
    [
      searchProvidersAsync.request,
      searchProvidersAsync.success,
      searchProvidersAsync.failure,
      searchProvidersAsync.cancel,
    ],
    commonAsyncReducer(searchProvidersAsync),
  )
  .handleAction([getServiceListByProvidersAsync.success], (state, action) => ({
    ...state,
    data: [],
  }))
  .handleAction(addProviderToServiceByStudentAsync.success, (state, action) => ({
    ...state,
    data: [],
  }))
  .handleAction(setSchedulerProviderName, (state, { payload }) => ({ ...state, selected: payload }));

const searchSchoolsReducer = createReducer(initialState.searchSchools)
  .handleAction(
    [searchSchoolsAsync.request, searchSchoolsAsync.success, searchSchoolsAsync.failure, searchSchoolsAsync.cancel],
    commonAsyncReducer(searchSchoolsAsync),
  )
  .handleAction(setSchedulerSchoolName, (state, { payload }) => ({ ...state, selected: payload }))
  .handleAction([getServiceListByProvidersAsync.success], (state, action) => ({
    ...state,
    data: [],
  }));

const searchStudentsReducer = createReducer(initialState.searchStudents)
  .handleAction(
    [searchStudentsAsync.request, searchStudentsAsync.success, searchStudentsAsync.failure, searchStudentsAsync.cancel],
    commonAsyncReducer(searchStudentsAsync),
  )
  .handleAction([getStudentServicesByStudentAsync.success], (state, action) => ({
    ...state,
    data: [],
  }))
  .handleAction(setSchedulerStudentName, (state, { payload }) => ({ ...state, selected: payload }));

const uiStateReducer = (state: SchedulersState['uiState'] = initialState.uiState, action: AnyAction) => {
  switch (action.type) {
    case getType(setSelectedTab):
      return {
        ...state,
        selectedTab: action.payload,
      };
    case getType(setSchedulerView):
      return {
        ...state,
        schedulerView: action.payload,
      };
    default:
      return state;
  }
};

const combinedReducers = combineReducers<SchedulersState>({
  servicesByProvider: servicesByProviderReducer,
  servicesByStudent: servicesByStudentReducer,
  searchProviders: searchProvidersReducer,
  searchProvidersByStudent: searchProvidersByStudentReducer,
  searchSchools: searchSchoolsReducer,
  searchStudents: searchStudentsReducer,
  uiState: uiStateReducer,
});

const resetStateReducer = (state: SchedulersState = initialState, action: Action) => {
  const {
    searchProviders,
    servicesByProvider,
    searchStudents,
    searchProvidersByStudent,
    servicesByStudent,
    searchSchools,
    uiState,
  } = initialState;
  switch (action.type) {
    case getType(setSchedulerProvidersInitialValue):
      return {
        ...state,
        searchProviders,
        servicesByProvider,
        searchSchools,
      };
    case getType(setSchedulerStudentsInitialValue):
      return {
        ...state,
        servicesByStudent,
        searchStudents,
        searchProvidersByStudent,
      };
    case getType(signOutAsync.success):
      return {
        ...state,
        servicesByProvider,
        servicesByStudent,
        searchProviders,
        searchProvidersByStudent,
        searchSchools,
        searchStudents,
        uiState,
      };
    case '@@router/LOCATION_CHANGE': {
      return {
        ...state,
        servicesByProvider,
        servicesByStudent,
        searchProviders,
        searchProvidersByStudent,
        searchSchools,
        searchStudents,
        uiState,
      };
    }
    default:
      return state;
  }
};

export default reduceReducers<any>(initialState, combinedReducers, resetStateReducer);
