import { ProviderName } from 'src/redux/schedulerRedux/types';
import { CreateTieredServiceLogPayload } from 'src/redux/tieredServices/action';
import { EditableStudentName } from 'src/redux/tieredServices/types';
import { toApiDateOrNull } from 'src/utils/momentUtils';
import { TieredServiceFormValue } from '../Components/TieredServiceForm';

export const formValuesToPayload = ({
  alternativeProvider,
  startDate,
  projectedEndDate,
  tier,
  frequency,
  comments,
  targets,
  practiceElements,
  outcome,
  endDate,
  providerHUserId,
}: TieredServiceFormValue): Omit<CreateTieredServiceLogPayload, 'studentId' | 'hStudentId' | 'providerUserId'> => ({
  altProviderUserId: alternativeProvider?.userId,
  hAltProviderUserId: null,
  hProviderUserId: providerHUserId,
  startDate: toApiDateOrNull(startDate),
  endDate: toApiDateOrNull(endDate),
  projectedEndDate: toApiDateOrNull(projectedEndDate),
  tier: +tier || 2,
  frequencyMinute: frequency[0],
  frequencyTimes: frequency[1],
  frequencyInterval: frequency[2].toUpperCase(),
  outcomeId: outcome,
  notes: comments,
  target: Array.from({ length: targets.length }, (_, i) => ({
    targetId: targets[i] !== null ? targets[i].targetId : null,
    targetOther: targets[i].needDescription ? targets[i].description : null,
    practiceId: practiceElements[i].id !== null ? +practiceElements[i].id : null,
    practiceOther: practiceElements[i].needDescription ? practiceElements[i].description : null,
  })),
  createdLogId: null,
  updatedLogId: null,
  logs: [
    {
      comment: '',
    },
  ],
});

export const toTieredServiceSubmitPayload = (
  studentId: number | string,
  provider: ProviderName,
  selectedStudents: EditableStudentName[],
  formValues: TieredServiceFormValue,
): CreateTieredServiceLogPayload => {
  // loosely compare studentId because studentId can be string or number
  /* eslint-disable-next-line */
  const student = selectedStudents?.find(s => s.studentId == studentId);

  return {
    studentId: student.studentId,
    hStudentId: student.hStudentId,
    providerUserId: provider.userId,
    ...formValuesToPayload(formValues),
  };
};
