import reduceReducers from 'reduce-reducers';
import { combineReducers } from 'redux';
import { AsyncState, commonAsyncReducer } from 'src/utils/commonAsyncReducer';
import { Action, AsyncActionCreatorBuilder, createReducer, getType } from 'typesafe-actions';
import { ProviderName, SchoolName, StudentName } from '../schedulerRedux/types';
import {
  clearHStudentId,
  clearSearchSessionLogs,
  clearSelectedLog,
  getLogByIdAsync,
  getLogByIdCloneAsync,
  searchLogsAsync,
  searchProvidersAsync,
  searchSchoolsAsync,
  searchStudentsAsync,
  setLicenseAttestFlagSL,
  setModeSelectedLog,
  updateSessionLogsAsync,
} from './actions';
import { SearchSessionRequestType, SearchSessionType, SessionLogDetail } from './types';

export type SearchLogState = {
  logs: AsyncState<SearchSessionType> & { totalCount: number; currentSearchParams: SearchSessionRequestType };
  selectedLog: AsyncState<SessionLogDetail> & { mode: string };
  searchProviders: AsyncState<ProviderName[]>;
  searchSchools: AsyncState<SchoolName[]>;
  searchStudents: AsyncState<StudentName[]>;
};

const defaultAsyncState = {
  loading: false,
  error: null,
};

export const initialStateSearchLog: SearchLogState = {
  logs: {
    data: null,
    totalCount: 0,
    currentSearchParams: null,
    ...defaultAsyncState,
  },
  selectedLog: {
    data: null,
    mode: null,
    ...defaultAsyncState,
  },
  searchProviders: {
    data: [],
    ...defaultAsyncState,
  },
  searchSchools: {
    data: [],
    ...defaultAsyncState,
  },
  searchStudents: {
    data: [],
    ...defaultAsyncState,
  },
};

const logsReducer = createReducer<
  AsyncState<SearchSessionType> & { totalCount: number; currentSearchParams: SearchSessionRequestType }
>(initialStateSearchLog.logs)
  .handleAction([searchLogsAsync.request], (state, action) => ({
    ...state,
    loading: true,
    currentSearchParams: action.payload,
  }))
  .handleAction([searchLogsAsync.success], (state, { payload }) => ({
    ...state,
    data: payload,
    totalCount: payload.totalCount || 0,
    loading: false,
    error: null,
  }))
  .handleAction([searchLogsAsync.failure], (state, { payload }) => ({
    ...state,
    loading: false,
    error: payload,
  }));

const selectedLogReducer = createReducer<AsyncState<SessionLogDetail> & { mode: string }>(
  initialStateSearchLog.selectedLog,
)
  .handleAction([getLogByIdAsync.request, getLogByIdAsync.success, getLogByIdAsync.failure], (state, action) => ({
    ...state,
    ...commonAsyncReducer<any, any>(getLogByIdAsync)(state.selectedLog, action),
  }))
  .handleAction([getLogByIdCloneAsync.request, getLogByIdCloneAsync.success, getLogByIdCloneAsync.failure], (state, action) => ({
    ...state,
    ...commonAsyncReducer<any, any>(getLogByIdCloneAsync)(state.selectedLog, action),
  }))
  .handleAction(setModeSelectedLog, (state, action) => ({
    ...state,
    mode: action.payload,
  }))
  .handleAction(setLicenseAttestFlagSL, (state, action) => ({
    ...state,
    licenseAttestFlag: action.payload,
  }))
  .handleAction([updateSessionLogsAsync.success], (state, action) => ({
    ...state,
    mode: null,
    ...defaultAsyncState,
  }))
  .handleAction(clearSelectedLog, (state, action) => ({
    ...state,
    data: null,
    mode: null,
    ...defaultAsyncState,
  }));

const searchReducer = (searchAsyncAction: AsyncActionCreatorBuilder<any, any, any, any>, initial) =>
  createReducer(initial).handleAction(
    [searchAsyncAction.request, searchAsyncAction.success, searchAsyncAction.failure, searchAsyncAction.cancel],
    (state, action) => ({
      ...state,
      ...commonAsyncReducer<any, any>(searchAsyncAction)(state.initial, action),
    }),
  );

const searchStudentsReducer = createReducer<AsyncState<StudentName[]>>(initialStateSearchLog.searchStudents)
  .handleAction(
    [searchStudentsAsync.request, searchStudentsAsync.success, searchStudentsAsync.failure, searchStudentsAsync.cancel],
    (state, action) => ({
      ...state,
      ...commonAsyncReducer<any, any>(searchStudentsAsync)(state, action),
    }),
  )
  .handleAction(clearHStudentId, (state, action) => ({
    data: [],
    ...defaultAsyncState,
  }));

const combinedReducers = combineReducers<SearchLogState>({
  logs: logsReducer,
  selectedLog: selectedLogReducer,
  searchProviders: searchReducer(searchProvidersAsync, initialStateSearchLog.searchProviders),
  searchSchools: searchReducer(searchSchoolsAsync, initialStateSearchLog.searchSchools),
  searchStudents: searchStudentsReducer,
});

const resetStateReducer = (state: SearchLogState = initialStateSearchLog, action: Action) => {
  const { logs, searchProviders, searchSchools, searchStudents } = initialStateSearchLog;
  switch (action.type) {
    case getType(clearSearchSessionLogs):
      return {
        ...state,
        logs,
        searchProviders,
        searchSchools,
        searchStudents,
      };

    default:
      return state;
  }
};

export default reduceReducers<any>(initialStateSearchLog, combinedReducers, resetStateReducer);
