export const PATHS = {
  root: '/',
  dev: '/dev',
  signIn: '/sign-in',
  home: '/home',
  sessionLogs: '/session-logs',
  searchSessionLogs: '/session-logs/search',
  addSessionLog: '/session-logs/add',
  addSessionLogTemplate: '/session-logs/add/template',
  editSessionLog: '/session-logs/edit',
  copySessionLog: '/session-logs/copy',
  submissionHistorySessionLog: '/session-logs/submission-history',
  scheduler: '/scheduler',
  tieredService: '/tiered-service',
  editTieredService: '/tiered-service/edit',
  copyTieredService: '/tiered-service/copy',
  addTieredService: '/tiered-service/add',
  submissionHistory: '/tiered-service/submission-history',
  version: '/version',
};
