import { Pagination as MuiPagination, PaginationProps } from '@material-ui/lab';
import cn from 'classnames';

import './styles.scss';

const Pagination: React.VFC<PaginationProps> = ({ count, page, onChange, className, size }) => {
  return (
    <div className={cn('cmp-pagination', className)}>
      <MuiPagination count={count} page={page} onChange={onChange} size={size} />
    </div>
  );
};

export default Pagination;
