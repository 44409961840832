import { createStyles, makeStyles, Theme } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';

type Props = {
  label: string;
  color?: 'primary' | 'secondary';
  focus?: boolean;
};

const MuiFormControlContainer: React.FC<Props> = ({ label, color = 'primary', children, focus }) => {
  const classes = useStyles({
    color,
  });

  return (
    <div className={cn(classes.container, focus && classes.containerFocus)}>
      <span className={classes.label}>{label}</span>
      {children}
    </div>
  );
};

export default MuiFormControlContainer;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      position: 'relative',
      minHeight: 56,
      marginTop: 14,
      paddingTop: 14,
      paddingBottom: 10,
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: 4,
      padding: '0 14px',
      '&:hover': {
        borderColor: theme.palette.grey[900],
        '& > span': {
          color: (props: any) => theme.palette[props.color], // theme.palette.primary,
        },
      },
      boxSizing: 'border-box',
    },
    containerFocus: {
      '&, &:hover': {
        borderWidth: 2,
        height: 56,
        borderColor: (props: any) => theme.palette[props.color].main,
      },

      '& > span': {
        color: (props: any) => `${theme.palette[props.color].main} !important`,
      },
    },
    label: {
      ...theme.typography.caption,
      position: 'absolute',
      top: -13,
      left: -2,
      backgroundColor: 'white',
      padding: '0 7px',
      fontSize: '1rem',
      color: 'rgb(0, 0, 0, 0.54)',
      transform: 'scale(0.75)',
    },
  }),
);
