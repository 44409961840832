import { Button, Checkbox, FormControlLabel, Grid, TextField, useMediaQuery } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import cn from 'classnames';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { muiResponsive } from 'src/appConfig/muiTheme';
import { PATHS } from 'src/appConfig/paths';
import CustomPopperAutocomplete from 'src/components/CustomPopperAutocomplete';
import Empty from 'src/components/Empty';
import PageContentContainer from 'src/components/PageContentContainer';
import SearchDropdownBox from 'src/components/SearchDropdownBox';
import StickyPageHeader from 'src/components/StickyPageHeader';
import { selectUserPermissions } from 'src/redux/authRedux/selectors';
import { IRootState } from 'src/redux/rootReducer';
import { ProviderName, SchoolName, StudentName } from 'src/redux/schedulerRedux/types';
import {
  clearTieredServiceSearchResults,
  getGradeTSAsync,
  getTieredServicesAsync,
  searchProvidersAsync,
  searchSchoolsAsync,
  searchStudentsAsync,
  setSelectedStudents,
} from 'src/redux/tieredServices/action';
import { Grade } from 'src/redux/tieredServices/types';
import { Pagination } from 'src/redux/types';
import { DefaultRoute, Navigator, Toastify } from 'src/services';
import { LOCAL_STORAGE_SUBMIT_CLONE } from 'src/services/submitCloneNewSL';
import { filterOptionsMatchAllKeywords } from 'src/utils';
import { getStudentInfoLabel } from 'src/utils/nameUtils';
import { getCorrectPagination } from 'src/utils/paginationUtils';
import * as yup from 'yup';
import { getProviderWithPositionLabel, getStudentOptionLabel } from '../SchedulerContainers/helper';
import AddTieredServiceDialog from './AddProviderAndStudentsDialog';
import TieredServiceList from './Components/TieredServiceList';
import './styles.scss';

type Props = ReturnType<typeof mapState> & typeof mapDispatch;
type SearchTieredServiceFormValues = {
  provider?: ProviderName;
  student?: StudentName;
  school?: SchoolName;
  grade?: number;
  includeInactive?: boolean;
};

const formSchema = yup.object({
  provider: yup.object().nullable(),
  student: yup.object().nullable(),
  school: yup.object().nullable(),
  grade: yup.object().nullable(),
  includeInactive: yup.boolean(),
});

const clsPrefix = 'tiered-service';

const TieredServicePage: React.VFC<Props> = ({
  permissions,
  currentLoggedInProvider,
  data,
  totalCount,
  loading,
  loadTieredServices,
  searchProviders,
  clearSearchProvider,
  searchStudents,
  clearSearchStudents,
  searchSchools,
  clearSearchSchools,
  providers,
  students,
  schools,
  setSelectedStudents,
  isEdittingOrCloning,
  isAddingNewService,
  clearTieredServiceSearchResults,
  grades,
  getGradesOption,
}) => {
  const isMobile = useMediaQuery(muiResponsive.MOBILE);

  const [showAddTieredServiceDialog, setShowAddTieredServiceDialog] = React.useState<boolean>(false);
  const [searchFilters, setSearchFilters] = React.useState<SearchTieredServiceFormValues>({});
  const [searched, setSearched] = React.useState<boolean>(false);
  const [pagination, setPagination] = React.useState<Pagination>({
    currentPage: 1,
    pageSize: 50,
    totalPages: 0,
  });

  const formRef = React.useRef<FormikProps<SearchTieredServiceFormValues>>(null);
  const history = useHistory();
  localStorage.setItem("clickCount", '0');
  localStorage.setItem("isCompleted", "false");
  localStorage.setItem(LOCAL_STORAGE_SUBMIT_CLONE, "false");

  React.useEffect(() => {
    if (!permissions.isTS) {
      Toastify.error('You do NOT have permission to access TIERED SERVICES function.');
      Navigator.navigate(DefaultRoute.getLocalDefaultRoute());
    }
  }, []);

  React.useEffect(() => {
    setPagination(getCorrectPagination(pagination, totalCount));
  }, [totalCount]);

  React.useEffect(() => {
    if (isEmpty(grades)) {
      getGradesOption();
    }
  }, []);

  const retrieveTieredServices = (
    itemsPerPage = pagination.pageSize,
    pageNumber = pagination.currentPage,
    filters = searchFilters,
  ) => {
    const { school, student, provider, grade, includeInactive } = filters;
    loadTieredServices({
      schoolid: school?.schoolId,
      studentid: student?.studentId,
      providerid: provider?.userId,
      includeinactive: includeInactive,
      itemsperpage: itemsPerPage,
      pagenumber: pageNumber,
      grade,
    });
    setSearched(true);
  };

  const handleRetrieveButtonClicked = (formValues: SearchTieredServiceFormValues) => {
    const { provider, student, school, grade } = formValues;
    if (isEmpty(provider) && isEmpty(student) && isEmpty(school) && isEmpty(grade)) {
      return;
    } else {
      setSearchFilters(formValues);
      retrieveTieredServices(pagination.pageSize, pagination.currentPage, formValues);
    }
  };

  const handlePageSizeChange = (itemsPerPage: number) => {
    setPagination(
      getCorrectPagination(
        {
          ...pagination,
          pageSize: itemsPerPage,
        },
        totalCount,
      ),
    );
    retrieveTieredServices(itemsPerPage);
  };
  const handlePageNumberChange = (page: number) => {
    setPagination(
      getCorrectPagination(
        {
          ...pagination,
          currentPage: page,
        },
        totalCount,
      ),
    );
    retrieveTieredServices(pagination.pageSize, page);
  };

  const handleAddTieredService = () => {
    setShowAddTieredServiceDialog(true);
  };

  React.useEffect(() => {
    if (isEdittingOrCloning) {
      Navigator.navigate(PATHS.editTieredService);
    }
  }, [isEdittingOrCloning]);

  React.useEffect(() => {
    if (isAddingNewService) {
      Navigator.navigate(PATHS.addTieredService);
    }
  }, [isAddingNewService]);

  React.useEffect(() => {
    const unlisten = history.listen(({ pathname }) => {
      if (pathname !== PATHS.tieredService) {
        clearTieredServiceSearchResults();
        handleClearParameters();
      }
    });
    return unlisten;
  }, [history]);

  const handleOnCreateTieredService = (provider, students) => {
    setSelectedStudents(students);
    Navigator.navigate(PATHS.addTieredService);
  };

  const handleClearParameters = () => {
    formRef.current.resetForm();
    clearSearchProvider();
    clearSearchStudents();
    clearSearchSchools();
  };

  React.useEffect(() => {
    function onKeyup(e) {
      if (e.key === 'Enter') {
        formRef.current?.handleSubmit();
        // handleRetrieveButtonClicked(formRef.current?.values);
      }
    }
    if (!showAddTieredServiceDialog) {
      window.addEventListener('keyup', onKeyup);
    }
    return () => window.removeEventListener('keyup', onKeyup);
  }, [showAddTieredServiceDialog]);

  return (
    <>
      <PageContentContainer contentClassName={cn(`${clsPrefix}-page-container`)} loading={loading}>
        <StickyPageHeader>
          <div className={`${clsPrefix}-header-container`}>
            <h3>Tiered Services</h3>
            <Button startIcon={<FaPlus />} variant="contained" color="secondary" onClick={handleAddTieredService}>
              ADD TIERED SERVICE
            </Button>
          </div>
        </StickyPageHeader>
        <Formik<SearchTieredServiceFormValues>
          initialValues={{
            provider: currentLoggedInProvider,
            student: null,
            school: null,
            grade: null,
            includeInactive: false,
          }}
          onSubmit={handleRetrieveButtonClicked}
          validationSchema={formSchema}
          innerRef={formRef}>
          {(formProps: FormikProps<SearchTieredServiceFormValues>) => {
            const shouldDisabledRetrieveLogs = () => {
              const { provider, student, school, grade } = formProps.values;
              if (isEmpty(provider) && isEmpty(student) && isEmpty(school) && isEmpty(grade)) {
                return true;
              }
              return false;
            };

            return (
              <Form>
                <div className={`${clsPrefix}-filters`}>
                  <span>Search Tiered Services</span>
                  <Button color="secondary" onClick={handleClearParameters}>
                    CLEAR PARAMETERS
                  </Button>
                </div>
                <Grid container spacing={isMobile ? 2 : 3} alignItems="flex-end">
                  <Grid item xs={12} sm={4}>
                    <Field name="provider">
                      {({ field, form }: FieldProps) => (
                        <SearchDropdownBox<ProviderName, number>
                          {...field}
                          value={field.value}
                          onChange={v => form.setFieldValue('provider', v)}
                          loading={providers.loading}
                          placeholder="Find Provider"
                          options={providers.data || []}
                          getOptionLabel={o => getProviderWithPositionLabel(o)}
                          onSearch={searchProviders}
                          onClear={clearSearchProvider}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field name="student">
                      {({ field, form }: FieldProps) => (
                        <SearchDropdownBox<StudentName>
                          {...field}
                          value={field.value}
                          onChange={v => form.setFieldValue('student', v)}
                          loading={students.loading}
                          placeholder="Find Student"
                          options={students.data || []}
                          getOptionLabel={getStudentOptionLabel}
                          onSearch={searchStudents}
                          onClear={clearSearchStudents}
                          noOptionsText="No students found"
                          renderOptionCustom={getStudentInfoLabel}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field name="school">
                      {({ field, form }: FieldProps) => (
                        <SearchDropdownBox<SchoolName, number>
                          {...field}
                          value={field.value}
                          onChange={v => form.setFieldValue('school', v)}
                          placeholder="Find School"
                          loading={schools.loading}
                          options={schools.data || []}
                          onSearch={searchSchools}
                          getOptionLabel={o => o.schoolName}
                          onClear={clearSearchSchools}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field name="grade">
                      {({
                        field: { name, value },
                        form: { errors, setFieldValue, setFieldTouched },
                        meta: { touched, error },
                      }: FieldProps) => {
                        const getOptionTitle = (o: Grade) => o.name || '';
                        return (
                          <Autocomplete
                            key={`grade-${value}`}
                            options={grades}
                            getOptionLabel={getOptionTitle}
                            PopperComponent={CustomPopperAutocomplete}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Grade"
                                variant="outlined"
                                error={touched && !!error}
                                helperText={touched && error}
                              />
                            )}
                            value={grades.find(g => g.value === value) || ''}
                            onChange={(_e, g: Grade) => {
                              setFieldValue(`grade`, g?.value || '');
                            }}
                            filterOptions={filterOptionsMatchAllKeywords<Grade>(getOptionTitle)}
                            onBlur={() => setFieldTouched(name, true)}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field name="includeInactive">
                      {({ field, form }: FieldProps) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value}
                              onChange={(_, checked) => form.setFieldValue('includeInactive', checked)}
                              color="primary"
                            />
                          }
                          label="Include Inactive Tiered Services"
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button
                      onClick={formProps.handleSubmit as any}
                      variant="contained"
                      color="secondary"
                      fullWidth
                      className={`${clsPrefix}-filters-full-height-button`}
                      disabled={shouldDisabledRetrieveLogs()}>
                      Retrieve Tiered Services
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
        {totalCount === 0 && searched && <Empty description={`No Tiered Services found.`} />}
        {!isEmpty(data) && (
          <TieredServiceList
            className={isMobile ? 'mt-32' : 'mt-40'}
            data={data}
            pagination={pagination}
            onPageChange={handlePageNumberChange}
            onPageSizeChange={handlePageSizeChange}
            totalCount={totalCount}
          />
        )}
      </PageContentContainer>

      {showAddTieredServiceDialog && (
        <AddTieredServiceDialog
          onCreate={handleOnCreateTieredService}
          onClose={() => setShowAddTieredServiceDialog(false)}
          skipSelectProvider={!permissions.isAdmin}
        />
      )}
    </>
  );
};

const mapState = (state: IRootState) => {
  const {
    searchSchools,
    searchProviders,
    searchStudents,
    services,
    removeTieredService,
    editAndClone,
    addTieredService,
    searchGrades,
  } = state.tieredServices;
  const { userId, fullName, position, username, credentialName, credentialType } = state.auth.user;
  const currentLoggedInProvider: ProviderName = {
    userId,
    firstName: fullName.split(' ')[0],
    lastName: fullName.split(' ')[1],
    position,
    username,
    credentialName,
    credentialType,
  };
  return {
    permissions: selectUserPermissions(state),
    currentLoggedInProvider,
    data: services.data,
    totalCount: services.totalCount,
    loading: services.loading || removeTieredService.loading || searchGrades.loading,
    providers: searchProviders,
    students: searchStudents,
    schools: searchSchools,
    isEdittingOrCloning: !!editAndClone.planId,
    isAddingNewService: !!addTieredService.searchStudents.selected?.length,
    grades: searchGrades.data.sort((cur, next) => cur.sortOrder - next.sortOrder),
  };
};

const mapDispatch = {
  loadTieredServices: getTieredServicesAsync.request,
  searchProviders: searchProvidersAsync.request,
  clearSearchProvider: searchProvidersAsync.cancel,
  searchStudents: searchStudentsAsync.request,
  clearSearchStudents: searchStudentsAsync.cancel,
  searchSchools: searchSchoolsAsync.request,
  clearSearchSchools: searchSchoolsAsync.cancel,
  setSelectedStudents,
  clearTieredServiceSearchResults,
  getGradesOption: getGradeTSAsync.request,
};

export default connect(mapState, mapDispatch)(TieredServicePage);
