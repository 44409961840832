import cn from 'classnames';
import React from 'react';
import View from '../View';
import './styles.scss';

const Screen: React.FC = ({ children }) => {
  return <View className={cn('cmp-screen')}>{children}</View>;
};

export default Screen;
