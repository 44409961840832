import { Grid, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { muiResponsive } from 'src/appConfig/muiTheme';
import { StudentName } from 'src/redux/schedulerRedux/types';
import { getDateDisplay } from 'src/utils/momentUtils';
import './styles.scss';

const clsPrefix = 'student-info-banner';

const StudentInfoBanner: React.FC<{ student: Partial<StudentName> }> = ({ student }) => {
  const isMobile = useMediaQuery(muiResponsive.MOBILE);

  if (!student) {
    return null;
  }

  const { firstName, lastName, studentExtId, schoolName, grade, gender, birthDate } = student;

  return (
    <div className={`${clsPrefix}-background`}>
      <Grid container className={clsPrefix}>
        <Grid item xs={12} md={6} lg={4}>
          <span className={`${clsPrefix}-label`}>Student</span>
          <span className="name">
            {firstName} {lastName} ({studentExtId})
          </span>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <span className={`${clsPrefix}-label`}>School/Grade</span>
          <span>
            {schoolName} ({grade})
          </span>
          {isMobile && <span>{gender}</span>}
        </Grid>
        {!isMobile && (
          <Grid item xs={12} md={6} lg={2}>
            <span className={`${clsPrefix}-label`}>Gender</span>
            <span>{gender}</span>
          </Grid>
        )}
        <Grid item xs={12} md={6} lg={2}>
          <span className={[`${clsPrefix}-label`, 'show-mobile'].join(' ')}>Birth Date</span>
          <span>{getDateDisplay(birthDate)}</span>
        </Grid>
      </Grid>
    </div>
  );
};

export default StudentInfoBanner;
