import { CreateTieredServiceLogPayload, UpdateTieredServiceLogPayload } from 'src/redux/tieredServices/action';
import { TieredServiceDetail } from 'src/redux/tieredServices/TieredServiceDetail';
import { toApiDateOrNull } from 'src/utils/momentUtils';
import { formValuesToPayload } from '../AddTieredService/helper';
import { TieredServiceFormValue } from '../Components/TieredServiceForm';

export const formValuesEditToPayload = ({
  alternativeProvider,
  startDate,
  projectedEndDate,
  tier,
  frequency,
  comments,
  targets,
  practiceElements,
  outcome,
  endDate,
  reasonForEdit,
}: TieredServiceFormValue): Omit<
  CreateTieredServiceLogPayload,
  'studentId' | 'hStudentId' | 'providerUserId' | 'hProviderUserId'
> => ({
  altProviderUserId: alternativeProvider?.userId,
  hAltProviderUserId: null,
  startDate: toApiDateOrNull(startDate),
  endDate: toApiDateOrNull(endDate),
  projectedEndDate: toApiDateOrNull(projectedEndDate),
  tier: +tier || 2,
  frequencyMinute: frequency[0],
  frequencyTimes: frequency[1],
  frequencyInterval: frequency[2].toUpperCase(),
  outcomeId: outcome,
  notes: comments,
  target: Array.from({ length: targets.length }, (_, i) => ({
    id: targets[i] !== null ? +targets[i].id : null,
    targetId: targets[i] !== null ? +targets[i].targetId : null,
    targetOther: targets[i].description ? targets[i].description : null,
    practiceId: practiceElements[i].id != null ? +practiceElements[i].id : null,
    practiceOther: practiceElements[i].description !== null ? practiceElements[i].description : null,
  })),
  createdLogId: null,
  updatedLogId: null,
  logs: [
    {
      comment: reasonForEdit,
    },
  ],
});

export const getUpdatedTieredServicePayload = (
  { planId, studentId, hStudentId, providerUserId, hProviderUserId }: TieredServiceDetail,
  formValues: TieredServiceFormValue,
): UpdateTieredServiceLogPayload => {
  return {
    planId,
    studentId,
    hStudentId,
    providerUserId,
    hProviderUserId,
    ...formValuesEditToPayload(formValues),
  };
};

export const toTieredServiceSubmitPayload = (
  service: TieredServiceDetail,
  formValues: TieredServiceFormValue,
): UpdateTieredServiceLogPayload => {
  return {
    studentId: service.studentId,
    hStudentId: service.hStudentId,
    providerUserId: service.providerUserId,
    hProviderUserId: service.hProviderUserId,
    ...formValuesToPayload(formValues),
  };
};
