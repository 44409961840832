import { FormHelperText, Grid, Input, InputAdornment, makeStyles, TextField, Theme } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import cn from 'classnames';
import React from 'react';
import CustomPopperAutocomplete from 'src/components/CustomPopperAutocomplete';
import { filterOptionsMatchAllKeywords } from 'src/utils';

type MuiSelectProps<TValue extends [number, number, string | number]> = {
  value: TValue;
  onChange: (v: TValue) => void;
  label: string;
  color?: 'primary' | 'secondary';
  name?: string;
  errors?: [string, string, string];
  errorMessage?: string;
  onBlur?: (e) => void;
  disabled?: boolean;
};

type OptionType = { value: string; label: string };

const options: OptionType[] = [
  { value: 'day', label: 'Day' },
  { value: 'week', label: 'Week' },
  { value: 'month', label: 'Month' },
  { value: 'quarter', label: 'Quarter' },
  { value: 'tri', label: 'Trimester' },
  { value: 'sem', label: 'Semester' },
  { value: 'year', label: 'Year' },
];

const FrequencyInput = ({
  value = [null, null, ''],
  onChange,
  label,
  color = 'primary',
  name,
  errors = ['', '', ''],
  errorMessage = '',
  onBlur,
  disabled,
  ...otherProps
}: MuiSelectProps<[number, number, string]>) => {
  const classes = useStyles();

  const [focus, setFocus] = React.useState<boolean>(false);

  const handleFocus = () => setFocus(true);
  const handleBlur = e => {
    setFocus(false);
    onBlur(e);
  };

  const handleChange = (index: number, newVal) => onChange(value.map((v, i) => (i === index ? newVal : v)) as any);

  const hasError = errors?.length && errors.find(e => !!e);

  const numberRange = {
    min: 0,
    max: 999,
    step10: 10,
  };

  return (
    <div>
      <Grid
        className={cn(
          classes.container,
          focus && classes.containerFocus,
          errorMessage && classes.containerError,
          disabled && classes.containerDisabled,
        )}
        container>
        <span className={classes.label}>{label}</span>
        <Grid item xs={3} sm={3}>
          <Input
            color={color}
            className={classes.input}
            endAdornment={<InputAdornment position="end">mins</InputAdornment>}
            onFocus={handleFocus}
            // onBlur={handleBlur}
            value={value?.[0] || ''}
            onChange={e => {
              let value = +e.target.value;
              if (value > 9999) value = +value.toString().slice(0, 4);
              if (value < numberRange.min) value = 1;
              handleChange(0, value);
            }}
            type="number"
            inputProps={{
              min: numberRange.min,
              max: 9999,
              step: numberRange.step10,
              style: { textAlign: 'right' },
              className: classes.inputAlignRight,
            }}
            error={!!errors[0]}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <Input
            className={classes.input}
            color={color}
            endAdornment={<InputAdornment position="end">times</InputAdornment>}
            onFocus={handleFocus}
            // onBlur={handleBlur}
            type="number"
            value={value?.[1] || ''}
            onChange={e => {
              let value = +e.target.value;
              if (value > numberRange.max) value = +value.toString().slice(0, 3);
              if (value < numberRange.min) value = 1;
              handleChange(1, value);
            }}
            inputProps={{
              min: numberRange.min,
              max: numberRange.max,
              style: { textAlign: 'right', MozAppearance: 'textfield' },
              className: classes.inputAlignRight,
            }}
            error={!!errors[1]}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={5}>
          <Autocomplete
            className={classes.input}
            options={options}
            getOptionLabel={option => option.label}
            onChange={(e, value: OptionType) => {
              handleChange(2, value?.value);
            }}
            PopperComponent={CustomPopperAutocomplete}
            renderInput={params => (
              <TextField
                {...params}
                name={name}
                error={!!errors[2]}
                helperText={!!errors[2]}
                fullWidth
                color={color}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: <InputAdornment position="start">per</InputAdornment>,
                }}
              />
            )}
            filterOptions={filterOptionsMatchAllKeywords<{ value: string; label: string }>(option => option.label)}
            disableClearable
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={disabled}
            value={options.find(o => o.value === value?.[2])}
            key={value?.[2]}
          />
        </Grid>
      </Grid>
      {hasError && <FormHelperText error>{errorMessage}</FormHelperText>}
    </div>
  );
};

export default FrequencyInput;

// const useStyles = makeStyles((theme: Theme) => {
//   const isMobile = theme.breakpoints.down(600);
//   return {
//     container: {
//       display: 'flex',
//       alignItems: 'center',
//       flexDirection: 'row',
//       marginBottom: 16,
//       [isMobile]: {
//         alignItems: 'flex-start',
//         flexDirection: 'column',
//       },
//     },

//   };
// });

const useStyles = makeStyles((theme: Theme) => {
  const isMobile = theme.breakpoints.down(600);
  return {
    container: {
      position: 'relative',
      height: 56,
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: 4,
      padding: '0 14px',
      '&:hover': {
        borderColor: theme.palette.grey[900],
        '& > span': {
          color: theme.palette.primary.main,
        },
      },
      boxSizing: 'border-box',
    },
    containerFocus: {
      '&, &:hover': {
        borderWidth: 2,
        height: 56,
        borderColor: theme.palette.primary.main,
      },

      '& > span': {
        color: theme.palette.primary.main,
      },
    },
    containerError: {
      borderColor: theme.palette.error.main,
      '&, &:hover': {
        borderColor: theme.palette.error.main,
        '& > span': {
          color: theme.palette.error.main,
        },
      },
      '& > span': {
        color: theme.palette.error.main,
      },
    },
    containerDisabled: {
      '&, &:hover': {
        borderColor: theme.palette.grey[400],
        '& > span': {
          color: theme.palette.grey[400],
        },
      },
      '& > span': {
        color: theme.palette.grey[400],
      },
    },
    label: {
      ...theme.typography.caption,
      position: 'absolute',
      top: -13,
      left: -2,
      backgroundColor: 'white',
      padding: '0 7px',
      fontSize: '1rem',
      color: 'rgb(0, 0, 0, 0.54)',
      transform: 'scale(0.75)',
    },
    input: {
      '&:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
      '&.MuiInputBase-root, & .MuiInputBase-root': {
        height: 54,
        borderBottom: '0 !important',
        transition: 'none',

        '&::before': {
          borderBottom: '0 !important',
          transition: 'none',
        },
      },
      '& .MuiFormControl-root .MuiInputBase-root input': {
        textAlign: 'center',
      },
    },
    inputAlignRight: {
      MozAppearance: 'textfield',
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        marginLeft: '5px',
        [isMobile]: {
          marginLeft: '0',
        },
      },
    },
  };
});
