import { all } from 'redux-saga/effects';
import { API } from './../services';
/* ------------- Sagas ------------- */
import authSaga from './authRedux/saga';
import schedulersSagas from './schedulerRedux/sagas';
import tieredServicesSagas from './tieredServices/sagas';
import sessionLogsSagas from './sessionLogsRedux/sagas';

/* ------------- API ------------- */
const withMockApis = process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_API;
const apiInstance = API.create();

const api = withMockApis
  ? {
      ...apiInstance,
      // OVERWRITE
      ...require('../mocks/mocks').default,
    }
  : apiInstance;

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([...authSaga(api), ...schedulersSagas(api), ...tieredServicesSagas(api), ...sessionLogsSagas(api)]);
}
