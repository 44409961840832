import { Button } from '@material-ui/core';
import { FormikProps } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PATHS } from 'src/appConfig/paths';
import { useConfirmDialog } from 'src/components/common/ConfirmDialog';
import PageContentContainer, { PageContentFooter } from 'src/components/PageContentContainer';
import StickyPageHeader from 'src/components/StickyPageHeader';
import { IRootState } from 'src/redux/rootReducer';
import {
  cancelEditOrCloneService,
  createTieredServiceLogsAsync,
  getTieredServiceByIdForEdittingOrCloningAsync,
  saveEditServiceFormProgress,
  updateTieredServiceLogsAsync,
} from 'src/redux/tieredServices/action';
import { Navigator } from 'src/services';
import { toTieredServiceSubmitPayload } from './helper';
import StudentInfoBanner from '../Components/StudentInfoBanner';
import TieredServiceFormik, {
  ConnectedTieredServiceForm,
  TieredServiceFormValue,
} from '../Components/TieredServiceForm';
import { getUpdatedTieredServicePayload } from './helper';
import './styles.scss';
import { isEmpty } from 'src/validations';
import { modeValue } from 'src/redux/tieredServices/edittingServiceReducer';

type Props = ReturnType<typeof mapState> & typeof mapDispatch;

const clsPrefix = 'edit-tiered-service';

const EditTieredService: React.VFC<Props> = ({
  planId,
  reason,
  service,
  formProgress,
  loading,
  mode,
  updateSuccess,
  saveFormProgress,
  cancel,
  getTieredServiceDetail,
  updateTieredServiceLogs,
  createTieredServices,
}) => {
  const [cancelling, setCancelling] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (planId) {
      getTieredServiceDetail(planId);
    } else {
      Navigator.navigate(PATHS.tieredService);
    }
  }, [planId]);

  const { studentId, studentFirstName, studentLastName, studentExtId, schoolName, grade, gender, birthDate } = {
    ...service,
  };

  const [formInitialValue, setFormInitialValue] = React.useState<TieredServiceFormValue>({});

  const formRef = React.useRef<FormikProps<TieredServiceFormValue>>(null);

  const [ConfirmDialog, confirm] = useConfirmDialog(
    'You have unsaved information. Are you sure you want to leave this page?',
  );

  const history = useHistory();

  const title = `${mode === modeValue.CLONE ? 'Copy' : 'Edit'} Tiered Service`;

  React.useEffect(() => {
    if (service) {
      const {
        providerFullName,
        providerPosition,
        providerUserId,
        tier,
        startDate,
        endDate,
        projectedEndDate,
        altProviderFullName,
        altProviderPosition,
        altProviderUserId,
        notes,
        frequencyMinute,
        frequencyTimes,
        frequencyInterval,
        outcomeId,
        target,
      } = service;
      setFormInitialValue({
        mode,
        reasonForEdit: reason,
        providerFullName: `${providerFullName} ${providerPosition ? ` / ${providerPosition}` : ''}`,
        provider: providerUserId,
        tier: +tier,
        startDate,
        endDate,
        projectedEndDate,
        comments: isEmpty(notes) ? '' : notes,
        frequency: [frequencyMinute, frequencyTimes, frequencyInterval.toLowerCase()],
        outcome: outcomeId,
        ...target.reduce(
          (acc, t) => ({
            targets: [
              ...acc.targets,
              {
                id: t.id,
                targetId: t.targetId,
                needDescription: t.targetOther ? true : false,
                description: t.targetOther,
              },
            ],
            practiceElements: [...acc.practiceElements, { id: t.practiceId, needDescription: t.practiceOther ? true : false, description: t.practiceOther },],
          }),
          {
            targets: [],
            practiceElements: [],
          },
        ),
        alternativeProvider: altProviderUserId
          ? {
              providerFullname: `${altProviderFullName} ${altProviderPosition ? ` / ${altProviderPosition}` : ''}`,
              userId: altProviderUserId,
            }
          : null,
      });
    }
  }, [service]);

  React.useEffect(() => {
    if (formProgress && Object.keys(formProgress).length) {
      setFormInitialValue(formProgress);
    }
  }, [formProgress]);

  React.useEffect(() => {
    const unlisten = history.listen(() => {
      if (cancelling) {
        saveFormProgress({
          studentId: studentExtId,
          formValues: formRef.current.values,
        });
      }
    });
    return unlisten;
  }, [cancelling, history, formRef.current]);

  const handleCancelEditing = () => {
    setCancelling(true);
    confirm(() => {
      cancel();
      Navigator.goBack();
    });
  };

  const handleSubmitAndClone = () => {
    const formValues = formRef.current.values;
    const payload = toTieredServiceSubmitPayload(service, formValues);
    createTieredServices({ payloads: [payload], final: false });
  };

  const handleSubmit = (formValues: TieredServiceFormValue) => {
    if (mode === modeValue.CLONE) {
      const payload = toTieredServiceSubmitPayload(service, formValues);
      createTieredServices({ payloads: [payload], final: true });
    } else {
      const payload = getUpdatedTieredServicePayload(service, formValues);
      updateTieredServiceLogs(payload);
    }
  };

  return (
    <>
      <TieredServiceFormik initialValues={formInitialValue} onSubmit={handleSubmit} innerRef={formRef}>
        {(formProps: FormikProps<TieredServiceFormValue>) => {
          // console.log('formProps values: ', formProps.values);
          // console.log('formProps errors: ', formProps.errors);
          return (
            <PageContentContainer loading={loading} contentClassName={`${clsPrefix}-page-container`}>
              <StickyPageHeader>
                <h3 className={`${clsPrefix}-header`}>{title}</h3>
              </StickyPageHeader>
              {studentExtId && (
                <StudentInfoBanner
                  student={{
                    studentExtId,
                    firstName: studentFirstName,
                    lastName: studentLastName,
                    schoolName,
                    grade,
                    birthDate,
                    gender,
                  }}
                />
              )}
              <ConnectedTieredServiceForm {...formProps} studentId={studentId} />
              <PageContentFooter>
                <Button variant="text" color="secondary" onClick={handleCancelEditing} disabled={loading}>
                  CANCEL
                </Button>
                {mode === modeValue.CLONE && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleSubmitAndClone}
                    disabled={!formProps.isValid || loading}>
                    SUBMIT & CLONE
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={!formProps.isValid || loading}
                  onClick={() => formProps.handleSubmit()}>
                  SUBMIT
                </Button>
              </PageContentFooter>
            </PageContentContainer>
          );
        }}
      </TieredServiceFormik>
      <ConfirmDialog />
    </>
  );
};

const mapState = (state: IRootState) => {
  const { mode, planId, service, loading, formProgress, updateSuccess, reason } = state.tieredServices.editAndClone;
  const cloning = state.tieredServices.addTieredService.loading;
  return {
    reason,
    planId,
    mode,
    service,
    loading: loading || (mode === modeValue.CLONE && cloning),
    formProgress: (service?.studentExtId && formProgress[service.studentExtId]) || {},
    updateSuccess,
  };
};

const mapDispatch = {
  saveFormProgress: saveEditServiceFormProgress,
  cancel: cancelEditOrCloneService,
  getTieredServiceDetail: getTieredServiceByIdForEdittingOrCloningAsync.request,
  updateTieredServiceLogs: updateTieredServiceLogsAsync.request,
  createTieredServices: createTieredServiceLogsAsync.request,
};
export default connect(mapState, mapDispatch)(EditTieredService);
