import { IRootState } from '../rootReducer';
import { RecentLogsType, SearchSessionRequestType, SessionLogDetail } from './types';

export const selectRecentSessionLogs = (state: IRootState): RecentLogsType[] => {
  return state.sessionLogs.recentLogs.data || [];
};

export const selectCurrentSearchParams = (state: IRootState): SearchSessionRequestType => {
  return state.sessionLogs.searchLogs.logs.currentSearchParams;
};

export const selectMode = (state: IRootState): string => {
  return state.sessionLogs.searchLogs.selectedLog.mode || '';
};

export const selectSubmissionRecordsSL = (
  state: IRootState,
): {
  records: SessionLogDetail[];
  final: boolean;
} => {
  return state.sessionLogs.addSession.submissions;
};
