import React from 'react';
import { Button, Grid } from '@material-ui/core';
import moment from 'moment';
import Dialog from 'src/components/common/Dialog';
import MuiDatePicker, { MuiDatePickerMomentUtilsProvider } from 'src/components/common/KeyboardDatePicker';
import SearchDropdownBox from 'src/components/SearchDropdownBox';
import { getProviderOptionLabel } from 'src/containers/SchedulerContainers/helper';
import { ProviderName } from 'src/redux/schedulerRedux/types';
import * as yup from 'yup';

type Props = {
  open: boolean;
  onClose: () => void;
  onNext: () => void;
  sessionDate: string;
  onSessionDateChange?: (event) => void;
  searchSLActiveProvider: any;
  clearSearchActiveProvider: any;
  readOnly: boolean;

  activeProvider: any;
  handleActiveProviderChange: any;
  loading: boolean;
  options: ProviderName[];
};

export type FilterFormValue = {
  sessionDate: string;
};

export const FilerFormSchema = yup.object().shape({
  sessionDate: yup.date().typeError(`Please enter a valid date (MM/DD/YYYY)`).required(`Session Date is required`),
});

const SelectProviderDialog = ({
  open,
  onClose,
  onNext,
  sessionDate = moment().toISOString(),
  onSessionDateChange,
  loading,
  options,
  searchSLActiveProvider,
  clearSearchActiveProvider,
  readOnly,
  activeProvider,
  handleActiveProviderChange,
}: Props) => {
  const [errorMsgDate, setErrorMsgDate] = React.useState<string>('');
  return (
    <MuiDatePickerMomentUtilsProvider>
      <Dialog
        open={open}
        title="Select Provider"
        maxWidth="sm"
        fullWidth
        onClose={onClose}
        dialogActions={
          <>
            <Button color="secondary" onClick={onNext} disabled={!activeProvider || !!errorMsgDate}>
              Next
            </Button>
          </>
        }>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <SearchDropdownBox<ProviderName, ProviderName>
              loading={loading}
              options={options}
              getOptionLabel={getProviderOptionLabel}
              placeholder="Provider"
              value={activeProvider}
              onChange={handleActiveProviderChange}
              disabled={readOnly}
              hideDropdownIcon={readOnly}
              onSearch={searchSLActiveProvider}
              onClear={clearSearchActiveProvider}
              noOptionsText="No providers found"
              debounceDelay={500}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <MuiDatePicker
              value={moment(sessionDate)}
              onChange={newDate => {
                if (!newDate?.isValid()) {
                  setErrorMsgDate('Please enter a valid date (MM/DD/YYYY)');
                } else {
                  setErrorMsgDate('');
                  if (newDate.isAfter(moment())) {
                    setErrorMsgDate('Session Date cannot be in the future');
                  }
                  if (newDate.isBefore(moment().subtract(1, 'years'))) {
                    setErrorMsgDate('Session Date cannot be more than a year in the past');
                  }
                }

                onSessionDateChange(newDate);
              }}
              label="Session Date"
              maxDate={moment()}
              minDate={moment().subtract(1, 'years')}
              errorMessage={errorMsgDate}
            />
          </Grid>
        </Grid>
      </Dialog>
    </MuiDatePickerMomentUtilsProvider>
  );
};

export default SelectProviderDialog;
