import { AsyncState } from 'src/utils/commonAsyncReducer';
import { createReducer } from 'typesafe-actions';
import {
  exchangeTokenAsync,
  GetUserPreferencesAsync,
  setToken,
  signInAsync,
  signOutAsync,
  UpdateUserPreferencesDefaultMenuAsync,
  UpdateUserPreferencesTooltipAsync,
} from './actions';
import { MenuDefaultValue, Permissions, PreferenceKeyValue, User, UserPreferencesType } from './types';

export type IAuthState = Readonly<{
  user: User | null;
  permissions: Permissions;
  error?: AuthError;
  isAuthenticated: boolean | null;
  loading: boolean;
  isSigningIn: boolean;
  token: string;
  tooltip: AsyncState<UserPreferencesType[]> & { state: boolean };
  defaultMenu: AsyncState<UserPreferencesType[]> & { state: string };
}>;

/* ------------- Initial State ------------- */
const initialState: IAuthState = {
  user: null,
  permissions: {
    isAdmin: false,
    isScheduler: false,
    isSL: false,
    isTS: false,
    hasSessionEditRights: false,
    hasSessionViewRights: false,
    hasTieredEditRights: false,
    hasTieredViewRights: false,
    hasFullRights: false,
  },
  error: null,
  isAuthenticated: null,
  loading: false,
  isSigningIn: false,
  tooltip: {
    data: [],
    loading: false,
    error: null,
    state: true,
  },
  defaultMenu: {
    data: [],
    loading: false,
    error: null,
    state: null,
  },
  token: null,
};

export default createReducer(initialState)
  /* ------------- signInAsync ------------- */
  .handleAction(signInAsync.request, state => ({
    ...state,
    isSigningIn: true,
    loading: true,
    error: null,
  }))
  .handleAction(signInAsync.success, (state, { payload: params }) => ({
    ...state,
    isSigningIn: false,
    error: null,
    loading: false,
    user: params.user,
    token: params.token,
    isAuthenticated: true,
    permissions: {
      isAdmin: params.user.isAdmin,
      isScheduler: params.user.isScheduler,
      isSL: params.user.hasSessionViewRights || params.user.hasSessionEditRights ? true : false,
      isTS: params.user.hasTieredEditRights || params.user.hasTieredViewRights ? true : false,
      hasSessionEditRights: params.user.hasSessionEditRights,
      hasSessionViewRights: params.user.hasSessionViewRights,
      hasTieredEditRights: params.user.hasTieredEditRights,
      hasTieredViewRights: params.user.hasTieredViewRights,
      hasFullRights:
        (params.user.hasSessionEditRights || params.user.hasSessionViewRights) &&
        (params.user.hasTieredEditRights || params.user.hasTieredViewRights) &&
        params.user.isScheduler
          ? true
          : false,
    },
  }))
  .handleAction(signInAsync.failure, (state, action) => ({
    ...state,
    loading: false,
    isSigningIn: false,
    error: action.payload,
    isAuthenticated: null,
  }))

  /* ------------- signOutAsync ------------- */
  .handleAction(signOutAsync.request, (state, action) => ({
    ...state,
    loading: true,
  }))
  .handleAction(signOutAsync.success, (state, action) => ({
    user: null,
    permissions: {
      isAdmin: false,
      isScheduler: false,
      isSL: false,
      isTS: false,
      hasSessionEditRights: false,
      hasSessionViewRights: false,
      hasTieredEditRights: false,
      hasTieredViewRights: false,
      hasFullRights: false,
    },
    error: null,
    isAuthenticated: null,
    loading: false,
    isSigningIn: false,
    tooltip: {
      data: [],
      loading: false,
      error: null,
      state: true,
    },
    defaultMenu: {
      data: [],
      loading: false,
      error: null,
      state: MenuDefaultValue.SESSION_LOGS,
    },
    token: null,
  }))
  .handleAction(signOutAsync.failure, (state, action) => ({
    ...state,
    loading: false,
    error: null,
  }))

  // /* ------------- exchangeTokenAsync ------------- */
  .handleAction(exchangeTokenAsync.request, state => ({
    ...state,
    loading: true,
  }))
  .handleAction(exchangeTokenAsync.success, (state, { payload: params }) => ({
    ...state,
    error: null,
    loading: false,
    user: params.user,
    token: params.token,
    isAuthenticated: true,
    permissions: {
      isAdmin: params.user.isAdmin,
      isScheduler: params.user.isScheduler,
      isSL: params.user.hasSessionViewRights || params.user.hasSessionEditRights ? true : false,
      isTS: params.user.hasTieredEditRights || params.user.hasTieredViewRights ? true : false,
      hasSessionEditRights: params.user.hasSessionEditRights,
      hasSessionViewRights: params.user.hasSessionViewRights,
      hasTieredEditRights: params.user.hasTieredEditRights,
      hasTieredViewRights: params.user.hasTieredViewRights,
      hasFullRights:
        (params.user.hasSessionEditRights || params.user.hasSessionViewRights) &&
        (params.user.hasTieredEditRights || params.user.hasTieredViewRights) &&
        params.user.isScheduler
          ? true
          : false,
    },
  }))
  .handleAction(exchangeTokenAsync.failure, (state, action) => ({
    ...state,
    loading: false,
  }))
  .handleAction(setToken, (state, action) => ({
    ...state,
    token: action.payload.token,
  }))
  .handleAction([GetUserPreferencesAsync.request], (state, action) => ({
    ...state,
    tooltip: {
      ...state.tooltip,
      loading: true,
    },
    defaultMenu: {
      ...state.defaultMenu,
      loading: true,
    },
  }))
  .handleAction([GetUserPreferencesAsync.success], (state, action) => {
    const tooltipData = action.payload.find(item => item.preferenceKey === PreferenceKeyValue.SBBH_HELP);
    const defaultMenuData = action.payload.find(item => item.preferenceKey === PreferenceKeyValue.SBBH_MENU_DEFAULT);
    return {
      ...state,
      tooltip: {
        loading: false,
        data: tooltipData,
        error: null,
        state: tooltipData.value === 'enabled' ? true : false,
      },
      defaultMenu: {
        loading: false,
        data: defaultMenuData,
        error: null,
        state: defaultMenuData.value,
      },
    };
  })
  .handleAction([GetUserPreferencesAsync.failure], (state, action) => ({
    ...state,
    tooltip: {
      ...state.tooltip,
      loading: false,
      error: action.payload,
    },
    defaultMenu: {
      ...state.defaultMenu,
      loading: false,
      error: action.payload,
    },
  }))
  .handleAction([UpdateUserPreferencesTooltipAsync.request], (state, action) => ({
    ...state,
    tooltip: {
      ...state.tooltip,
      loading: true,
    },
  }))
  .handleAction([UpdateUserPreferencesTooltipAsync.success], (state, action) => ({
    ...state,
    tooltip: {
      ...state.tooltip,
      loading: false,
      data: action.payload,
      state: action.payload.value === 'enabled' ? true : false,
    },
  }))
  .handleAction([UpdateUserPreferencesTooltipAsync.failure], (state, action) => ({
    ...state,
    tooltip: {
      ...state.tooltip,
      loading: false,
      error: action.payload,
    },
  }))
  .handleAction([UpdateUserPreferencesDefaultMenuAsync.request], (state, action) => ({
    ...state,
    defaultMenu: {
      ...state.defaultMenu,
      loading: true,
    },
  }))
  .handleAction([UpdateUserPreferencesDefaultMenuAsync.success], (state, action) => ({
    ...state,
    defaultMenu: {
      ...state.defaultMenu,
      loading: false,
      data: action.payload,
      state: action.payload.value,
    },
  }))
  .handleAction([UpdateUserPreferencesDefaultMenuAsync.failure], (state, action) => ({
    ...state,
    defaultMenu: {
      ...state.defaultMenu,
      loading: false,
      error: action.payload,
    },
  }));
