import { Divider, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import PageContentContainer from 'src/components/PageContentContainer';
import StickyPageHeader from 'src/components/StickyPageHeader';
import { IRootState } from 'src/redux/rootReducer';
import {
  setSchedulerProvidersInitialValue,
  setSchedulerStudentsInitialValue,
  setSelectedTab,
} from 'src/redux/schedulerRedux/actions';
import { selectSelectedTab } from 'src/redux/schedulerRedux/selectors';
import { SelectedTabValue } from 'src/redux/schedulerRedux/types';
import { selectUserPermissions } from 'src/redux/authRedux/selectors';
import { DefaultRoute, Navigator, Toastify } from 'src/services';
import Provider from './Providers';
import Student from './Student';
import './styles.scss';

const SchedulerContainer: React.FC<Props> = ({
  loadingProviders,
  loadingStudents,
  setSchedulerProvidersInitialValue,
  setSchedulerStudentsInitialValue,
  selectedTab,
  setSelectedTab,
  permissions,
}) => {
  React.useEffect(() => {
    if (!permissions.isScheduler) {
      Toastify.error('You do NOT have permission to access SCHEDULER function.');
      Navigator.navigate(DefaultRoute.getLocalDefaultRoute());
    }
  }, []);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: SelectedTabValue) => {
    switch (newValue) {
      case SelectedTabValue.Student:
        setSchedulerProvidersInitialValue();
        break;
      case SelectedTabValue.Provider:
        setSchedulerStudentsInitialValue();
        break;
      default:
        setSchedulerProvidersInitialValue();
        setSchedulerStudentsInitialValue();
        break;
    }
    setSelectedTab(newValue);
  };

  return (
    <PageContentContainer className="scheduler-page-container" loading={loadingProviders || loadingStudents}>
      <StickyPageHeader>
        <h3 className="scheduler__title">Scheduler</h3>
        <Tabs
          variant="fullWidth"
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          className=""
          centered>
          <Tab label="Add by Provider" id={SelectedTabValue.Provider} value={SelectedTabValue.Provider} />
          <Tab label="Add by Student" id={SelectedTabValue.Student} value={SelectedTabValue.Student} />
        </Tabs>
        <Divider />
      </StickyPageHeader>

      <TabPanel value={selectedTab} index={SelectedTabValue.Provider}>
        <Provider />
      </TabPanel>
      <TabPanel value={selectedTab} index={SelectedTabValue.Student}>
        <Student />
      </TabPanel>
    </PageContentContainer>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  value: any;
  index: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`${value}`} className="" {...other}>
      {value === index && <>{children}</>}
    </div>
  );
}

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const mapStateToProps = (state: IRootState) => ({
  loadingProviders: state.schedulers.servicesByProvider.loading,
  loadingStudents: state.schedulers.servicesByStudent.loading,
  selectedTab: selectSelectedTab(state),
  permissions: selectUserPermissions(state),
});

const mapDispatchToProps = {
  setSchedulerProvidersInitialValue: setSchedulerProvidersInitialValue,
  setSchedulerStudentsInitialValue: setSchedulerStudentsInitialValue,
  setSelectedTab: setSelectedTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerContainer);
