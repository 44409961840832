import { History, LocationState } from 'history';
import appConfig from 'src/appConfig';
import urlJoin from 'url-join';

let _history: History;

const setTopHistory = (history: History) => {
  _history = history;
};

const navigate = <T extends LocationState>(routeName: string, state?: T) => {
  _history.push(routeName, state);
};

const replace = (routeName: string, state?: LocationState) => {
  _history.replace(routeName, state);
};

const goBack = () => {
  var isCannotBack = _history.action === 'POP';
  isCannotBack ? _history.push(`/`) : _history.goBack();
};

const getNextPathInBaseUrl = (nextPath?: string) => {
  const baseURL = appConfig.WEB_URL as string;
  const path = nextPath || _history.location.pathname;
  const url = urlJoin(baseURL, path);

  return url;
};

const jumpToCrossDomain = (subDomain: string, nextPath?: string) => {
  const toUrl = getNextPathInBaseUrl(nextPath);
  const toCrossUrl = toUrl.replace('://', `://${subDomain}.`);
  window.location.href = toCrossUrl;
};

export default {
  setTopHistory,
  navigate,
  goBack,
  replace,
  jumpToCrossDomain,
};
