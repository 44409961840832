import { createAction, createAsyncAction } from 'typesafe-actions';
import { Pagination } from '../types';
import {
  Provider,
  ServicesByStudent,
  ProviderName,
  SchoolName,
  StudentName,
  GetServiceListByProviderParams,
  AddProviderToServiceParams,
  RemoveProviderFromServiceParams,
  SelectedTabValue,
  SchedulerView,
  GetServiceListByStudentParams,
} from './types';

export const getServiceListByProvidersAsync = createAsyncAction(
  'scheduler/providers/GET_SERVICES_BY_PROVIDERS_REQUEST',
  'scheduler/providers/GET_SERVICES_BY_PROVIDERS_SUCCESS',
  'scheduler/providers/GET_SERVICES_BY_PROVIDERS_FAILURE',
)<GetServiceListByProviderParams, Provider[], Error>();

export const setSchedulerProvidersPagination = createAction('scheduler/providers/SET_PAGINATION')<Pagination>();

export const setSchedulerProvidersInitialValue = createAction('scheduler/providers/SET_INITIAL_VALUE')();
export const setSchedulerStudentsInitialValue = createAction('scheduler/students/SET_INITIAL_VALUE')();

export const setSchedulerProviderName = createAction('scheduler/SET_PROVIDER_NAME')<ProviderName>();
export const setSchedulerSchoolName = createAction('scheduler/SET_SCHOOL_NAME')<SchoolName>();
export const setSchedulerStudentName = createAction('scheduler/SET_STUDENT_NAME')<StudentName>();
export const setSelectedTab = createAction('scheduler/SET_SELECTED_TAB')<SelectedTabValue>();
export const setSchedulerView = createAction('scheduler/SET_VIEW')<SchedulerView>();
export const setServiceId = createAction('scheduler/students/SET_SERVICE_ID')<number>();

export const getStudentServicesByStudentAsync = createAsyncAction(
  'scheduler/students/GET_STUDENT_SERVICES_BY_STUDENT_REQUEST',
  'scheduler/students/GET_STUDENT_SERVICES_BY_STUDENT_SUCCESS',
  'scheduler/students/GET_STUDENT_SERVICES_BY_STUDENT_FAILURE',
)<GetServiceListByStudentParams, ServicesByStudent[], Error>();

export const searchProvidersAsync = createAsyncAction(
  'scheduler/providers/SEARCH_PROVIDERS_REQUEST',
  'scheduler/providers/SEARCH_PROVIDERS_SUCCESS',
  'scheduler/providers/SEARCH_PROVIDERS_FAILURE',
  'scheduler/providers/SEARCH_PROVIDERS_RESET',
)<string, any, Error, void>();

export const searchProviderByStudentAsync = createAsyncAction(
  'scheduler/students/SEARCH_PROVIDERS_REQUEST',
  'scheduler/students/SEARCH_PROVIDERS_SUCCESS',
  'scheduler/students/SEARCH_PROVIDERS_FAILURE',
  'scheduler/students/SEARCH_PROVIDERS_RESET',
)<string, any, Error, void>();

export const searchSchoolsAsync = createAsyncAction(
  'scheduler/providers/SEARCH_SCHOOLS_REQUEST',
  'scheduler/providers/SEARCH_SCHOOLS_SUCCESS',
  'scheduler/providers/SEARCH_SCHOOLS_FAILURE',
  'scheduler/providers/SEARCH_SCHOOLS_RESET',
)<string, any, Error, void>();

export const searchStudentsAsync = createAsyncAction(
  'scheduler/students/SEARCH_STUDENTS_REQUEST',
  'scheduler/students/SEARCH_STUDENTS_SUCCESS',
  'scheduler/students/SEARCH_STUDENTS_FAILURE',
  'scheduler/students/SEARCH_STUDENTS_RESET',
)<string, any, Error, void>();

export const addProviderToServiceByProviderAsync = createAsyncAction(
  'scheduler/providers/ADD_PROVIDER_TO_SERVICE_REQUEST',
  'scheduler/providers/ADD_PROVIDER_TO_SERVICE_SUCCESS',
  'scheduler/providers/ADD_PROVIDER_TO_SERVICE_FAILURE',
)<AddProviderToServiceParams, any, Error>();

export const removeProviderFromServiceByProviderAsync = createAsyncAction(
  'scheduler/providers/REMOVE_PROVIDER_FROM_SERVICE_REQUEST',
  'scheduler/providers/REMOVE_PROVIDER_FROM_SERVICE_SUCCESS',
  'scheduler/providers/REMOVE_PROVIDER_FROM_SERVICE_FAILURE',
)<RemoveProviderFromServiceParams, any, Error>();

export const addProviderToServiceByStudentAsync = createAsyncAction(
  'scheduler/students/ADD_PROVIDER_TO_SERVICE_REQUEST',
  'scheduler/students/ADD_PROVIDER_TO_SERVICE_SUCCESS',
  'scheduler/students/ADD_PROVIDER_TO_SERVICE_FAILURE',
)<AddProviderToServiceParams, any, Error>();

export const removeProviderFromServiceByStudentAsync = createAsyncAction(
  'scheduler/students/REMOVE_PROVIDER_FROM_SERVICE_REQUEST',
  'scheduler/students/REMOVE_PROVIDER_FROM_SERVICE_SUCCESS',
  'scheduler/students/REMOVE_PROVIDER_FROM_SERVICE_FAILURE',
)<RemoveProviderFromServiceParams, any, Error>();
