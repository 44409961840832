import { User } from 'src/redux/authRedux/types';
import { ProviderName } from 'src/redux/schedulerRedux/types';

export const toProvider = (user: User): ProviderName => {
  if (!user) {
    return null;
  }
  const { userId, hUserId, fullName, position, username, credentialName, credentialType } = user;

  return {
    userId,
    hUserId,
    firstName: fullName,
    lastName: '',
    position,
    username,
    credentialName,
    credentialType,
  };
};
