import React from 'react';
import './styles.scss';

import { Button, Chip, Grid, IconButton, TextField, Tooltip, useMediaQuery } from '@material-ui/core';

// import CloseIcon from '@material-ui/icons/Close';
import { FaPlusSquare, FaSearch } from 'react-icons/fa';

import Dialog from 'src/components/common/Dialog';
import MuiFormControlContainer from 'src/components/common/MuiFormControlContainer';
import PageContentContainer from 'src/components/PageContentContainer';
import StickyPageHeader from 'src/components/StickyPageHeader';

import Filters, { StudentFiltersSL } from './Filters';

import { EditableStudentsType, GetEditableStudentsRequest } from 'src/redux/sessionLogsRedux/types';

import { AsyncState } from 'src/utils/commonAsyncReducer';
import { getDateDisplay } from 'src/utils/momentUtils';
import { isEmpty } from 'src/validations';
import PaginationComponent from 'src/components/common/Pagination';
import { Pagination } from 'src/redux/types';
import { muiResponsive } from 'src/appConfig/muiTheme';
import { useLocation } from 'react-router-dom';
import { PATHS } from 'src/appConfig/paths';

export type SearchName = { firstName?: string; lastName?: string };

type Props = {
  open: boolean;
  onClose: () => void;
  onChangeProvider: () => void;
  showChangeProvider: boolean;
  onCreateService: () => void;
  editableStudentsAsync: AsyncState<EditableStudentsType[]>;
  selectedStudents: EditableStudentsType[];
  onSelectStudent: (student: EditableStudentsType) => void;
  onDeselectStudent: (student: EditableStudentsType) => void;
  onDeselectAll: () => void;
  filters: StudentFiltersSL;
  onFilterChange: (filter: StudentFiltersSL) => void;
  searchValue: GetEditableStudentsRequest;
  onSearch: (filters: GetEditableStudentsRequest) => void;
  // eslint-disable-next-line no-empty-pattern
  onSearchValueChange: ({}: GetEditableStudentsRequest) => void;
  pagination: Pagination;
  onPageChange: (p: number) => void;
  totalCount: number;
  completedStudentIds: (string | number)[];
  providerName: string;
  sessionDate: string;
  reduxSelectedStudents: EditableStudentsType[];
};

const clsPrefix = 'add-session-dialog';
const toolTipPreferences = localStorage.getItem('toolTipPreferences') === 'true' ? true : false;

const AddStudentDialog: React.VFC<Props> = ({
  open,
  onClose,
  onChangeProvider,
  showChangeProvider,
  onCreateService,
  editableStudentsAsync,
  selectedStudents,
  onSelectStudent,
  onDeselectStudent,
  onDeselectAll,
  filters,
  onFilterChange,
  searchValue,
  onSearchValueChange,
  onSearch,
  pagination,
  onPageChange,
  totalCount,
  completedStudentIds,
  providerName,
  sessionDate,
  reduxSelectedStudents,
}) => {
  const isMobile = useMediaQuery(muiResponsive.MOBILE);
  const [searched, setSearched] = React.useState<boolean>(!!editableStudentsAsync.data?.length);

  const [filteredStudents, setFilteredStudents] = React.useState<EditableStudentsType[]>(
    editableStudentsAsync.data || [],
  );

  const location = useLocation();
  const hideChangeProvider =
    location.pathname === PATHS.addSessionLog || location.pathname === PATHS.addSessionLogTemplate ? true : false;
  const isSLFormTempRoute = location.pathname.includes(PATHS.addSessionLogTemplate);

  React.useEffect(() => {
    const filtered = (editableStudentsAsync.data || []).filter(
      student =>
        !selectedStudents.some(selectedStudent => {
          if (selectedStudent.supportId === null) {
            return selectedStudent.studentId === student.studentId;
          } else {
            return selectedStudent.supportId === student.supportId;
          }
        }),
    );
    setFilteredStudents(filtered);
  }, [editableStudentsAsync.data, selectedStudents]);

  const handleOnSearch = () => {
    onSearch(searchValue);
    setSearched(true);
  };

  const renderSearchStudent = () => (
    <>
      <Filters value={filters} onChange={onFilterChange} />
      <div className={`${clsPrefix}-search-students`}>
        <span className={`${clsPrefix}-search-students-title`}>Search Students</span>
        <Grid container spacing={isMobile ? 1 : 2} className={`${clsPrefix}-search-students-inputs`}>
          <Grid item xs={12} sm={5}>
            <TextField
              type="search"
              variant="outlined"
              label="First Name"
              value={searchValue.firstName}
              size={isMobile ? 'small' : 'medium'}
              fullWidth
              onChange={e =>
                onSearchValueChange({
                  ...searchValue,
                  firstName: e.target.value,
                })
              }
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  !isEmpty(searchValue.firstName) && handleOnSearch();
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              type="search"
              variant="outlined"
              label="Last Name"
              value={searchValue.lastName}
              size={isMobile ? 'small' : 'medium'}
              fullWidth
              onChange={e =>
                onSearchValueChange({
                  ...searchValue,
                  lastName: e.target.value,
                })
              }
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  !isEmpty(searchValue.lastName) && handleOnSearch();
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              startIcon={<FaSearch />}
              fullWidth
              color="secondary"
              onClick={handleOnSearch}
              disabled={
                editableStudentsAsync.loading ||
                (filters.supportTypes.includes('unsched') && !searchValue.firstName && !searchValue.lastName)
              }>
              SEARCH
            </Button>
          </Grid>
          {filters.supportTypes.includes('unsched') && !searchValue.firstName && !searchValue.lastName && (
            <span className={`${clsPrefix}-search-students-title red`}>
              Enter student’s First and/or Last Name to Search for non-caseload students.
            </span>
          )}
        </Grid>
        {!!selectedStudents.length && (
          <MuiFormControlContainer label="Selected Student(s)">
            <div className={`${clsPrefix}-selected-students-container`}>
              {selectedStudents.map((student, studentIndex) => {
                const completedStudent = completedStudentIds.some(s => s === student.studentId);
                // show InProgress when created log for selected students
                const inProgress = studentIndex === completedStudentIds.length && !isEmpty(reduxSelectedStudents);
                return (
                  <Chip
                    key={`${student.studentId} - ${student.supportId}`}
                    className={`${clsPrefix}-selected-students-chip`}
                    label={
                      <span>
                        {student.firstName} {student.lastName}
                        <br />
                        {student.studentExtId}
                        {completedStudent && (
                          <>
                            <br />
                            (COMPLETED)
                          </>
                        )}
                        {inProgress && !isSLFormTempRoute && (
                          <>
                            <br />
                            (IN PROGRESS)
                          </>
                        )}
                      </span>
                    }
                    {...(!completedStudent || (!inProgress && isSLFormTempRoute)
                      ? { onDelete: () => onDeselectStudent(student) }
                      : undefined)}
                    disabled={completedStudent || (inProgress && !isSLFormTempRoute)}
                  />
                );
              })}
              {/* <IconButton
                onClick={onDeselectAll}
                className={`${clsPrefix}-selected-students-deselect-all`}
                size="small">
                <CloseIcon />
              </IconButton> */}
            </div>
          </MuiFormControlContainer>
        )}
      </div>
    </>
  );

  const disabledCreateSLButton =
    selectedStudents.every(student => completedStudentIds.includes(student.studentId)) || !selectedStudents.length;

  const buttonText = showChangeProvider ? 'Change Provider' : 'Back';
  return (
    <Dialog
      open={open}
      title="Add Students"
      maxWidth="md"
      fullWidth
      fullScreen
      onClose={onClose}
      dialogContentClasses={{
        root: `${clsPrefix}-mui-dialog-content`,
      }}
      dialogActions={
        <>
          {showChangeProvider && !hideChangeProvider && (
            <Button color="secondary" onClick={() => onChangeProvider()}>
              {buttonText}
            </Button>
          )}
          <Button color="secondary" variant="contained" onClick={onCreateService} disabled={disabledCreateSLButton}>
            Create Log
          </Button>
        </>
      }>
      {!isMobile && (
        <StickyPageHeader className={`${clsPrefix}-sticky-header`}>{renderSearchStudent()}</StickyPageHeader>
      )}
      {isMobile && renderSearchStudent()}
      <PageContentContainer className={`${clsPrefix}-content`} loading={editableStudentsAsync.loading}>
        <div className={clsPrefix}>
          <div>
            <p className="mb-8" style={{ fontSize: '16px' }}>
              {searched && <>Results: {isEmpty(totalCount) ? 0 : totalCount} for </>}
              {providerName && (
                <>
                  {isMobile && <br />}Provider: <b>{providerName}</b>
                  {isMobile ? '' : ', '}
                </>
              )}
              {sessionDate && (
                <>
                  {isMobile && <br />}Session Date: <b>{getDateDisplay(sessionDate)}</b>
                </>
              )}
            </p>
            {!!filteredStudents?.length && !isMobile && (
              <table className={`${clsPrefix}-students-table`}>
                <tbody>
                  {filteredStudents.map((student, studentIndex) => (
                    <tr key={student.studentId + student.supportId}>
                      <td>
                        <IconButton color="secondary" onClick={() => onSelectStudent(student)}>
                          <FaPlusSquare />
                        </IconButton>
                      </td>
                      <td>
                        <Tooltip placement="bottom-start" title={ toolTipPreferences ? "Student Name" : ''}>
                          <h5>
                            {' '}
                            {student.firstName} {student.lastName}
                          </h5>
                        </Tooltip>
                        <Tooltip placement="bottom-start" title={ toolTipPreferences ? "Student ID" : ''}>
                          <p>{student.studentExtId}</p>
                        </Tooltip>
                      </td>
                      <td>
                        <Tooltip placement="bottom-start" title={ toolTipPreferences ? "School Name" : ''}>
                          <h5>{student.schoolName}</h5>
                        </Tooltip>
                        <Tooltip placement="bottom-start" title={ toolTipPreferences ? "Grade" : ''}>
                          <p>{student.grade}</p>
                        </Tooltip>
                      </td>
                      <td>
                        <h5>&nbsp;</h5>
                        <Tooltip placement="bottom-start" title={ toolTipPreferences ? "Gender" : ''}>
                          <p> {student.gender}</p>
                        </Tooltip>
                      </td>
                      <td>
                        <Tooltip title={ toolTipPreferences ? "Support Name" : ''}>
                          <h5>
                            {student.supportName}
                            {student.supportActiveFlag === true ? (
                              ''
                            ) : student.supportActiveFlag === false ? (
                              <span className="serviceInactive ml-8">Inactive</span>
                            ) : (
                              ''
                            )}
                          </h5>
                        </Tooltip>
                        <Tooltip title={ toolTipPreferences ? `Service Start Date - End Date ${student.esy ? `ESY` : ''}` : ''}>
                          <p>
                            {student.startDate &&
                              student.endDate &&
                              `${getDateDisplay(student.startDate)} - ${getDateDisplay(student.endDate)}`}
                            {student.esy ? ' (ESY)' : null}
                          </p>
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {!!filteredStudents?.length && isMobile && (
              <div className={`${clsPrefix}-students-table-mobile`}>
                {filteredStudents.map((student, studentIndex) => (
                  <div key={student.studentId + studentIndex} className={`${clsPrefix}-students-table-item-mobile`}>
                    <IconButton color="secondary" onClick={() => onSelectStudent(student)}>
                      <FaPlusSquare />
                    </IconButton>
                    <div className={`${clsPrefix}-students-table-item-mobile-content`}>
                      <div>
                        <span>
                          {' '}
                          <b>
                            {student.firstName} {student.lastName}
                          </b>
                        </span>
                        <span>
                          <b>{student.studentExtId}</b>
                        </span>
                      </div>
                      <div>
                        <span>{student.schoolName}</span>
                        <span>{student.grade}</span>
                        <span> {student.gender}</span>
                      </div>
                      <div>
                        <span>
                          {student.supportName}{' '}
                          {student.supportActiveFlag === true ? (
                            ''
                          ) : student.supportActiveFlag === false ? (
                            <span className="serviceInactive ml-8">Inactive</span>
                          ) : (
                            ''
                          )}
                        </span>
                      </div>
                      <div>
                        <span>
                          {getDateDisplay(student.startDate)}
                          {getDateDisplay(student.endDate, formattedDate => ` - ${formattedDate}`)}{' '}
                          {student.esy ? ' (ESY)' : null}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {!!editableStudentsAsync.data?.length && (
              <PaginationComponent
                count={pagination.totalPages}
                page={pagination.currentPage}
                onChange={(_, p) => onPageChange(p)}
                className={`${clsPrefix}-pagination`}
                size={isMobile ? 'small' : 'medium'}
              />
            )}
          </div>
        </div>
      </PageContentContainer>
    </Dialog>
  );
};
export default AddStudentDialog;
