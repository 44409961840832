import { Pagination } from 'src/redux/types';

export const getPage = <T extends unknown = unknown>(data: T[], pagination: Pagination): T[] => {
  if (!data?.length) return [];
  const { currentPage, pageSize } = pagination;
  const from = Math.min((currentPage - 1) * pageSize, data.length);
  const to = Math.min(from + pageSize, data.length);
  return data.slice(from, to);
};

export const calcNumberOfPages = (dataLength?: number, pageSize?: number): number => {
  if (!pageSize || !dataLength) return !!dataLength ? 1 : 0;
  return Math.ceil((dataLength || 0) / pageSize) || 1;
};

export const getCorrectPagination = (newPagination: Pagination, dataLength: number): Pagination => {
  const totalPages = calcNumberOfPages(dataLength, newPagination.pageSize);
  const currentPageIndex = totalPages > 0 ? Math.min(newPagination.currentPage, totalPages) : newPagination.currentPage;
  return {
    totalPages,
    currentPage: currentPageIndex,
    pageSize: newPagination.pageSize,
  };
};
