import { Grid, useMediaQuery } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { muiResponsive } from 'src/appConfig/muiTheme';
import { PATHS } from 'src/appConfig/paths';
import { EditableStudentsType } from 'src/redux/sessionLogsRedux/types';
import { getDateDisplay } from 'src/utils/momentUtils';
import { getSupportTypeName } from 'src/utils/nameUtils';
import { isEmpty } from 'src/validations';
import { Accordion, AccordionDetails, AccordionSummary } from './CustomAccordion';
import './styles.scss';

const clsPrefix = 'sl-student-info-banner';

type Props = {
  student: EditableStudentsType;
  loading: boolean;
};

const SLStudentInfoBanner: React.VFC<Props> = ({ student, loading }) => {
  const isMobile = useMediaQuery(muiResponsive.MOBILE);
  // service
  // sbbhtiered
  const [expanded, setExpanded] = React.useState<boolean>(false);

  useEffect(() => {
    setExpanded(false);
    localStorage.setItem('ProjectedEndDate', student.endDate);
    localStorage.setItem('ProjectedStartDate', student.startDate);
  }, [student]);
  
  //TODO: Update
  const handleChangeExpanded = () => {
    setExpanded(!expanded);
  };

  if (!student) {
    return <Redirect to={PATHS.sessionLogs} />;
  }

  return (
    <div className={`${clsPrefix}`}>
      <Accordion
        defaultExpanded={false}
        square={isMobile ? false : true}
        expanded={student.supportType === 'unsched' ? false : expanded}
        onClick={() => handleChangeExpanded()}>
        <AccordionSummary
          style={{
            position: 'relative',
            cursor: student.supportType === 'unsched' ? 'default' : 'pointer',
          }}>
          {student.supportType !== 'unsched' && (
            <i
              className={`${clsPrefix}-icon-expand`}
              style={{
                transform: expanded ? 'rotate(180deg)' : 'rotate(0)',
              }}>
              <ExpandMore style={{ color: '#fff' }} />
            </i>
          )}

          <Grid container item spacing={isMobile ? 1 : 2} className={`${clsPrefix}-summary`}>
            {!isMobile && (
              <>
                <Grid item xs={12} sm={3}>
                  <span>Student</span>
                  {loading ? (
                    <>
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                    </>
                  ) : (
                    <span>
                      {student.firstName} {student.lastName} ({student.studentExtId})
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} sm={2}>
                  <span>School/Grade</span>
                  {loading ? (
                    <Skeleton variant="text" />
                  ) : (
                    <span>
                      {student.schoolName} ({student.grade})
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} sm={2}>
                  <span>Gender</span>
                  {loading ? <Skeleton variant="text" /> : <span>{student.gender}</span>}
                </Grid>
                <Grid item xs={12} sm={2}>
                  <span>Birth Date</span>
                  {loading ? <Skeleton variant="text" /> : <span> {getDateDisplay(student.birthDate)}</span>}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <span>Support Type</span>
                  {loading ? (
                    <Skeleton variant="text" />
                  ) : (
                    <span>
                      {!isEmpty(student.supportName) ? student.supportName : getSupportTypeName(student.supportType)}
                    </span>
                  )}
                </Grid>
              </>
            )}
            {isMobile && (
              <>
                <Grid item xs={12}>
                  <h4>
                    {loading ? (
                      <Skeleton variant="text" />
                    ) : (
                      <>
                        {student.firstName} {student.lastName} ({student.studentExtId})
                      </>
                    )}
                  </h4>
                </Grid>
                <Grid item xs={12}>
                  <h5>
                    {loading ? (
                      <Skeleton variant="text" />
                    ) : (
                      <>
                        {student.schoolName} ({student.grade}) &nbsp; {student.gender}
                      </>
                    )}
                  </h5>
                </Grid>
                <Grid item xs={12}>
                  <h5>
                    {loading ? <Skeleton variant="text" /> : <>Birth Date: {getDateDisplay(student.birthDate)}</>}
                  </h5>
                </Grid>
                <Grid item xs={12}>
                  <h5>
                    {loading ? (
                      <Skeleton variant="text" />
                    ) : (
                      <>
                        {!isEmpty(student.supportName) ? student.supportName : getSupportTypeName(student.supportType)}
                        &nbsp;&nbsp;
                        {expanded && student.supportType !== 'unsched' ? (
                          <>
                            {student.startDate && getDateDisplay(student.startDate)}&nbsp;-&nbsp;
                            {student.endDate && getDateDisplay(student.endDate)}
                          </>
                        ) : null}
                      </>
                    )}
                  </h5>
                </Grid>
              </>
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={`${clsPrefix}-details`}>
          <Grid container item spacing={isMobile ? 1 : 2}>
            {!isMobile && (
              <>
                <Grid item xs={12} sm={3}>
                  {student.frequency && (
                    <>
                      <span>Frequency</span>
                      {student.frequency}
                    </>
                  )}
                </Grid>
                <Grid item xs={12} sm={2}>
                  {student.startDate && (
                    <>
                      <span>{student.supportType === 'service' && 'Projected'} Start Date</span>
                      {getDateDisplay(student.startDate)}
                    </>
                  )}
                </Grid>
                <Grid item xs={12} sm={2}>
                  {student.endDate && (
                    <>
                      <span>
                        {student.supportType === 'sbbhtiered' && !student.supportActiveFlag ? '' : 'Projected'} End Date
                      </span>
                      {getDateDisplay(student.endDate)}
                    </>
                  )}
                </Grid>
                <Grid item xs={12} sm={2}>
                  {student.supportType === 'service' && (
                    <>
                      <span>ESY</span>
                      {student.esy === true ? 'Yes' : student.esy === false ? 'No' : ''}
                    </>
                  )}
                </Grid>
              </>
            )}
            {isMobile && (
              <>
                <Grid item xs={12}>
                  <h5>
                    {student.frequency} {student.frequency && <>&nbsp; </>}
                    {student.supportType === 'service' && (
                      <>ESY: {student.esy === true ? 'Yes' : student.esy === false ? 'No' : ''}</>
                    )}
                  </h5>
                </Grid>
                {student.primaryTargetArea && (
                  <Grid item xs={12}>
                    <h5>Primary Target Area: {student.primaryTargetArea}</h5>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default SLStudentInfoBanner;
