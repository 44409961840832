import { withStyles } from '@material-ui/core/styles';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from '@material-ui/core';

export const Accordion = withStyles(theme => ({
  root: {
    boxShadow: 'none',
    padding: '16px 32px',
    backgroundColor: 'var(--primary)',
    color: '#fff',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0',
      padding: '8px 16px',
    },

    '&$expanded': {
      [theme.breakpoints.down('sm')]: {
        margin: '0',
      },
    },
  },
  expanded: {},
}))(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,
    padding: 0,
    margin: 0,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles(theme => ({
  root: {
    padding: '24px 0px 12px 0px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '8px',
    },
  },
}))(MuiAccordionDetails);
