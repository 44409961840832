import { Grid, IconButton, MenuItem, Popover, Tooltip } from '@material-ui/core';
import React from 'react';
import { FaCopy, FaPen, FaTrash } from 'react-icons/fa';

const noop = e => {};

export const ActionsButton = ({
  onEdit = noop,
  onCopy = noop,
  onDelete = noop,
  showDelete = true,
  renderAsMenu = false,
  asGridItem = false,
}) => {
  const buttons = {
    Edit: {
      onClick: onEdit,
      Icon: <FaPen />,
    },
    Clone: {
      onClick: onCopy,
      Icon: <FaCopy />,
    },

    Delete: showDelete
      ? {
          onClick: onDelete,
          Icon: <FaTrash color="#E31B0C" />,
        }
      : null,
  };

  if (!renderAsMenu) {
    if (asGridItem) {
      return (
        <>
          {Object.entries(buttons).map(
            ([label, b]) =>
              b && (
                <Grid item xs={4} key={label}>
                  <Tooltip title={label}>
                    <IconButton size="small" onClick={b.onClick}>
                      {b.Icon}
                    </IconButton>
                  </Tooltip>
                </Grid>
              ),
          )}
        </>
      );
    } else {
      return (
        <>
          {Object.entries(buttons).map(
            ([label, b]) =>
              b && (
                <Tooltip title={label}>
                  <IconButton key={label} size="small" onClick={b.onClick}>
                    {b.Icon}
                  </IconButton>
                </Tooltip>
              ),
          )}
        </>
      );
    }
  }

  return (
    <>
      {Object.entries(buttons).map(
        ([label, b]) =>
          b && (
            <MenuItem key={label} onClick={b.onClick} className={`actions-menu-item`}>
              <IconButton size="small">{b.Icon}</IconButton> <p className="ml-8">{label}</p>
            </MenuItem>
          ),
      )}
    </>
  );
};

const ActionsButtonPopover = ({ container, onClose, children, elevation = 8 }) => (
  <Popover
    open={!!container}
    anchorEl={container}
    onClose={onClose}
    elevation={elevation}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}>
    {children}
  </Popover>
);

export default ActionsButtonPopover;
