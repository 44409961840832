import MomentUtils from '@date-io/moment';
import { TextField, TextFieldProps } from '@material-ui/core';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FieldProps } from 'formik';
import { IoMdTime } from 'react-icons/io';
import moment, { Moment } from 'moment';
import './styles.scss';
import React from 'react';
import { isEmpty } from 'src/validations';

type MuiTimePickerProps = {
  value: Moment;
  onChange: (time: Moment, value?: string) => void;
  label: string;
  timeFormat?: string;
  color?: 'primary' | 'secondary';
  step?: number;
  name?: string;
  ampm?: boolean;
  errorMessage?: string;
  onBlur?: () => void;
};

const StyledTextField = (props: TextFieldProps) => (
  <TextField
    className="cmp-date-picker"
    {...props}
    variant="outlined"
    error={props.error}
    helperText={props.helperText}
    inputProps={{
      autoComplete: 'off',
    }}
    InputLabelProps={{ shrink: props.value ? true : false }}
  />
);

const MuiTimePicker: React.VFC<MuiTimePickerProps> = ({
  value,
  onChange,
  label,
  color = 'primary',
  step = 1,
  timeFormat = 'hh:mm A',
  ampm = true,
  errorMessage,
  onBlur = () => {},
  ...otherProps
}) => {
  return (
    <KeyboardTimePicker
      ampm={ampm}
      label={label}
      format={timeFormat}
      color={color}
      value={value}
      onChange={onChange}
      KeyboardButtonProps={{
        'aria-label': label,
      }}
      mask="__:__ _M"
      keyboardIcon={<IoMdTime />}
      TextFieldComponent={StyledTextField}
      minutesStep={step > 0 && step < 60 ? Math.round(step) : 1}
      error={errorMessage === ' ' || isEmpty(errorMessage) ? false : true}
      helperText={errorMessage}
      onBlur={onBlur}
      {...otherProps}
    />
  );
};
export const MuiTimePickerMomentUtilsProvider = ({ children }) => (
  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
    {children}
  </MuiPickersUtilsProvider>
);

export const MuiTimePickerFormikField: React.VFC<
  FieldProps &
    Omit<MuiTimePickerProps, 'onChange' | 'value' | 'disabledHelperText'> & {
      onChange?: (time: Moment) => void;
      disabledHelperText?: boolean;
    }
> = ({
  field: { name, value },
  form: { setFieldValue, errors, values, setFieldTouched },
  meta: { touched, error },
  onChange,
  disabledHelperText = false,
  ...otherProps
}) => {
  const handleChange = (time: Moment) => {
    onChange ? onChange(time) : setFieldValue(name, time);
  };
  const setTouched = () => setFieldTouched(name, true);

  return (
    <MuiTimePicker
      name={name}
      value={(value && moment(value)) || null}
      onChange={handleChange}
      errorMessage={touched && error ? error : disabledHelperText ? '' : ' '}
      onBlur={setTouched}
      {...otherProps}
    />
  );
};

export default MuiTimePicker;
