import { IconButton } from '@material-ui/core';
import { FaBars } from 'react-icons/fa';
import { connect } from 'react-redux';
import { toggleSideMenu } from 'src/redux/commonRedux/actions';

const BurgerMenuButton = ({ className, toggle }) => {
  return (
    <IconButton onClick={toggle}>
      <FaBars className={className} />
    </IconButton>
  );
};

const mapDispatch = {
  toggle: toggleSideMenu,
};

export default connect(null, mapDispatch)(BurgerMenuButton);
