import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { isActive, SidebarMenu } from 'src/appConfig/sidebar';
import { selectUserPermissions } from 'src/redux/authRedux/selectors';
import { setCollapseSidebar } from 'src/redux/commonRedux/actions';
import { IRootState } from 'src/redux/rootReducer';
import './styles.scss';

type Props = { defaultHide?: boolean } & ReturnType<typeof mapState> & typeof mapDispatch;

export const SideMenu: React.FC<Props> = ({ defaultHide, isCollapsed, setCollapseSidebar, permissions }) => {
  React.useEffect(() => {
    setCollapseSidebar(defaultHide);
  }, [setCollapseSidebar, defaultHide]);

  return (
    <div className={['side-bar-drawer', isCollapsed && 'hide'].join(' ')}>
      <ul className="side-bar-list">
        {SidebarMenu.map(({ title, href, icon }) => {
          if (
            (!permissions.isScheduler && title === 'SCHEDULER') ||
            (!permissions.isSL && title === 'SESSION LOGS') ||
            (!permissions.isTS && title === 'TIERED SERVICES')
          ) {
            return null;
          } else
            return (
              <li className={['side-bar-list-item', isActive(href) && 'active'].join(' ')} key={title}>
                <Link to={href}>
                  {icon}
                  <p className="side-bar-item-text">{title}</p>
                </Link>
              </li>
            );
        })}
      </ul>
    </div>
  );
};
const mapDispatch = {
  setCollapseSidebar,
};

const mapState = (state: IRootState) => ({
  isCollapsed: state.common.collapseSidebar,
  permissions: selectUserPermissions(state),
});
export default connect(mapState, mapDispatch)(SideMenu);
