import MomentUtils from '@date-io/moment';
import { TextField, TextFieldProps } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FieldProps } from 'formik';
import moment, { Moment } from 'moment';
import { Callback } from 'src/redux/types';
import { isEmpty } from 'src/validations';
import './styles.scss';

type MuiDatePickerProps = {
  value: Moment;
  onChange: (date: Moment, value?: string) => void;
  label: string;
  dateFormat?: string;
  color?: 'primary' | 'secondary';
  maxDate?: Moment;
  minDate?: Moment;
  name?: string;
  disabled?: boolean;
  errorMessage?: string;
  onBlur?: () => void;
};

const StyledTextField = (props: TextFieldProps) => (
  <TextField
    className="cmp-date-picker"
    {...props}
    variant="outlined"
    inputProps={{
      autoComplete: 'off',
    }}
    InputLabelProps={{ shrink: props.value ? true : false }}
  />
);

const MuiDatePicker: React.VFC<MuiDatePickerProps> = ({
  value,
  onChange,
  label,
  dateFormat = 'MM/DD/yyyy',
  color = 'primary',
  maxDate = moment().endOf('year'),
  minDate = moment().subtract(1, 'years'),
  disabled = false,
  errorMessage,
  onBlur = () => {},
  ...otherProps
}) => {
  return (
    <KeyboardDatePicker
      error={errorMessage === ' ' || isEmpty(errorMessage) ? false : true}
      helperText={errorMessage}
      // disableToolbar
      variant="inline"
      format={dateFormat}
      label={label}
      value={value}
      onChange={onChange}
      KeyboardButtonProps={{
        'aria-label': label,
      }}
      color={color}
      allowKeyboardControl
      maxDate={maxDate}
      minDate={minDate}
      TextFieldComponent={StyledTextField}
      disabled={disabled}
      onBlur={onBlur}
      {...otherProps}
    />
  );
};
export const MuiDatePickerMomentUtilsProvider = ({ children }) => (
  <MuiPickersUtilsProvider utils={MomentUtils}>{children}</MuiPickersUtilsProvider>
);

export const MuiDatePickerFormikField: React.VFC<
  FieldProps &
    Omit<MuiDatePickerProps, 'onChange' | 'value'> & {
      disabledHelperText?: boolean;
      onChange?: Callback;
    }
> = ({
  field: { name, value },
  form: { setFieldValue, setFieldTouched },
  meta: { touched, error },
  disabledHelperText = false,
  errorMessage={},
  onChange,
  ...otherProps
}) => {
  const handleChange = (date: Moment) => {
    setFieldValue(name, date);
  };
  const setTouched = () => setFieldTouched(name, true);

  return (
    <MuiDatePicker
      name={name}
      value={(value && moment(value)) || null}
      onChange={onChange ? onChange : handleChange}
      errorMessage={touched && error ? error : disabledHelperText ? '' : ' '}
      onBlur={setTouched}
      {...otherProps}
    />
  );
};

export default MuiDatePicker;
