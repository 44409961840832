export type OutcomeOption = {
  id: number;
  name: string;
};
export const outcomes: OutcomeOption[] = [
  { id: 1, name: '1 - Deterioration <0%' },
  { id: 2, name: '2 - No Significant Changes 0% - 10%' },
  { id: 3, name: '3 - Minimal Improvement 11% - 30%' },
  { id: 4, name: '4 - Some Improvement 31% - 50%' },
  { id: 5, name: '5 - Moderate Improvement 51% - 70%' },
  { id: 6, name: '6 - Significant Improvement 71% - 90%' },
  { id: 7, name: '7 - Complete Improvement 91% - 100%' },
];
