import { Button, Chip, IconButton, TextField, Tooltip } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { FaPlusSquare, FaSearch } from 'react-icons/fa';
import { muiResponsive } from 'src/appConfig/muiTheme';
import Dialog from 'src/components/common/Dialog';
import MuiFormControlContainer from 'src/components/common/MuiFormControlContainer';
import PaginationComponent from 'src/components/common/Pagination';
import PageContentContainer from 'src/components/PageContentContainer';
import StickyPageHeader from 'src/components/StickyPageHeader';
import { EditableStudentName } from 'src/redux/tieredServices/types';
import { Pagination } from 'src/redux/types';
import Filters, { StudentFilters } from './Filters';
import './styles.scss';
import { useLocation } from 'react-router-dom';
import { PATHS } from 'src/appConfig/paths';
import { isEmpty } from 'src/validations';

export type SearchName = { firstName?: string; lastName?: string };

type Props = {
  open: boolean;
  onClose: () => void;
  onChangeProvider: () => void;
  showChangeProvider: boolean;
  onCreateService: () => void;
  records: EditableStudentName[];
  loading: boolean;
  selectedStudents: EditableStudentName[];
  totalCount: number;
  onSelectStudent: (student: EditableStudentName) => void;
  onDeselectStudent: (student: EditableStudentName) => void;
  onDeselectAll: () => void;
  filters: StudentFilters;
  onFilterChange: (filter: StudentFilters) => void;
  searchValue: SearchName;
  onSearch: (names: SearchName) => void;
  onSearchValueChange: (name: SearchName) => void;
  pagination: Pagination;
  onPageChange: (p: number) => void;
  completedStudentIds: (string | number)[];
  providerName: string;
  reduxSelectedStudents: EditableStudentName[];
};
const clsPrefix = 'add-service-dialog';
const toolTipPreferences = localStorage.getItem('toolTipPreferences') === 'true' ? true : false;

const AddStudentDialog = ({
  open,
  onClose,
  onChangeProvider,
  showChangeProvider,
  providerName,
  onCreateService,
  records,
  loading,
  selectedStudents,
  totalCount,
  onSelectStudent,
  onDeselectStudent,
  onDeselectAll,
  filters,
  onFilterChange,
  searchValue,
  onSearchValueChange,
  onSearch,
  pagination,
  onPageChange,
  completedStudentIds,
  reduxSelectedStudents,
}: Props) => {
  const isMobile = useMediaQuery(muiResponsive.MOBILE);
  const [searched, setSearched] = React.useState<boolean>(!!records?.length);
  const [filteredStudents, setFilteredStudents] = React.useState<EditableStudentName[]>(records || []);

  React.useEffect(() => {
    const filtered = records.filter(
      student => !selectedStudents.some(selectedStudent => selectedStudent.studentId === student.studentId),
    );
    setFilteredStudents(filtered);
  }, [records, selectedStudents]);

  const handleOnSearch = () => {
    onSearch(searchValue);
    setSearched(true);
  };

  const disabledCreateTSButton =
    selectedStudents.every(student => completedStudentIds.includes(student.studentId)) || !selectedStudents.length;

  const location = useLocation();
  const hideChangeProvider = location.pathname === PATHS.addTieredService ? true : false;

  const renderSearchStudents = () => {
    return (
      <div style={{ margin: isMobile ? '16px' : 0 }}>
        <Filters value={filters} onChange={onFilterChange} />
        <span className={`${clsPrefix}-search-students-title`}>Search Students</span>
        <div className={`${clsPrefix}-search-students-inputs`}>
          <TextField
            variant="outlined"
            label="First Name"
            value={searchValue.firstName}
            fullWidth
            onChange={e =>
              onSearchValueChange({
                ...searchValue,
                firstName: e.target.value,
              })
            }
            onKeyDown={e => {
              if (e.key === 'Enter') {
                searchValue.firstName && handleOnSearch();
              }
            }}
          />
          <TextField
            variant="outlined"
            label="Last Name"
            value={searchValue.lastName}
            fullWidth
            onChange={e =>
              onSearchValueChange({
                ...searchValue,
                lastName: e.target.value,
              })
            }
            onKeyDown={e => {
              if (e.key === 'Enter') {
                searchValue.lastName && handleOnSearch();
              }
            }}
          />
          <Button
            variant="contained"
            startIcon={<FaSearch />}
            color="secondary"
            onClick={handleOnSearch}
            disabled={loading || (!searchValue.firstName && !searchValue.lastName)}>
            SEARCH
          </Button>
        </div>
        {!!selectedStudents.length && (
          <MuiFormControlContainer label="Selected Student(s)">
            <div className={`${clsPrefix}-selected-students-container`}>
              {selectedStudents.map((student, studentIndex) => {
                const completedStudent = completedStudentIds.some(s => s === student.studentId);
                // show InProgress when created log for selected students
                const inProgress = studentIndex === completedStudentIds.length && !isEmpty(reduxSelectedStudents);

                return (
                  <Chip
                    key={student.studentId}
                    className={`${clsPrefix}-selected-students-chip`}
                    label={
                      <span>
                        {student.firstName} {student.lastName}
                        <br />
                        {student.studentExtId}
                        {completedStudent && (
                          <>
                            <br />
                            (COMPLETED)
                          </>
                        )}
                        {inProgress && (
                          <>
                            <br />
                            (IN PROGRESS)
                          </>
                        )}
                      </span>
                    }
                    {...(!completedStudent || !inProgress ? { onDelete: () => onDeselectStudent(student) } : undefined)}
                    disabled={completedStudent || inProgress}
                  />
                );
              })}
              {/* <IconButton
                onClick={onDeselectAll}
                className={`${clsPrefix}-selected-students-deselect-all`}
                size="small">
                <CloseIcon />
              </IconButton> */}
            </div>
          </MuiFormControlContainer>
        )}
      </div>
    );
  };

  return (
    <Dialog
      open={open}
      title="Add Students"
      maxWidth="md"
      fullWidth
      fullScreen
      onClose={onClose}
      dialogContentClasses={{
        root: `${clsPrefix}-mui-dialog-content`,
      }}
      dialogActions={
        <>
          {showChangeProvider && !hideChangeProvider && (
            <Button color="secondary" onClick={onChangeProvider}>
              Change Provider
            </Button>
          )}
          <Button color="secondary" variant="contained" onClick={onCreateService} disabled={disabledCreateTSButton}>
            Create Tiered Service
          </Button>
        </>
      }>
      {!isMobile && (
        <StickyPageHeader className={`${clsPrefix}-sticky-header`}>{renderSearchStudents()}</StickyPageHeader>
      )}
      {isMobile && renderSearchStudents()}
      <PageContentContainer
        className={`${clsPrefix}-content`}
        contentClassName={`${clsPrefix}-content-page-content`}
        loading={loading}>
        <div className={clsPrefix}>
          <div>
            <p className="mb-8" style={{ fontSize: '16px' }}>
              {searched && (
                <>
                  Results: {isEmpty(totalCount) ? 0 : totalCount}
                  {' for '}
                </>
              )}
              {providerName && (
                <>
                  Provider: <b>{providerName}</b>
                </>
              )}
            </p>
            {!!filteredStudents?.length && !isMobile && (
              <table className={`${clsPrefix}-students-table`}>
                <tbody>
                  {filteredStudents.map(student => (
                    <tr key={student.studentId}>
                      <td>
                        <IconButton color="secondary" onClick={() => onSelectStudent(student)}>
                          <FaPlusSquare />
                        </IconButton>
                      </td>
                      <td>
                        <Tooltip title={ toolTipPreferences ? "Student Name" : ''} placement="bottom-start">
                          <h5>{' '}
                            {student.firstName} {student.lastName}</h5>
                        </Tooltip>
                        <Tooltip title={ toolTipPreferences ? "Student ID" : ''} placement="bottom-start">
                          <p>{student.studentExtId}</p>
                        </Tooltip>
                      </td>
                      <td>
                        <Tooltip title={ toolTipPreferences ? "School Name" : ''} placement="bottom-start">
                          <h5>{student.schoolName}</h5>
                        </Tooltip>
                        <Tooltip title={ toolTipPreferences ? "Grade" : ''} placement="bottom-start">
                          <p>{student.grade}</p>
                        </Tooltip>
                      </td>
                      <td>
                        <h5>&nbsp;</h5>
                        <Tooltip title={ toolTipPreferences ? "Gender" : ''} placement="bottom-start">
                          <p>{student.gender}</p>
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {!!filteredStudents?.length && isMobile && (
              <div className={`${clsPrefix}-students-table-mobile`}>
                {filteredStudents.map(student => (
                  <div className={`${clsPrefix}-students-table-item-mobile`}>
                    <IconButton color="secondary" onClick={() => onSelectStudent(student)}>
                      <FaPlusSquare />
                    </IconButton>
                    <div className={`${clsPrefix}-students-table-item-mobile-content`}>
                      <div className={`student-id`}>
                        <span>
                          <b>{student.studentName}</b>
                        </span>{' '}
                        <span>
                          <b>{student.studentExtId}</b>
                        </span>
                      </div>
                      <div>
                        <span>{student.schoolName}</span>
                        <span>{student.grade}</span>
                        <span>{student.gender}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {!!records?.length && (
              <PaginationComponent
                count={pagination.totalPages}
                page={pagination.currentPage}
                onChange={(_, p) => onPageChange(p)}
                className={`${clsPrefix}-pagination`}
                size={isMobile ? 'small' : 'medium'}
              />
            )}
          </div>
        </div>
      </PageContentContainer>
    </Dialog>
  );
};
export default AddStudentDialog;
