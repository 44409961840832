import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#673AB7',
    },
    secondary: {
      main: '#009688',
    },
  },
});

export const muiResponsive = {
  MOBILE: '(max-width:600px)',
  MEDIUM_SCREEN: '(max-width:1279px)',
};
