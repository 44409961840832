import { ApiResponse } from 'apisauce';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { Navigator, Toastify } from 'src/services';
import { Apis } from 'src/services/api';
import { callApi } from '../commonSagas/callApi';
import { toastifyErrorSaga } from '../commonSagas/toastifyFailureSaga';
import { IRootState } from '../rootReducer';
import { ApiResponse as ApiStatusWrapperResponse } from '../types';
import {
  CreateTieredServiceLogPayload,
  createTieredServiceLogsAsync,
  getGradeTSAsync,
  getProviderHUserTSAsync,
  getTieredServiceByIdAsync,
  getTieredServiceByIdForEdittingOrCloningAsync,
  GetTieredServiceParams,
  getTieredServicesAsync,
  // getTieredServiceSessionLogsAsync,
  getTieredServiceTargetsAndPracticesAsync,
  removeTieredServiceAsync,
  searchAlternativeProvidersAsync,
  searchProvidersAsync,
  searchProvidersToAddTSAsync,
  searchSchoolsAsync,
  searchSchoolsForFilterAsync,
  searchStudentsAsync,
  searchStudentsToAddServiceAsync,
  updateTieredServiceLogsAsync,
} from './action';
import { TieredServiceDetail } from './TieredServiceDetail';
import { selectCurrentSearchParams, selectSubmissionRecordsTS, selectUpdateTSSuccess } from './selectors';
import { PATHS } from 'src/appConfig/paths';
import { selectCurrentPathname } from '../commonSagas/selectors';

function* getTieredServices(api, { payload }: any) {
  yield call(
    callApi,
    api,
    {
      asyncAction: getTieredServicesAsync,
      onFailure: toastifyErrorSaga,
    },
    payload,
  );
}

function* reloadTieredServices() {
  const hasSearched = yield select((state: IRootState) => !!state.tieredServices.services?.data?.length);
  if (hasSearched) {
    const params = yield select((state: IRootState) => state.tieredServices.services.currentSearchParams);
    yield put(getTieredServicesAsync.request(params));
  }
}

function* getDataWithoutParams(api, asyncActionCreator) {
  yield call(callApi, api, {
    asyncAction: asyncActionCreator,
    onFailure: toastifyErrorSaga,
  });
}

function* searchFilters(api, asyncActionCreator, { payload }: any) {
  yield call(
    callApi,
    api,
    {
      asyncAction: asyncActionCreator,
      onFailure: toastifyErrorSaga,
    },
    payload,
  );
}
function* searchAlternativeProviders(api, asyncActionCreator, { payload }: any) {
  yield call(
    callApi,
    api,
    {
      asyncAction: asyncActionCreator,
    },
    payload,
  );
}

function* removeTieredService(api, { payload }: ReturnType<typeof removeTieredServiceAsync.request>) {
  let response;
  try {
    response = yield call(api, payload);
    yield put(removeTieredServiceAsync.success(payload));
    Toastify.success(`Tiered Service has been successfully removed`);
    yield call(handleRefetchLogs);
  } catch (err) {
    toastifyErrorSaga(response);
    yield put(removeTieredServiceAsync.failure(err));
  }
}

function* getTieredServiceById(
  api,
  asyncAction: typeof getTieredServiceByIdAsync | typeof getTieredServiceByIdForEdittingOrCloningAsync,
  {
    payload,
  }: ReturnType<
    typeof getTieredServiceByIdAsync.request | typeof getTieredServiceByIdForEdittingOrCloningAsync.request
  >,
) {
  yield call(
    callApi,
    api,
    {
      asyncAction: asyncAction,
      onFailure: toastifyErrorSaga,
    },
    payload,
  );
}

// function* getTieredServiceSessionLogs(api, action: ReturnType<typeof getTieredServiceSessionLogsAsync.request>) {
//   try {
//     const response = yield call(api, action.payload);
//     if (response.ok && response.data?.status === 'success' && response.data?.data[0]) {
//       const data = response.data.data[0];
//       return yield put(getTieredServiceSessionLogsAsync.success(data));
//     } else {
//       yield put(getTieredServiceSessionLogsAsync.failure(new Error(`No session logs with plan id: ${action.payload}`)));
//       return console.error(`No session logs with plan id: ${action.payload}`);
//     }
//   } catch (err) {
//     return null;
//   }
// }

type TieredServiceCreateResponse = ApiResponse<ApiStatusWrapperResponse<TieredServiceDetail>>;

function* createTieredService(createTsApi, payload: CreateTieredServiceLogPayload) {
  try {
    const postResponse: TieredServiceCreateResponse = yield call(createTsApi, payload);
    if (postResponse.ok && postResponse.data?.status === 'success' && postResponse.data?.data) {
      const data = postResponse.data.data;
      return data;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
}

function* createTieredServiceLogs(api, action: ReturnType<typeof createTieredServiceLogsAsync.request>) {
  try {
    const { payloads } = action.payload;
    const calls = payloads.map(payload => call(createTieredService, api, payload));

    const successItems: TieredServiceDetail[] = yield all(calls);

    if (successItems.filter(i => !!i).length) {
      yield put(
        createTieredServiceLogsAsync.success({
          records: successItems,
          final: action.payload.final,
        }),
      );
      const recordsSubmission: {
        records: TieredServiceDetail[];
        final: boolean;
      } = yield select(selectSubmissionRecordsTS);
      if (recordsSubmission.records?.length) {
        if (recordsSubmission.final) {
          Navigator.navigate(PATHS.submissionHistory);
        }
        Toastify.success('Tiered Services has been successfully submitted');
      }
    } else {
      Toastify.error('Failed to create tiered service log(s).');
      yield put(createTieredServiceLogsAsync.failure(new Error('Failed to create tiered service log(s).')));
    }
  } catch (err) {
    yield put(createTieredServiceLogsAsync.failure(err));
  }
}

function* getTieredServiceTargetsAndPractices(apis) {
  try {
    const responses = yield all(apis.map(api => call(api)));

    const [targets, practices] = responses.map(res => (res.ok && res.status === 200 ? res.data.data : null));

    yield put(
      getTieredServiceTargetsAndPracticesAsync.success({
        targets,
        practices,
      }),
    );
  } catch (err) {
    yield put(getTieredServiceTargetsAndPracticesAsync.failure(err));
  }
}

function* updateTieredServiceLog(api, action: ReturnType<typeof updateTieredServiceLogsAsync.request>) {
  try {
    const planId = action.payload.planId;
    const updateResult: ApiResponse<ApiStatusWrapperResponse<TieredServiceDetail>> = yield call(
      api,
      planId,
      action.payload,
    );

    if (updateResult.ok && updateResult.data?.status === 'success') {
      yield put(
        updateTieredServiceLogsAsync.success({
          records: [updateResult.data.data],
          final: true,
        }),
      );

      const recordsSubmission: {
        records: TieredServiceDetail[];
        final: boolean;
      } = yield select(selectSubmissionRecordsTS);
      const updateSuccess = yield select(selectUpdateTSSuccess);
      if (recordsSubmission.records?.length && updateSuccess) {
        Navigator.navigate(PATHS.submissionHistory);
        Toastify.success('Updated Tiered Service successfully!');
      }
    }
  } catch (err) {
    yield put(updateTieredServiceLogsAsync.failure(err));
    Toastify.error('Failed to update Tiered Service.');
  }
}

function* handleRefetchLogs() {
  const pathname: string = yield select(selectCurrentPathname);
  if (pathname === PATHS.tieredService) {
    const params: GetTieredServiceParams = yield select(selectCurrentSearchParams);
    yield put(getTieredServicesAsync.request(params));
  }
}

export default function schedulersSagas(apiInstance: Apis) {
  return [
    takeLatest(getTieredServicesAsync.request, getTieredServices, apiInstance.getTieredServices),
    takeLatest(searchProvidersAsync.request, searchFilters, apiInstance.searchTSProviders, searchProvidersAsync),
    takeLatest(
      searchAlternativeProvidersAsync.request,
      searchAlternativeProviders,
      apiInstance.searchTSPotentialProviders,
      searchAlternativeProvidersAsync,
    ),
    takeLatest(
      searchProvidersToAddTSAsync.request,
      searchFilters,
      apiInstance.searchTSProvidersProxiesAddTS,
      searchProvidersToAddTSAsync,
    ),
    takeLatest(searchStudentsAsync.request, searchFilters, apiInstance.searchTSStudents, searchStudentsAsync),
    takeLatest(searchSchoolsAsync.request, searchFilters, apiInstance.searchTSSchools, searchSchoolsAsync),
    takeLatest(
      searchSchoolsForFilterAsync.request,
      searchFilters,
      apiInstance.searchTSSchools,
      searchSchoolsForFilterAsync,
    ),
    takeLatest(
      searchStudentsToAddServiceAsync.request,
      searchFilters,
      apiInstance.searchEditableStudents,
      searchStudentsToAddServiceAsync,
    ),
    takeLatest(removeTieredServiceAsync.request, removeTieredService, apiInstance.removeTieredPlan),
    takeLatest(
      getTieredServiceByIdAsync.request,
      getTieredServiceById,
      apiInstance.getTieredServiceById,
      getTieredServiceByIdAsync,
    ),
    takeLatest(
      getTieredServiceByIdForEdittingOrCloningAsync.request,
      getTieredServiceById,
      apiInstance.getTieredServiceById,
      getTieredServiceByIdForEdittingOrCloningAsync,
    ),
    // takeLatest(
    //   getTieredServiceSessionLogsAsync.request,
    //   getTieredServiceSessionLogs,
    //   apiInstance.getTieredServiceSessionLogs,
    // ),
    takeLatest(getTieredServiceTargetsAndPracticesAsync.request, getTieredServiceTargetsAndPractices, [
      apiInstance.getTieredServiceTargets,
      apiInstance.getTieredServicePractices,
    ]),
    takeLatest(createTieredServiceLogsAsync.request, createTieredServiceLogs, apiInstance.createTieredServiceLog),
    takeLatest(updateTieredServiceLogsAsync.request, updateTieredServiceLog, apiInstance.updateTieredServiceLog),
    takeLatest([createTieredServiceLogsAsync.success, updateTieredServiceLogsAsync.success], reloadTieredServices),
    takeLatest(getProviderHUserTSAsync.request, searchFilters, apiInstance.getProviderHUser, getProviderHUserTSAsync),
    takeLatest(getGradeTSAsync.request, getDataWithoutParams, apiInstance.getGradesTS, getGradeTSAsync),
  ];
}
