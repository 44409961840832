export const LOCAL_STORAGE_DEFAULT_ROUTE = 'default-route';

const clearLocalDefaultRoute = () => {
  localStorage.removeItem(LOCAL_STORAGE_DEFAULT_ROUTE);
};

const getLocalDefaultRoute = () => {
  const token = localStorage.getItem(LOCAL_STORAGE_DEFAULT_ROUTE);
  return token;
};

const setLocalDefaultRoute = (route: string) => {
  localStorage.setItem(LOCAL_STORAGE_DEFAULT_ROUTE, route);
};

export default {
  clearLocalDefaultRoute,
  getLocalDefaultRoute,
  setLocalDefaultRoute,
};
