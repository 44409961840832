import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import authReducer, { IAuthState } from './authRedux/reducer';
import commonReducer, { ICommonState } from './commonRedux/reducer';

import schedulersReducer, { SchedulersState } from './schedulerRedux/reducer';
import sessionLogsReducer, { SessionLogsState } from './sessionLogsRedux/reducer';
import serviceLogsReducer, { TieredServicesState } from './tieredServices/reducer';

export interface IRootState {
  router: RouterState;
  common: ICommonState;
  auth: IAuthState;

  schedulers: SchedulersState;
  tieredServices: TieredServicesState;
  sessionLogs: SessionLogsState;
}

/* ------------- Assemble The Reducers ------------- */
const createRootReducer = (history: History) =>
  combineReducers<IRootState>({
    router: connectRouter(history),
    common: commonReducer,
    auth: authReducer,
    schedulers: schedulersReducer,
    tieredServices: serviceLogsReducer,
    sessionLogs: sessionLogsReducer,
  });

export default createRootReducer;
