import { Button, DialogProps } from '@material-ui/core';
import React from 'react';
import Dialog from 'src/components/common/Dialog';
import MuiTextarea from 'src/components/common/MuiTextarea';

export const ReasonForEdittingDialog: React.FC<
  {
    onClose: () => void;
    onConfirm: (reason: string) => void;
  } & DialogProps
> = ({ onClose, onConfirm, ...dialogProps }) => {
  const [value, setValue] = React.useState<string>('');
  return (
    <Dialog
      title="Reason for Editing"
      onClose={onClose}
      dialogActions={
        <>
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button color="secondary" variant="contained" onClick={() => onConfirm(value)} disabled={!value}>
            Next
          </Button>
        </>
      }
      {...dialogProps}>
      <MuiTextarea
        className="mt-16 mb-16"
        value={value}
        onChange={setValue}
        placeholder="Please enter reason for editing here"
      />
    </Dialog>
  );
};
