import { PATHS } from './../../appConfig/paths';
import { AuthResponse, Permissions, User } from './types';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { Toastify, TokenService } from 'src/services';
import { Apis } from 'src/services/api';
import {
  exchangeTokenAsync,
  signInAsync,
  signOutAsync,
  UpdateUserPreferencesTooltipAsync,
  UpdateUserPreferencesDefaultMenuAsync,
  GetUserPreferencesAsync,
} from './actions';
import { SOMETHING_WENT_WRONG, toastifyErrorSaga } from '../commonSagas/toastifyFailureSaga';
import { push } from 'connected-react-router';
import { callApi } from '../commonSagas/callApi';
import { selectDefaultMenu, selectUserPermissions, selectUserPreferencesTooltip } from './selectors';
import { getUserDefaultRoute } from 'src/utils/getUserDefaultRoute';
import { DefaultRoute } from 'src/services';

function* signIn(apiInstance, { payload: params }: ReturnType<typeof signInAsync.request>) {
  try {
    const response = yield call(apiInstance, params);
    const data: AuthResponse = response.data;
    if (response.ok && data?.status === 'success') {
      const token = data.data.token;
      TokenService.setToken(token);
      yield put(signInAsync.success(data.data));
      const user: User = data.data?.user;
      localStorage.setItem('userId', String(user.userId));
      localStorage.setItem('userFullName', user.fullName);
      yield put(GetUserPreferencesAsync.request({ userId: user.userId }));
    }
    if (!response.ok || data?.status === 'error') {
      yield put(signInAsync.failure(new Error(data?.message || SOMETHING_WENT_WRONG)));
    }
  } catch (err) {
    yield put(signInAsync.failure(err));
  }
}

function* checkValidToken(api, { payload: params }: ReturnType<typeof exchangeTokenAsync.request>) {
  try {
    const response = yield call(api, params);
    const data = response.data;
    if (response.ok && data?.status === 'success') {
      const token = params.token;
      TokenService.setToken(token);
      yield put(exchangeTokenAsync.success({ user: data.data, token: token }));

      return;
    }
    if (!response.ok || data?.status === 'error') {
      yield put(exchangeTokenAsync.failure(new Error(data?.message || SOMETHING_WENT_WRONG)));
      yield put(push(PATHS.signIn));
    }
  } catch (err) {
    yield put(exchangeTokenAsync.failure(new Error(err || SOMETHING_WENT_WRONG)));
  }
}

function* signOut() {
  TokenService.clearToken();
  DefaultRoute.clearLocalDefaultRoute();
  localStorage.removeItem('toolTipPreferences');
  localStorage.removeItem('userId');
  localStorage.removeItem('userFullName');
  yield all([yield put(signOutAsync.success()), yield put(push(PATHS.signIn))]);
}

function* getUserPreferences(api, asyncActionCreator, { payload: params }: any) {
  yield call(
    callApi,
    api,
    {
      asyncAction: asyncActionCreator,
      onFailure: toastifyErrorSaga,
    },
    params,
  );
}

function* checkPermissionAndPreferences() {
  const permissions: Permissions = yield select(selectUserPermissions);
  const userDefaultRoute = yield select(selectDefaultMenu);
  const userToolTipPreferences = yield select(selectUserPreferencesTooltip);
  localStorage.setItem('toolTipPreferences', userToolTipPreferences);

  if (!permissions.isScheduler && !permissions.isSL && !permissions.isTS && !permissions.isAdmin) {
    return yield put(signInAsync.failure(new Error('This user does not have rights to access this app.')));
  }

  const userRoute = getUserDefaultRoute(permissions, userDefaultRoute);
  DefaultRoute.setLocalDefaultRoute(userRoute);
  yield put(push(userRoute));

  return;
}

function* updateUserPreferencesTooltip(
  apiInstance,
  asyncActionCreator,
  { payload: params }: ReturnType<typeof UpdateUserPreferencesTooltipAsync.request>,
) {
  yield call(
    callApi,
    apiInstance,
    {
      asyncAction: asyncActionCreator,
      onFailure: toastifyErrorSaga,
    },
    params,
  );
}

function* updateUserPreferencesDefaultMenu(
  apiInstance,
  asyncActionCreator,
  { payload: params }: ReturnType<typeof UpdateUserPreferencesDefaultMenuAsync.request>,
) {
  yield call(
    callApi,
    apiInstance,
    {
      asyncAction: asyncActionCreator,
      onFailure: toastifyErrorSaga,
    },
    params,
  );
}

// eslint-disable-next-line require-yield
function* showToastifySuccess() {
  yield delay(50);
  Toastify.success('User Preferences updated successfully');
}

function* setLocalUserDefaultRoute() {
  yield delay(50);
  const defaultRoute = yield select(selectDefaultMenu);
  DefaultRoute.setLocalDefaultRoute(defaultRoute);
}

export default function authSaga(apiInstance: Apis) {
  return [
    takeLatest(signInAsync.request, signIn, apiInstance.signIn),
    takeLatest(exchangeTokenAsync.request, checkValidToken, apiInstance.exchangeToken),
    takeLatest(signOutAsync.request, signOut),
    takeLatest(
      GetUserPreferencesAsync.request,
      getUserPreferences,
      apiInstance.getUserPreference,
      GetUserPreferencesAsync,
    ),

    takeLatest(GetUserPreferencesAsync.success, checkPermissionAndPreferences),
    takeLatest(
      UpdateUserPreferencesTooltipAsync.request,
      updateUserPreferencesTooltip,
      apiInstance.updateUserPreferences,
      UpdateUserPreferencesTooltipAsync,
    ),
    takeLatest(
      UpdateUserPreferencesDefaultMenuAsync.request,
      updateUserPreferencesDefaultMenu,
      apiInstance.updateUserPreferences,
      UpdateUserPreferencesDefaultMenuAsync,
    ),
    takeLatest(
      [UpdateUserPreferencesTooltipAsync.success, UpdateUserPreferencesDefaultMenuAsync.success],
      showToastifySuccess,
    ),
    takeLatest(UpdateUserPreferencesDefaultMenuAsync.success, setLocalUserDefaultRoute),
  ];
}
