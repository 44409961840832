import { ApiResponse } from 'apisauce';
import { Saga } from 'redux-saga';
import { call, put, race, take } from 'redux-saga/effects';
import { AsyncActionCreatorBuilder, getType } from 'typesafe-actions';
import { ApiResponse as ApiStatusWrapperResponse } from '../types';

export function* callAuth<TResponse extends unknown = any, TError extends Error = Error>(
  api,
  action: {
    asyncAction: AsyncActionCreatorBuilder<any, [string, [TResponse, any]], [string, [TError, any]]>;
    responseExtractor?: (res) => TResponse;
    errorBuilder?: (err) => any;
  },
  ...args: any[]
) {
  const { asyncAction, responseExtractor = undefined, errorBuilder = err => err } = action;
  try {
    const response = yield call(api, ...args);
    yield put(asyncAction.success(responseExtractor?.(response), undefined));
  } catch (err) {
    yield put(asyncAction.failure(errorBuilder(err), undefined));
  }
}

const sanitizeActionType = actionType => actionType.split('/').pop().replace('_REQUEST', '').replaceAll('_', ' ');

export function* callApi<TResponse extends unknown = any, TError extends Error = Error>(
  api,
  action: {
    asyncAction:
      | AsyncActionCreatorBuilder<any, [string, [TResponse, any]], [string, [TError, any]]>
      | AsyncActionCreatorBuilder<any, [string, [TResponse, any]], [string, [TError, any]], any>;
    responseExtractor?: (res) => TResponse;
    errorBuilder?: (err) => any;
    onSuccess?: Saga;
    onFailure?: Saga;
  },
  ...args: any[]
) {
  const { asyncAction, responseExtractor = res => res, errorBuilder = err => err, onSuccess, onFailure } = action;
  let response: ApiResponse<ApiStatusWrapperResponse<TResponse>>;
  if (asyncAction['cancel']) {
    const { fetchedResponse, cancel } = yield race({
      fetchedResponse: call(api, ...args),
      cancel: take(asyncAction['cancel']),
    });
    if (cancel) {
      return;
    }
    response = fetchedResponse;
  } else {
    response = yield call(api, ...args);
  }
  if (response.ok && response.data?.status === 'success') {
    yield put(asyncAction.success(responseExtractor(response.data.data), undefined));
    if (onSuccess) {
      yield call(onSuccess, response);
    }
  } else if (!response.ok || response.data?.status === 'error') {
    yield put(asyncAction.failure(errorBuilder(response.data), undefined));
    if (onFailure) {
      yield call(onFailure, response, `${sanitizeActionType(getType(asyncAction.request))}: ${response.data?.message}`);
    }
  }
}
