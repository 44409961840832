import React from 'react';

import { Divider, List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer, useMediaQuery } from '@material-ui/core';
import { Image } from 'src/components/common';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { IRootState } from 'src/redux/rootReducer';
import { setCollapseSidebar, toggleSideMenu } from 'src/redux/commonRedux/actions';

import { isActive, SidebarMenu } from 'src/appConfig/sidebar';
import { IMAGES } from 'src/appConfig/images';
import './styles.scss';
import { selectUserPermissions } from 'src/redux/authRedux/selectors';
import { muiResponsive } from 'src/appConfig/muiTheme';

const SidebarMobile: React.VFC<Props> = ({ toggle, collapseSidebar, permissions, setCollapseSidebar }) => {
  const isMobile = useMediaQuery(muiResponsive.MOBILE);
  React.useEffect(() => {
    if (isMobile) {
      setCollapseSidebar(false);
    }
  }, [setCollapseSidebar, isMobile]);

  return (
    <div className="side-bar-drawer-mobile">
      <SwipeableDrawer
        anchor="left"
        open={collapseSidebar === undefined ? false : collapseSidebar ? true : false}
        onClose={toggle}
        onOpen={toggle}>
        <div className="side-bar-header">
          <Image className="side-bar-header__image " alt="logoSBBH" src={IMAGES.logoSBBH} />
          <h3 className="side-bar-header__text">Lōkahi</h3>
        </div>
        <Divider />
        <List>
          {SidebarMenu.map(({ title, href, icon }) => {
            if (
              (!permissions.isScheduler && title === 'SCHEDULER') ||
              (!permissions.isSL && title === 'SESSION LOGS') ||
              (!permissions.isTS && title === 'TIERED SERVICES')
            ) {
              return null;
            } else
              return (
                <Link to={href} key={title} onClick={toggle}>
                  <ListItem button className={['side-bar-list-item mobile', isActive(href) && 'active'].join(' ')}>
                    <ListItemIcon className="side-bar-list-item-icon">{icon}</ListItemIcon>
                    <ListItemText primary={<p className="side-bar-list-item-text">{title}</p>} />
                  </ListItem>
                </Link>
              );
          })}
        </List>
      </SwipeableDrawer>
    </div>
  );
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps & {
    defaultHide?: boolean;
  };
const mapStateToProps = (state: IRootState) => ({
  collapseSidebar: state.common.collapseSidebar,
  permissions: selectUserPermissions(state),
});

const mapDispatchToProps = {
  toggle: toggleSideMenu,
  setCollapseSidebar: setCollapseSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMobile);
