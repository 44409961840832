import { ProviderName, SchoolName, ServiceLocation, StudentName } from 'src/redux/schedulerRedux/types';

export const getProviderOptionLabel = ({ firstName, lastName, credentialName }: ProviderName): string =>
  `${firstName} ${lastName}${credentialName ? ` / ${credentialName}` : ''}`;

export const getProviderWithPositionLabel = ({ firstName, lastName, position }: ProviderName): string =>
  `${firstName} ${lastName}${position ? ` / ${position}` : ''}`;

export const getSchoolOptionLabel = ({ schoolName }: SchoolName): string => schoolName;
export const getLocationOptionLabel = ({ locationName }: ServiceLocation): string => locationName;

export const getStudentOptionLabel = ({ firstName, lastName, studentExtId }: StudentName): string =>
  `${firstName} ${lastName} (${studentExtId})`;
