import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import api, { Apis } from 'src/services/api';
import { calcNumberOfPages } from 'src/utils/paginationUtils';
import { callApi } from '../commonSagas/callApi';
import { toastifyErrorSaga } from '../commonSagas/toastifyFailureSaga';
import { Pagination } from '../types';
import {
  addProviderToServiceByProviderAsync,
  addProviderToServiceByStudentAsync,
  getServiceListByProvidersAsync,
  getStudentServicesByStudentAsync,
  removeProviderFromServiceByProviderAsync,
  removeProviderFromServiceByStudentAsync,
  searchProviderByStudentAsync,
  searchProvidersAsync,
  searchSchoolsAsync,
  searchStudentsAsync,
  setSchedulerProvidersPagination,
} from './actions';
import {
  selectSchedulerProvidersPagination,
  selectSchedulerView,
  selectProviderInfoServiceByProvider,
  selectSchoolInfoServiceByProvider,
  selectStudentInfoServiceByStudent,
  selectServicesDataByProvider,
  selectServiceId,
} from './selectors';
import { Provider, SchedulerView } from './types';

function* setProvidersDefaultPagination() {
  const pagination: Pagination = yield select(selectSchedulerProvidersPagination);
  const providers: Provider[] = yield select(selectServicesDataByProvider);
  yield put(
    setSchedulerProvidersPagination({
      ...pagination,
      totalPages: calcNumberOfPages(providers.length, pagination.pageSize),
      currentPage: 1,
    }),
  );
}

function* getSchedulerServiceListByProvider(
  api,
  { payload: params }: ReturnType<typeof getServiceListByProvidersAsync.request>,
) {
  yield call(
    callApi,
    api,
    {
      asyncAction: getServiceListByProvidersAsync,
      onFailure: toastifyErrorSaga,
      onSuccess: setProvidersDefaultPagination,
    },
    params,
  );
}

function* getStudentServicesByStudent(
  api,
  { payload: params }: ReturnType<typeof getStudentServicesByStudentAsync.request>,
) {
  yield call(
    callApi,
    api,
    {
      asyncAction: getStudentServicesByStudentAsync,
      onFailure: toastifyErrorSaga,
    },
    params,
  );
}

function* searchFilters(api, asyncActionCreator, { payload: params }: any) {
  yield call(
    callApi,
    api,
    {
      asyncAction: asyncActionCreator,
      onFailure: toastifyErrorSaga,
    },
    params,
  );
}

function* searchProviderByStudentFilter(api, asyncActionCreator, { payload: search }: any) {
  yield call(
    callApi,
    api,
    {
      asyncAction: asyncActionCreator,
      onFailure: toastifyErrorSaga,
    },
    { search, serviceId: yield select(selectServiceId) },
  );
}

function* fetchServicesByProvider(providerId: number, schoolId: number, unassignedOnly: boolean) {
  const apiInstance = api.create().getSchedulerServiceListByProvider;
  yield call(
    callApi,
    apiInstance,
    {
      asyncAction: getServiceListByProvidersAsync,
      onFailure: toastifyErrorSaga,
      // onSuccess: setProvidersDefaultPagination,
    },
    { providerId, schoolId, unassignedOnly },
  );
}

function* fetchServicesByStudent(studentId: number) {
  const apiInstance = api.create().getStudentServicesByStudent;
  yield call(
    callApi,
    apiInstance,
    {
      asyncAction: getStudentServicesByStudentAsync,
      onFailure: toastifyErrorSaga,
    },
    { studentId },
  );
}

function* getServiceListByProvider() {
  const provider = yield select(selectProviderInfoServiceByProvider);
  const school = yield select(selectSchoolInfoServiceByProvider);
  const schedulerView = yield select(selectSchedulerView);
  const providerId = provider.userId;
  const schoolId = school.schoolId;
  const unassignedOnly = schedulerView === SchedulerView.UnAssigned ? true : false;
  yield call(fetchServicesByProvider, providerId, schoolId, unassignedOnly);
}

function* getServicesByStudent() {
  const student = yield select(selectStudentInfoServiceByStudent);
  const studentId = student.studentId;
  yield call(fetchServicesByStudent, studentId);
}

function* addProviderToServiceByProvider(
  api,
  { payload: { providerUserId, serviceId } }: ReturnType<typeof addProviderToServiceByProviderAsync.request>,
) {
  yield call(
    callApi,
    api,
    {
      asyncAction: addProviderToServiceByProviderAsync,
      onFailure: toastifyErrorSaga,
    },
    { serviceId, providerUserId },
  );
}

function* removeProviderFromServiceByProvider(
  api,
  { payload: { providerId } }: ReturnType<typeof removeProviderFromServiceByProviderAsync.request>,
) {
  yield call(
    callApi,
    api,
    {
      asyncAction: removeProviderFromServiceByProviderAsync,
      onFailure: toastifyErrorSaga,
    },
    providerId,
  );
}

function* addProviderToServiceByStudent(
  api,
  { payload: { providerUserId, serviceId } }: ReturnType<typeof addProviderToServiceByStudentAsync.request>,
) {
  yield call(
    callApi,
    api,
    {
      asyncAction: addProviderToServiceByStudentAsync,
      onFailure: toastifyErrorSaga,
    },
    { serviceId, providerUserId },
  );
}

function* removeProviderFromServiceByStudent(
  api,
  { payload: { providerId } }: ReturnType<typeof removeProviderFromServiceByStudentAsync.request>,
) {
  yield call(
    callApi,
    api,
    {
      asyncAction: removeProviderFromServiceByStudentAsync,
      onFailure: toastifyErrorSaga,
    },
    providerId,
  );
}

export default function schedulersSagas(apiInstance: Apis) {
  return [
    takeLatest(
      getServiceListByProvidersAsync.request,
      getSchedulerServiceListByProvider,
      apiInstance.getSchedulerServiceListByProvider,
    ),
    takeLatest(
      getStudentServicesByStudentAsync.request,
      getStudentServicesByStudent,
      apiInstance.getStudentServicesByStudent,
    ),
    takeLatest(
      searchProvidersAsync.request,
      searchFilters,
      apiInstance.getSchedulerProvidersFilter,
      searchProvidersAsync,
    ),
    takeLatest(
      searchProviderByStudentAsync.request,
      searchProviderByStudentFilter,
      apiInstance.getProviderListForAddToServiceFilter,
      searchProviderByStudentAsync,
    ),
    takeLatest(searchSchoolsAsync.request, searchFilters, apiInstance.getSchedulerSchoolsFilter, searchSchoolsAsync),
    takeLatest(searchStudentsAsync.request, searchFilters, apiInstance.getSchedulerStudentsFilter, searchStudentsAsync),
    takeLatest(
      searchProviderByStudentAsync.request,
      searchProviderByStudentFilter,
      apiInstance.getProviderListForAddToServiceFilter,
      searchProviderByStudentAsync,
    ),
    takeLatest(
      addProviderToServiceByProviderAsync.request,
      addProviderToServiceByProvider,
      apiInstance.addProviderToService,
    ),
    takeLatest(
      removeProviderFromServiceByProviderAsync.request,
      removeProviderFromServiceByProvider,
      apiInstance.removeProviderFromService,
    ),
    takeLatest(
      addProviderToServiceByStudentAsync.request,
      addProviderToServiceByStudent,
      apiInstance.addProviderToService,
    ),
    takeLatest(
      removeProviderFromServiceByStudentAsync.request,
      removeProviderFromServiceByStudent,
      apiInstance.removeProviderFromService,
    ),
    takeEvery(
      [addProviderToServiceByProviderAsync.success, removeProviderFromServiceByProviderAsync.success],
      getServiceListByProvider,
    ),
    takeEvery(
      [addProviderToServiceByStudentAsync.success, removeProviderFromServiceByStudentAsync.success],
      getServicesByStudent,
    ),
  ];
}
