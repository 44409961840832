import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'src/redux/rootReducer';
import { ProviderName, SchoolName } from 'src/redux/schedulerRedux/types';
import {
  clearSearchEditableStudents,
  getEditableStudentsAsync,
  getProviderHUserSLAsync,
  searchActiveProvidersAsync,
  setFiltersEditableStudent,
  setSelectedStudentsAddSession,
} from 'src/redux/sessionLogsRedux/actions';
import { EditableStudentsType, GetEditableStudentsRequest } from 'src/redux/sessionLogsRedux/types';
import { Pagination } from 'src/redux/types';
import { getCorrectPagination } from 'src/utils/paginationUtils';
import { isEmpty } from 'src/validations';
import AddStudentsDialog from './AddStudentsDialog';
import { StudentFiltersSL } from './Filters';
import SelectProviderDialog from './SelectProviderDialog';
import './styles.scss';

type Props = ReturnType<typeof mapState> &
  typeof mapDispatchToProps & {
    onClose: () => void;
    skipSelectProvider?: boolean;
    onCreate: (provider: ProviderName, students: EditableStudentsType[], sessionDate: string) => void;
  };

export const allOptionSchool: SchoolName = {
  schoolId: -1,
  schoolCode: '-1',
  schoolName: 'All Schools',
};

const AddSessionLogsDialog: React.VFC<Props> = ({
  editableStudentsAsync,
  onCreate,
  onClose,
  skipSelectProvider,
  searchActiveProviderData,
  searchSLActiveProvider,
  clearSearchActiveProvider,
  isAdmin,
  isSL,
  currentLoggedInProvider,
  getEditableStudents,
  setFiltersEditableStudent,
  getProviderHUserId,
  students: initialSelectedStudents,
  setSelectedStudentAddSession,
  clearSearchEditableStudents,
  completedStudentIds,
}) => {
  const [activeDialog, setActiveDialog] = React.useState<'select-provider' | 'add-students'>(
    skipSelectProvider ? 'add-students' : 'select-provider',
  );
  const [selectedStudents, setSelectedStudents] = React.useState<EditableStudentsType[]>(initialSelectedStudents);

  const [sessionDate, setSessionDate] = React.useState<string>(moment().toISOString());
  const [filters, setFilters] = React.useState<StudentFiltersSL>({
    supportTypes: ['service', 'sbbhtiered'],
    schools: null,
    supportStatus: false,
    studentTypes: false,
  });

  const totalCountEditableStudents: number = editableStudentsAsync.data[0]?.totalCount;

  const [activeProvider, setActiveProvider] = React.useState<ProviderName>(currentLoggedInProvider);
  const hasSLRights = !isAdmin && isSL;

  const [search, setSearch] = React.useState<GetEditableStudentsRequest>({
    sessionDate: moment(sessionDate).format('YYYY-MM-DD'),
    providerUserId: activeProvider && activeProvider.userId,
    searchService: 1,
    searchTiered: 1,
    firstName: '',
    lastName: '',
  });

  const [pagination, setPagination] = React.useState<Pagination>({
    currentPage: 1,
    pageSize: 10,
    totalPages: 0,
  });

  React.useEffect(() => {
    setPagination(getCorrectPagination(pagination, totalCountEditableStudents));
  }, [editableStudentsAsync.data]);

  const clearStateAddStudents = () => {
    clearSearchEditableStudents();
    setSearch({
      sessionDate: moment(sessionDate).format('YYYY-MM-DD'),
      providerUserId: activeProvider && activeProvider.userId,
      searchService: 1,
      searchTiered: 1,
      firstName: '',
      lastName: '',
    });
    setPagination({
      currentPage: 1,
      pageSize: 10,
      totalPages: 0,
    });

    setFilters({
      supportTypes: ['service', 'sbbhtiered'],
      schools: null,
      supportStatus: false,
      studentTypes: false,
    });
  };

  const handleNextClicked = () => {
    setActiveDialog('add-students');
    clearStateAddStudents();
    setFiltersEditableStudent(search);
    setSearch({ ...search, providerUserId: activeProvider.userId });
    getProviderHUserId({ providerUserId: activeProvider.userId });
  };
  const onChangeProvider = () => {
    clearStateAddStudents();
    setSelectedStudents([]);
    setActiveDialog('select-provider');
  };

  const handleOnAddSession = () => {
    onCreate(activeProvider, selectedStudents, sessionDate);
    setSelectedStudentAddSession(selectedStudents);
    clearStateAddStudents();
  };

  const handleSelectStudent = (student: EditableStudentsType) => {
    setSelectedStudents([...selectedStudents, student]);
  };

  // React.useEffect(() => {
  //   setSelectedStudentAddSession(selectedStudents);
  // }, [selectedStudents]);

  const handleDeselectStudent = (student: EditableStudentsType) => {
    setSelectedStudents(selectedStudents.filter(s => s.studentId !== student.studentId));
  };

  const handleDeselectAllStudents = () => {
    setSelectedStudents([]);
  };

  const fetchStudents = (_pagination = pagination, _filters = search) => {
    getEditableStudents({
      sessionDate: _filters.sessionDate,
      providerUserId: _filters.providerUserId,
      searchService: _filters.searchService,
      searchTiered: _filters.searchTiered,
      searchUnsched: _filters.searchUnsched,
      firstName: _filters.firstName,
      lastName: _filters.lastName,
      itemsPerPage: _pagination.pageSize,
      pageNumber: _pagination.currentPage,
      includeInactiveStudents: _filters.includeInactiveStudents,
      includeInactiveSupports: _filters.includeInactiveSupports,
      schoolId: _filters.schoolId,
    });
  };

  const handleSearch = (_filters: GetEditableStudentsRequest = search) => {
    setFiltersEditableStudent(_filters);
    fetchStudents(pagination, _filters);
  };

  const handlePageChange = (page: number) => {
    const newPagination = getCorrectPagination(
      {
        ...pagination,
        currentPage: page,
      },
      totalCountEditableStudents,
    );
    setPagination(newPagination);
    fetchStudents(newPagination);
  };

  const handleFilterStudents = (_filter: StudentFiltersSL = filters) => {
    setFilters(_filter);
    let newFilters: GetEditableStudentsRequest = {
      sessionDate: search.sessionDate,
      providerUserId: search.providerUserId,
    };
    if (search.firstName) {
      newFilters = {
        ...newFilters,
        firstName: search.firstName,
      };
    }
    if (search.lastName) {
      newFilters = {
        ...newFilters,
        lastName: search.lastName,
      };
    }

    if (isEmpty(_filter.supportTypes)) {
      newFilters = {
        ...newFilters,
        searchService: 1,
        searchTiered: 1,
      };
    } else {
      _filter.supportTypes.forEach(supportType => {
        switch (supportType) {
          case 'service':
            newFilters = {
              ...newFilters,
              searchService: 1,
            };
            break;
          case 'sbbhtiered':
            newFilters = {
              ...newFilters,
              searchTiered: 1,
            };
            break;
          case 'unsched':
            newFilters = {
              ...newFilters,
              searchUnsched: 1,
            };
            break;
          default:
            break;
        }
      });
    }

    if (_filter.schools?.schoolId !== -1) {
      newFilters = {
        ...newFilters,
        schoolId: _filter.schools?.schoolId,
      };
    }

    if (_filter.supportStatus) {
      newFilters = {
        ...newFilters,
        includeInactiveSupports: 1,
      };
    }
    if (_filter.studentTypes) {
      newFilters = {
        ...newFilters,
        includeInactiveStudents: 1,
      };
    }
    setSearch(newFilters);
    setFiltersEditableStudent(newFilters);

    // allow search with empty name when support type is !unsched
    // Not allow search with empty name when support type is unsched
    if (isEmpty(search.firstName) && isEmpty(search.lastName) && newFilters.searchUnsched === 1) return;
    fetchStudents(pagination, newFilters);
  };

  const handleActiveProviderChange = (provider: ProviderName) => {
    setActiveProvider(provider);
  };

  return (
    <>
      <SelectProviderDialog
        open={activeDialog === 'select-provider'}
        onClose={onClose}
        onNext={handleNextClicked}
        sessionDate={sessionDate}
        onSessionDateChange={newDate => {
          if (isEmpty(newDate)) return;
          setSessionDate(newDate);
          setSearch({ ...search, sessionDate: newDate.format('YYYY-MM-DD') });
        }}
        loading={searchActiveProviderData.loading}
        options={!searchActiveProviderData.data?.length ? [currentLoggedInProvider] : searchActiveProviderData.data}
        searchSLActiveProvider={searchSLActiveProvider}
        clearSearchActiveProvider={clearSearchActiveProvider}
        activeProvider={activeProvider}
        handleActiveProviderChange={handleActiveProviderChange}
        readOnly={hasSLRights}
      />
      <AddStudentsDialog
        selectedStudents={selectedStudents}
        onChangeProvider={onChangeProvider}
        open={activeDialog === 'add-students'}
        onClose={onClose}
        onCreateService={handleOnAddSession}
        editableStudentsAsync={editableStudentsAsync}
        onSelectStudent={handleSelectStudent}
        onDeselectStudent={handleDeselectStudent}
        onDeselectAll={handleDeselectAllStudents}
        filters={filters}
        onFilterChange={handleFilterStudents}
        searchValue={search}
        onSearchValueChange={setSearch}
        onSearch={handleSearch}
        pagination={pagination}
        onPageChange={handlePageChange}
        totalCount={totalCountEditableStudents}
        showChangeProvider={isAdmin}
        providerName={`${activeProvider?.firstName} ${activeProvider?.lastName}`}
        sessionDate={sessionDate}
        completedStudentIds={completedStudentIds}
        reduxSelectedStudents={initialSelectedStudents}
      />
    </>
  );
};

const mapState = (state: IRootState) => {
  const { userId, fullName, position, username, credentialName, credentialType } = state.auth.user;
  const { isAdmin, isSL } = state.auth.permissions;

  const { editableStudents, students, completedStudentIds } = state.sessionLogs.addSession;
  const { searchActiveProviders } = state.sessionLogs.addSession.search;

  const currentLoggedInProvider: ProviderName = {
    userId,
    firstName: fullName.split(' ')[0],
    lastName: fullName.split(' ')[1],
    position,
    username,
    credentialName,
    credentialType,
  };

  return {
    isAdmin,
    isSL,
    currentLoggedInProvider,
    searchActiveProviderData: searchActiveProviders,
    editableStudentsAsync: editableStudents,
    students,
    completedStudentIds,
  };
};

const mapDispatchToProps = {
  searchSLActiveProvider: searchActiveProvidersAsync.request,
  clearSearchActiveProvider: searchActiveProvidersAsync.cancel,
  getEditableStudents: getEditableStudentsAsync.request,
  setFiltersEditableStudent: setFiltersEditableStudent,
  getProviderHUserId: getProviderHUserSLAsync.request,
  setSelectedStudentAddSession: setSelectedStudentsAddSession,
  clearSearchEditableStudents: clearSearchEditableStudents,
};

export default connect(mapState, mapDispatchToProps)(AddSessionLogsDialog);
