import reduceReducers from 'reduce-reducers';
import { combineReducers } from 'redux';
import { AsyncState, commonAsyncReducer } from 'src/utils/commonAsyncReducer';
import { Action, createReducer, getType } from 'typesafe-actions';
import { signInAsync, signOutAsync } from '../authRedux/actions';
import { cancelAddingSession, getRecentLogsAsync, setAddSessionTemplateFirstInfo } from './actions';
import addSessionReducer, { AddSessionState, initialStateAddLog } from './addSessionLogReducer';
import searchLogReducer, { initialStateSearchLog, SearchLogState } from './searchSessionLogReducer';
import { RecentLogsType } from './types';

export interface SessionLogsState {
  recentLogs: AsyncState<RecentLogsType[]>;
  searchLogs: SearchLogState;
  addSession: AddSessionState;
}

const defaultAsyncState = {
  loading: false,
  error: null,
};

const initialState: SessionLogsState = {
  recentLogs: {
    data: [],
    ...defaultAsyncState,
  },
  searchLogs: initialStateSearchLog,
  addSession: initialStateAddLog,
};

const recentLogsReducer = createReducer<AsyncState<RecentLogsType[]>>(initialState.recentLogs).handleAction(
  [getRecentLogsAsync.request, getRecentLogsAsync.success, getRecentLogsAsync.failure],
  commonAsyncReducer(getRecentLogsAsync),
);

const combinedReducers = combineReducers<SessionLogsState>({
  recentLogs: recentLogsReducer,
  searchLogs: searchLogReducer,
  addSession: addSessionReducer,
});

const resetStateReducer = (state: SessionLogsState = initialState, action: Action) => {
  const { addSession, recentLogs, searchLogs } = initialState;
  switch (action.type) {
    case getType(cancelAddingSession):
    case getType(signOutAsync.success):
    case getType(signInAsync.success):
      return {
        recentLogs,
        searchLogs,
        addSession,
      };
    case getType(setAddSessionTemplateFirstInfo):
      return {
        ...state,
        searchLogs,
      };

    default:
      return state;
  }
};

export default reduceReducers<any>(initialState, combinedReducers, resetStateReducer);
