import { PATHS } from 'src/appConfig/paths';
import { checkedValue, routeValue } from 'src/containers/UAMContainer/UserPreferences/helper';
import { Permissions } from 'src/redux/authRedux/types';

export const getUserDefaultRoute = (permissions: Permissions, menuDefault: string): string => {
  const { isScheduler, isSL, isTS } = permissions;

  if (
    menuDefault === checkedValue.SCHEDULER ||
    menuDefault === checkedValue.SESSION_LOGS ||
    menuDefault === checkedValue.TIERED_SERVICES ||
    menuDefault === routeValue.SESSION_LOGS ||
    menuDefault === null ||
    menuDefault === 'null'
  ) {
    if (isScheduler && isSL && isTS) {
      return PATHS.sessionLogs;
    }
    if (isScheduler && !isSL && isTS) {
      return PATHS.scheduler;
    }
    if (isScheduler && isSL && !isTS) {
      return PATHS.sessionLogs;
    }
    if (!isScheduler && isSL && isTS) {
      return PATHS.sessionLogs;
    }
    if (!isScheduler && !isSL && isTS) {
      return PATHS.tieredService;
    }
    if (isScheduler) {
      return PATHS.scheduler;
    }
    if (isSL) {
      return PATHS.sessionLogs;
    }
    if (isTS) {
      return PATHS.tieredService;
    } else {
      return PATHS.signIn;
    }
  } else {
    return `/${menuDefault}`;
  }
};
