import reduceReducers from 'reduce-reducers';
import { TieredServiceFormValue } from 'src/containers/TieredServicesContainers/Components/TieredServiceForm';
import { AsyncState, commonAsyncReducer } from 'src/utils/commonAsyncReducer';
import { isEmpty } from 'src/validations';
import { createReducer } from 'typesafe-actions';
import { ProviderName, SchoolName } from '../schedulerRedux/types';
import { ProviderHUserId } from '../sessionLogsRedux/types';
import {
  cancelRemainingStudentsAddingService,
  clearCompletedStudentTS,
  createTieredServiceLogsAsync,
  getProviderHUserTSAsync,
  getTieredServiceTargetsAndPracticesAsync,
  removeTieredServiceAsync,
  saveAddTieredServiceFormProgress,
  searchAlternativeProvidersAsync,
  searchProvidersToAddTSAsync,
  searchSchoolsForFilterAsync,
  searchStudentsToAddServiceAsync,
  setSelectedProviderToAddTS,
  setSelectedStudents,
  updateTieredServiceLogsAsync,
} from './action';
import { TieredServiceDetail } from './TieredServiceDetail';
import { EditableStudentName, Practice, Target } from './types';

export type AddingTieredServiceState = {
  providers: AsyncState<ProviderName[]> & { selected: ProviderName; providerHUserId: AsyncState<ProviderHUserId[]> };
  alternativeProviders: AsyncState<ProviderName[]> & { selected?: ProviderName };
  searchStudents: AsyncState<EditableStudentName[]> & { selected: EditableStudentName[] };
  searchSchools: AsyncState<SchoolName[]>;
  progress: Record<number, TieredServiceFormValue>;
  completedStudentIds: (string | number)[];
  targetsAndPractices: {
    targets: Target[];
    practices: Practice[];
  };
  loading?: boolean;
  error?: Error;
  submissions: {
    records: TieredServiceDetail[];
    final: false;
  };
};

const defaultAsyncState = {
  loading: false,
  error: null,
};

export const initial: AddingTieredServiceState = {
  providers: {
    data: [],
    selected: null,
    providerHUserId: {
      data: [],
      ...defaultAsyncState,
    },
    ...defaultAsyncState,
  },
  alternativeProviders: {
    data: [],
    ...defaultAsyncState,
  },
  // results of search in "Add students" dialog
  searchStudents: {
    data: [],
    selected: [],
    ...defaultAsyncState,
  },
  searchSchools: {
    data: [],
    ...defaultAsyncState,
  },
  progress: {},
  completedStudentIds: [],
  targetsAndPractices: {
    targets: [],
    practices: [],
  },
  submissions: { records: [], final: false },
  ...defaultAsyncState,
};

export const addTieredServiceReducer = createReducer<AddingTieredServiceState>(initial)
  .handleAction(saveAddTieredServiceFormProgress, (state, { payload }) => {
    const completedStudentIds =
      payload.completed && !state.completedStudentIds.includes(payload.studentId)
        ? [...state.completedStudentIds, payload.studentId]
        : state.completedStudentIds;

    return {
      ...state,
      progress: { ...state.progress, [payload.studentId]: payload.formValues },
      completedStudentIds,
    };
  })

  .handleAction(clearCompletedStudentTS, (state, { payload }) => {
    const studentId = payload.studentId;
    const progress = { ...state.progress };
    //delete progress[studentId];

    const completedStudents = [...state.completedStudentIds];
    const removedStudents = completedStudents.filter((student, index) => student !== studentId);

    return {
      ...state,
      progress: progress,
      completedStudentIds: removedStudents,
    };
  })
  .handleAction(
    [
      searchSchoolsForFilterAsync.request,
      searchSchoolsForFilterAsync.success,
      searchSchoolsForFilterAsync.failure,
      searchSchoolsForFilterAsync.cancel,
    ],
    (state, action) => ({
      ...state,
      searchSchools: {
        ...state.searchSchools,
        ...commonAsyncReducer(searchSchoolsForFilterAsync, 'data', {
          initial: [],
        })(state.searchSchools, action),
      },
    }),
  )
  .handleAction(
    [
      searchStudentsToAddServiceAsync.request,
      searchStudentsToAddServiceAsync.success,
      searchStudentsToAddServiceAsync.failure,
      searchStudentsToAddServiceAsync.cancel,
    ],
    (state, action) => ({
      ...state,
      searchStudents: {
        ...state.searchStudents,
        ...commonAsyncReducer(searchStudentsToAddServiceAsync, 'data', {
          initial: [],
        })(state.searchStudents, action),
      },
    }),
  )
  .handleAction(
    [
      searchAlternativeProvidersAsync.request,
      searchAlternativeProvidersAsync.success,
      searchAlternativeProvidersAsync.failure,
      searchAlternativeProvidersAsync.cancel,
    ],
    (state, action) => ({
      ...state,
      alternativeProviders: {
        ...state.alternativeProviders,
        ...commonAsyncReducer(searchAlternativeProvidersAsync, 'data', {
          initial: [],
        })(state.alternativeProviders, action),
      },
    }),
  )
  .handleAction(
    [
      searchProvidersToAddTSAsync.request,
      searchProvidersToAddTSAsync.success,
      searchProvidersToAddTSAsync.failure,
      searchProvidersToAddTSAsync.cancel,
    ],
    (state, action) => ({
      ...state,
      providers: {
        ...state.providers,
        ...commonAsyncReducer(searchProvidersToAddTSAsync, 'data', {
          initial: [],
        })(state.providers, action),
      },
    }),
  )
  .handleAction(setSelectedProviderToAddTS, (state, action) => ({
    ...state,
    providers: {
      ...state.providers,
      selected: action.payload,
    },
  }))
  .handleAction(
    [getProviderHUserTSAsync.request, getProviderHUserTSAsync.success, getProviderHUserTSAsync.failure],
    (state, action) => ({
      ...state,
      providers: {
        ...state.providers,
        providerHUserId: {
          ...state.providers.providerHUserId,
          ...commonAsyncReducer<any, any>(getProviderHUserTSAsync)(state.providers.providerHUserId, action),
        },
      },
    }),
  )
  .handleAction(setSelectedStudents, (state, { payload }) => ({
    ...state,
    searchStudents: {
      ...state.searchStudents,
      data: [],
      selected: payload,
    },
    completedStudentIds: state.completedStudentIds.filter(i => payload.some(s => s.studentId === i)),
  }))

  .handleAction(
    [
      getTieredServiceTargetsAndPracticesAsync.request,
      getTieredServiceTargetsAndPracticesAsync.success,
      getTieredServiceTargetsAndPracticesAsync.failure,
    ],
    commonAsyncReducer(getTieredServiceTargetsAndPracticesAsync, 'targetsAndPractices', {
      initial: {
        targets: [],
        practices: [],
      },
    }),
  )
  .handleAction(createTieredServiceLogsAsync.request, (state, action) => ({
    ...state,
    loading: true,
    submissions: {
      ...state.submissions,
      final: action.payload.final,
    },
  }))
  .handleAction(createTieredServiceLogsAsync.success, (state, action) => {
    return {
      ...state,
      ...defaultAsyncState,
      submissions: { ...state.submissions, records: [...state.submissions.records, ...action.payload.records] },
    };
  })
  .handleAction(createTieredServiceLogsAsync.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))

  .handleAction(updateTieredServiceLogsAsync.success, (state, action) => {
    let stateRecords = [...state.submissions.records];
    let actionRecords = [...action.payload.records];
    let deDuplicateRecords = actionRecords.filter((record, index) => {
      return stateRecords.find(submission => submission.planId === record.planId);
    });

    return {
      ...state,
      ...defaultAsyncState,
      submissions: {
        ...state.submissions,
        records: isEmpty(deDuplicateRecords)
          ? [...state.submissions.records, ...action.payload.records]
          : deDuplicateRecords,
      },
    };
  })

  .handleAction(removeTieredServiceAsync.success, (state, action) => ({
    ...state,
    submissions: {
      ...state.submissions,
      records: state.submissions.records.filter(r => r.planId !== action.payload.planId),
    },
  }))
  .handleAction(cancelRemainingStudentsAddingService, (state, action) => {
    const completedStudentIds = [...state.completedStudentIds];
    const selectedStudents = [...state.searchStudents.selected];
    const removeRemainingStudents = selectedStudents.filter(s => completedStudentIds.includes(s.studentId));
    return {
      ...state,
      searchStudents: {
        ...state.searchStudents,
        selected: removeRemainingStudents,
      },
    };
  });

export default reduceReducers<any>(initial, addTieredServiceReducer);
