import React from 'react';
import './styles.scss';

import { PATHS } from 'src/appConfig/paths';
import { Link, useHistory } from 'react-router-dom';
import { Button, Grid, TextField, useMediaQuery } from '@material-ui/core';
import { Element } from 'react-scroll';

import { AiOutlineArrowLeft, AiOutlinePlus } from 'react-icons/ai';
import PageContentContainer from 'src/components/PageContentContainer';
import StickyPageHeader from 'src/components/StickyPageHeader';
import SearchDropdownBox from 'src/components/SearchDropdownBox';
import { MuiDatePickerFormikField, MuiDatePickerMomentUtilsProvider } from 'src/components/common/KeyboardDatePicker';
import { getCorrectPagination } from 'src/utils/paginationUtils';
import AddSessionDialog from '../AddProviderAndStudentsDialog';

import * as yup from 'yup';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';

import { connect } from 'react-redux';
import { IRootState } from 'src/redux/rootReducer';
import {
  clearSearchSessionLogs,
  searchLogsAsync,
  searchProvidersAsync,
  searchSchoolsAsync,
  searchStudentsAsync,
  setAddSessionTemplateFirstInfo,
  setSelectedProviderAddSession,
  setSessionDateAddSession,
} from 'src/redux/sessionLogsRedux/actions';
import { EditableStudentsType, SupportTypeOptions, TypeStringDefault } from 'src/redux/sessionLogsRedux/types';
import { ProviderName, SchoolName, StudentName } from 'src/redux/schedulerRedux/types';
import { Pagination } from 'src/redux/types';
import {
  getProviderOptionLabel,
  getSchoolOptionLabel,
  getStudentOptionLabel,
} from 'src/containers/SchedulerContainers/helper';
import SessionLogList from '../components/SessionLogList';
import Empty from 'src/components/Empty';
import moment from 'moment';
import { selectUserPermissions } from 'src/redux/authRedux/selectors';
import { DefaultRoute, Navigator, Toastify } from 'src/services';
import { getStudentInfoLabel } from 'src/utils/nameUtils';
import { muiResponsive } from 'src/appConfig/muiTheme';
import { Autocomplete } from '@material-ui/lab';
import CustomPopperAutocomplete from 'src/components/CustomPopperAutocomplete';
import { filterOptionsMatchAllKeywords } from 'src/utils';
import { isEmpty } from 'src/validations';

const clsPrefix = 'search-session-logs';

type SearchSLFormValues = {
  provider?: ProviderName;
  school?: SchoolName;
  student?: StudentName;
  supportType?: string;
  from?: string;
  to?: string;
};

const SearchSLFormSchema = yup.object({
  provider: yup.object().nullable(),
  school: yup.object().nullable(),
  student: yup.object().nullable(),
  supportType: yup.string().nullable(),
  from: yup.date().nullable().typeError('Please enter a valid date (MM/DD/YYYY)'),
  to: yup
    .date()
    .min(yup.ref('from'), 'End Date must be on or later than Start Date')
    .nullable()
    .typeError('Please enter a valid date (MM/DD/YYYY)'),
});

const SearchSessionLogs: React.FC<Props> = ({
  permissions,
  currentLoggedInProvider,
  loading,
  logs,
  totalCount,
  searchProviderData,
  searchStudentData,
  searchSchoolData,
  searchSLProvider,
  searchSLStudent,
  searchSLSchool,
  clearSearchProvider,
  clearSearchSchool,
  clearSearchStudent,
  clearSearchSessionLogs,
  getLogs,
  setSelectedProviderAddSession,
  setSessionDateAddSession,
  setAddSessionTemplateFirstInfo,
}) => {
  const isMobile = useMediaQuery(muiResponsive.MOBILE);

  const formRef = React.useRef<FormikProps<SearchSLFormValues>>(null);
  const [searchFilters, setSearchFilters] = React.useState<SearchSLFormValues>({});
  const [showAddSessionDialog, setShowAddSessionDialog] = React.useState<boolean>(false);
  const [searched, setSearched] = React.useState<boolean>(false);

  const [pagination, setPagination] = React.useState<Pagination>({
    currentPage: 1,
    pageSize: 25,
    totalPages: 0,
  });

  React.useEffect(() => {
    if (!permissions.isSL) {
      Toastify.error('You do NOT have permission to access SESSION LOGS function.');
      Navigator.navigate(DefaultRoute.getLocalDefaultRoute());
    }
  }, []);

  React.useEffect(() => {
    setPagination(getCorrectPagination(pagination, totalCount));
  }, [totalCount]);

  const retrieveLogs = (
    itemsPerPage = pagination.pageSize,
    pageNumber = pagination.currentPage,
    filters = searchFilters,
  ) => {
    const { provider, school, student, supportType, from, to } = filters;
    // TODO: update supportType to id string

    const formatType = 'YYYY-MM-DD';
    getLogs({
      itemsPerPage: itemsPerPage,
      pageNumber: pageNumber,
      providerUserId: provider?.userId,
      schoolId: school?.schoolId,
      studentId: student?.studentId,
      supportType: supportType,
      from: from && moment(from).format(formatType),
      to: to && moment(to).format(formatType),
    });
    setSearched(true);
  };

  const handlePageSizeChange = (itemsPerPage: number) => {
    setPagination(
      getCorrectPagination(
        {
          ...pagination,
          pageSize: itemsPerPage,
        },
        totalCount,
      ),
    );
    retrieveLogs(itemsPerPage);
  };

  const handlePageNumberChange = (page: number) => {
    setPagination(
      getCorrectPagination(
        {
          ...pagination,
          currentPage: page,
        },
        totalCount,
      ),
    );
    retrieveLogs(pagination.pageSize, page);
  };

  const handleRetrieveLogsClicked = (formValues: SearchSLFormValues) => {
    const { provider, school, student, supportType, from, to } = formValues;
    if (
      isEmpty(provider) &&
      isEmpty(school) &&
      isEmpty(student) &&
      isEmpty(supportType) &&
      isEmpty(from) &&
      isEmpty(to)
    ) {
      return;
    } else {
      setSearchFilters(formValues);
      retrieveLogs(pagination.pageSize, pagination.currentPage === 0 ? 1 : pagination.currentPage, formValues);
    }
  };

  const handleClearParameters = () => {
    if (formRef?.current?.values !== formRef?.current?.initialValues) {
      formRef?.current?.resetForm();
      clearSearchProvider();
      clearSearchSchool();
      clearSearchStudent();
    }
  };

  const history = useHistory();
  React.useEffect(() => {
    const unlisten = history.listen(({ pathname }) => {
      if (pathname !== PATHS.searchSessionLogs) {
        handleClearParameters();
        clearSearchSessionLogs();
      }
    });
    return unlisten;
  }, [history]);

  const closeAddStudentDialog = () => setShowAddSessionDialog(false);

  const handleOnCreateSessionLog = (provider: ProviderName, students: EditableStudentsType[], sessionDate: string) => {
    setSelectedProviderAddSession(provider);
    setSessionDateAddSession(sessionDate);
    setAddSessionTemplateFirstInfo({
      provider: provider,
      credentialName: provider?.credentialName,
      sessionDate: sessionDate,
    });

    students.length > 1 ? Navigator.navigate(PATHS.addSessionLogTemplate) : Navigator.navigate(PATHS.addSessionLog);
  };

  React.useEffect(() => {
    function onKeyup(e) {
      if (e.key === 'Enter') {
        formRef.current?.handleSubmit();
      }
    }
    if (!showAddSessionDialog) {
      window.addEventListener('keyup', onKeyup);
    }
    return () => window.removeEventListener('keyup', onKeyup);
  }, [showAddSessionDialog]);

  return (
    <>
      <PageContentContainer className={`${clsPrefix}-page-container`} loading={loading}>
        <StickyPageHeader>
          <div className={`${clsPrefix}-header-container`}>
            <h3>Session Logs</h3>
            <Grid container spacing={1} justifyContent="space-between" alignItems="center">
              <Grid item>
                <Link to={PATHS.sessionLogs}>
                  <Button startIcon={<AiOutlineArrowLeft />} color="secondary">
                    Back To Dashboard
                  </Button>
                </Link>
              </Grid>
              <Grid item>
                <Button
                  startIcon={<AiOutlinePlus />}
                  size="large"
                  variant="contained"
                  color="secondary"
                  onClick={() => setShowAddSessionDialog(true)}
                  disabled={loading}>
                  Add Log
                </Button>
              </Grid>
            </Grid>
          </div>
        </StickyPageHeader>
        <Formik<SearchSLFormValues>
          initialValues={{
            provider: !permissions.isAdmin ? currentLoggedInProvider : null,
            school: null,
            student: null,
            supportType: null,
            from: null,
            to: null,
          }}
          onSubmit={handleRetrieveLogsClicked}
          validationSchema={SearchSLFormSchema}
          enableReinitialize
          innerRef={formRef}>
          {(formProps: FormikProps<SearchSLFormValues>) => {
            const shouldDisabledRetrieveLogs = () => {
              const { provider, school, student, supportType, from, to } = formProps.values;
              if (
                isEmpty(provider) &&
                isEmpty(school) &&
                isEmpty(student) &&
                isEmpty(supportType) &&
                isEmpty(from) &&
                isEmpty(to)
              ) {
                return true;
              }
              return false;
            };
            return (
              <Form>
                <MuiDatePickerMomentUtilsProvider>
                  <Grid container spacing={2}>
                    <Grid item container justifyContent="space-between" alignItems="center" className={`${clsPrefix}`}>
                      <Grid item style={{ display: isMobile && 'none' }}>
                        <h4 className={`${clsPrefix}__title`}>Search Session Logs</h4>
                      </Grid>
                      <Grid item container justifyContent="flex-end">
                        <Button color="secondary" onClick={handleClearParameters}>
                          Clear Parameters
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={3}>
                      <Grid item xs={12} sm={4}>
                        <Field name="provider">
                          {({ field, form }: FieldProps) => {
                            return (
                              <SearchDropdownBox<ProviderName>
                                {...field}
                                value={field.value}
                                onChange={v => {
                                    form.setFieldValue('provider', v);
                                    formProps.setStatus(true);
                                }}
                                loading={searchProviderData.loading}
                                placeholder="Find Provider"
                                options={searchProviderData.data || []}
                                getOptionLabel={getProviderOptionLabel}
                                onSearch={searchSLProvider}
                                onClear={clearSearchProvider}
                                noOptionsText="No providers found"
                              />
                            );
                          }}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field name="student">
                          {({ field, form }: FieldProps) => {
                            return (
                              <SearchDropdownBox<StudentName>
                                {...field}
                                value={field.value}
                                onChange={v => form.setFieldValue('student', v)}
                                loading={searchStudentData.loading}
                                placeholder="Find Student"
                                options={searchStudentData.data || []}
                                getOptionLabel={getStudentOptionLabel}
                                onSearch={searchSLStudent}
                                onClear={clearSearchStudent}
                                noOptionsText="No students found"
                                renderOptionCustom={getStudentInfoLabel}
                              />
                            );
                          }}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field name="school">
                          {({ field, form }: FieldProps) => {
                            return (
                              <SearchDropdownBox<SchoolName>
                                {...field}
                                value={field.value}
                                onChange={v => {
                                  form.setFieldValue('school', v);
                                  formProps.setStatus(true);
                                }}
                                loading={searchSchoolData.loading}
                                placeholder="Find School"
                                options={searchSchoolData.data || []}
                                onSearch={searchSLSchool}
                                onClear={clearSearchSchool}
                                getOptionLabel={getSchoolOptionLabel}
                                noOptionsText="No schools found"
                              />
                            );
                          }}
                        </Field>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={3}>
                      <Grid item xs={12} sm={4}>
                        <Field name="supportType">
                          {({
                            field: { name, value },
                            form: { errors, setFieldValue, setFieldTouched },
                            meta: { touched, error },
                          }: FieldProps) => {
                            const getOptionTitle = (o: TypeStringDefault) => o.value;
                            return (
                              <Autocomplete
                                key={`supportType-${value}`}
                                options={SupportTypeOptions}
                                getOptionLabel={getOptionTitle}
                                PopperComponent={CustomPopperAutocomplete}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label="Support Type"
                                    variant="outlined"
                                    error={touched && !!error}
                                    helperText={touched && error}
                                  />
                                )}
                                value={SupportTypeOptions.find(t => t.id === value || '')}
                                onChange={(_e, o: TypeStringDefault) => setFieldValue(`supportType`, o?.id || '')}
                                filterOptions={filterOptionsMatchAllKeywords<TypeStringDefault>(getOptionTitle)}
                                onBlur={() => setFieldTouched(name, true)}
                              />
                            );
                          }}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field name="from">
                          {(renderProps: FieldProps) => (
                            <MuiDatePickerFormikField
                              label="Start Date"
                              disabledHelperText={isMobile ? true : false}
                              {...renderProps}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field name="to">
                          {(renderProps: FieldProps) => (
                            <MuiDatePickerFormikField
                              {...renderProps}
                              disabledHelperText={isMobile ? true : false}
                              label="End Date"
                            />
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                    <Grid item container justifyContent="flex-end" spacing={3}>
                      <Grid item>
                        <Button
                          type="submit"
                          onClick={formProps.handleSubmit as any}
                          variant="contained"
                          color="secondary"
                          size="large"
                          disabled={!formProps.isValid || shouldDisabledRetrieveLogs()}
                          fullWidth>
                          Retrieve logs
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </MuiDatePickerMomentUtilsProvider>
              </Form>
            );
          }}
        </Formik>

        {totalCount === 0 && searched && <Empty description={`No session found.`} />}
        {!isEmpty(logs) && (
          <Element name="session-log-list">
            <SessionLogList
              logs={logs.sort((cur, next) => (cur.sessionDate > next.sessionDate ? -1 : 1))}
              pagination={pagination}
              onPageChange={handlePageNumberChange}
              onPageSizeChange={handlePageSizeChange}
              totalCount={totalCount}
            />
          </Element>
        )}
      </PageContentContainer>
      {showAddSessionDialog && <AddSessionDialog onCreate={handleOnCreateSessionLog} onClose={closeAddStudentDialog} />}
    </>
  );
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const mapStateToProps = (state: IRootState) => {
  const { searchProviders, searchStudents, searchSchools, logs, selectedLog } = state.sessionLogs.searchLogs;
  const { removeLog } = state.sessionLogs.addSession;
  const { userId, fullName, position, username, credentialName, credentialType } = state.auth.user;
  const currentLoggedInProvider: ProviderName = {
    userId,
    firstName: fullName.split(' ')[0],
    lastName: fullName.split(' ')[1],
    position,
    username,
    credentialName,
    credentialType,
  };

  return {
    permissions: selectUserPermissions(state),
    currentLoggedInProvider,
    searchProviderData: searchProviders,
    searchStudentData: searchStudents,
    searchSchoolData: searchSchools,
    logs: logs.data?.items || [],
    totalCount: logs.totalCount || 0,
    loading: logs.loading || selectedLog.loading || removeLog.loading,
  };
};

const mapDispatchToProps = {
  searchSLProvider: searchProvidersAsync.request,
  searchSLStudent: searchStudentsAsync.request,
  searchSLSchool: searchSchoolsAsync.request,
  clearSearchProvider: searchProvidersAsync.cancel,
  clearSearchSchool: searchSchoolsAsync.cancel,
  clearSearchStudent: searchStudentsAsync.cancel,
  getLogs: searchLogsAsync.request,
  clearSearchSessionLogs: clearSearchSessionLogs,
  setSelectedProviderAddSession: setSelectedProviderAddSession,
  setSessionDateAddSession: setSessionDateAddSession,
  setAddSessionTemplateFirstInfo: setAddSessionTemplateFirstInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchSessionLogs);
