export const LOCAL_STORAGE_SUBMIT_CLONE = 'submitCloneButton';

const clearSubmitCloneVal = () => {
  localStorage.removeItem(LOCAL_STORAGE_SUBMIT_CLONE);
};

const getSubmitCloneVal = () => {
  const submitCloneVal = localStorage.getItem(LOCAL_STORAGE_SUBMIT_CLONE);
  return submitCloneVal;
};

const setSubmitCloneVal = (submitCloneVal: string) => {
  localStorage.setItem(LOCAL_STORAGE_SUBMIT_CLONE, submitCloneVal);
};

export default {
    clearSubmitCloneVal,
    getSubmitCloneVal,
    setSubmitCloneVal,
};

