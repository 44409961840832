import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'src/redux/rootReducer';
import { ProviderName } from 'src/redux/schedulerRedux/types';
import {
  getProviderHUserTSAsync,
  searchProvidersToAddTSAsync,
  searchStudentsToAddServiceAsync,
  setSelectedProviderToAddTS,
} from 'src/redux/tieredServices/action';
import { EditableStudentName } from 'src/redux/tieredServices/types';
import { Pagination } from 'src/redux/types';
import { getCorrectPagination } from 'src/utils/paginationUtils';
import { toProvider } from 'src/utils/userToProvider';
import { isEmpty } from 'src/validations';
import AddStudentDialog, { SearchName } from './AddStudentsDialog';
import { StudentFilters } from './Filters';
import SelectProviderDialog from './SelectProviderDialog';
import './styles.scss';

type Props = ReturnType<typeof mapState> &
  typeof mapDispatch & {
    onCreate: (provider: ProviderName, students: EditableStudentName[]) => void;
    onClose: () => void;
    skipSelectProvider?: boolean;
  };

const AddTieredServiceDialog: React.VFC<Props> = ({
  studentsAsyncState,
  totalCount,
  searchStudents,
  searchProviders,
  clearSearchProvidersResults,
  clearSearchStudentsToAddService,
  setSelectedProviderToAddTieredService,
  providersAsyncState,
  currentLoggedInProvider,
  getProviderHUserId,
  onCreate,
  onClose,
  skipSelectProvider,
  selectedStudents: initialSelectedStudents,
  isAdmin,
  completedStudentIds,
}) => {
  const [activeDialog, setActiveDialog] = React.useState<'select-provider' | 'add-students'>(
    skipSelectProvider ? 'add-students' : 'select-provider',
  );
  const [selectedStudents, setSelectedStudents] = React.useState<EditableStudentName[]>(initialSelectedStudents);
  const [selectedProvider, setSelectedProvider] = React.useState<ProviderName>(currentLoggedInProvider);
  const [filters, setFilters] = React.useState<StudentFilters>({
    schools: null,
    studentTypes: [],
  });
  const [search, setSearch] = React.useState<SearchName>({});
  const [searchName, setSearchName] = React.useState<SearchName>({});
  const [pagination, setPagination] = React.useState<Pagination>({
    currentPage: 1,
    pageSize: 10,
    totalPages: 0,
  });
  React.useEffect(() => {
    setPagination(getCorrectPagination(pagination, totalCount));
  }, [studentsAsyncState.data, totalCount]);

  const clearStateStudentsToAddService = () => {
    clearSearchStudentsToAddService();
    setSearch({});
    setSearchName({});
    setFilters({
      schools: null,
      studentTypes: [],
    });
  };

  const handleNextClicked = () => {
    setActiveDialog('add-students');
    clearStateStudentsToAddService();
    if (isEmpty(selectedProvider?.hUserId)) {
      getProviderHUserId({ providerUserId: selectedProvider.userId });
    }
  };

  const onChangeProvider = () => {
    setActiveDialog('select-provider');
    clearStateStudentsToAddService();
    handleDeselectAllStudents();
  };

  const handleOnCreateService = () => {
    onCreate(selectedProvider, selectedStudents);
    setSelectedProviderToAddTieredService(selectedProvider);
  };

  const handleProviderChange = (p: ProviderName) => {
    setSelectedProvider(p);
    setSelectedProviderToAddTieredService(p);
  };

  React.useEffect(() => {
    const emptySearch = {
      firstName: '',
      lastName: '',
    };
    setSearch(emptySearch);
  }, [selectedStudents]);

  const handleSelectStudent = (student: EditableStudentName) => {
    setSelectedStudents([...selectedStudents, student]);
  };

  const handleDeselectStudent = (student: EditableStudentName) => {
    setSelectedStudents(selectedStudents.filter(s => s.studentId !== student.studentId));
  };

  const handleDeselectAllStudents = () => {
    setSelectedStudents([]);
  };

  const fetchStudents = (name, _pagination = pagination, _filters = filters, _provider = selectedProvider) => {
    searchStudents({
      firstname: name?.firstName,
      lastname: name?.lastName,
      includeinactive: _filters.studentTypes?.[0],
      schoolid: _filters.schools?.schoolId,
      itemsperpage: _pagination.pageSize,
      pagenumber: _pagination.currentPage,
      provideruserid: _provider.userId,
    });
  };

  const handleSearch = (name: SearchName) => {
    setSearchName(name);
    fetchStudents(name);
  };

  React.useEffect(() => {}, [searchName]);

  const handlePageChange = (page: number) => {
    const newPagination = getCorrectPagination(
      {
        ...pagination,
        currentPage: page,
      },
      totalCount,
    );
    setPagination(newPagination);
    fetchStudents(searchName, newPagination);
  };

  const handleFilterStudents = (_filter: StudentFilters) => {
    setFilters(_filter);
    if (isEmpty(search.firstName) && isEmpty(search.lastName)) return;
    fetchStudents(search, pagination, _filter);
  };

  return (
    <>
      <SelectProviderDialog
        disabled={!isAdmin}
        providers={!providersAsyncState.data?.length ? [currentLoggedInProvider] : providersAsyncState.data}
        loading={providersAsyncState.loading}
        open={activeDialog === 'select-provider'}
        onClose={onClose}
        onNext={handleNextClicked}
        onChange={handleProviderChange}
        value={selectedProvider}
        onSearch={searchProviders}
        onClear={clearSearchProvidersResults}
      />
      <AddStudentDialog
        selectedStudents={selectedStudents}
        onChangeProvider={onChangeProvider}
        open={activeDialog === 'add-students'}
        onClose={onClose}
        onCreateService={handleOnCreateService}
        totalCount={totalCount}
        records={studentsAsyncState.data}
        loading={studentsAsyncState.loading}
        onSelectStudent={handleSelectStudent}
        onDeselectStudent={handleDeselectStudent}
        onDeselectAll={handleDeselectAllStudents}
        filters={filters}
        onFilterChange={handleFilterStudents}
        searchValue={search}
        onSearchValueChange={setSearch}
        onSearch={handleSearch}
        pagination={pagination}
        onPageChange={handlePageChange}
        showChangeProvider={isAdmin}
        providerName={`${selectedProvider?.firstName} ${selectedProvider?.lastName}`}
        completedStudentIds={completedStudentIds}
        reduxSelectedStudents={initialSelectedStudents}
      />
    </>
  );
};

const mapState = (state: IRootState) => {
  const { searchStudents: studentsAsyncState, completedStudentIds } = state.tieredServices.addTieredService;

  return {
    isAdmin: state.auth.permissions.isAdmin,
    studentsAsyncState,
    totalCount: studentsAsyncState.data[0]?.totalCount || 0,
    providersAsyncState: state.tieredServices.addTieredService.providers,
    currentLoggedInProvider: toProvider(state.auth.user),
    selectedStudents: studentsAsyncState.selected || [],
    completedStudentIds: completedStudentIds,
  };
};

const mapDispatch = {
  searchStudents: searchStudentsToAddServiceAsync.request,
  searchProviders: searchProvidersToAddTSAsync.request,
  clearSearchProvidersResults: searchProvidersToAddTSAsync.cancel,
  setSelectedProviderToAddTieredService: setSelectedProviderToAddTS,
  clearSearchStudentsToAddService: searchStudentsToAddServiceAsync.cancel,
  getProviderHUserId: getProviderHUserTSAsync.request,
};
export default connect(mapState, mapDispatch)(AddTieredServiceDialog);
