import { withStyles } from '@material-ui/core/styles';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from '@material-ui/core';

export const Accordion = withStyles(theme => ({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    padding: '16px 24px',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
    },

    '&$expanded': {
      margin: 'auto',
      boxShadow:
        '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
      [theme.breakpoints.down('sm')]: {
        padding: '8px 8px 0 8px',
      },
    },
  },
  expanded: {},
}))(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,
    padding: 0,
    margin: 0,
    '&$expanded': {
      minHeight: 56,
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
    },
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles(theme => ({
  root: {
    padding: '24px 0px 12px 0px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '8px',
    },
  },
}))(MuiAccordionDetails);
