import React from 'react';
import cn from 'classnames';
import './styles.scss';

import PaginationView from 'src/components/common/Pagination';
import SessionLogItem from '../SessionLogItem';
import { useConfirmDialog } from 'src/components/common/ConfirmDialog';
import PageSizeSelect from 'src/components/ItemsPerPage';

import { connect } from 'react-redux';
import { IRootState } from 'src/redux/rootReducer';
import { Pagination } from 'src/redux/types';
import { SessionLogDetail } from 'src/redux/sessionLogsRedux/types';
import {
  clearSelectedLog,
  getLogByIdAsync,
  getLogByIdCloneAsync,
  removeSessionLogByIdAsync,
  setModeSelectedLog,
} from 'src/redux/sessionLogsRedux/actions';
import Detail from '../../Detail';
import { isEmpty } from 'src/validations';
import { Navigator } from 'src/services';
import { PATHS } from 'src/appConfig/paths';
import { scrollToTop } from '../../AddSessionLog/helper';

const clsPrefix = 'session-log-list';

const SessionLogList: React.VFC<Props> = ({
  logs,
  pagination,
  onPageSizeChange,
  onPageChange,
  totalCount,
  className,
  getLogById,
  getLogByIdClone,
  selectedLog,
  removeSessionLogById,
  setModeSelectedLog,
  clearSelectedLog,
  mode,
}) => {
  const [ConfirmDialog, confirm] = useConfirmDialog('Are you sure you want to delete this session log?');
  const [selectedLogItem, setSelectedLogItem] = React.useState<SessionLogDetail>(null);

  const handleOnEditLog = (log: SessionLogDetail) => {
    setModeSelectedLog('edit');
    // console.log('selectedLog: ', selectedLog);
    isEmpty(selectedLog) ? getLogById(log.sessionId) : Navigator.navigate(PATHS.editSessionLog);
  };

  const handleCloneLog = (log: SessionLogDetail) => {
    setModeSelectedLog('clone');
    
    //Clear out license attest information when selecting 'clone' button from view submission
    log.licenseAttestUserId = null;
    log.licenseAttestDate = '';
    log.licenseAttestName = '';
    
    isEmpty(selectedLog) ? getLogByIdClone(log.sessionId) : Navigator.navigate(PATHS.copySessionLog);
  };

  const handleDeleteLog = (log: SessionLogDetail) => {
    confirm(() => {
      removeSessionLogById({ sessionId: log.sessionId });

      selectedLogItem && setSelectedLogItem(null);
    });
  };

  const handleSessionLogItemSelected = (log: SessionLogDetail) => {
    getLogById(log.sessionId);
    setModeSelectedLog('click');
  };

  React.useEffect(() => {
    !isEmpty(selectedLog) && mode === 'click' && setSelectedLogItem(selectedLog);
  }, [selectedLog]);

  return (
    <div className={cn(`${clsPrefix}`, className)}>
      <div className={`${clsPrefix}-head`}>
        <div className={`${clsPrefix}-head-left`}>
          <h6>Session Logs</h6>
          <span className={`${clsPrefix}-head-left-count`}>
            {totalCount} result{totalCount > 1 ? 's' : ''}
          </span>
        </div>
        <PageSizeSelect
          value={pagination.pageSize}
          onChange={(_, v) => onPageSizeChange(v)}
          sizes={[10, 25, 50, 100]}
        />
      </div>

      {logs.map((log, logIndex) => (
        <SessionLogItem
          key={log.sessionId}
          log={log}
          onEdit={handleOnEditLog}
          onCopy={handleCloneLog}
          onDelete={handleDeleteLog}
          onClick={handleSessionLogItemSelected}
        />
      ))}
      {!!pagination.totalPages && (
        <PaginationView
          count={pagination.totalPages}
          page={pagination.currentPage}
          onChange={(_, v) => {
            onPageChange(v);
            scrollToTop('session-log-list');
          }}
        />
      )}
      {selectedLogItem && mode === 'click' && (
        <Detail
          service={selectedLogItem}
          onClose={() => {
            setSelectedLogItem(null);
            clearSelectedLog();
          }}
          onEdit={handleOnEditLog}
          onCopy={handleCloneLog}
          onDelete={handleDeleteLog}
        />
      )}
      <ConfirmDialog />
    </div>
  );
};

// eslint-disable-next-line
type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps & {
    logs: SessionLogDetail[];
    pagination: Pagination;
    totalCount: number;
    onPageSizeChange: (size: number) => void;
    /**
     * starts from 1
     */
    onPageChange: (page: number) => void;
    unit?: string;
    className?: string;
  };

const mapStateToProps = (state: IRootState) => ({
  selectedLog: state.sessionLogs.searchLogs.selectedLog?.data,
  mode: state.sessionLogs.searchLogs.selectedLog?.mode || '',
});

const mapDispatchToProps = {
  getLogById: getLogByIdAsync.request,
  getLogByIdClone: getLogByIdCloneAsync.request,
  removeSessionLogById: removeSessionLogByIdAsync.request,
  setModeSelectedLog: setModeSelectedLog,
  clearSelectedLog: clearSelectedLog,
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionLogList);
