import { Button, useMediaQuery } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { muiResponsive } from 'src/appConfig/muiTheme';
import { PATHS } from 'src/appConfig/paths';
import PageContentContainer from 'src/components/PageContentContainer';
import StickyPageHeader from 'src/components/StickyPageHeader';
import { IRootState } from 'src/redux/rootReducer';
import { clearSubmissionHistory, cancelEditOrCloneService } from 'src/redux/tieredServices/action';
import { TieredServiceDetail } from 'src/redux/tieredServices/TieredServiceDetail';
import { TieredService } from 'src/redux/tieredServices/types';
import { Navigator } from 'src/services';
import { calcNumberOfPages } from 'src/utils/paginationUtils';
import TieredServiceList from '../Components/TieredServiceList';
import './styles.scss';

const clsPrefix = 'submission-history';

const toTieredService = ({
  birthDate,
  projectedEndDate,
  grade,
  gender,
  planId,
  providerFullName,
  providerPosition,
  providerUserId,
  schoolCode,
  schoolName,
  startDate,
  studentExtId,
  studentFirstName,
  studentId,
  studentLastName,
  tier,
  endDate,
  displayedEndDate,
  activeFlag,
}: TieredServiceDetail): TieredService => ({
  birthdate: birthDate,
  canEdit: true,
  gender,
  grade,
  planId,
  providerFullName,
  providerPosition,
  providerUserId,
  schoolCode,
  schoolName,
  startDate,
  studentExtId,
  studentFirstName,
  studentId,
  studentLastName,
  tier,
  totalCount: null,
  hasSessions: 0,
  endDate,
  projectedEndDate,
  displayedEndDate,
  activeFlag,
});

const SubmissionHistory: React.VFC<typeof mapDispatch & ReturnType<typeof mapState>> = ({
  loading,
  submissions,
  clearSubmissionHistory,
  cancelEditOrCloneService,
}) => {
  const [services, setServices] = React.useState<TieredService[]>([]);
  const isMobile = useMediaQuery(muiResponsive.MOBILE);

  const [itemsPerPage, setItemsPerPage] = React.useState<number>(50);
  const [page, setPage] = React.useState<number>(1);

  const toDashboard = () => {
    cancelEditOrCloneService();
    Navigator.navigate(PATHS.tieredService);
    clearSubmissionHistory();
  };

  const history = useHistory();
  React.useEffect(() => {
    const unlisten = history.listen(({ pathname }) => {
      if (pathname !== PATHS.editTieredService && pathname !== PATHS.copyTieredService) {
        clearSubmissionHistory();
      }
    });
    return unlisten;
  }, [history]);

  React.useEffect(() => {
    if (!submissions.length) {
      toDashboard();
    } else {
      setServices(submissions.filter(s => !!s).map(toTieredService));
    }
  }, [submissions]);

  return (
    <PageContentContainer loading={loading}>
      <StickyPageHeader className={isMobile ? 'pb-8' : 'pb-20'}>
        <h3 className={`${clsPrefix}-header`}>Submission History</h3>
        <Button
          variant="text"
          startIcon={<ArrowBack />}
          color="secondary"
          onClick={toDashboard}
          size="small"
          className={`${clsPrefix}-back-button`}>
          BACK TO DASHBOARD
        </Button>
      </StickyPageHeader>
      <TieredServiceList
        data={services}
        onPageChange={setPage}
        onPageSizeChange={setItemsPerPage}
        pagination={{
          pageSize: itemsPerPage,
          currentPage: page,
          totalPages: calcNumberOfPages(services.length, itemsPerPage),
        }}
        totalCount={services.length}
        unit="submission"
      />
    </PageContentContainer>
  );
};

const mapState = (state: IRootState) => ({
  submissions: state.tieredServices.addTieredService.submissions.records || [],
  loading: state.tieredServices.removeTieredService.loading || false,
});
const mapDispatch = {
  clearSubmissionHistory,
  cancelEditOrCloneService,
};

export default connect(mapState, mapDispatch)(SubmissionHistory);
