import { Button, Grid } from '@material-ui/core';
import Dialog from 'src/components/common/Dialog';

type Props = {
  open: boolean;
  onClose: () => void;
  onCancelRemaining?: () => void;
  onCancelAll?: () => void;
};

const CancelDialog = ({ open, onClose, onCancelRemaining, onCancelAll }: Props) => {
  return (
    <Dialog
      open={open}
      title="You have unsaved information"
      maxWidth="xs"
      fullWidth
      onClose={onClose}
      dialogActions={
        <>
          <Button color="primary" variant="text" onClick={onClose}>
            Cancel
          </Button>
        </>
      }>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button color="secondary" variant="text" onClick={onCancelRemaining} fullWidth>
            Cancel this student and remaining students
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button color="secondary" variant="text" onClick={onCancelAll} fullWidth>
            Cancel all, including ready to submit
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CancelDialog;
