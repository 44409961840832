import { Collapse, Dialog, DialogContent, DialogProps, DialogTitle, IconButton } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import cn from 'classnames';
import React from 'react';
import { FaAddressBook, FaAngleDown, FaAngleUp, FaFile, FaFileAlt } from 'react-icons/fa';
// IoMdCalendar,
import { IoIosMore, IoMdStopwatch } from 'react-icons/io';
import { connect } from 'react-redux';
import { useDialog } from 'src/components/common/useDialog/useDialog';
import PageContentContainer from 'src/components/PageContentContainer';
import { IRootState } from 'src/redux/rootReducer';
// import { getTieredServiceByIdAsync, getTieredServiceSessionLogsAsync } from 'src/redux/tieredServices/action';
import { getTieredServiceByIdAsync } from 'src/redux/tieredServices/action';
import { TieredServiceDetail } from 'src/redux/tieredServices/TieredServiceDetail';
import { TieredService } from 'src/redux/tieredServices/types';
import { getDateDisplay } from 'src/utils/momentUtils';
import { ServiceItemActionPopover, ServiceItemActions } from '../Components/TieredServiceItem';
// import SessionLogs from './SessionLog';
import { isEmpty } from 'src/validations';
import './styles.scss';
import { muiResponsive } from 'src/appConfig/muiTheme';
import moment from 'moment';

type Props = ReturnType<typeof mapState> &
  typeof mapDispatch & {
    service: TieredService;
    planId: number;
    onClose: () => void;
    onEdit?: (service: TieredService) => void;
    onCopy?: (service: TieredService) => void;
    onEnd?: (service: TieredService) => void;
    onDelete?: (service: TieredService) => void;
  };

type Row =
  | 'break'
  | [label: string, content: React.ReactNode]
  | [label: string, content: React.ReactNode, vertical: boolean];

const clsPrefix = 'service-detail';
const InfoSession = ({
  title = null,
  icon = null,
  rows = [],
  omitMargin = false,
  children,
}: {
  rows?: Row[];
  title?: string;
  icon?: React.ReactNode;
  omitMargin?: boolean;
  children?: React.ReactNode;
}) => {
  const isMobile = useMediaQuery(muiResponsive.MOBILE);
  const [collapsed, setCollapsed] = React.useState<boolean>(false);

  return (
    <div className={cn(`${clsPrefix}-session-info`, omitMargin && 'omit-margin', !title && 'no-title')}>
      {title && (
        <button
          className={`${clsPrefix}-session-info-title`}
          {...(isMobile ? { onClick: () => setCollapsed(collapsed => !collapsed) } : undefined)}>
          <div>
            {icon}
            <h6>{title}</h6>
          </div>
          {isMobile && (
            <IconButton className={`${clsPrefix}-session-info-title-collapse-button`}>
              {collapsed ? <FaAngleDown /> : <FaAngleUp />}
            </IconButton>
          )}
        </button>
      )}
      <Collapse in={!collapsed}>
        {rows.map((row, i) => {
          if (row === 'break') {
            return <div key={`${title}-break-${i}`} className={`${clsPrefix}-session-info-break`} />;
          }

          return (
            <div
              key={`${title || rows[0]}-${i}`}
              className={cn(`${clsPrefix}-session-info-row`, row.length > 2 && row[2] && 'vertical')}>
              <span className={`${clsPrefix}-session-info-label`}>{row[0]}</span>
              {row[1] && <span className={`${clsPrefix}-session-info-content`}>{row[1]}</span>}
            </div>
          );
        })}
        {children}
      </Collapse>
    </div>
  );
};

const ReasonForEdittingDialog: React.VFC<DialogProps & { reason: string }> = ({ reason, ...dialogProps }) => (
  <Dialog fullWidth maxWidth="xs" {...dialogProps}>
    <DialogTitle className={`${clsPrefix}-dialog-title`} disableTypography>
      <h6>Reason for Editing</h6>
      <IconButton className={`${clsPrefix}-dialog-icon`} onClick={() => dialogProps.onClose(null, null)}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>{reason}</DialogContent>
  </Dialog>
);

const AuditLog: React.VFC<{
  logs: TieredServiceDetail['logs'];
}> = ({ logs = [] }) => {
  const [reasonToEdit, setReasonToEdit] = React.useState<string>('');
  const [ReasonForEditting, show] = useDialog(ReasonForEdittingDialog, {
    reason: reasonToEdit,
  });

  const handleReasonForEditClicked = (comment: string) => {
    setReasonToEdit(comment);
    show();
  };

  const rows = logs.map<Row>(({ comment, updateName, fullName, updatedDate }) => [
    fullName,
    <div className={`${clsPrefix}-audit-log-item-container`}>
      <span>
        {updateName} {moment(updatedDate).format('MM/DD/YYYY h:mm A')}
      </span>
      {!!comment && (
        <IconButton size="small" onClick={() => handleReasonForEditClicked(comment)}>
          <FaFile />
        </IconButton>
      )}
    </div>,
    true,
  ]);

  return (
    <>
      <InfoSession title="Audit Log" icon={<IoMdStopwatch />} rows={rows} />
      <ReasonForEditting />
    </>
  );
};

const TieredServiceDetailView: React.VFC<Props> = ({
  planId,
  service,
  detail,
  sessionLogs,
  loading,
  getTieredServiceById,
  // getTieredServiceSessionLogs,
  onClose,
  onEdit,
  onCopy,
  onEnd,
  onDelete,
}) => {
  const {
    studentFirstName,
    studentLastName,
    studentExtId,
    schoolName,
    grade,
    schoolCode,
    gender,
    birthdate,
    tier,
    startDate,
    providerFullName,
    providerPosition,
    hasSessions,
    canEdit,
    activeFlag,
  } = service;

  const {
    frequencyMinute,
    frequencyTimes,
    frequencyInterval,
    projectedEndDate,
    endDate,
    altProviderFullName,
    altProviderPosition,
    logs,
    outcome,
    notes,
    target: targets = [],
  } = {
    ...detail,
  };
  const isMobile = useMediaQuery(muiResponsive.MOBILE);

  const [actionPopoverAnchor, setActionPopoverAnchor] = React.useState<HTMLButtonElement>(null);

  React.useEffect(() => {
    if (planId) {
      getTieredServiceById(planId);
      // getTieredServiceSessionLogs(planId);
    }
  }, [planId]);

  const handleTitleActionClicked = callback => () => callback(service);

  const studentInfo = () => (
    <InfoSession
      title="Student Info"
      icon={<FaAddressBook />}
      rows={[
        ['Name', `${studentFirstName} ${studentLastName}`],
        ['ID', studentExtId],
        ['School', `${schoolName} (${schoolCode})`],
        ['Gender', `${gender}`],
        ['Grade', grade],
        ['Birth Date', getDateDisplay(birthdate)],
      ]}
    />
  );

  const getProviderLabel = (fullname: string, position?: string) =>
    fullname ? `${fullname}${position ? ` / ${position}` : ''}` : '';

  const tieredServiceInfo = () => {
    return (
      <InfoSession
        title="Tiered Service Info"
        icon={<FaFileAlt />}
        rows={[
          ['Tier', tier],
          ['Start Date', getDateDisplay(startDate)],
          ['End Date', endDate ? getDateDisplay(endDate) : ''],
          ['Projected End Date', projectedEndDate ? getDateDisplay(projectedEndDate) : ''],
          [
            'Frequency',
            `${
              isEmpty(frequencyMinute) && isEmpty(frequencyTimes) && isEmpty(frequencyInterval)
                ? ''
                : `${frequencyMinute && `${frequencyMinute} min${frequencyMinute > 1 ? 's' : ''}`} ${
                    frequencyTimes !== null && frequencyTimes !==0 ? `${frequencyTimes} time${frequencyTimes > 1 ? 's' : ''}` : ``
                  } per ${frequencyInterval === "SEM" ? 'semester' : `${frequencyInterval === "TRI" ? 'trimester' : `${frequencyInterval.toLowerCase()}`}`}`
            }`,
            true,
          ],
          ['Provider', getProviderLabel(providerFullName, providerPosition), true],
          ['Alternate Provider', getProviderLabel(altProviderFullName, altProviderPosition), true],
          'break',
          ...targets.reduce(
            (rows, t) => [
              ...rows,
              ['Target', t.otherFlag ? `${t.targetArea}: ${t.targetOther}` : t.targetFullName, true],
              ['Practice Element', t.practice, true],
              'break',
            ],
            [],
          ),
        ]}
      />
    );
  };

  const outcomeAndNotes = () => (
    <InfoSession
      rows={[
        ['Outcome', outcome || '', true],
        ['Notes/Analysis', notes || '', true],
      ]}
    />
  );

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullScreen={isMobile}
      fullWidth
      maxWidth="md"
      scroll="paper"
      className={`${clsPrefix}-dialog`}>
      <DialogTitle className={`${clsPrefix}-dialog-title`} disableTypography>
        <h6>View Tiered Service</h6>
        <div className={`${clsPrefix}-dialog-actions-container`}>
          {isMobile && canEdit && (
            <>
              <IconButton onClick={({ currentTarget }) => setActionPopoverAnchor(currentTarget)}>
                <IoIosMore />
              </IconButton>
              <ServiceItemActionPopover container={actionPopoverAnchor} onClose={() => setActionPopoverAnchor(null)}>
                <ServiceItemActions
                  renderAsMenu
                  onEdit={handleTitleActionClicked(onEdit)}
                  onCopy={handleTitleActionClicked(onCopy)}
                  onEnd={handleTitleActionClicked(onEnd)}
                  onDelete={handleTitleActionClicked(onDelete)}
                  showDelete={!hasSessions}
                  showMarkAsDone={activeFlag}
                />
              </ServiceItemActionPopover>
            </>
          )}
          {!isMobile && canEdit && (
            <ServiceItemActions
              onEdit={handleTitleActionClicked(onEdit)}
              onCopy={handleTitleActionClicked(onCopy)}
              onEnd={handleTitleActionClicked(onEnd)}
              onDelete={handleTitleActionClicked(onDelete)}
              showDelete={!hasSessions}
              showMarkAsDone={activeFlag}
            />
          )}
        </div>
        <IconButton className={`${clsPrefix}-dialog-icon`} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <PageContentContainer loading={loading}>
          {detail && (
            <>
              {studentInfo()}
              {tieredServiceInfo()}
              {outcomeAndNotes()}
              {/* {createSessionLogs()} */}
              <AuditLog logs={logs.sort((cur, nex) => (cur.updatedDate < nex.updatedDate ? -1 : 1))} />
            </>
          )}
        </PageContentContainer>
      </DialogContent>
    </Dialog>
  );
};

const mapState = (state: IRootState) => ({
  detail: state.tieredServices.detail.data,
  sessionLogs: state.tieredServices.detail.sessionLogs,
  loading:
    state.tieredServices.detail.loading ||
    state.sessionLogs.addSession.removeLog.loading ||
    state.sessionLogs.searchLogs.selectedLog.loading,
  error: state.tieredServices.detail.error,
});

const mapDispatch = {
  getTieredServiceById: getTieredServiceByIdAsync.request,
  // getTieredServiceSessionLogs: getTieredServiceSessionLogsAsync.request,
};

export default connect(mapState, mapDispatch)(TieredServiceDetailView);
