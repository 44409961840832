import React from 'react';

import './styles.scss';
import { IMAGES } from 'src/appConfig/images';

type EmptyProps = {
  description: string;
};

const Empty: React.FC<EmptyProps> = (props: EmptyProps) => {
  const { description } = props;

  return (
    <div className="empty">
      <img src={IMAGES.emptyIcon} alt="empty" />
      <p className="mt-20">{description}</p>
    </div>
  );
};

export default Empty;
