import { IRootState } from '../rootReducer';
import { User, Permissions } from './types';

export const selectUser = (state: IRootState): User => {
  return state.auth.user;
};

export const selectDefaultMenu = (state: IRootState): string => {
  return state.auth.defaultMenu.state;
};

export const selectUserPreferencesTooltip = (state: IRootState): boolean => {
  return state.auth.tooltip.state;
};

export const selectUserPermissions = (state: IRootState): Permissions => {
  return state.auth.permissions;
};
