import React from 'react';
import './styles.scss';

import { connect } from 'react-redux';
import { IRootState } from 'src/redux/rootReducer';

import { Grid, IconButton, Paper, Tooltip } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { MdMoreVert } from 'react-icons/md';
import ActionsButtonPopover, { ActionsButton } from '../ActionsButtonPopover';
import { SessionLogDetail } from 'src/redux/sessionLogsRedux/types';
import { DateFormatDisplayShort, getDateDisplay, TimeFormat12 } from 'src/utils/momentUtils';
import moment from 'moment';
import { muiResponsive } from 'src/appConfig/muiTheme';
import { getSupportTypeName } from 'src/utils/nameUtils';
import { isEmpty } from 'src/validations';

const clsPrefix = 'submission-history-item';
const toolTipPreferences = localStorage.getItem('toolTipPreferences') === 'true' ? true : false;

// TODO: update type any 'T = any' to ServiceLog in redux store
type SessionLogItemCallback<T = SessionLogDetail> = (service: T) => void;
type SessionLogItemProps<T = SessionLogDetail> = {
  service?: T;
  onClick?: SessionLogItemCallback<T>;
  onEdit?: SessionLogItemCallback<T>;
  onCopy?: SessionLogItemCallback<T>;
  onEnd?: SessionLogItemCallback<T>;
  onDelete?: SessionLogItemCallback<T>;
};

const noop = e => {};

const SessionLogItem: React.VFC<SessionLogItemProps<any>> = ({
  service,

  onClick = noop,
  onEdit = noop,
  onCopy = noop,
  onEnd = noop,
  onDelete = noop,
}) => {
  const isMobile = useMediaQuery(muiResponsive.MOBILE);
  const [actionPopoverAnchor, setActionPopoverAnchor] = React.useState<HTMLButtonElement>(null);

  const handleOnClick = callback => e => {
    e.stopPropagation();
    callback(service);
  };

  const sessionDateFormat = moment(service.sessionDate).format(DateFormatDisplayShort);

  const sessionTimeFormat =
    service.sessionTime !== null ? moment(service.sessionTime, 'HH:mm:ss').format(TimeFormat12) : '';

  const sessionMinutes = service.studentAbsentFlag
    ? 'Student Not Available'
    : `${service.sessionMinutes} min${service.sessionMinutes > 1 ? 's' : ''}`;

  const actions = (renderAsMenu: boolean, asGridItem: boolean) => (
    <ActionsButton
      renderAsMenu={renderAsMenu}
      asGridItem={asGridItem}
      onEdit={handleOnClick(onEdit)}
      onCopy={handleOnClick(onCopy)}
      onDelete={handleOnClick(onDelete)}
    />
  );

  return (
    <Paper variant="outlined" elevation={3} className={`${clsPrefix}`}>
      <Grid item container spacing={isMobile ? 1 : 2} onClick={handleOnClick(onClick)}>
        <Grid item xs={12} sm={3}>
          <Tooltip arrow placement="bottom-start" title={ toolTipPreferences ? "Student Name (Student ID)" : ''}>
            <h5 className={`mb-8 mobile ${clsPrefix}-name-highlight-mobile`}>
              {service.studentFirstName} {service.studentLastName} ({service.studentExtId})
            </h5>
          </Tooltip>
          <Tooltip arrow placement="bottom-start" title={ toolTipPreferences ? "School Name (Grade)" : ''}>
            <h6 className="">
              {service.studentSchoolName} ({service.grade})
            </h6>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Tooltip arrow placement="bottom-start" title={ toolTipPreferences ? `Session Date / Time / Minutes` : ''}>
            <h5 className="mb-8 mobile">{`${sessionDateFormat} - ${sessionTimeFormat} (${sessionMinutes})`}</h5>
          </Tooltip>
          {isMobile && (
            <h6 className={`${clsPrefix}-text-bold-mobile`}>
              {!isEmpty(service.supportTypeName) ? service.supportTypeName : getSupportTypeName(service.supportType)}
            </h6>
          )}
          <Tooltip arrow placement="bottom-start" title={ toolTipPreferences ? `Service Start Date - End Date` : ''}>
            <h6 className="mb-8 mobile">
              {service.serviceStartDate ? getDateDisplay(service.serviceStartDate) : ''}{' '}
              {service.serviceEndDate
                ? getDateDisplay(service.serviceEndDate, formattedDate => ` - ${formattedDate}`)
                : ''}{' '}
              {service.serviceEsy && '(ESY)'}
            </h6>
          </Tooltip>
          {!isMobile && (
            <Tooltip arrow placement="bottom-start" title={ toolTipPreferences ? "Support Type" : ''}>
              <h6>
                {!isEmpty(service.supportTypeName) ? service.supportTypeName : getSupportTypeName(service.supportType)}
              </h6>
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={12} sm={3}>
          <Tooltip arrow placement="bottom-start" title={ toolTipPreferences ? `Location Name ${service.locationDetails ? `- Details` : ``}`: ''}>
            <h5 className="mb-8 mobile">
              {service.locationName} {service.locationDetails ? `- ${service.locationDetails}` : ''}
            </h5>
          </Tooltip>
          <Tooltip arrow placement="bottom-start" title={ toolTipPreferences ? "Provider Name" : ''}>
            <h6>
              {service.providerFullName ? service.providerFullName : ''}{' '}
              {service.providerCredential && isMobile
                ? `/ ${service.providerCredentialType}`
                : service.providerCredential && !isMobile
                ? `/ ${service.providerCredential}`
                : null}
            </h6>
          </Tooltip>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={2}
          spacing={2}
          justifyContent="flex-end"
          alignItems="flex-start"
          style={{ display: isMobile && 'none' }}>
          {actions(false, true)}
        </Grid>
      </Grid>

      {isMobile && (
        <>
          <div className={`${clsPrefix}__moreBtn`}>
            <IconButton size="small" onClick={({ currentTarget }) => setActionPopoverAnchor(currentTarget)}>
              <MdMoreVert />
            </IconButton>
            <ActionsButtonPopover container={actionPopoverAnchor} onClose={() => setActionPopoverAnchor(null)}>
              {actions(true, false)}
            </ActionsButtonPopover>
          </div>
        </>
      )}
    </Paper>
  );
};

// eslint-disable-next-line
type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SessionLogItem);
