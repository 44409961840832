import React from 'react';
import './styles.scss';

import { connect } from 'react-redux';
import { IRootState } from 'src/redux/rootReducer';

import PageContentContainer from 'src/components/PageContentContainer';
import StickyPageHeader from 'src/components/StickyPageHeader';
import Empty from 'src/components/Empty';

import { Button } from '@material-ui/core';

import { AiOutlineArrowLeft } from 'react-icons/ai';
import { PATHS } from 'src/appConfig/paths';
import SubmissionHistoryItem from '../components/SubmissionHistoryItem';
import Detail from '../Detail';
import {
  cancelAddingSession,
  getLogByIdAsync,
  getLogByIdCloneAsync,
  removeSessionLogByIdAsync,
  setModeSelectedLog,
} from 'src/redux/sessionLogsRedux/actions';
import { Navigator } from 'src/services';
import { SessionLogDetail } from 'src/redux/sessionLogsRedux/types';
import { useConfirmDialog } from 'src/components/common/ConfirmDialog';
import { useHistory } from 'react-router-dom';

const clsPrefix = 'submission-history';

const SubmissionHistory: React.FC<Props> = ({
  records,
  clearSubmissionHistory,
  getLogById,
  getLogByIdClone,
  setModeSelectedLog,
  removeSessionLogById,
  mode,
  loading,
}) => {
  const [ConfirmDialog, confirm] = useConfirmDialog('Are you sure you want to delete this session log?');

  const [selectedSubmissionItem, setSelectedSubmissionItem] = React.useState<SessionLogDetail>(null);

  const handleOnEditAction = (service: SessionLogDetail) => {
    getLogById(service.sessionId);
    setModeSelectedLog('edit');
  };

  const handleOnCopyAction = (service: SessionLogDetail) => {
    getLogByIdClone(service.sessionId);
    setModeSelectedLog('clone');
  };

  const handleOnDeleteAction = (service: SessionLogDetail) => {
    confirm(() => {
      removeSessionLogById({ sessionId: service.sessionId });
      setSelectedSubmissionItem(null);
    });
  };

  // TODO: update type, function
  const handleSubmissionHistoryItemSelected = (service: SessionLogDetail) => {
    setSelectedSubmissionItem(service);
    setModeSelectedLog('click');
  };

  const toDashboard = () => {
    Navigator.navigate(PATHS.sessionLogs);
    clearSubmissionHistory();
  };

  const history = useHistory();
  React.useEffect(() => {
    const unlisten = history.listen(({ pathname }) => {
      if (pathname !== PATHS.submissionHistorySessionLog) {
        clearSubmissionHistory();
      }
    });
    return unlisten;
  }, [history]);

  React.useEffect(() => {
    if (!records.length) {
      toDashboard();
    }
  }, [records]);

  return (
    <>
      <PageContentContainer className={`${clsPrefix}-page-container`} loading={loading}>
        <StickyPageHeader>
          <div className={`${clsPrefix}-header-container`}>
            <h3>Submission History</h3>
            <Button startIcon={<AiOutlineArrowLeft />} variant="text" color="secondary" onClick={toDashboard}>
              Back To Dashboard
            </Button>
          </div>
        </StickyPageHeader>
        {!records.length ? (
          <Empty description="There is no any search result" />
        ) : (
          <div className={`${clsPrefix}-results-container`}>
            <div className={`${clsPrefix}-results-head`}>
              <div className={`${clsPrefix}-results-head-left`}>
                <h6>Session Logs</h6>
                <span className={`${clsPrefix}-results-head-left-count`}>
                  {records.length} submission{records.length > 1 ? 's' : ''}
                </span>
              </div>
            </div>
            <div className={`${clsPrefix}-results-list`}>
              {records.map((record, index) => (
                <SubmissionHistoryItem
                  key={record?.supportId + index}
                  service={record}
                  onEdit={handleOnEditAction}
                  onCopy={handleOnCopyAction}
                  onDelete={handleOnDeleteAction}
                  onClick={handleSubmissionHistoryItemSelected}
                />
              ))}
            </div>
          </div>
        )}
      </PageContentContainer>
      {selectedSubmissionItem && mode === 'click' && (
        <Detail
          service={selectedSubmissionItem}
          onClose={() => {
            setSelectedSubmissionItem(null);
            setModeSelectedLog(null);
          }}
          onEdit={handleOnEditAction}
          onCopy={handleOnCopyAction}
          onDelete={handleOnDeleteAction}
        />
      )}
      <ConfirmDialog />
    </>
  );
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const mapStateToProps = (state: IRootState) => {
  const { records } = state.sessionLogs.addSession.submissions;
  const { removeLog } = state.sessionLogs.addSession;
  return {
    records,
    mode: state.sessionLogs.searchLogs.selectedLog.mode,
    loading: removeLog.loading,
  };
};

const mapDispatchToProps = {
  clearSubmissionHistory: cancelAddingSession,
  getLogById: getLogByIdAsync.request,
  getLogByIdClone: getLogByIdCloneAsync.request,
  setModeSelectedLog: setModeSelectedLog,
  removeSessionLogById: removeSessionLogByIdAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionHistory);
