import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  makeStyles,
  Popover,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import React from 'react';
import { FaFilter } from 'react-icons/fa';
import { connect } from 'react-redux';
import { MuiSelectFormikField } from 'src/components/common/MuiSelect';
import SearchDropdownBox from 'src/components/SearchDropdownBox';
import { IRootState } from 'src/redux/rootReducer';
import { SchoolName } from 'src/redux/schedulerRedux/types';
import { searchSchoolsAddSLAsync } from 'src/redux/sessionLogsRedux/actions';
import { SupportTypeOptions, TypeStringDefault } from 'src/redux/sessionLogsRedux/types';
import { isEmpty } from 'src/validations';
import * as yup from 'yup';

export type StudentFiltersSL = {
  supportTypes: string[];
  schools: SchoolName;
  supportStatus: boolean;
  studentTypes: boolean;
};

type Props = {
  value: StudentFiltersSL;
  onChange: (filters: StudentFiltersSL) => void;
} & ReturnType<typeof mapState> &
  typeof mapDispatch;

export type FilterFormValue = {
  supportTypes: string[];
  schools?: SchoolName;
  supportStatus: boolean;
  studentTypes: boolean;
};

export const FilerFormSchema = yup.object().shape({
  supportTypes: yup
    .array(yup.string().required('Please select support type'))
    .min(1, 'Please select support type')
    .required('Please select support type'),
  schools: yup.mixed().nullable(),
  supportStatus: yup.boolean(),
  studentTypes: yup.boolean(),
});

const StudentFiltersSLView: React.VFC<Props> = ({
  searchSchoolState,
  value,
  onChange,
  searchSchools,
  clearSearchSchools,
}) => {
  const classes = useStyles();
  const [filtersPopoverAnchor, setFiltersPopoverAnchor] = React.useState<HTMLButtonElement>(null);
  const [initialFormValue, setInitialFormValue] = React.useState<FilterFormValue>({
    supportTypes: null,
    schools: null,
    supportStatus: false,
    studentTypes: false,
  });

  React.useEffect(() => {
    setInitialFormValue(value);
  }, [value]);

  const onClose = () => {
    setFiltersPopoverAnchor(null);
  };

  const handleReset = () => {
    onChange({
      supportTypes: ['service', 'sbbhtiered'],
      schools: null,
      supportStatus: false,
      studentTypes: false,
    });

    onClose();
  };

  const handleApply = ({ supportTypes, schools, supportStatus, studentTypes }: FilterFormValue) => {
    onChange({
      supportTypes: supportTypes,
      schools,
      supportStatus: supportStatus,
      studentTypes: studentTypes,
    });
    onClose();
  };

  const removeFilter = (filter: keyof StudentFiltersSL, id?: string | number) => {
    if (filter === 'schools') {
      onChange({
        ...value,
        schools: null,
      });
    } else if (filter === 'studentTypes') {
      onChange({
        ...value,
        studentTypes: false,
      });
    } else if (filter === 'supportStatus') {
      onChange({
        ...value,
        supportStatus: false,
      });
    } else if (filter === 'supportTypes') {
      onChange({
        ...value,
        supportTypes: value.supportTypes.filter(v => v !== id),
      });
    }
  };

  return (
    <div className={classes.container}>
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<FaFilter />}
        onClick={({ currentTarget }) => setFiltersPopoverAnchor(currentTarget)}>
        FILTER
      </Button>
      <Popover
        open={!!filtersPopoverAnchor}
        anchorEl={filtersPopoverAnchor}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <Formik<FilterFormValue>
          initialValues={initialFormValue}
          onSubmit={handleApply}
          validationSchema={FilerFormSchema}>
          {(formProps: FormikProps<FilterFormValue>) => {
            // console.log('formProps values: ', formProps.values);
            // console.log('formProps errors: ', formProps.errors);
            return (
              <Form>
                <Card>
                  <CardHeader
                    classes={{ root: classes.popoverHeader }}
                    title={<Typography component="h6">Filter By</Typography>}
                  />
                  <CardContent
                    classes={{
                      root: classes.popoverContent,
                    }}>
                    <Field name="supportTypes" className={`support-types-select`}>
                      {(props: FieldProps) => (
                        <MuiSelectFormikField<TypeStringDefault, string>
                          multiple
                          emptyText=""
                          options={SupportTypeOptions}
                          label={'Support Type'}
                          getOptionTitle={o => o.value}
                          getOptionValue={o => o.id}
                          style={{ height: "6rem" }}
                          {...props}
                          onBlur={() => props.form.setFieldTouched(props.field.name, true)}
                          errorMessage={props.meta.error}
                        />
                      )}
                    </Field>
                    <Field name="schools">
                      {({ field, form }: FieldProps) => (
                        <SearchDropdownBox<SchoolName>
                          placeholder="School"
                          value={field.value}
                          onChange={v => {
                            form.setFieldValue('schools', v);
                          }}
                          loading={searchSchoolState.loading}
                          options={searchSchoolState.data || []}
                          onSearch={searchSchools}
                          getOptionLabel={o => o.schoolName}
                          onClear={clearSearchSchools}
                          noOptionsText="No schools found"
                        />
                      )}
                    </Field>
                    <Field name="supportStatus">
                      {({ field, form, meta }: FieldProps) => {
                        return (
                          <div className="textfield-checkbox-wrapper">
                            <TextField
                              variant="outlined"
                              label="Support Status"
                              defaultValue="All Supports (Incl Inactive)"
                              InputProps={{
                                readOnly: true,
                              }}
                              fullWidth
                              className="cmp-select"
                            />
                            <Checkbox
                              checked={field.value}
                              onChange={() => {
                                formProps.setFieldValue(field.name, !field.value);
                              }}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              className="textfield-checkbox-icon"
                            />
                          </div>
                        );
                      }}
                    </Field>

                    <Field name="studentTypes">
                      {({ field, form, meta }: FieldProps) => (
                        <div className="textfield-checkbox-wrapper">
                          <TextField
                            variant="outlined"
                            label="Student Type"
                            defaultValue="All Students (Incl Inactive)"
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                            className="cmp-select"
                          />
                          <Checkbox
                            checked={field.value}
                            onChange={() => {
                              formProps.setFieldValue(field.name, !field.value);
                            }}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            className="textfield-checkbox-icon"
                          />
                        </div>
                      )}
                    </Field>
                  </CardContent>
                  <CardActions classes={{ root: classes.popoverActions }}>
                    <Button color="secondary" onClick={handleReset}>
                      RESET
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => formProps.handleSubmit()}
                      disabled={isEmpty(formProps.values.supportTypes) || (!formProps.dirty && !formProps.isValid)}>
                      APPLY
                    </Button>
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Popover>

      <div className={classes.chipContainer}>
        {!!value.supportTypes?.length &&
          SupportTypeOptions.filter(o => value.supportTypes.includes(o.id)).map(supportType => (
            <Chip
              key={supportType.id}
              className={classes.chip}
              label={supportType.value}
              {...(value.supportTypes.length > 1
                ? { onDelete: () => removeFilter('supportTypes', supportType.id) }
                : undefined)}
            />
          ))}
        {value.schools && value.schools?.schoolId !== -1 && (
          <Chip
            className={`${classes.chip}`}
            label={`School: ${value.schools?.schoolName}`}
            onDelete={() => {
              removeFilter('schools');
              clearSearchSchools();
            }}
          />
        )}
        {value.supportStatus && (
          <Chip
            className={classes.chip}
            label={`All Supports (Incl Inactive)`}
            onDelete={() => removeFilter('supportStatus')}
          />
        )}
        {value.studentTypes && (
          <Chip
            className={classes.chip}
            label={`All Students (Incl Inactive)`}
            onDelete={() => removeFilter('studentTypes')}
          />
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    margin: '0 0 16px 0',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
      margin: 16,
    },
  },
  chipContainer: {
    marginLeft: 16,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  chip: {
    borderRadius: '4px !important',
    '&:not(:last-child)': {
      marginRight: 8,
    },
    marginTop: 0,
    [theme.breakpoints.down('xs')]: {
      marginTop: 8,
    },
  },
  popoverContent: {
    padding: 24,
    width: 490,
    '& > *:not(:last-child)': {
      marginBottom: 24,
    },
    [theme.breakpoints.down('xs')]: {
      width: 350,
    },
  },
  popoverHeader: {
    borderBottom: '1px solid #ebebeb',
  },
  popoverActions: {
    borderTop: '1px solid #ebebeb',
    justifyContent: 'flex-end',
    paddingRight: 24,
  },
}));
const mapState = (state: IRootState) => {
  return {
    searchSchoolState: state.sessionLogs.addSession.search.searchSchools,
  };
};

const mapDispatch = {
  searchSchools: searchSchoolsAddSLAsync.request,
  clearSearchSchools: searchSchoolsAddSLAsync.cancel,
};

export default connect(mapState, mapDispatch)(StudentFiltersSLView);
