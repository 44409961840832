import cn from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { PATHS } from 'src/appConfig/paths';
import { useConfirmDialog } from 'src/components/common/ConfirmDialog';
import PaginationView from 'src/components/common/Pagination';
import { useDialog } from 'src/components/common/useDialog/useDialog';
import PageSizeSelect from 'src/components/ItemsPerPage';
import {
  clearTieredServiceDetail,
  cloneTieredService,
  editTieredService,
  endTieredService,
  removeTieredServiceAsync,
  setSelectedStudents,
} from 'src/redux/tieredServices/action';
import { TieredService } from 'src/redux/tieredServices/types';
import { Pagination } from 'src/redux/types';
import { Navigator } from 'src/services';
import Detail from '../../Detail';
import { ReasonForEdittingDialog } from '../ReasonForEditting';
import TieredServiceItem from '../TieredServiceItem';
import './styles.scss';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { muiResponsive } from 'src/appConfig/muiTheme';
const clsPrefix = 'tiered-service-list';

type Props = {
  data: TieredService[];
  pagination: Pagination;
  totalCount: number;
  onPageSizeChange: (size: number) => void;
  /**
   * starts from 1
   */
  onPageChange: (page: number) => void;
  unit?: string;
  className?: string;
} & typeof mapDispatch;

export const TieredServiceList = ({
  data,
  pagination,
  onPageSizeChange,
  onPageChange,
  totalCount,
  unit = 'result',
  className,

  editTieredService,
  cloneTieredService,
  endTieredService,
  removeTieredService,
  clearTieredServiceDetail,
}: Props) => {
  const isMobile = useMediaQuery(muiResponsive.MOBILE);
  const [selectedServiceItem, setSelectedServiceItem] = React.useState<TieredService>(null);

  const [ConfirmDialog, confirm] = useConfirmDialog('Are you sure you want to delete this Tiered Service?');

  const [Reason, showReasonForEditingDialog] = useDialog(ReasonForEdittingDialog);

  const handleOnEditService = (service: TieredService) => {
    showReasonForEditingDialog(close => ({
      onConfirm: (reason: string) => {
        onProceedEditting(reason, service);
        close();
      },
    }));
  };

  const handleServiceItemSelected = (service: TieredService) => setSelectedServiceItem(service);

  const onProceedEditting = (reason: string, service: TieredService) => {
    editTieredService({ reason, planId: service.planId });
    Navigator.navigate(PATHS.editTieredService);
  };

  const handleCloneServiceItem = (service: TieredService) => {
    cloneTieredService(service.planId);
    Navigator.navigate(PATHS.copyTieredService);
  };

  const handleDeleteServiceItem = (service: TieredService) => {
    confirm(() => {
      removeTieredService({
        planId: service.planId,
      });
      setSelectedServiceItem(null);
    });
  };

  const handleEndTieredService = (service: TieredService) => {
    endTieredService(service.planId);
    Navigator.navigate(PATHS.editTieredService);
  };

  return (
    <>
      <div className={cn(`${clsPrefix}-results-container`, className)}>
        <div className={`${clsPrefix}-results-head`}>
          {!isMobile && (
            <>
              <div className={`${clsPrefix}-results-head-left`}>
                <h6 className={`${clsPrefix}-results-header`}>Tiered Services</h6>
                <span className={`${clsPrefix}-results-head-left-count`}>
                  {`${totalCount} ${unit}${totalCount > 1 ? 's' : ''}`}
                </span>
              </div>
              <PageSizeSelect
                value={pagination.pageSize}
                onChange={(_, v) => onPageSizeChange(v)}
                sizes={[10, 25, 50, 100]}
              />
            </>
          )}
          {isMobile && (
            <>
              <h6 className={`${clsPrefix}-results-header`}>Tiered Services</h6>
              <div className={`${clsPrefix}-results-head-left`}>
                <span className={`${clsPrefix}-results-head-left-count`}>
                  {`${totalCount} ${unit}${totalCount > 1 ? 's' : ''}`}
                </span>
                <PageSizeSelect
                  value={pagination.pageSize}
                  onChange={(_, v) => onPageSizeChange(v)}
                  sizes={[10, 25, 50, 100]}
                />
              </div>
            </>
          )}
        </div>
        <div className={`${clsPrefix}-results-list`}>
          {data.map(service => (
            <TieredServiceItem
              key={service.planId}
              service={service}
              onEdit={handleOnEditService}
              onClick={handleServiceItemSelected}
              onCopy={handleCloneServiceItem}
              onDelete={handleDeleteServiceItem}
              onEnd={handleEndTieredService}
            />
          ))}
          {!!pagination.totalPages && (
            <PaginationView
              count={pagination.totalPages}
              page={pagination.currentPage}
              onChange={(_, v) => onPageChange(v)}
            />
          )}
        </div>
      </div>
      {selectedServiceItem && (
        <Detail
          planId={selectedServiceItem.planId}
          service={selectedServiceItem}
          onClose={() => {
            setSelectedServiceItem(null);
            clearTieredServiceDetail();
          }}
          onEdit={handleOnEditService}
          onCopy={handleCloneServiceItem}
          onDelete={handleDeleteServiceItem}
          onEnd={handleEndTieredService}
        />
      )}
      <ConfirmDialog />
      <Reason />
    </>
  );
};

const mapDispatch = {
  editTieredService,
  cloneTieredService,
  endTieredService,
  removeTieredService: removeTieredServiceAsync.request,
  setSelectedStudents,
  clearTieredServiceDetail: clearTieredServiceDetail,
};

export default connect(null, mapDispatch)(TieredServiceList);
