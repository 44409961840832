import { CircularProgress } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';
import StickyPageHeader from '../StickyPageHeader';
import './styles.scss';
interface Prop {
  className?: string;
  contentClassName?: string;
  loading?: boolean;
}
/**
 * Use this component as root container for pages
 * @param param0
 * @returns
 */
const PageContentContainer: React.FC<Prop> = ({ children, className, contentClassName, loading }) => {
  const footerRef = React.useRef(null);
  const stickyHeaderRef = React.useRef(null);

  const restOfChildren = React.useMemo(
    () =>
      React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          if (child.type === PageContentFooter) {
            footerRef.current = child;
            return null;
          }

          if (child.type === StickyPageHeader) {
            stickyHeaderRef.current = child;
            return;
          }
        }
        return child;
      })?.filter(child => child) || [],
    [children],
  );

  return (
    <>
      <main className={cn('page-main', !!footerRef.current && 'has-footer', className)}>
        {stickyHeaderRef.current}

        <div className={cn('page-content', contentClassName)}>
          {loading && (
            <div className="page-content-loading">
              <CircularProgress color="primary" className="page-content-loading-indicator" />
            </div>
          )}
          {restOfChildren}
        </div>
      </main>
      {footerRef.current}
    </>
  );
};

/**
 * Use this component to render footer in pages
 * @param param0
 * @returns
 */
export const PageContentFooter: React.FC = ({ children }) => {
  return <footer className="page-footer">{children}</footer>;
};

export default PageContentContainer;
