export const LOCAL_STORAGE_TOKEN = 'token';

const clearToken = () => {
  localStorage.removeItem(LOCAL_STORAGE_TOKEN);
};

const getExchangedToken = () => {
  const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);
  return token;
};

const setToken = (token: string) => {
  localStorage.setItem(LOCAL_STORAGE_TOKEN, token);
};

const getExpTime = (token: string) => {
  if (localStorage.getItem(LOCAL_STORAGE_TOKEN)) {
    const expDate = getJWTExpireDate(localStorage.getItem(LOCAL_STORAGE_TOKEN));
    const now = new Date().getTime();
    const diff = expDate - now;
    const secondsTillExpire = Math.floor(diff / 1000);
    return secondsTillExpire;
  }
  else {
    return null;
  }
};

function getJWTExpireDate(token: string) {
  if (token) {
    try {
      const [, payload] = token.split('.');
      const { exp: expires } = JSON.parse(window.atob(payload));
      if (typeof expires === 'number') {
        return new Date(expires * 1000).getTime();
      }
    } catch {
      // ignore
    }
  }
  return null;
}

const getToken = () => {
  return new Promise((resolve, reject) => {
    const token = getExchangedToken();
    if (token) {
      resolve(token);
    } else {
      reject();
    }
  });
};

export default {
  clearToken,
  getToken,
  setToken,
  getExchangedToken,
  getExpTime,
};

