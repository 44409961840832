import {
  GetEditableStudentsRequest,
  getSLHeaderParams,
  getSupportTypeIdParams,
} from './../redux/sessionLogsRedux/types';
import apisauce from 'apisauce';
import appConfig from 'src/appConfig';
import { PATHS } from 'src/appConfig/paths';
import { GetUserPreferencesParams, SignInPayload, UserPreferencesType } from 'src/redux/authRedux/types';
import { newCancelToken, stringify } from 'src/utils';
import { Navigator, Toastify, TokenService } from '.';
import { SearchSessionRequestType } from 'src/redux/sessionLogsRedux/types';
import { LOCAL_STORAGE_TOKEN } from './token';

const create = (baseURL = appConfig.API_URL) => {
  //
  // Create and configure an apisauce-based api object.
  //

  const api = apisauce.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: 0,
      Accept: 'application/json',
    },
    timeout: appConfig.CONNECTION_TIMEOUT,
  });

  let isRefreshing = false;
  api.axiosInstance.interceptors.request.use(
    config => {
      const secondsUntilExpire = TokenService.getExpTime(localStorage.getItem(LOCAL_STORAGE_TOKEN));
      const token = TokenService.getExchangedToken();

      if (secondsUntilExpire > 3600 || secondsUntilExpire === null) {
        return TokenService.getToken()
          .then(token => {
            config.headers.Authorization = `Bearer ${token}`;
            return Promise.resolve(config);
          })
          .catch(() => {
            return Promise.resolve(config);
          });
      } else if (token) {
        return refreshToken()
          .then(tokenNew => {
            const token2 = tokenNew || token;
            config.headers.Authorization = `Bearer ${token2}`;
            return Promise.resolve(config);
          })
          .catch(() => {
            return Promise.resolve(config);
          });
      }
    },
    error => {
      return Promise.reject('token not valid');
    },
  );

  api.axiosInstance.interceptors.response.use(
    response => response,
    async error => {
      const errorMessage = 'No authorization token was found' || 'jwt expired';
      if (error.response.status === 401 && error.response.data.message === errorMessage) {
        Toastify.error('Your session has expired.\nPlease login again.');
        localStorage.removeItem('userId');
        localStorage.removeItem('userFullName');
        return Navigator.navigate(PATHS.signIn);
      } else {
        return Promise.reject(error);
      }
    },
  );

  const getRoot = () => api.get('');

  // ====================== Auth ======================
  const signIn = (body: SignInPayload) => {
    return api.post(`/auth/login?app=sbbh`, body);
  };

  // const signOut = () => Auth.signOut();

  const exchangeToken = () => api.get('/auth/logged_in_user', {});

  /* const renewToken = () => { 
    return api.get<{ status: string; data: { token: string } }>('/auth/renew', {});
  } */

  const refreshToken = () => {
    return new Promise((resolve, reject) => {
      /* const config = {
        RequestHeaders: {
          Authorization: TokenService.getToken() ? `Bearer ${TokenService.getToken()}` : ''
        }
      }; */

      if (!isRefreshing) {
        isRefreshing = true;
        api
          .get<{ status: string; data: { token: string } }>('/auth/renew')
          .then(res => {
            const tokenNew = res.data.data.token;
            localStorage.setItem(LOCAL_STORAGE_TOKEN, tokenNew);
            isRefreshing = false;
            resolve(tokenNew);
          })
          .catch(err => {
            isRefreshing = false;
            reject(err);
          });
      } else {
        resolve('');
      }
    });
  };

  const getUserPreference = (params: GetUserPreferencesParams) => {
    const { userId } = params;
    return api.get(`/users/${userId}/preferences`);
  };

  const updateUserPreferences = (params: UserPreferencesType) => {
    const { userId, preferenceKey } = params;
    return api.put(`/users/${userId}/preferences/${preferenceKey}`, params);
  };

  // ====================== Schedulers =================
  const getSchedulerServiceListByProvider = (params: any) => {
    const { providerId, schoolId, unassignedOnly } = params;
    return api.get(
      `/sbbh_scheduler/students_services_by_provider?provideruserid=${providerId}&schoolid=${schoolId}&unassignedonly=${unassignedOnly}`,
      {},
      newCancelToken(),
    );
  };
  const getStudentServicesByStudent = (params: any) => {
    const { studentId } = params;
    return api.get(`/sbbh_scheduler/students_services_by_student?studentid=${studentId}`, {}, newCancelToken());
  };
  const getSchedulerProvidersFilter = (search: string) =>
    api.get(`/sbbh_scheduler/providers?search=${search}`, {}, newCancelToken());

  const getSchedulerSchoolsFilter = (search: string) =>
    api.get(`/sbbh_scheduler/schools?search=${search}`, {}, newCancelToken());

  const getProviderListForAddToServiceFilter = ({ serviceId, search }) => {
    return api.get(
      `/sbbh_scheduler/students_services_by_student/${serviceId}/providers?search=${search}`,
      {},
      newCancelToken(),
    );
  };
  const addProviderToService = (body: any) => {
    return api.post(`/sbbh_scheduler/student_services/providers`, body);
  };

  const addProviderToStudentsService = (body: any) => {
    const { serviceId, providerUserId } = body;
    return api.post(`/api/sbbh_scheduler/student_services/providers`, { serviceId, providerUserId });
  };

  const removeProviderFromService = (params: any) => {
    return api.delete(`/sbbh_scheduler/student_services/providers/${params}`);
  };

  const getSchedulerStudentsFilter = (search: string) =>
    api.get(`/sbbh_scheduler/students?search=${search}`, {}, newCancelToken());

  // Tiered Services
  const getTieredServices = filters =>
    api.get(`/sbbh_tiered/search_sbbh_tiered_plans?${stringify(filters)}`, {}, newCancelToken());

  const searchTSProviders = (search: string) =>
    api.get(`/sbbh_tiered/tiered_plan_providers?search=${search}`, {}, newCancelToken());

  const searchTSProvidersProxiesAddTS = (search: string) =>
    api.get(`/sbbh_tiered/tiered_plan_provider_proxies?providername=${search}`, {}, newCancelToken());

  const searchTSPotentialProviders = ({ search, studentId }) =>
    api.get(
      `/sbbh_tiered/tiered_plan_potential_providers?searchtext=${search}&studentid=${studentId}`,
      {},
      newCancelToken(),
    );

  const searchTSStudents = (search: string) =>
    api.get(`/sbbh_tiered/tiered_plan_viewable_students?search=${search}`, {}, newCancelToken());
  const searchTSSchools = (search: string) =>
    api.get(`/sbbh_tiered/tiered_plan_schools?search=${search}`, {}, newCancelToken());

  const getGradesTS = () => {
    return api.get(`sbbh_tiered/grades`, {}, newCancelToken());
  };

  const searchEditableStudents = filters =>
    api.get(`/sbbh_tiered/tiered_plan_editable_students?${stringify(filters)}`, {}, newCancelToken());

  const removeTieredPlan = ({ planId }) =>
    api.get(`/sbbh_tiered/remove_tiered_plan?planid=${planId}`, {}, newCancelToken());

  const getTieredServiceById = (id: number) => api.get(`/ts_service_logs/${id}`, {}, newCancelToken());

  // const getTieredServiceSessionLogs = (planId: number) =>
  //   api.get(`/sbbh_session_logs/plan-id/${planId}`, {}, newCancelToken());

  const createTieredServiceLog = payload => api.post(`/ts_service_logs`, payload, newCancelToken());
  const updateTieredServiceLog = (planId: number, payload) =>
    api.put(`/ts_service_logs/${planId}`, payload, newCancelToken());
  const updateTieredServiceAddAuditLog = (planId: number, payload) =>
    api.put(`ts_service_logs/tiered_plan_add_audit/${planId}`, payload, newCancelToken());
  const getTieredServiceTargets = () => api.get('ts_service_logs/target', {}, newCancelToken());
  const getTieredServicePractices = () => api.get('ts_service_logs/practice', {}, newCancelToken());

  // Session Logs

  const sessionLogUrl = '/sbbh_session_logs';

  // SL - recent
  const getRecentSessionLogs = () => {
    return api.get(`${sessionLogUrl}/dashboard?days=5`, {}, newCancelToken());
  };
  // END - SL - recent

  // SL - add
  const searchSLActiveProviders = (search: string) =>
    api.get(`${sessionLogUrl}/provider-proxies?search=${search}`, {}, newCancelToken());

  const getEditableStudentsApi = (filters: GetEditableStudentsRequest) => {
    const url = `${sessionLogUrl}/editable-students?${stringify(filters)}`;
    return api.get(url, {}, newCancelToken());
  };

  const getTargetOptionsSL = () => {
    return api.get(`${sessionLogUrl}/target`, {}, newCancelToken());
  };

  const getConcernTreatmentOptionsSL = () => {
    return api.get(`${sessionLogUrl}/concern-treatment`, {}, newCancelToken());
  };
  const getFocusAreaAsPlanOptionsSL = () => {
    return api.get(`${sessionLogUrl}/app_code?codeSeriesKey=SBBH_DIRECT_NON_DIRECT`, {}, newCancelToken());
  };

  const getBehaviorCategoryOptionsSL = () => {
    return api.get(`${sessionLogUrl}/bimas`, {}, newCancelToken());
  };

  const getBehaviorMeasureOptionsSL = () => {
    return api.get(`${sessionLogUrl}/behavior-measures`, {}, newCancelToken());
  };

  const getPracticeOptionsSL = () => {
    return api.get(`${sessionLogUrl}/practice`, {}, newCancelToken());
  };

  const getOutcomeOptionsSL = () => {
    return api.get(`${sessionLogUrl}/outcome`, {}, newCancelToken());
  };

  const getContactOptionsSL = () => {
    return api.get(`${sessionLogUrl}/contact_types`, {}, newCancelToken());
  };

  const getSessionOptionsSL = () => {
    return api.get(`${sessionLogUrl}/session_types`, {}, newCancelToken());
  };

  const getDeliveryOptionsSL = () => {
    return api.get(`${sessionLogUrl}/delivery_modes`, {}, newCancelToken());
  };

  const getDirectNonDirectOptionsSL = () => {
    return api.get(`${sessionLogUrl}/app_code?codeSeriesKey=SBBH_DIRECT_NON_DIRECT`, {}, newCancelToken());
  };

  const getReferredByOptionsSL = () => {
    return api.get(`${sessionLogUrl}/referred_by`, {}, newCancelToken());
  };

  const getStudentAbsentReasonOptionsSL = () => {
    return api.get(`${sessionLogUrl}/absent-reasons`, {}, newCancelToken());
  };

  const getSupportTypeIdSL = (params: getSupportTypeIdParams) => {
    const { supportId, supportType } = params;
    const url = `${sessionLogUrl}/support-type-id?supportId=${supportId}&supportType=${supportType}`;
    return api.get(url, {}, newCancelToken());
  };

  const getProviderHUser = params => {
    const { providerUserId } = params;
    let url = `${sessionLogUrl}/provider-h-user-id?providerUserId=${providerUserId}`;
    return api.get(url, {}, newCancelToken());
  };
  const createSessionLog = payload => api.post(`${sessionLogUrl}`, payload, newCancelToken());

  const updateSessionLog = payload => {
    return api.put(`${sessionLogUrl}/${payload.payload.sessionId}`, payload.payload.payload, newCancelToken());
  };

  const removeSessionLogById = (params: any) => {
    const { sessionId } = params;
    const url = `${sessionLogUrl}/${sessionId}`;
    return api.delete(url, {}, newCancelToken());
  };

  const getSLHeader = (params: getSLHeaderParams) => {
    const url = `${sessionLogUrl}/session_log_header?${stringify(params)}`;
    return api.get(url, {}, newCancelToken());
  };

  // END SL - add

  // SL - Search
  const searchSLProviders = (search: string) =>
    api.get(`${sessionLogUrl}/provider?search=${search}`, {}, newCancelToken());

  const searchSLStudents = (search: string) =>
    api.get(`${sessionLogUrl}/viewable-students?search=${search}`, {}, newCancelToken());

  const searchSLSchools = (search: string) =>
    api.get(`${sessionLogUrl}/schools?search=${search}`, {}, newCancelToken());

  const searchServiceLocations = (search: string) => {
    return api.get(`/service_locations?search=${search}`, {}, newCancelToken());
  };

  const searchLogs = (filters: SearchSessionRequestType) => {
    let url = `${sessionLogUrl}/search?${stringify(filters)}`;

    return api.get(`${url}`, {}, newCancelToken());
  };

  const getLogById = (id: number) => {
    const url = `${sessionLogUrl}/${id}`;
    return api.get(url, {}, newCancelToken());
  };

  const getLogByIdClone = (id: number) => {
    const url = `${sessionLogUrl}/clone/${id}`;
    return api.get(url, {}, newCancelToken());
  };
  // END SL - Search

  // END Session Logs

  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  //
  // Notice we're not returning back the `api` created in step 1. That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    getRoot,
    // ====================== Auth ======================
    signIn,
    // signOut,
    exchangeToken,
    getUserPreference,
    updateUserPreferences,

    // Schedulers
    getSchedulerServiceListByProvider,
    getStudentServicesByStudent,
    getSchedulerProvidersFilter,
    getSchedulerSchoolsFilter,
    addProviderToService,
    removeProviderFromService,
    getSchedulerStudentsFilter,
    getProviderListForAddToServiceFilter,
    addProviderToStudentsService,

    // Tiered services
    getTieredServices,
    searchTSProviders,
    searchTSPotentialProviders,
    getTieredServiceById,
    // getTieredServiceSessionLogs,
    createTieredServiceLog,
    updateTieredServiceLog,
    updateTieredServiceAddAuditLog,
    searchTSStudents,
    searchTSSchools,
    searchEditableStudents,
    removeTieredPlan,
    getTieredServiceTargets,
    getTieredServicePractices,
    searchTSProvidersProxiesAddTS,
    getGradesTS,

    // Session Logs
    getRecentSessionLogs,
    searchSLProviders,
    searchSLStudents,
    searchSLSchools,
    searchSLActiveProviders,
    searchLogs,
    getLogById,
    getLogByIdClone,
    getTargetOptionsSL,
    getConcernTreatmentOptionsSL,
    getFocusAreaAsPlanOptionsSL,
    getBehaviorCategoryOptionsSL,
    getBehaviorMeasureOptionsSL,
    getEditableStudentsApi,
    getPracticeOptionsSL,
    getOutcomeOptionsSL,
    getContactOptionsSL,
    getSessionOptionsSL,
    getDeliveryOptionsSL,
    getDirectNonDirectOptionsSL,
    getReferredByOptionsSL,
    getProviderHUser,
    createSessionLog,
    removeSessionLogById,
    updateSessionLog,
    getStudentAbsentReasonOptionsSL,
    getSupportTypeIdSL,
    searchServiceLocations,
    getSLHeader,
  };
};

export type Apis = ReturnType<typeof create>;

export default {
  create,
};
