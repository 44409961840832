import React from 'react';
import './styles.scss';

import { connect } from 'react-redux';
import { IRootState } from 'src/redux/rootReducer';

import PageContentContainer, { PageContentFooter } from 'src/components/PageContentContainer';
import StickyPageHeader from 'src/components/StickyPageHeader';
import { useConfirmDialog } from 'src/components/common/ConfirmDialog';
import AddSessionLogTemplateFormik, {
  ConnectedAddSessionLogTemplateForm,
} from '../../components/SessionLogFormTemplate';
import AddSessionDialog from '../../AddProviderAndStudentsDialog';

import { Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { FormikProps } from 'formik';
import { FaUsers } from 'react-icons/fa';
import { DefaultRoute, Navigator, Toastify } from 'src/services';
import { PATHS } from 'src/appConfig/paths';

import { AddSessionTemplateType, EditableStudentsType } from 'src/redux/sessionLogsRedux/types';
import {
  cancelAddingSession,
  getTargetOptionsSLAsync,
  getBehaviorCategoryOptionsSLAsync,
  getBehaviorMeasureOptionsSLAsync,
  searchLocationAddSLAsync,
  setAddSessionTemplate,
  setAddSessionTemplateFirstInfo,
  setSelectedProviderAddSession,
  setSelectedStudentsAddSession,
  setSessionDateAddSession,
} from 'src/redux/sessionLogsRedux/actions';
import { selectUserPermissions } from 'src/redux/authRedux/selectors';

import { ProviderName } from 'src/redux/schedulerRedux/types';
import { muiResponsive } from 'src/appConfig/muiTheme';

const clsPrefix = 'add-session-logs-template';

const AddSessionLogTemplate: React.FC<Props> = ({
  permissions,
  provider,
  students,
  sessionDate,
  cancelAddingSession,
  setSelectedProviderAddSession,
  setSelectedStudentAddSession,
  setSessionDateAddSession,
  setAddSessionTemplate,
  setAddSessionTemplateFirstInfo,
  searchSLSchool,
  clearSearchSchool,
  getTargetOptions,
}) => {
  const isMobile = useMediaQuery(muiResponsive.MOBILE);

  const [ConfirmDialog, confirm] = useConfirmDialog(
    'You have unsaved information. Are you sure you want to leave this page?',
  );
  const [showAddSessionDialog, setShowAddSessionDialog] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!permissions.isSL) {
      Toastify.error('You do NOT have permission to access SESSION LOGS function.');
      Navigator.navigate(DefaultRoute.getLocalDefaultRoute());
    }
  }, []);

  React.useEffect(() => {
    if (!provider || !students) {
      Toastify.error('You need to select Provider/Students to Add Log!');
      Navigator.navigate(PATHS.sessionLogs);
    }
  }, [provider, students]);

  const closeAddStudentDialog = () => setShowAddSessionDialog(false);

  const handleOnCreateSessionLog = (provider: ProviderName, students: EditableStudentsType[], sessionDate) => {
    setSelectedProviderAddSession(provider);
    setSessionDateAddSession(sessionDate);
    setAddSessionTemplateFirstInfo({
      provider: provider,
      credentialName: provider?.credentialName,
      sessionDate: sessionDate,
    });
    closeAddStudentDialog();
  };

  const handleCancel = () => {
    confirm(() => {
      cancelAddingSession();
      Navigator.navigate(PATHS.sessionLogs);
    });
  };

  const handleSaveTemplate = (formProps: AddSessionTemplateType) => {
    if (!formProps.provider.credentialName) {
      Toastify.warning('This provider has no associated credentials.');
    }
    clearSearchSchool();
    setAddSessionTemplate(formProps);
    Navigator.navigate(PATHS.addSessionLog);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <AddSessionLogTemplateFormik initialValues={{}} onSubmit={handleSaveTemplate}>
        {(formProps: FormikProps<AddSessionTemplateType>) => {
          // console.log('formProps values: ', formProps.values);
          // console.log('formProps errors: ', formProps.errors);
          return (
            <PageContentContainer className={`${clsPrefix}-page-container`}>
              <StickyPageHeader>
                <div className={`${clsPrefix}-header-container`}>
                  <h3>Session Log Template</h3>
                  <span className={`${clsPrefix}-header-status-text mr-8`}>
                    # Students Selected: <b>{students.length !== 0 ? students.length : 'unknown'}</b>
                    {isMobile && <br />}
                    <>
                      {' | '}First Student:{' '}
                      <b>
                        {students.length !== 0 ? students[0].firstName + ' ' + students[0].lastName : 'unknown'} &nbsp;
                      </b>
                    </>
                  </span>
                  {isMobile && <br />}
                  <Button
                    variant="text"
                    startIcon={<FaUsers />}
                    color="secondary"
                    onClick={() => setShowAddSessionDialog(true)}>
                    ADD/REMOVE STUDENTS
                  </Button>
                </div>
              </StickyPageHeader>
              <ConnectedAddSessionLogTemplateForm {...formProps} />
              <PageContentFooter>
                <Button variant="text" color="secondary" onClick={handleCancel}>
                  CANCEL
                </Button>

                <Button
                  variant="outlined"
                  color="secondary"
                  type="submit"
                  onClick={formProps.handleSubmit as any}
                  // disabled={!(formProps.isValid && formProps.dirty)}
                >
                  {`SAVE TEMPLATE`}
                  {` & NEXT`}
                </Button>
              </PageContentFooter>
            </PageContentContainer>
          );
        }}
      </AddSessionLogTemplateFormik>

      {showAddSessionDialog && (
        <AddSessionDialog
          skipSelectProvider={!!provider}
          onCreate={() => handleOnCreateSessionLog(provider, students, sessionDate)}
          onClose={closeAddStudentDialog}
        />
      )}
      <ConfirmDialog />
    </>
  );
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const mapStateToProps = (state: IRootState) => {
  const { provider, students, sessionDate } = state.sessionLogs.addSession;
  return {
    permissions: selectUserPermissions(state),
    provider,
    students,
    sessionDate,
  };
};

const mapDispatchToProps = {
  cancelAddingSession: cancelAddingSession,
  setSelectedProviderAddSession: setSelectedProviderAddSession,
  setSelectedStudentAddSession: setSelectedStudentsAddSession,
  setSessionDateAddSession: setSessionDateAddSession,
  setAddSessionTemplateFirstInfo: setAddSessionTemplateFirstInfo,
  setAddSessionTemplate: setAddSessionTemplate,
  searchSLSchool: searchLocationAddSLAsync.request,
  clearSearchSchool: searchLocationAddSLAsync.cancel,
  getTargetOptions: getTargetOptionsSLAsync.request,
  getBehaviorCategoryOptions: getBehaviorCategoryOptionsSLAsync.request,
  getBehaviorMeasureOptions: getBehaviorMeasureOptionsSLAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSessionLogTemplate);
