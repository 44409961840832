import { ReactNode } from 'react';
import { MdHome, MdViewCompact, MdViewModule } from 'react-icons/md';
import { PATHS } from './paths';

export const isActive = (href: string, exact?: boolean) => {
  if (exact) {
    return window.location.pathname.endsWith(href);
  }
  return window.location.pathname.includes(href);
};

type MenuType = {
  title: string;
  icon: ReactNode;
  subMenu?: { title: string; href: string }[];
  href?: string;
};

export const SidebarMenu: MenuType[] = [
  {
    title: 'SESSION LOGS',
    icon: <MdHome />,
    href: PATHS.sessionLogs,
  },
  {
    title: 'TIERED SERVICES',
    icon: <MdViewModule />,
    href: PATHS.tieredService,
  },
  {
    title: 'SCHEDULER',
    icon: <MdViewCompact />,
    href: PATHS.scheduler,
  },
];
