export const byProvider = {
  status: 'success',
  data: [
    {
      firstName: 'Robert',
      lastName: 'Farani',
      grade: '01',
      gender: 'M',
      schoolId: 123,
      schoolCode: '274',
      schoolName: 'Waipahu Elem',
      studentExtId: '2741900752',
      studentId: 622177,
      services: [
        {
          serviceId: 7541143,
          providerId: null,
          studentId: 622177,
          lastName: 'Farani',
          firstName: 'Robert',
          studentExtId: '2741900752',
          grade: '03',
          gender: 'M',
          schoolId: 123,
          schoolName: 'Waipahu Elem',
          schoolCode: '274',
          planTypeName: '504 Plan',
          serviceType: 'Occupational Therapy Services',
          startDate: '2020-11-24T00:00:00',
          endDate: '2021-11-24T00:00:00',
          frequency: '1 times per Month',
          esy: null,
          active: true,
          providerUserId: 201788,
          isProvider: true,
          addType: 'PROVIDER',
          removeType: '',
        },
        {
          serviceId: 7157074,
          providerId: null,
          studentId: 622177,
          lastName: 'Farani',
          firstName: 'Robert',
          studentExtId: '2741900752',
          grade: '03',
          gender: 'M',
          schoolId: 123,
          schoolName: 'Waipahu Elem',
          schoolCode: '274',
          planTypeName: '504 Plan',
          serviceType: 'Occupational Therapy Services',
          startDate: '2019-12-03T00:00:00',
          endDate: '2020-12-03T00:00:00',
          frequency: '1 times per Month',
          esy: null,
          active: false,
          providerUserId: 201788,
          isProvider: true,
          addType: 'PROVIDER',
          removeType: '',
        },
      ],
    },
    {
      firstName: 'Robert',
      lastName: 'Farani',
      grade: '02',
      gender: 'M',
      schoolId: 123,
      schoolCode: '274',
      schoolName: 'Waipahu Elem',
      studentExtId: '2741900752',
      studentId: 622171,
      services: [
        {
          serviceId: 7541143,
          providerId: null,
          studentId: 622177,
          lastName: 'Farani',
          firstName: 'Robert',
          studentExtId: '2741900752',
          grade: '03',
          gender: 'M',
          schoolId: 123,
          schoolName: 'Waipahu Elem',
          schoolCode: '274',
          planTypeName: '504 Plan',
          serviceType: 'Occupational Therapy Services',
          startDate: '2020-11-24T00:00:00',
          endDate: '2021-11-24T00:00:00',
          frequency: '1 times per Month',
          esy: null,
          active: true,
          providerUserId: 201788,
          isProvider: true,
          addType: 'PROVIDER',
          removeType: '',
        },
        {
          serviceId: 7157074,
          providerId: null,
          studentId: 622177,
          lastName: 'Farani',
          firstName: 'Robert',
          studentExtId: '2741900752',
          grade: '03',
          gender: 'M',
          schoolId: 123,
          schoolName: 'Waipahu Elem',
          schoolCode: '274',
          planTypeName: '504 Plan',
          serviceType: 'Occupational Therapy Services',
          startDate: '2019-12-03T00:00:00',
          endDate: '2020-12-03T00:00:00',
          frequency: '1 times per Month',
          esy: null,
          active: false,
          providerUserId: 201788,
          isProvider: true,
          addType: 'PROVIDER',
          removeType: '',
        },
      ],
    },
    {
      firstName: 'Robert',
      lastName: 'Farani',
      grade: '03',
      gender: 'M',
      schoolId: 123,
      schoolCode: '274',
      schoolName: 'Waipahu Elem',
      studentExtId: '2741900752',
      studentId: 622178,
      services: [
        {
          serviceId: 7541143,
          providerId: null,
          studentId: 622171,
          lastName: 'Farani',
          firstName: 'Robert',
          studentExtId: '2741900752',
          grade: '03',
          gender: 'M',
          schoolId: 123,
          schoolName: 'Waipahu Elem',
          schoolCode: '274',
          planTypeName: '504 Plan',
          serviceType: 'Occupational Therapy Services',
          startDate: '2020-11-24T00:00:00',
          endDate: '2021-11-24T00:00:00',
          frequency: '1 times per Month',
          esy: null,
          active: true,
          providerUserId: 201788,
          isProvider: true,
          addType: 'PROVIDER',
          removeType: '',
        },
        {
          serviceId: 7157074,
          providerId: null,
          studentId: 622172,
          lastName: 'Farani',
          firstName: 'Robert',
          studentExtId: '2741900752',
          grade: '03',
          gender: 'M',
          schoolId: 123,
          schoolName: 'Waipahu Elem',
          schoolCode: '274',
          planTypeName: '504 Plan',
          serviceType: 'Occupational Therapy Services',
          startDate: '2019-12-03T00:00:00',
          endDate: '2020-12-03T00:00:00',
          frequency: '1 times per Month',
          esy: null,
          active: false,
          providerUserId: 201788,
          isProvider: true,
          addType: 'PROVIDER',
          removeType: '',
        },
      ],
    },
    {
      firstName: 'Robert',
      lastName: 'Farani',
      grade: '04',
      gender: 'M',
      schoolId: 123,
      schoolCode: '274',
      schoolName: 'Waipahu Elem',
      studentExtId: '2741900752',
      studentId: 622189,
      services: [
        {
          serviceId: 7541143,
          providerId: null,
          studentId: 622171,
          lastName: 'Farani',
          firstName: 'Robert',
          studentExtId: '2741900752',
          grade: '03',
          gender: 'M',
          schoolId: 123,
          schoolName: 'Waipahu Elem',
          schoolCode: '274',
          planTypeName: '504 Plan',
          serviceType: 'Occupational Therapy Services',
          startDate: '2020-11-24T00:00:00',
          endDate: '2021-11-24T00:00:00',
          frequency: '1 times per Month',
          esy: null,
          active: true,
          providerUserId: 201788,
          isProvider: true,
          addType: 'PROVIDER',
          removeType: '',
        },
        {
          serviceId: 7157074,
          providerId: null,
          studentId: 622172,
          lastName: 'Farani',
          firstName: 'Robert',
          studentExtId: '2741900752',
          grade: '03',
          gender: 'M',
          schoolId: 123,
          schoolName: 'Waipahu Elem',
          schoolCode: '274',
          planTypeName: '504 Plan',
          serviceType: 'Occupational Therapy Services',
          startDate: '2019-12-03T00:00:00',
          endDate: '2020-12-03T00:00:00',
          frequency: '1 times per Month',
          esy: null,
          active: false,
          providerUserId: 201788,
          isProvider: true,
          addType: 'PROVIDER',
          removeType: '',
        },
      ],
    },
    {
      firstName: 'Robert',
      lastName: 'Farani',
      grade: '05',
      gender: 'M',
      schoolId: 123,
      schoolCode: '274',
      schoolName: 'Waipahu Elem',
      studentExtId: '2741900752',
      studentId: 6221456,
      services: [
        {
          serviceId: 7541143,
          providerId: null,
          studentId: 622171,
          lastName: 'Farani',
          firstName: 'Robert',
          studentExtId: '2741900752',
          grade: '03',
          gender: 'M',
          schoolId: 123,
          schoolName: 'Waipahu Elem',
          schoolCode: '274',
          planTypeName: '504 Plan',
          serviceType: 'Occupational Therapy Services',
          startDate: '2020-11-24T00:00:00',
          endDate: '2021-11-24T00:00:00',
          frequency: '1 times per Month',
          esy: null,
          active: true,
          providerUserId: 201788,
          isProvider: true,
          addType: 'PROVIDER',
          removeType: '',
        },
        {
          serviceId: 7157074,
          providerId: null,
          studentId: 622172,
          lastName: 'Farani',
          firstName: 'Robert',
          studentExtId: '2741900752',
          grade: '03',
          gender: 'M',
          schoolId: 123,
          schoolName: 'Waipahu Elem',
          schoolCode: '274',
          planTypeName: '504 Plan',
          serviceType: 'Occupational Therapy Services',
          startDate: '2019-12-03T00:00:00',
          endDate: '2020-12-03T00:00:00',
          frequency: '1 times per Month',
          esy: null,
          active: false,
          providerUserId: 201788,
          isProvider: true,
          addType: 'PROVIDER',
          removeType: '',
        },
      ],
    },
  ],
};

export const byStudent = {
  status: 'success',
  data: [
    {
      serviceId: 7541143,
      providerId: 1593225,
      studentId: 622177,
      lastName: 'Farani',
      firstName: 'Robert',
      studentExtId: '2741900752',
      grade: '03',
      gender: 'M',
      schoolId: 123,
      schoolName: 'Waipahu Elem',
      schoolCode: '274',
      planTypeName: '504 Plan',
      serviceType: 'Occupational Therapy Services',
      startDate: '2020-11-24T00:00:00',
      endDate: '2021-11-24T00:00:00',
      frequency: '1 times per Month',
      esy: null,
      active: true,
      providerUserId: 201801,
      providers: [
        {
          providerId: 1593225,
          providerUserId: 201801,
          position: 'Account Clerk',
          credentialType: 'OT',
          addType: '',
          removeType: '',
          fullName: 'RA TestB',
          credentialName: 'Licensed Occupational Therapist',
        },
        {
          providerId: 1593226,
          providerUserId: 201802,
          position: 'Account Clerk',
          credentialType: 'OT',
          addType: '',
          removeType: 'PROVIDER',
          fullName: 'RA TestG',
          credentialName: 'Licensed Occupational Therapist',
        },
      ],
    },
    {
      serviceId: 7157074,
      providerId: null,
      studentId: 622177,
      lastName: 'Farani',
      firstName: 'Robert',
      studentExtId: '2741900752',
      grade: '03',
      gender: 'M',
      schoolId: 123,
      schoolName: 'Waipahu Elem',
      schoolCode: '274',
      planTypeName: '504 Plan',
      serviceType: 'Occupational Therapy Services',
      startDate: '2019-12-03T00:00:00',
      endDate: '2020-12-03T00:00:00',
      frequency: '1 times per Month',
      esy: null,
      active: false,
      providerUserId: null,
      providers: [],
    },
  ],
};
