/**
 * SPECIFY `REACT_APP_MOCK_API` IN `.env` FILE TO ENABLE MOCKS
 */

import { ApiOkResponse } from 'apisauce';
import { Apis } from '../services/api';
import * as schedulers from './responses/getStudentScheduler';

function response<T = unknown>(code: number, data: T, delay = 500) {
  return new Promise<ApiOkResponse<any>>(resolve =>
    setTimeout(
      () =>
        resolve({
          ok: true,
          problem: null,
          originalError: null,
          data: data,
          status: code,
        }),
      delay,
    ),
  );
}

export default {
  getStudentSchedulers: () => response(200, schedulers.byProvider),
  getStudentServicesByStudent: () => response(200, schedulers.byStudent),
} as Partial<Apis>;
