import { Button } from '@material-ui/core';
import Dialog from 'src/components/common/Dialog';
import SearchDropdownBox from 'src/components/SearchDropdownBox';
import { getProviderWithPositionLabel } from 'src/containers/SchedulerContainers/helper';
import { ProviderName } from 'src/redux/schedulerRedux/types';

type Props = {
  open: boolean;
  onClose: () => void;
  onNext: () => void;
  onChange: (v: ProviderName) => void;
  value: ProviderName;
  providers: ProviderName[];
  loading?: boolean;
  disabled?: boolean;
  onSearch: (name: string) => void;
  onClear: () => void;
};

const SelectProviderDialog = ({
  open,
  onClose,
  onNext,
  providers = [],
  value,
  onChange: onSelectProvider,
  loading,
  disabled,
  onSearch,
  onClear,
}: Props) => {
  return (
    <Dialog
      open={open}
      title="Select Provider"
      maxWidth="xs"
      fullWidth
      onClose={onClose}
      dialogActions={
        <>
          <Button color="secondary" variant="contained" onClick={onNext} disabled={!value}>
            Next
          </Button>
        </>
      }>
      <SearchDropdownBox<ProviderName, ProviderName>
        loading={loading}
        options={providers}
        getOptionLabel={getProviderWithPositionLabel}
        placeholder="Provider"
        value={value}
        onChange={onSelectProvider}
        disabled={disabled}
        hideDropdownIcon={disabled}
        onSearch={onSearch}
        onClear={onClear}
      />
    </Dialog>
  );
};

export default SelectProviderDialog;
