import { createAction, createAsyncAction } from 'typesafe-actions';
import { AuthPayload, SignInPayload, Token, UserPreferencesType } from './types';

export const signInAsync = createAsyncAction('auth/SIGN_IN_REQUEST', 'auth/SIGN_IN_SUCCESS', 'auth/SIGN_IN_FAILURE')<
  SignInPayload,
  AuthPayload,
  Error
>();

export const signOutAsync = createAsyncAction(
  'auth/SIGN_OUT_REQUEST',
  'auth/SIGN_OUT_SUCCESS',
  'auth/SIGN_OUT_FAILURE',
)<void, void, AuthError>();

export const exchangeTokenAsync = createAsyncAction(
  'auth/EXCHANGE_TOKEN_REQUEST',
  'auth/EXCHANGE_TOKEN_SUCCESS',
  'auth/EXCHANGE_TOKEN_FAILURE',
)<{ token: string }, AuthPayload, Error>();

export const setToken = createAction('auth/SET_TOKEN')<Token>();

export const GetUserPreferencesAsync = createAsyncAction(
  'auth/GET_USER_PREFERENCES_REQUEST',
  'auth/GET_USER_PREFERENCES_SUCCESS',
  'auth/GET_USER_PREFERENCES_FAILURE',
)<{ userId: number }, UserPreferencesType, Error>();

export const UpdateUserPreferencesDefaultMenuAsync = createAsyncAction(
  'auth/UPDATE_USER_DEFAULT_MENU_REQUEST',
  'auth/UPDATE_USER_DEFAULT_MENU_SUCCESS',
  'auth/UPDATE_USER_DEFAULT_MENU_FAILURE',
)<UserPreferencesType, UserPreferencesType, Error>();

export const UpdateUserPreferencesTooltipAsync = createAsyncAction(
  'auth/UPDATE_USER_TOOLTIP_REQUEST',
  'auth/UPDATE_USER_TOOLTIP_SUCCESS',
  'auth/UPDATE_USER_TOOLTIP_FAILURE',
)<UserPreferencesType, UserPreferencesType, Error>();
