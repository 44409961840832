import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid, useMediaQuery } from '@material-ui/core';
import PageContentContainer from 'src/components/PageContentContainer';
import { muiResponsive } from 'src/appConfig/muiTheme';

export default function SkeletonForm() {
  const isMobile = useMediaQuery(muiResponsive.MOBILE);

  return (
    <PageContentContainer loading={true}>
      <Skeleton variant="rect" height={56} animation="wave" style={{ paddingTop: '1%' }} />
      <div style={{ margin: isMobile ? '32px 16px' : '32px' }}>
        <Skeleton
          variant="rect"
          height={87}
          animation="wave"
          style={{ backgroundColor: '#673ab7', marginBottom: 42 }}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Skeleton variant="rect" height={56} animation="wave" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Skeleton variant="rect" height={56} animation="wave" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Skeleton variant="rect" height={56} animation="wave" />
          </Grid>

          <Grid item xs={12} sm={6}>
            &nbsp;
          </Grid>

          <Grid item xs={12} sm={6}>
            <Skeleton variant="rect" height={56} animation="wave" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Skeleton variant="rect" height={56} animation="wave" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Skeleton variant="rect" height={56} animation="wave" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Skeleton variant="rect" height={56} animation="wave" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Skeleton variant="rect" height={56} animation="wave" />
          </Grid>

          <Grid item xs={12} sm={6}>
            &nbsp;
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={2}
            style={{ border: '4px solid rgb(224,224,224)', padding: 8, marginTop: 20, width: '100%' }}>
            <Grid item xs={12} sm={6}>
              <Skeleton variant="rect" height={56} animation="wave" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Skeleton variant="rect" height={56} animation="wave" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Skeleton variant="rect" height={56} animation="wave" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Skeleton variant="rect" height={56} animation="wave" />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </PageContentContainer>
  );
}
