import { Grid, IconButton, MenuItem, Popover, Tooltip } from '@material-ui/core';
import React from 'react';
import { FaCheckCircle, FaCopy, FaPen, FaTrash } from 'react-icons/fa';
import { IoIosMore } from 'react-icons/io';
import { TieredService } from 'src/redux/tieredServices/types';
import { getDateDisplay } from 'src/utils/momentUtils';
import { isEmpty } from 'src/validations';
import './styles.scss';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { muiResponsive } from 'src/appConfig/muiTheme';

const clsPrefix = 'tiered-service-item';
const toolTipPreferences = localStorage.getItem('toolTipPreferences') === 'true' ? true : false;

type ServiceItemCallback<T = TieredService> = (service: T) => void;
export type TieredServiceItemProps<T> = {
  service: T;
  onClick?: ServiceItemCallback<T>;
  onEdit?: ServiceItemCallback<T>;
  onCopy?: ServiceItemCallback<T>;
  onEnd?: ServiceItemCallback<T>;
  onDelete?: ServiceItemCallback<T>;
  asMenu?: boolean;
};

const noop = e => {};

export const ServiceItemActions = ({
  onEdit = noop,
  onCopy = noop,
  onEnd = noop,
  onDelete = noop,
  showDelete = true,
  renderAsMenu = false,
  showMarkAsDone = true,
}) => {
  const buttons = {
    Edit: {
      onClick: onEdit,
      Icon: <FaPen />,
    },
    Clone: {
      onClick: onCopy,
      Icon: <FaCopy />,
    },
    'Mark as Done': showMarkAsDone
      ? {
          onClick: onEnd,
          Icon: <FaCheckCircle />,
        }
      : null,
    Delete: showDelete
      ? {
          onClick: onDelete,
          Icon: <FaTrash color="#E31B0C" />,
        }
      : null,
  };

  if (!renderAsMenu) {
    return (
      <>
        {Object.entries(buttons).map(
          ([label, b]) =>
            b && (
              <Tooltip title={label} key={label}>
                <IconButton onClick={b.onClick}>{b.Icon}</IconButton>
              </Tooltip>
            ),
        )}
      </>
    );
  }

  return (
    <>
      {Object.entries(buttons).map(
        ([label, b]) =>
          b && (
            <MenuItem key={label} onClick={b.onClick} className={`${clsPrefix}-actions-menu-item`}>
              <IconButton key={label}>{b.Icon}</IconButton> {label}
            </MenuItem>
          ),
      )}
    </>
  );
};

export const ServiceItemActionPopover = ({ container, onClose, children }) => (
  <Popover
    open={!!container}
    anchorEl={container}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}>
    {children}
  </Popover>
);

const TieredServiceItem: React.VFC<TieredServiceItemProps<TieredService>> = ({
  service,
  onClick = noop,
  onEdit = noop,
  onCopy = noop,
  onEnd = noop,
  onDelete = noop,
}) => {
  const isMobile = useMediaQuery(muiResponsive.MOBILE);
  const [actionPopoverAnchor, setActionPopoverAnchor] = React.useState<HTMLButtonElement>(null);
  const {
    studentFirstName,
    studentLastName,
    studentExtId,
    schoolName,
    grade,
    providerFullName,
    tier,
    startDate,
    displayedEndDate,
    projectedEndDate,
    hasSessions,
    canEdit,
    activeFlag,
  } = service;

  const handleOnClick = callback => e => {
    e.stopPropagation();
    callback(service);
  };

  const onClose = () => {
    setActionPopoverAnchor(null);
  };

  const actions = (renderAsMenu: boolean) => (
    <ServiceItemActions
      renderAsMenu={renderAsMenu}
      onEdit={handleOnClick(onEdit)}
      onCopy={handleOnClick(onCopy)}
      onEnd={handleOnClick(onEnd)}
      onDelete={handleOnClick(onDelete)}
      showDelete={!hasSessions}
      showMarkAsDone={activeFlag}
    />
  );

  return (
    <div className={`${clsPrefix}-container`}>
      {isMobile && canEdit && (
        <>
          <IconButton
            className={`${clsPrefix}-container-action-button-trigger`}
            onClick={({ currentTarget }) => setActionPopoverAnchor(currentTarget)}>
            <IoIosMore />
          </IconButton>
          <Popover
            open={!!actionPopoverAnchor}
            anchorEl={actionPopoverAnchor}
            onClose={onClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}>
            {actions(true)}
          </Popover>
        </>
      )}
      <div className={`${clsPrefix}-container-clickable`}>
        <Grid container spacing={1} className={`${clsPrefix}-service-info`} onClick={handleOnClick(onClick)}>
          <Grid item xs={12} md={4} className={`${clsPrefix}-service-info-student`}>
            <Tooltip arrow placement="bottom-start" title={toolTipPreferences ? "Student Name (Student ID)" : ''}>
              <span className="student-name">
                {studentFirstName} {studentLastName} ({studentExtId})
              </span>
            </Tooltip>
            <Tooltip arrow placement="bottom-start" title={toolTipPreferences ? "School Name (Grade)" : ''}>
              <span>
                {schoolName} ({grade})
              </span>
            </Tooltip>
          </Grid>
          {!isMobile && (
            <>
              <Grid item xs={12} md={2}>
                <Tooltip arrow placement="bottom-start" title={toolTipPreferences ? "Provider Name" : ''}>
                  <span>{providerFullName}</span>
                </Tooltip>
              </Grid>
              <Grid item xs={12} md={1}>
                <Tooltip arrow placement="bottom-start" title={toolTipPreferences ? "Tier" : ''}>
                  <span>Tier {tier}</span>
                </Tooltip>
              </Grid>
              <Grid item xs={12} md={3}>
                <Tooltip
                  arrow
                  placement="bottom-start"
                  title={toolTipPreferences ? `Start Date / ${displayedEndDate ? '' : 'Projected'} End Date` : ''}>
                  <span>
                    {getDateDisplay(startDate)}
                    {!isEmpty(displayedEndDate)
                      ? getDateDisplay(displayedEndDate, formattedDate => ` - ${formattedDate}`)
                      : getDateDisplay(projectedEndDate, formattedDate => ` - ${formattedDate}`)}
                    {}
                  </span>
                </Tooltip>
              </Grid>
              {canEdit && (
                <Grid item xs={12} md={2} className={`${clsPrefix}-actions`}>
                  {actions(false)}
                </Grid>
              )}
            </>
          )}
          {isMobile && (
            <>
              <Grid item xs={12} md={2} className={`${clsPrefix}-service-info-mobile`}>
                <div>
                  <span>Tier {tier}</span>
                  <span>{providerFullName}</span>
                </div>
                <span>
                  {getDateDisplay(startDate)}
                  {!isEmpty(displayedEndDate)
                    ? getDateDisplay(displayedEndDate, formattedDate => ` - ${formattedDate}`)
                    : getDateDisplay(projectedEndDate, formattedDate => ` - ${formattedDate}`)}
                  {}
                </span>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default TieredServiceItem;
