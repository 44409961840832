import { Button, Grid, IconButton, Tooltip, useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai';
import { MdExpandMore, MdMoreVert } from 'react-icons/md';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { muiResponsive } from 'src/appConfig/muiTheme';
import { PATHS } from 'src/appConfig/paths';
import { useConfirmDialog } from 'src/components/common/ConfirmDialog';
import PageContentContainer from 'src/components/PageContentContainer';
import StickyPageHeader from 'src/components/StickyPageHeader';
import { selectUserPermissions } from 'src/redux/authRedux/selectors';
import { IRootState } from 'src/redux/rootReducer';
import { ProviderName } from 'src/redux/schedulerRedux/types';
import {
  clearSearchEditableStudents,
  clearSelectedLog,
  getLogByIdAsync,
  getLogByIdCloneAsync,
  getRecentLogsAsync,
  removeSessionLogByIdAsync,
  setAddSessionTemplateFirstInfo,
  setModeSelectedLog,
  setSelectedProviderAddSession,
  setSelectedStudentsAddSession,
  setSessionDateAddSession,
} from 'src/redux/sessionLogsRedux/actions';
import { selectRecentSessionLogs } from 'src/redux/sessionLogsRedux/selectors';
import { EditableStudentsType, SessionLogDetail } from 'src/redux/sessionLogsRedux/types';
import { DefaultRoute, Navigator, Toastify } from 'src/services';
import { getDateDisplay } from 'src/utils/momentUtils';
import { getSupportTypeName, UserInfo } from 'src/utils/nameUtils';
import { isEmpty } from 'src/validations';
import AddProviderAndStudentsDialog from './AddProviderAndStudentsDialog';
import ActionsButtonPopover, { ActionsButton } from './components/ActionsButtonPopover';
import { Accordion, AccordionDetails, AccordionSummary } from './components/CustomAccordion';
import './styles.scss';
import Detail from './Detail';
import { LOCAL_STORAGE_SUBMIT_CLONE } from 'src/services/submitCloneNewSL';

const clsPrefix = 'session-logs';
var clickSelection = null;

const SessionLogsContainer: React.FC<Props> = ({
  selectedLog,
  recentLogs,
  getRecentLogsAsync,
  loading,
  permissions,
  setSelectedProviderAddSession,
  setSelectedStudentsAddSession,
  setSessionDateAddSession,
  setAddSessionTemplateFirstInfo,
  removeSessionLogById,
  getLogById,
  getLogByIdClone,
  setModeSelectedLog,
  clearSearchEditableStudents,
  clearSelectedLog,
}) => {
  const isMobile = useMediaQuery(muiResponsive.MOBILE);
  const [showAddSessionDialog, setShowAddSessionDialog] = React.useState<boolean>(false);
  const [actionPopoverAnchor, setActionPopoverAnchor] = React.useState<HTMLButtonElement>(null);
  const [ConfirmDialog, confirm] = useConfirmDialog('Are you sure you want to delete this session log?');
  const [currentTargetLogId, setCurrentTargetLogId] = React.useState<number>(null);

  const [selectedLogItem, setSelectedLogItem] = React.useState<SessionLogDetail>(null);
  localStorage.setItem('ProviderSignatureError', 'false');
  const toolTipPreferences = localStorage.getItem('toolTipPreferences') === 'true' ? true : false;

  React.useEffect(() => {
    if (!permissions.isSL) {
      Toastify.error('You do NOT have permission to access SESSION LOGS function.');
      Navigator.navigate(DefaultRoute.getLocalDefaultRoute());
    }
  }, []);

  const handleOnEditAction = (sessionId: number) => {
    clickSelection = 'edit';
    setModeSelectedLog('edit');
    getLogById(sessionId);
  };

  const handleOnCopyAction = (sessionId: number) => {
    clickSelection = 'copy'
    setModeSelectedLog('clone');
    getLogByIdClone(sessionId);
  };

  const handleOnDeleteAction = (sessionId: number) => {
    clickSelection = 'delete';
    confirm(() => {
      removeSessionLogById({ sessionId: sessionId });
      setSelectedLogItem(null);
    });
  };

  const handleOnItemSelectAction = (sessionId: number) => {
    clickSelection = 'click';
    getLogById(sessionId);
  };

  React.useEffect(() => {
    !isEmpty(selectedLog) && setSelectedLogItem(selectedLog);
  }, [selectedLog]);

  React.useEffect(() => {
    if (localStorage.getItem(LOCAL_STORAGE_SUBMIT_CLONE) === 'true') {
      localStorage.setItem(LOCAL_STORAGE_SUBMIT_CLONE, 'false');
    }
  }, [localStorage]);

  const actions = (renderAsMenu: boolean, asGridItem: boolean, sessionId: number = currentTargetLogId) => {
    return (
      <ActionsButton
        renderAsMenu={renderAsMenu}
        asGridItem={asGridItem}
        onEdit={() => handleOnEditAction(sessionId)}
        onCopy={() => handleOnCopyAction(sessionId)}
        onDelete={() => handleOnDeleteAction(sessionId)}
      />
    );
  };

  const handleOnCreateSessionLog = (provider: ProviderName, students: EditableStudentsType[], sessionDate: string) => {
    setSelectedProviderAddSession(provider);
    setSelectedStudentsAddSession(students);
    setSessionDateAddSession(sessionDate);

    setAddSessionTemplateFirstInfo({
      provider: provider,
      credentialName: provider.credentialName,
      sessionDate: sessionDate,
    });
    students.length > 1 ? Navigator.navigate(PATHS.addSessionLogTemplate) : Navigator.navigate(PATHS.addSessionLog);
  };

  React.useEffect(() => {
    getRecentLogsAsync();
  }, []);

  return (
    <>
      <PageContentContainer className={`${clsPrefix}-page-container`} loading={loading}>
        <StickyPageHeader>
          <div className={`${clsPrefix}-header-container`}>
            <h3>Session Logs</h3>
            <div className={`${clsPrefix}-header__buttonWrapper`}>
              <Link to={PATHS.searchSessionLogs}>
                <Button
                  startIcon={<AiOutlineSearch />}
                  fullWidth={isMobile ? true : false}
                  size="large"
                  variant="outlined"
                  color="secondary"
                  disabled={loading}>
                  Search Logs
                </Button>
              </Link>
              <Button
                startIcon={<AiOutlinePlus />}
                fullWidth={isMobile ? true : false}
                size="large"
                variant="contained"
                color="secondary"
                onClick={() => setShowAddSessionDialog(true)}
                className={`${clsPrefix}-header__buttonWrapper-add`}
                disabled={loading}>
                Add Log
              </Button>
            </div>
          </div>
        </StickyPageHeader>
        <div className={`${clsPrefix}`}>
          <h6>Recent Session Logs</h6>

          <div className={`${clsPrefix}-recent-accordion`}>
            {recentLogs.map((recentLog, recentLogIndex) => {
              return (
                <Accordion key={`${recentLog.date} ${recentLogIndex}`}>
                  <AccordionSummary expandIcon={<MdExpandMore />}>
                    <h3>
                      {getDateDisplay(recentLog.date)} ({recentLog.logs.length})
                    </h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container direction="column" spacing={1} className={`${clsPrefix}-recent-accordion-details`}>
                      {recentLog.logs.map((log, logIndex) => {
                        return (
                          <div key={log.sessionId} className={`${clsPrefix}-recent-accordion-details__item`}>
                            {/* <Tooltip arrow title={`Session ID: ${log.sessionId}`} placement="bottom-end"> */}
                            <Grid
                              container
                              direction="row"
                              spacing={1}
                              onClick={() => {
                                setCurrentTargetLogId(log.sessionId);
                                if ( clickSelection !== 'delete' ) {
                                  handleOnItemSelectAction(log.sessionId);
                                }
                                clickSelection = null;
                              }}>
                              <Grid item container spacing={1} xs={12} sm={4}>
                                <Grid item xs={12}>
                                  <UserInfo
                                    firstName={log.studentFirstName}
                                    lastName={log.studentLastName}
                                    studentExtId={log.studentExtId}
                                    className={`${clsPrefix}-text-bold mobile`}
                                    component="h5"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Tooltip arrow placement="bottom-start" title={ toolTipPreferences ? "School Name (Grade)" : ''}>
                                    <h5 className={`${clsPrefix}-text-color-gray`}>
                                      {log.studentSchoolName} ({log.grade})
                                    </h5>
                                  </Tooltip>
                                </Grid>
                              </Grid>

                              <Grid item container spacing={1} xs={12} sm={3}>
                                <Grid item xs={12}>
                                  <Tooltip arrow placement="bottom-start" title={toolTipPreferences ? "Support Type Name": ''}>
                                    <h5 className={`${clsPrefix}-text-bold mobile ${clsPrefix}-text-color-gray-mobile`}>
                                      {!isEmpty(log.supportTypeName)
                                        ? log.supportTypeName
                                        : getSupportTypeName(log.supportType)}
                                    </h5>
                                  </Tooltip>
                                </Grid>
                                {isMobile && !isEmpty(log.supportStartDate) && !isEmpty(log.supportEndDate) && (
                                  <Grid item xs={12}>
                                    <h5 className={`${clsPrefix}-text-bold ${clsPrefix}-text-color-gray-mobile`}>
                                      {getDateDisplay(log.supportStartDate)}
                                      {getDateDisplay(log.supportEndDate, formattedDate => ` - ${formattedDate}`)}
                                    </h5>
                                  </Grid>
                                )}
                                <Grid item xs={12}>
                                  <Tooltip arrow placement="bottom-start" title={toolTipPreferences ? "Session Time" : '' }>
                                    <h5
                                      className={`${clsPrefix}-recent-accordion-details__item-time ${clsPrefix}-text-color-gray`}>
                                      {log.sessionTime
                                        ? moment(`${log.sessionTime}`, `HH.mm.ss`).format('hh:mm A')
                                        : ''}{' '}
                                      {log.sessionMinutes
                                        ? `(${log.sessionMinutes} min${log.sessionMinutes > 1 ? 's' : ''})`
                                        : ''}
                                      {isEmpty(log.sessionTime) &&
                                        isEmpty(log.sessionTime) &&
                                        isEmpty(log.sessionMinutes) && <span style={{ color: 'red' }}>Student Not Available </span>}
                                    </h5>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                              {!isMobile && (
                                <Grid item xs={12} sm={3}>
                                  <Tooltip arrow placement="bottom-start" title={toolTipPreferences ? "Start Date - End Date" : ''}>
                                    <h5 className={`${clsPrefix}-text-bold ${clsPrefix}-text-color-gray-mobile`}>
                                      {getDateDisplay(log.supportStartDate)}{' '}
                                      {getDateDisplay(log.supportEndDate, formattedDate => ` - ${formattedDate}`)}
                                    </h5>
                                  </Tooltip>
                                </Grid>
                              )}
                              {log.canEdit && !isMobile && (
                                <Grid
                                  item
                                  xs={12}
                                  sm={2}
                                  spacing={2}
                                  container
                                  justifyContent="flex-end"
                                  alignItems="flex-start">
                                  {actions(false, true, log.sessionId)}
                                </Grid>
                              )}
                            </Grid>
                            {/* </Tooltip> */}

                            {isMobile && log.canEdit && (
                              <div className={`${clsPrefix}-recent-accordion-details__item-moreBtn`}>
                                <IconButton
                                  size="small"
                                  onClick={({ currentTarget }) => {
                                    setActionPopoverAnchor(currentTarget);
                                    setCurrentTargetLogId(log.sessionId);
                                  }}>
                                  <MdMoreVert />
                                </IconButton>
                                <ActionsButtonPopover
                                  container={actionPopoverAnchor}
                                  onClose={() => setActionPopoverAnchor(null)}
                                  elevation={1}>
                                  {actions(true, false)}
                                </ActionsButtonPopover>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </div>
      </PageContentContainer>
      {selectedLogItem && (
        <Detail
          service={selectedLogItem}
          onClose={() => {
            clickSelection = null;
            setSelectedLogItem(null);
            clearSelectedLog();
          }}
          key={currentTargetLogId}
          onEdit={() => handleOnEditAction(currentTargetLogId)}
          onCopy={() => handleOnCopyAction(currentTargetLogId)}
          onDelete={() => handleOnDeleteAction(currentTargetLogId)}
        />
      )}
      {showAddSessionDialog && (
        <AddProviderAndStudentsDialog
          onCreate={handleOnCreateSessionLog}
          onClose={() => {
            clickSelection = null;
            setShowAddSessionDialog(false);
            clearSearchEditableStudents();
          }}
        />
      )}
      <ConfirmDialog />
    </>
  );
};

type ServiceItemCallback<T = any> = (service: T) => void;
type SessionLogItemProps<T> = {
  service?: T;
  onClick?: ServiceItemCallback<T>;
  onEdit?: ServiceItemCallback<T>;
  onCopy?: ServiceItemCallback<T>;
  onEnd?: ServiceItemCallback<T>;
  onDelete?: ServiceItemCallback<T>;
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & SessionLogItemProps<any>;

const mapStateToProps = (state: IRootState) => {
  const { recentLogs, searchLogs, addSession } = state.sessionLogs;
  return {
    recentLogs: selectRecentSessionLogs(state),
    permissions: selectUserPermissions(state),
    loading: recentLogs.loading || searchLogs.selectedLog.loading || addSession.removeLog.loading,
    selectedLog: searchLogs.selectedLog?.data,
  };
};

const mapDispatchToProps = {
  getRecentLogsAsync: getRecentLogsAsync.request,
  setSelectedProviderAddSession: setSelectedProviderAddSession,
  setSelectedStudentsAddSession: setSelectedStudentsAddSession,
  setSessionDateAddSession: setSessionDateAddSession,
  setAddSessionTemplateFirstInfo: setAddSessionTemplateFirstInfo,
  removeSessionLogById: removeSessionLogByIdAsync.request,
  getLogById: getLogByIdAsync.request,
  getLogByIdClone: getLogByIdCloneAsync.request,
  setModeSelectedLog: setModeSelectedLog,
  clearSearchEditableStudents: clearSearchEditableStudents,
  clearSelectedLog: clearSelectedLog,
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionLogsContainer);
