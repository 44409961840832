import { Avatar, IconButton, ListItemIcon, Menu, MenuItem } from '@material-ui/core';
import { MoreVert, ExitToApp, Settings, Help } from '@material-ui/icons';
import React from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { IMAGES } from 'src/appConfig/images';
import { PATHS } from 'src/appConfig/paths';
import { signOutAsync } from 'src/redux/authRedux/actions';
import { IRootState } from 'src/redux/rootReducer';
import BurgerMenuButton from '../SideMenu/BurgerMenuButton';
import './styles.scss';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import UserPreferencesDialog from 'src/containers/UAMContainer/UserPreferences';
import { selectDefaultMenu, selectUserPermissions } from 'src/redux/authRedux/selectors';
import { getUserDefaultRoute } from 'src/utils/getUserDefaultRoute';
import { muiResponsive } from 'src/appConfig/muiTheme';
import urlJoin from 'url-join';
const NavBar: React.VFC<Props> = ({ user, onSignOut, userDefaultRoute, permissions }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const pathname = useLocation().pathname;
  const [showUserPreferences, setShowUserPreferences] = React.useState<boolean>(false);
  const isMobile = useMediaQuery(muiResponsive.MOBILE);

  // Help Links
  const baseHelpLink = process.env.REACT_APP_HELP_URL as string;

  // Session Log Help link
  const SL = `/sbbh-session-logs-faqs/`;
  const SLHelpLink = urlJoin(baseHelpLink, SL);

  // Scheduler Help link
  const sched = `/sbbh-scheduler-faqs/`;
  const schedHelpLink = urlJoin(baseHelpLink, sched);

  // Tiered Service Help Link
  const ts = `/tiered-services-faqs/`;
  const tsHelpLink = urlJoin(baseHelpLink, ts);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const getUserName = () => {
    if (!user) return 'Anonymous';
    return user.fullName;
  };

  const getNavbarTitle = (route: string) => {
    switch (route) {
      case PATHS.scheduler:
        return 'Scheduler';
      case PATHS.tieredService:
        return 'Tiered Services';
      case PATHS.editTieredService:
        return 'Edit Tiered Service';
      case PATHS.copyTieredService:
        return 'Copy Tiered Service';
      case PATHS.addTieredService:
        return 'Add Tiered Service';
      case PATHS.submissionHistory:
        return 'Submission History';
      case PATHS.submissionHistorySessionLog:
        return 'Submission History';
      case PATHS.sessionLogs:
        return 'Session Logs';
      case PATHS.searchSessionLogs:
        return 'Search Session Logs';
      case PATHS.addSessionLog:
        return 'Add Session Log';
      case PATHS.addSessionLogTemplate:
        return 'Session Log Template';
      case PATHS.editSessionLog:
        return 'Edit Session Log';
      default:
        break;
    }
  };

  const handleLogOut = () => {
    handleCloseMenu();
    onSignOut();
  };

  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <div className="nav-bar-container">
      <div className="nav-bar">
        <div className="nav-bar-left">
          <BurgerMenuButton className="nav-bar-icon burger" />
          {isMobile ? (
            <h4 className="nav-bar-app-name mobile">{getNavbarTitle(pathname)}</h4>
          ) : (
            <Link to={getUserDefaultRoute(permissions, userDefaultRoute)} className="nav-bar-app-name">
              Lōkahi
            </Link>
          )}
        </div>
        <div className="nav-bar-right">
          <span className="nav-bar-username mr-12">{getUserName()}</span>
          {user ? <Avatar>{getUserName().substring(0, 2)}</Avatar> : <Avatar src={IMAGES.defaultUser} />}
          <IconButton aria-label="more" aria-haspopup="true" onClick={handleClick}>
            <MoreVert className="nav-bar-icon" />
          </IconButton>
        </div>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleCloseMenu}
          PopoverClasses={{
            paper: 'nav-bar-menu-popover',
          }}>
          <MenuItem key={0} onClick={() => setShowUserPreferences(true)}>
            <ListItemIcon>
              <Settings fontSize="medium" className="nav-bar-icon listItemIcon" />
            </ListItemIcon>
            <p>User Preferences</p>
          </MenuItem>
          <MenuItem key={1} onClick={() => openInNewTab(SLHelpLink)}>
            <ListItemIcon>
              <Help fontSize="medium" className="nav-bar-icon listItemIcon" />
            </ListItemIcon>
              <p>Session Log Help</p>
          </MenuItem>
          <MenuItem key={2} onClick={() => openInNewTab(tsHelpLink)}>
            <ListItemIcon>
              <Help fontSize="medium" className="nav-bar-icon listItemIcon" />
            </ListItemIcon>
                <p>Tiered Services Help</p>
          </MenuItem>
          <MenuItem key={3} onClick={() => openInNewTab(schedHelpLink)}>
            <ListItemIcon>
              <Help fontSize="medium" className="nav-bar-icon listItemIcon" />
            </ListItemIcon>
                <p>Scheduler Help</p>
          </MenuItem>
          <MenuItem key={4} onClick={handleLogOut}>
            <ListItemIcon>
              <ExitToApp fontSize="medium" className="nav-bar-icon listItemIcon" />
            </ListItemIcon>
            <p>Log out</p>
          </MenuItem>
        </Menu>

        {/* Dialog */}
        {showUserPreferences && <UserPreferencesDialog onClose={() => setShowUserPreferences(false)} />}
      </div>
    </div>
  );
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
const mapStateToProps = (state: IRootState) => ({
  user: state.auth.user,
  userDefaultRoute: selectDefaultMenu(state),
  permissions: selectUserPermissions(state),
});

const mapDispatchToProps = {
  onSignOut: signOutAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
