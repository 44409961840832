import { Button, Grid, IconButton, TextField, useMediaQuery } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  ArrayHelpers,
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
  FormikConfig,
  FormikErrors,
  FormikProps,
} from 'formik';
import moment from 'moment';
import React from 'react';
import { useEffect } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { connect } from 'react-redux';
import { muiResponsive } from 'src/appConfig/muiTheme';
import { MuiDatePickerFormikField, MuiDatePickerMomentUtilsProvider } from 'src/components/common/KeyboardDatePicker';
import CustomPopperAutocomplete from 'src/components/CustomPopperAutocomplete';
import SearchDropdownBox from 'src/components/SearchDropdownBox';
import { getProviderWithPositionLabel } from 'src/containers/SchedulerContainers/helper';
import { IRootState } from 'src/redux/rootReducer';
import { ProviderName } from 'src/redux/schedulerRedux/types';
import {
  getTieredServiceTargetsAndPracticesAsync,
  searchAlternativeProvidersAsync,
} from 'src/redux/tieredServices/action';
import { Practice, Target } from 'src/redux/tieredServices/types';
import { filterDisabledOptionsTS, filterOptionsMatchAllKeywords } from 'src/utils';
import { toProvider } from 'src/utils/userToProvider';
import { isEmpty } from 'src/validations';

import * as yup from 'yup';
import FrequencyInput from '../FrequencyInput';
import { OutcomeOption, outcomes } from './outcomes';
import './styles.scss';

type FormSetupState = {
  mode?: string;
};
export type TieredServiceFormValue = {
  providerFullName?: string;
  provider?: number;
  providerHUserId?: number;
  startDate?: string;
  endDate?: string;
  outcome?: number;
  alternativeProvider?: Partial<ProviderName> & { providerFullname?: string };
  tier?: number;
  projectedEndDate?: string;
  frequency?: [number, number, string];
  targets?: { id: number; targetId: number; needDescription: boolean; description?: string }[];
  practiceElements?: { id: number; needDescription: boolean; description?: string }[];
  comments?: string;
  reasonForEdit?: string;
} & FormSetupState;

const TieredServiceFormSchema = yup.object({
  mode: yup.string(),
  provider: yup.number().required(`Provider is required`),
  providerHUserId: yup.number().nullable(),
  startDate: yup.date().typeError(`Please enter a valid date (MM/DD/YYYY)`).required(`Start Date is required`),
  endDate: yup
    .date()
    .nullable()
    .min(yup.ref('startDate'), 'End Date must be on or after the Start Date')
    .max(moment().add(1, 'days'), 'End Date cannot be a future date')
    .when('mode', {
      is: mode => mode === 'endPlan',
      then: yup
        .date()
        .min(yup.ref('startDate'), 'End Date must be on or after the Start Date')
        .max(moment().endOf('day'), 'End Date cannot be a future date')
        .typeError(`Please enter a valid date (MM/DD/YYYY)`)
        .required(`End Date is required`),
    }),
  outcome: yup
    .number()
    .nullable()
    .when('mode', {
      is: mode => mode === 'endPlan',
      then: yup.number().typeError(`Outcome is required.`).required(`Outcome is required.`),
    }),
  alternativeProvider: yup.object().nullable(),
  tier: yup.number().nullable().required(`Tier is required`),
  projectedEndDate: yup
    .date()
    .min(yup.ref('startDate'), 'Projected End Date must be on or after the Start Date')
    .typeError(`Please enter a valid date (MM/DD/YYYY)`)
    .required(`Projected End Date is required`),
  frequency: yup.array(),
  targets: yup
    .array(
      yup
        .object({
          id: yup.number(),
          targetId: yup.number(),
          needDescription: yup.boolean(),
          description: yup.string().nullable().optional(),
        })
        .nullable(),
    )
    .required(`Target is required`),
  practiceElements: yup
  .array(
    yup
      .object({
        id: yup.number(),
        practiceId: yup.number(),
        needDescription: yup.boolean(),
        description: yup.string().nullable().optional(),
      })
      .nullable(),
  )
  .required(`Practice Element is required`),
  comments: yup.string().nullable(),
});

const clsPrefix = 'tiered-service-form';

const isTargetTypeOther = (target: Target | null) => target?.otherFlag;

const isPracticeTypeOther = (practice: Practice | null) => practice?.otherFlag;

type Props = FormikProps<TieredServiceFormValue> & {
  studentId?: number;
} & ReturnType<typeof mapState> &
  typeof mapDispatch;

const tiers = [2, 3];

const MAX_TARGETS = 5;
const TieredServiceForm = ({
  values,
  errors,
  studentId,
  alternativeProvidersAsyncState,
  searchAlternativeProviders,
  clearSearchProvider,
  getTieredServiceTargetsAndPractices,
  targets,
  practices,
}: Props) => {
  const isMobile = useMediaQuery(muiResponsive.MOBILE);

  useEffect(() => {
    if (!targets?.length || !practices?.length) {
      getTieredServiceTargetsAndPractices();
    }
  }, [targets, practices]);

  const handleSearchAlternativeProvider = (search: string) => {
    searchAlternativeProviders({
      search,
      studentId,
    });
  };

  React.useEffect(() => {
    if(values?.endDate) console.log(values.endDate);
  }, [values]);

  const renderDynamicFields = () => (
    <FieldArray
      name="targets"
      render={({ push: pushTarget, remove: removeTarget }: ArrayHelpers) => (
        <FieldArray
          name="practiceElements"
          render={({ push: pushPracticeElement, remove: removePracticeElement }: ArrayHelpers) => {
            const showDeleteButton = values.targets?.length > 1;
            const handleAddTarget = () => {
              pushTarget(null);
              pushPracticeElement(null);
            };

            const handleRemoveTarget = (index: number) => {
              removeTarget(index);
              removePracticeElement(index);
            };

            const getTargetTitle = (t?: Target) => {
              return t && `${t.targetArea}${t.target ? `: ${t.target}` : ''}`;
            };

            const getPracticeElementTitle = (p: Practice) => {
              return p.practiceElement;
            };

            return (
              <>
                {Array.from(
                  { length: Math.max(values.targets?.length || 1, values.practiceElements?.length || 1) },
                  (_, index) => (
                    <Grid
                      key={`target-and-practice-${index}`}
                      container
                      item
                      spacing={3}
                      xs={12}
                      lg={12}
                      className={`${clsPrefix}-dynamic-group`}>
                      <Grid item container xs={showDeleteButton ? 11 : 12} spacing={2}>
                      <Grid item container xs={12} md={6}>
                        <Grid item xs={12}>
                          <Field name={`targets.${index}.targetId`}>
                            {({
                              field: { name, value },
                              form: { errors, setFieldValue, setFieldTouched, setFieldError, setErrors },
                              meta: { touched },
                            }: FieldProps) => (
                              <Autocomplete
                                key={`targetId-${value}`}
                                options={filterDisabledOptionsTS(targets)}
                                getOptionLabel={getTargetTitle}
                                getOptionDisabled={(options) => {
                                  if (options.disabledFlag) {
                                    return true;
                                  }
                                }}
                                PopperComponent={CustomPopperAutocomplete}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label="Target *"
                                    variant="outlined"
                                    error={touched && !!errors.targets?.[index]?.targetId}
                                    helperText={touched && errors.targets?.[index]?.targetId}
                                  />
                                )}
                                value={targets.find(t => t.targetId === value)}
                                onChange={(_e, target: Target) => {
                                  setFieldValue(`targets.${index}.targetId`, target?.targetId);
                                  setFieldValue(`targets.${index}.needDescription`, isTargetTypeOther(target));
                                  setFieldValue(`targets.${index}.description`, !target.otherFlag ? null : '' );
                                }}
                                filterOptions={filterOptionsMatchAllKeywords<Target>(getTargetTitle)}
                                disableClearable
                                onBlur={() => setFieldTouched(name, true)}
                                disabled={isEndPlanMode}
                              />
                            )}
                          </Field>
                        </Grid>
                        {values.targets?.[index]?.needDescription && (
                          <Grid item xs={12} className={`${clsPrefix}-dynamic-group-briefDescription`}>
                            <Field name={`targets.${index}.description`}>
                              {({ field, form, meta }: FieldProps) => (
                                <TextField
                                  label="Other Target Description *"
                                  variant="outlined"
                                  error={meta.touched && !!meta.error}
                                  helperText={(meta.touched && meta.error) || ''}
                                  fullWidth
                                  onBlur={() => form.setFieldTouched(field.name, true)}
                                  disabled={isEndPlanMode}
                                  {...field}
                                />
                              )}
                            </Field>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item container xs={12} md={6}>
                        <Grid item xs={showDeleteButton ? 10 : 12} md={showDeleteButton ? 11 : 12}>
                          <Field name={`practiceElements.${index}.id`}>
                            {({
                              field: { name, value },
                              form: { errors, setFieldValue, setFieldTouched },
                              meta: { touched },
                            }: FieldProps) => (
                              <Autocomplete
                                key={`practice-element-id-${value}`}
                                options={practices}
                                getOptionLabel={getPracticeElementTitle}
                                PopperComponent={CustomPopperAutocomplete}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label="Practice Element *"
                                    variant="outlined"
                                    error={touched && !!errors.practiceElements?.[index].id}
                                    helperText={touched && errors.practiceElements?.[index].id}
                                  />
                                )}
                                value={practices.find(p => p.practiceId === value)}
                                onChange={(_e, p: Practice) => {
                                  setFieldValue(`practiceElements.${index}.id`, p?.practiceId);
                                  setFieldValue(`practiceElements.${index}.needDescription`, isPracticeTypeOther(p));
                                }}
                                filterOptions={filterOptionsMatchAllKeywords<Practice>(getPracticeElementTitle)}
                                onBlur={() => setFieldTouched(name, true)}
                                disableClearable
                                disabled={isEndPlanMode}
                              />
                            )}
                          </Field>
                        </Grid>
                        {values.practiceElements?.[index]?.needDescription && (
                          <Grid item xs={12} className={`${clsPrefix}-dynamic-group-briefDescription`}>
                            <Field name={`practiceElements.${index}.description`}>
                              {({ field, form, meta }: FieldProps) => (
                                <TextField
                                  label="Other Practice Element Description *"
                                  variant="outlined"
                                  error={meta.touched && !!meta.error}
                                  helperText={(meta.touched && meta.error) || ''}
                                  fullWidth
                                  onBlur={() => form.setFieldTouched(field.name, true)}
                                  disabled={isEndPlanMode}
                                  {...field}
                                />
                              )}
                            </Field>
                          </Grid>
                        )}
                        </Grid>
                        </Grid>
                        {showDeleteButton && (
                          <Grid item xs={2} md={1}>
                            <IconButton onClick={() => handleRemoveTarget(index)} disabled={isEndPlanMode}>
                              <FaTrash />
                            </IconButton>
                          </Grid>
                        )}
                    </Grid>
                  ),
                )}
                <Grid container item spacing={3} className={`${clsPrefix}-the-rest`}>
                  <Grid item xs={12} md={12}>
                    <Button
                      startIcon={<FaPlus />}
                      variant="outlined"
                      color="primary"
                      onClick={handleAddTarget}
                      disabled={isEndPlanMode || values.targets?.length === MAX_TARGETS}>
                      Add Target
                    </Button>
                  </Grid>
                </Grid>
              </>
            );
          }}
        />
      )}
    />
  );

  const handleFormReset = () => {
    clearSearchProvider();
  };

  let isEndPlanMode = values.mode === 'endPlan';
  let isCloneMode = values.mode === 'clone';

  const isCloneHasEndPlan = (!isEmpty(values.outcome) || !isEmpty(values.endDate)) && values.mode === 'clone';
  if (isCloneHasEndPlan) {
    isEndPlanMode = true;
  }

  const isEditHasEndPlan = (!isEmpty(values.outcome) || !isEmpty(values.endDate)) && values.mode === 'edit';
  if (isEditHasEndPlan) {
    isEndPlanMode = true;
  }
  return (
    <Form className={clsPrefix} onReset={handleFormReset}>
      <MuiDatePickerMomentUtilsProvider>
        <Grid container>
          <Grid container item spacing={3}>
            <Grid item xs={12} md={isEndPlanMode || isEditHasEndPlan ? 3 : 6}>
              <Field name="provider">
                {(_fieldProps: FieldProps) => (
                  <TextField
                    label="Provider"
                    variant="outlined"
                    disabled
                    fullWidth
                    InputLabelProps={{
                      shrink: !!values.providerFullName,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={`${values.providerFullName}`}
                  />
                )}
              </Field>
            </Grid>
            {isMobile && (
              <Grid item xs={12}>
                <Field name="alternativeProvider">
                  {({ field, form, meta }: FieldProps) => (
                    <SearchDropdownBox<Partial<ProviderName> & { providerFullname?: string }>
                      placeholder="Alternate Provider"
                      value={field.value}
                      onChange={v => form.setFieldValue('alternativeProvider', v)}
                      loading={alternativeProvidersAsyncState.loading}
                      options={alternativeProvidersAsyncState.data || []}
                      onSearch={handleSearchAlternativeProvider}
                      getOptionLabel={o =>
                        o?.providerFullname
                          ? o.providerFullname
                          : getProviderWithPositionLabel(o as ProviderName) || null
                      }
                      onClear={clearSearchProvider}
                      noOptionsText="No providers found"
                      disabled={isEndPlanMode}
                      debounceDelay={500}
                    />
                  )}
                </Field>
              </Grid>
            )}
            <Grid item xs={12} md={(isEndPlanMode || isEditHasEndPlan ? 3 : 6)}>
              <Field name="startDate">
                {(renderProps: FieldProps) => {
                  const maxDate = moment().add(1, 'years');
                  return (
                    <MuiDatePickerFormikField
                      label="Start Date *"
                      {...renderProps}
                      maxDate={maxDate}
                      disabled={isEndPlanMode}
                      disabledHelperText={isMobile}
                    />
                  );
                }}
              </Field>
            </Grid>
            {(isEndPlanMode || isEditHasEndPlan) && (
              <>
                <Grid item xs={12}  md={isEndPlanMode || isEditHasEndPlan ? 3 : 6}>
                  <Field name="endDate">
                    {(renderProps: FieldProps) => {
                      const maxDate = moment().endOf('day');
                      return (
                        <MuiDatePickerFormikField
                          label="End Date *"
                          minDate={moment(values.startDate)}
                          maxDate={maxDate}
                          disabledHelperText={isMobile}
                          {...renderProps}
                        />
                      );
                    }}
                  </Field>
                </Grid>
                <Grid item xs={12}  md={isEndPlanMode || isEditHasEndPlan || isCloneMode ? 3 : 6}>
                  <Field name={`outcome`}>
                    {({
                      field: { name, value },
                      form: { errors, setFieldValue, setFieldTouched },
                      meta: { touched },
                    }: FieldProps) => {
                      const getOutcomeTitle = (o: OutcomeOption) => o.name;
                      return (
                        <Autocomplete
                          key={`outcome`}
                          options={outcomes}
                          getOptionLabel={getOutcomeTitle}
                          PopperComponent={CustomPopperAutocomplete}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Outcome *"
                              variant="outlined"
                              error={touched && !!errors.outcome}
                              helperText={touched && errors.outcome}
                            />
                          )}
                          value={outcomes.find(o => o.id === value)}
                          onChange={(_e, o: OutcomeOption) => setFieldValue(`outcome`, o?.id)}
                          filterOptions={filterOptionsMatchAllKeywords<OutcomeOption>(getOutcomeTitle)}
                          onBlur={() => setFieldTouched(name, true)}
                          // disableClearable
                        />
                      );
                    }}
                  </Field>
                </Grid>
              </>
            )}
            {isMobile && (
              <Grid item xs={12} md={6}>
                <Field name="projectedEndDate">
                  {(renderProps: FieldProps) => {
                    const maxDate = moment(values.startDate).add(2, 'years');
                    return (
                      <MuiDatePickerFormikField
                        label="Projected End Date *"
                        disabled={isEndPlanMode}
                        minDate={moment(values.startDate)}
                        maxDate={maxDate}
                        disabledHelperText={isMobile}
                        {...renderProps}
                      />
                    );
                  }}
                </Field>
              </Grid>
            )}
            {!isMobile && (
              <Grid item xs={12} md={6}>
                <Field name="alternativeProvider">
                  {({ field, form, meta }: FieldProps) => (
                    <SearchDropdownBox<Partial<ProviderName> & { providerFullname?: string }>
                      placeholder="Alternate Provider"
                      value={field.value}
                      onChange={v => form.setFieldValue('alternativeProvider', v)}
                      loading={alternativeProvidersAsyncState.loading}
                      options={alternativeProvidersAsyncState.data || []}
                      onSearch={handleSearchAlternativeProvider}
                      getOptionLabel={o =>
                        o?.providerFullname
                          ? o.providerFullname
                          : getProviderWithPositionLabel(o as ProviderName) || null
                      }
                      onClear={clearSearchProvider}
                      noOptionsText="No providers found"
                      disabled={isEndPlanMode}
                      debounceDelay={500}
                    />
                  )}
                </Field>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Field name="tier">
                {({
                  field: { name, value },
                  form: { errors, setFieldValue, setFieldTouched },
                  meta: { touched, error },
                }: FieldProps) => {
                  const getOptionTitle = (o: number) => o.toString();
                  return (
                    <Autocomplete
                      key={`tier-${value}`}
                      options={tiers}
                      getOptionLabel={getOptionTitle}
                      PopperComponent={CustomPopperAutocomplete}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Tier *"
                          variant="outlined"
                          error={touched && !!error}
                          helperText={touched && error}
                        />
                      )}
                      value={tiers.find(t => t === value)}
                      onChange={(_e, o) => setFieldValue(`tier`, +o)}
                      filterOptions={filterOptionsMatchAllKeywords<number>(getOptionTitle)}
                      onBlur={() => setFieldTouched(name, true)}
                      disableClearable
                      disabled={isEndPlanMode}
                    />
                  );
                }}
              </Field>
            </Grid>
            {!isMobile && (
              <Grid item xs={12} md={6}>
                <Field name="projectedEndDate">
                  {(renderProps: FieldProps) => {
                    const maxDate = moment(values.startDate).add(2, 'years');
                    return (
                      <MuiDatePickerFormikField
                        label="Projected End Date *"
                        disabled={isEndPlanMode}
                        minDate={moment(values.startDate)}
                        maxDate={maxDate}
                        {...renderProps}
                      />
                    );
                  }}
                </Field>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Field name="frequency">
                {({
                  field: { name, value },
                  form: { setFieldValue, setFieldTouched },
                  meta: { touched, error },
                }: FieldProps) => (
                  <FrequencyInput
                    label="Frequency *"
                    name={name}
                    value={value}
                    onChange={v => setFieldValue(name, v)}
                    //@ts-ignore
                    errors={touched && error}
                    //@ts-ignore
                    errorMessage={touched && error?.find(e => !!e)}
                    onBlur={() => setFieldTouched(name, true)}
                    disabled={isEndPlanMode}
                  />
                )}
              </Field>
            </Grid>
          </Grid>
          {renderDynamicFields()}
          <Grid container item spacing={3} className={`${clsPrefix}-the-rest`}>
            <Grid item xs={12} md={12}>
              <Field name="comments">
                {({ field, form }: FieldProps) => (
                  <>
                    <TextField
                      label="Notes/Analysis"
                      variant="outlined"
                      fullWidth
                      multiline
                      minRows={3}
                      maxRows={5}
                      {...field}
                    />
                  </>
                )}
              </Field>
            </Grid>
          </Grid>
        </Grid>
      </MuiDatePickerMomentUtilsProvider>
    </Form>
  );
};

const TieredServiceFormik: React.FC<FormikConfig<TieredServiceFormValue> & ReturnType<typeof mapState>> = ({
  children,
  initialValues,
  selectedProvider,
  providerHUserId,
  ...formProps
}) => {
  const validate = ({
    startDate,
    endDate,
    outcome,
    projectedEndDate,
    frequency,
    targets,
    practiceElements,
    mode,
  }: TieredServiceFormValue): FormikErrors<TieredServiceFormValue> => {
    const errors: FormikErrors<TieredServiceFormValue> = {
      targets: [],
      practiceElements: [],
    };

    if (!frequency[0] || !frequency[2]) {
      errors.frequency = [
        (!frequency[0] && `Frequency minutes and interval are required`) || '',
        '',
        (!frequency[2] && `Frequency minutes and interval are required`) || '',
      ];
    }
    if (!startDate) {
      errors.startDate = `Start Date is required`;
    } else {
      const today = moment();

      const diffTimeInMs = moment(startDate).diff(moment(today));
      const duration = Math.floor(moment.duration(diffTimeInMs).asDays());
      if (duration > 365) {
        errors.startDate = `Start Date cannot be more than 1 year from today`;
      }
    }

    // When ending a plan, both fields are required
    if ( mode === 'endPlan' && !endDate) {
      errors.endDate = `End Date is required.`;
    }
    if (mode === 'endPlan' && endDate) {
      const maxDate = moment(startDate).add(2, 'years');
      if (moment(endDate) > maxDate) {
        errors.endDate = `End Date must be within 2 years of the Start Date`;
      }
    }

    // When editing an ended plan, both fields need to be populated
    // or both fields need to be empty
    if (mode === 'edit' && !endDate && outcome) {
      errors.endDate = `End Date is required.`;

      const maxDate = moment(startDate).add(2, 'years');
      if (moment(endDate) > maxDate) {
        errors.endDate = `End Date must be within 2 years of the Start Date`;
      }
    }

    if (mode === 'edit' && endDate && !outcome) {
      errors.outcome = `Outcome is required.`;
    }

    if (!projectedEndDate) {
      errors.projectedEndDate = `Projected End Date is required`;
    } else {
      const maxDate = moment(startDate).add(2, 'years');
      if (moment(projectedEndDate) > maxDate) {
        errors.projectedEndDate = `Projected End Date must be within 2 years of the Start Date`;
      }
    }

    for (let i = 0; i < targets?.length; i++) {
      (errors.targets as Array<unknown>).push(
        !targets[i]?.targetId
          ? { targetId: `Target is required` }
          : {
              targetId: null,
              description: targets[i].needDescription && !targets[i].description ? `Target Other Is Required` : null,
            },
      );
      (errors.practiceElements as Array<unknown>).push(
        !practiceElements[i]?.id
          ? { id: `Target is required` }
          : {
              id: null,
              description: practiceElements[i].needDescription && !practiceElements[i].description ? `Practice Other Is Required` : null,
            },
      );
    }

    if (!(errors.targets as Array<{ targetId; description }>).some(err => !!err.targetId || !!err.description)) {
      delete errors.targets;
    }

    if (!(errors.practiceElements as Array<{ id; description }>).some(err => !!err.id || !!err.description)) {
      delete errors.practiceElements;
    }

    return errors;
  };

  return (
    <Formik<TieredServiceFormValue>
      validationSchema={TieredServiceFormSchema}
      validate={validate}
      enableReinitialize
      initialValues={{
        startDate: null,
        endDate: null,
        projectedEndDate: null,
        targets: [null],
        practiceElements: [null],
        providerFullName: getProviderWithPositionLabel(selectedProvider) || '',
        provider: selectedProvider.userId || null,
        providerHUserId: isEmpty(selectedProvider.hUserId) ? providerHUserId : selectedProvider.hUserId,
        alternativeProvider: null,
        comments: '',
        frequency: [null, null, ''],
        outcome: null,
        tier: null,
        ...initialValues,
      }}
      {...formProps}>
      {children}
    </Formik>
  );
};

const mapState = (state: IRootState) => {
  const { targets = [], practices = [] } = state.tieredServices.addTieredService.targetsAndPractices;
  const { providers } = state.tieredServices.addTieredService;

  return {
    selectedProvider: providers.selected || toProvider(state.auth.user),
    providerHUserId: providers.providerHUserId?.data[0]?.provider_h_user_id,
    alternativeProvidersAsyncState: state.tieredServices.addTieredService.alternativeProviders,
    targets: targets.sort((t1, t2) => t1.areaSortOrder - t2.areaSortOrder),
    practices: practices.sort((p1, p2) => p1.areaSortOrder - p2.areaSortOrder),
  };
};

const mapDispatch = {
  searchAlternativeProviders: searchAlternativeProvidersAsync.request,
  clearSearchProvider: searchAlternativeProvidersAsync.cancel,
  getTieredServiceTargetsAndPractices: getTieredServiceTargetsAndPracticesAsync.request,
};

export const ConnectedTieredServiceForm = connect(mapState, mapDispatch)(TieredServiceForm);

export default connect(mapState)(TieredServiceFormik);
