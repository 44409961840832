import { withStyles } from '@material-ui/core/styles';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from '@material-ui/core';

export const Accordion = withStyles(theme => ({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {},

    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}))(MuiAccordion);

export const AccordionSummary = withStyles(theme => ({
  root: {
    marginBottom: -1,
    minHeight: 40,
    padding: '8px 16px',
    transition: 'all 0.3s ease-in-out',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 16px',
    },

    '&:hover': {
      backgroundColor: '#f5f5f5',
    },

    '& h3': {
      fontSize: '18px',
      fontWeight: '400',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
      },
    },

    '&$expanded': {
      minHeight: 40,
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      backgroundColor: theme.palette.primary.main,
      '& > *': {
        color: '#fff',
      },
      '& h3': {
        color: '#fff',
        fontWeight: '500',
      },
    },
  },
  content: {
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

export const AccordionDetails = withStyles(theme => ({
  root: {
    padding: '16px',
  },
}))(MuiAccordionDetails);
