import { Tooltip } from '@material-ui/core';
import React from 'react';
import { ProviderName, StudentName } from 'src/redux/schedulerRedux/types';
const toolTipPreferences = localStorage.getItem('toolTipPreferences') === 'true' ? true : false;

export type nameFormat = {
  firstName: string;
  lastName: string;
  studentExtId: string;
  className: string;
  component: React.ReactNode;
};
export const UserInfo = (props: nameFormat) => {
  const { firstName, lastName, studentExtId, className, component } = props;
  const elements = React.createElement(
    `${component}`,
    { className: `${className}` },
    `${firstName} ${lastName} (${studentExtId})`,
  );

  return (
    <Tooltip placement="bottom-start" arrow title={toolTipPreferences ? "Student Name (Student ID)" : ''}>
      {elements}
    </Tooltip>
  );
};

export type schoolFormat = {
  schoolName: string;
  schoolCode: string;
  className: string;
  component: React.ReactNode;
};
export const SchoolInfo = (props: schoolFormat) => {
  const { schoolName, schoolCode, className, component } = props;

  const elements = React.createElement(`${component}`, { className: `${className}` }, `${schoolName} (${schoolCode})`);

  return (
    <Tooltip placement="bottom-start" arrow title={toolTipPreferences ? "School Name (School Code)" : ''}>
      {elements}
    </Tooltip>
  );
};

export type serviceFormat = {
  planTypeName: string;
  serviceType: string;
  className: string;
  component: React.ReactNode;
};
export const ServiceInfo = (props: serviceFormat) => {
  const { planTypeName, serviceType, className, component } = props;

  const elements = React.createElement(
    `${component}`,
    { className: `${className}` },
    `${planTypeName}: ${serviceType}`,
  );

  return (
    <Tooltip placement="bottom-start" arrow title={toolTipPreferences ? "Plan Type: Service Type" : ''}>
      {elements}
    </Tooltip>
  );
};

export const getFullName = (first, last) => `${first ? `${first} ` : ' '}${last ? ` ${last}` : ''}`;

export const getProviderLabel = (provider?: ProviderName) =>
  (provider &&
    `${getFullName(provider.firstName, provider.lastName)}${
      provider.credentialName ? ` / ${provider.credentialName}` : ''
    }`) ||
  undefined;

export const getStudentInfoLabel = (student: StudentName, _state) => (
  <div>
    <span style={{ fontSize: '14px', fontWeight: 500 }}>
      {student.firstName} {student.lastName}{' '}
    </span>
    <span style={{ fontSize: '12px' }}> ({student.studentExtId})</span>
    <br />
    <span style={{ fontSize: '12px', marginRight: 8 }}>{student.schoolName}</span>
    <span style={{ fontSize: '12px' }}>Grade: {student.grade}</span>
  </div>
);

export const getSupportTypeName = (supportType: string) => {
  switch (supportType) {
    case 'unsched':
      return 'Non-caseload';
    case 'service':
      return 'Related Service';
    case 'sbbhtiered':
      return 'Tiered Service';
    default:
      return '';
  }
};
