import { commonAsyncReducer } from 'src/utils/commonAsyncReducer';
import { createReducer } from 'typesafe-actions';
import { signOutAsync } from '../authRedux/actions';
import {
  cancelEditOrCloneService,
  cloneTieredService,
  createTieredServiceLogsAsync,
  editHasEndTieredService,
  editTieredService,
  endTieredService,
  getTieredServiceByIdForEdittingOrCloningAsync,
  saveEditServiceFormProgress,
  updateTieredServiceLogsAsync,
} from './action';
import { TieredServiceDetail } from './TieredServiceDetail';
const defaultAsyncState = {
  loading: false,
  error: null,
};

// export enum routeValue {
//   SESSION_LOGS = 'session-logs',
//   TIERED_SERVICES = 'tiered-service',
//   SCHEDULER = 'scheduler',
// }

export const modeValue = {
  EDIT: 'edit',
  EDIT_HAS_END: 'end-has-end',
  CLONE: 'clone',
  END_PLAN: 'endPlan',
};

export type EditAndCloneState = {
  mode: string;
  reason: string;
  planId: number;
  service: TieredServiceDetail;
  loading: boolean;
  error: Error;
  formProgress: Record<string | number, any>;
  updateSuccess: boolean;
};

export const initial: EditAndCloneState = {
  mode: modeValue.EDIT,
  reason: null,
  planId: null,
  service: null,
  formProgress: {},
  updateSuccess: false,
  ...defaultAsyncState,
};

export const edittingServiceReducer = createReducer<
  EditAndCloneState,
  ReturnType<
    | typeof editTieredService
    | typeof editHasEndTieredService
    | typeof cloneTieredService
    | typeof endTieredService
    | typeof saveEditServiceFormProgress
    | typeof cancelEditOrCloneService
    | typeof getTieredServiceByIdForEdittingOrCloningAsync.request
    | typeof getTieredServiceByIdForEdittingOrCloningAsync.success
    | typeof getTieredServiceByIdForEdittingOrCloningAsync.failure
    | typeof updateTieredServiceLogsAsync.request
    | typeof updateTieredServiceLogsAsync.success
    | typeof updateTieredServiceLogsAsync.failure
    | typeof createTieredServiceLogsAsync.success
    | typeof signOutAsync.success
  >
>(initial)
  .handleAction(editTieredService, (state, { payload }) => ({
    ...state,
    mode: modeValue.EDIT,
    reason: payload.reason,
    planId: payload.planId,
    updateSuccess: false,
  }))
  .handleAction(editHasEndTieredService, (state, { payload }) => ({
    ...state,
    mode: modeValue.EDIT_HAS_END,
    reason: payload.reason,
    planId: payload.planId,
    updateSuccess: false,
  }))
  .handleAction(cloneTieredService, (state, { payload }) => ({
    ...state,
    mode: modeValue.CLONE,
    planId: payload,
    updateSuccess: false,
  }))
  .handleAction(endTieredService, (state, { payload }) => ({
    ...state,
    mode: modeValue.END_PLAN,
    planId: payload,
    updateSuccess: false,
  }))
  .handleAction(saveEditServiceFormProgress, (state, { payload }) => ({
    ...state,
    formProgress: {
      ...state.formProgress,
      [payload.studentId]: payload.formValues,
    },
  }))
  .handleAction(cancelEditOrCloneService, state => ({
    ...state,
    ...defaultAsyncState,
    mode: modeValue.EDIT,
    reason: null,
    planId: null,
    service: null,
    formProgress: {},
  }))
  .handleAction(
    [
      getTieredServiceByIdForEdittingOrCloningAsync.request,
      getTieredServiceByIdForEdittingOrCloningAsync.success,
      getTieredServiceByIdForEdittingOrCloningAsync.failure,
    ],
    commonAsyncReducer(getTieredServiceByIdForEdittingOrCloningAsync, 'service', {
      initial: null,
    }),
  )
  .handleAction(
    [updateTieredServiceLogsAsync.request, updateTieredServiceLogsAsync.failure],
    commonAsyncReducer(updateTieredServiceLogsAsync, 'service', {
      initial: null,
    }),
  )
  .handleAction(updateTieredServiceLogsAsync.success, (state, action) => ({
    ...state,
    updateSuccess: true,
    reason: null,
  }))
  //clone success
  .handleAction(createTieredServiceLogsAsync.success, (state, action) => {
    if (!action.payload.final) {
      return state;
    }

    return {
      ...state,
      ...defaultAsyncState,
      mode: modeValue.EDIT,
      reason: null,
      planId: null,
      service: null,
    };
  });
