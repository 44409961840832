import reduceReducers from 'reduce-reducers';
import { combineReducers } from 'redux';
import { AsyncState, commonAsyncReducer } from 'src/utils/commonAsyncReducer';
import { Action, AsyncActionCreatorBuilder, createReducer, getType } from 'typesafe-actions';
import { signInAsync, signOutAsync } from '../authRedux/actions';
import { ProviderName, SchoolName, StudentName } from '../schedulerRedux/types';
// import { removeSessionLogByIdAsync } from '../sessionLogsRedux/actions';
import {
  cancelAddingService,
  clearSubmissionHistory,
  clearTieredServiceDetail,
  clearTieredServiceSearchResults,
  getGradeTSAsync,
  getTieredServiceByIdAsync,
  GetTieredServiceParams,
  getTieredServicesAsync,
  // getTieredServiceSessionLogsAsync,
  removeTieredServiceAsync,
  searchProvidersAsync,
  searchSchoolsAsync,
  searchStudentsAsync,
} from './action';
import {
  AddingTieredServiceState,
  addTieredServiceReducer,
  initial as addTieredServiceInitial,
} from './addTieredServiceReducer';
import { EditAndCloneState, edittingServiceReducer, initial as editAndCloneInitial } from './edittingServiceReducer';
import { TieredServiceDetail } from './TieredServiceDetail';
// import { Grade, TieredService, TieredServiceSessionLog } from './types';
import { Grade, TieredService } from './types';

export type TieredServicesState = {
  services: AsyncState<TieredService[]> & { totalCount: number; currentSearchParams: GetTieredServiceParams };
  searchProviders: AsyncState<ProviderName[]> & { selected?: ProviderName };
  searchSchools: AsyncState<SchoolName[]> & { selected?: SchoolName };
  searchStudents: AsyncState<StudentName[]> & { selected?: StudentName };
  searchGrades: AsyncState<Grade[]>;
  editAndClone: EditAndCloneState;
  // detail: AsyncState<TieredServiceDetail> & { sessionLogs: TieredServiceSessionLog[] };
  detail: AsyncState<TieredServiceDetail>;
  addTieredService: AddingTieredServiceState;
  removeTieredService: {
    loading: boolean;
    error?: any;
  };
};

const defaultAsyncState = {
  loading: false,
  error: null,
};

export const initial: TieredServicesState = {
  services: {
    data: [],
    totalCount: 0,
    currentSearchParams: null,
    ...defaultAsyncState,
  },
  searchProviders: {
    data: [],
    ...defaultAsyncState,
  },
  searchSchools: {
    data: [],
    ...defaultAsyncState,
  },
  searchStudents: {
    data: [],
    ...defaultAsyncState,
  },
  searchGrades: {
    data: [],
    ...defaultAsyncState,
  },
  editAndClone: editAndCloneInitial,
  detail: {
    data: null,
    sessionLogs: [],
    ...defaultAsyncState,
  },
  addTieredService: addTieredServiceInitial,
  removeTieredService: {
    ...defaultAsyncState,
  },
};

const tieredServicesReducer = createReducer(initial.services)
  .handleAction(getTieredServicesAsync.request, (state, action) => ({
    ...state,
    loading: true,
    currentSearchParams: action.payload,
  }))
  .handleAction(getTieredServicesAsync.failure, (state, action) => ({
    ...state,
    error: action.payload,
    loading: false,
  }))
  .handleAction(
    getTieredServicesAsync.success,
    (state, { payload }: ReturnType<typeof getTieredServicesAsync.success>) => ({
      ...state,
      data: payload.items,
      totalCount: payload.totalCount || 0,
      loading: false,
      error: null,
    }),
  )
  .handleAction(
    removeTieredServiceAsync.success,
    (state, { payload }: ReturnType<typeof removeTieredServiceAsync.success>) => ({
      ...state,
      data: state.data?.filter((s: TieredService) => s.planId !== payload.planId),
      totalCount: state.totalCount === 0 ? 0 : state.totalCount - 1,
    }),
  )
  .handleAction([clearTieredServiceSearchResults, signInAsync.success], state => ({
    ...state,
    data: [],
    totalCount: 0,
    loading: false,
    error: null,
  }));

const searchReducer = (searchAsyncAction: AsyncActionCreatorBuilder<any, any, any, any>, initial) =>
  createReducer(initial).handleAction(
    [searchAsyncAction.request, searchAsyncAction.success, searchAsyncAction.failure, searchAsyncAction.cancel],
    commonAsyncReducer(searchAsyncAction, 'data', {
      initial: [],
    }),
  );

export const serviceLogsDetailReducer = createReducer(initial.detail).handleAction(
  [getTieredServiceByIdAsync.request, getTieredServiceByIdAsync.success, getTieredServiceByIdAsync.failure],
  commonAsyncReducer(getTieredServiceByIdAsync, 'data'),
);
// .handleAction(
//   [
//     getTieredServiceSessionLogsAsync.request,
//     getTieredServiceSessionLogsAsync.success,
//     getTieredServiceSessionLogsAsync.failure,
//   ],
//   commonAsyncReducer(getTieredServiceSessionLogsAsync, 'sessionLogs'),
// )
// .handleAction(removeSessionLogByIdAsync.success, (state, action) => {
//   const sessionLogs = state.sessionLogs.filter(
//     (log: TieredServiceSessionLog) => log.session_id !== action.payload.sessionId,
//   );
//   return {
//     ...state,
//     sessionLogs,
//   };
// });

export const removeTieredServiceReducer = createReducer(initial.removeTieredService).handleAction(
  [removeTieredServiceAsync.request, removeTieredServiceAsync.success, removeTieredServiceAsync.failure],
  commonAsyncReducer(removeTieredServiceAsync, 'data'),
);

const resetStateReducer = (state: TieredServicesState = initial, action: Action) => {
  const {
    addTieredService,
    editAndClone,
    services,
    detail,
    searchProviders,
    searchSchools,
    searchStudents,
    removeTieredService,
    searchGrades,
  } = initial;
  switch (action.type) {
    case getType(signOutAsync.success):
    case getType(signInAsync.success):
    case getType(clearSubmissionHistory):
    case getType(cancelAddingService):
      return {
        addTieredService,
        editAndClone,
        services,
        detail,
        searchProviders,
        searchSchools,
        searchStudents,
        removeTieredService,
        searchGrades,
      };
    case getType(clearTieredServiceDetail):
      return {
        ...state,
        detail,
      };
    default:
      return state;
  }
};

const tieredServiceReducer = combineReducers<TieredServicesState>({
  services: tieredServicesReducer,
  searchProviders: searchReducer(searchProvidersAsync, initial.searchProviders),
  searchStudents: searchReducer(searchStudentsAsync, initial.searchStudents),
  searchSchools: searchReducer(searchSchoolsAsync, initial.searchSchools),
  searchGrades: searchReducer(getGradeTSAsync, initial.searchGrades),
  editAndClone: edittingServiceReducer,
  detail: serviceLogsDetailReducer,
  addTieredService: addTieredServiceReducer,
  removeTieredService: removeTieredServiceReducer,
});

export default reduceReducers<any>(initial, tieredServiceReducer, resetStateReducer);
