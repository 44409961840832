import { Button, Grid, MenuItem, Popover, useMediaQuery } from '@material-ui/core';
import { FormikProps } from 'formik';
import React from 'react';
import { scroller } from 'react-scroll';
import { FaCaretDown, FaUsers } from 'react-icons/fa';
import { connect } from 'react-redux';
import { muiResponsive } from 'src/appConfig/muiTheme';
import { PATHS } from 'src/appConfig/paths';
import { useConfirmDialog } from 'src/components/common/ConfirmDialog';
import PageContentContainer, { PageContentFooter } from 'src/components/PageContentContainer';
import StickyPageHeader from 'src/components/StickyPageHeader';
import { getProviderWithPositionLabel } from 'src/containers/SchedulerContainers/helper';
import { selectUserPermissions } from 'src/redux/authRedux/selectors';
import { IRootState } from 'src/redux/rootReducer';
import { ProviderName } from 'src/redux/schedulerRedux/types';
import {
  cancelAddingService,
  cancelRemainingStudentsAddingService,
  clearCompletedStudentTS,
  createTieredServiceLogsAsync,
  saveAddTieredServiceFormProgress,
  setSelectedStudents,
} from 'src/redux/tieredServices/action';
import { EditableStudentName } from 'src/redux/tieredServices/types';
import { DefaultRoute, Navigator, Toastify } from 'src/services';
import { getFullName } from 'src/utils/nameUtils';
import { isEmpty } from 'src/validations';
import AddTieredServiceDialog from '../AddProviderAndStudentsDialog';
import CancelDialog from '../Components/CancelDialog';
import StudentInfoBanner from '../Components/StudentInfoBanner';
import TieredServiceFormik, {
  ConnectedTieredServiceForm,
  TieredServiceFormValue,
} from '../Components/TieredServiceForm';
import { toTieredServiceSubmitPayload } from './helper';
import './styles.scss';

const clsPrefix = 'add-tiered-service';

type Props = ReturnType<typeof mapState> & typeof mapDispatch;

const AddTieredService: React.VFC<Props> = ({
  permissions,
  selectedStudents,

  selectedProvider,
  providerHUserId,
  setSelectedStudents,
  completedStudentIds,
  loading,
  saveFormProgress,
  formProgress,
  cancelAddingService,
  cancelRemainingStudents,
  createTieredServices,
  submitting,
  submissions,
  isFinalSubmission,
  clearCompletedStudentTS,
}) => {
  const [formInitialValue, setFormInitialValue] = React.useState<TieredServiceFormValue>({});
  const formRef = React.useRef<FormikProps<TieredServiceFormValue>>(null);
  const isMobile = useMediaQuery(muiResponsive.MOBILE);
  const [currentStudentIndex, setCurrentStudentIndex] = React.useState<number>(0);
  const [nextStudentIndex, setNextStudentIndex] = React.useState<number>(0);
  const [remaining, setRemaining] = React.useState<number>(0);
  const [submitActionType, setSubmitActionType] = React.useState<'save_next' | 'submit_clone'>('submit_clone');
  const [completedStudentListAnchor, setCompletedStudentListAnchor] = React.useState<HTMLButtonElement>(null);
  const [showAddTieredServiceDialog, setShowAddTieredServiceDialog] = React.useState<boolean>(false);
  const [showCancelDialog, setShowCancelDialog] = React.useState<boolean>(false);
  const [studentToCopy, setStudentToCopy] = React.useState<EditableStudentName>(null);

  const [ConfirmCancelCompletedDialog, confirmCancelCompletedStudent] = useConfirmDialog(
    'You have unsaved information. Are you sure you want to leave this page?',
  );

  const [ConfirmCancelOneStudentDialog, confirmCancelOneStudent] = useConfirmDialog(
    'You have unsaved information. Are you sure you want to leave this page?',
  );

  const [ConfirmCancelAllDialog, confirmCancelAll] = useConfirmDialog(
    'Are you sure you want to cancel all, including ready to submit?',
  );

  const [ConfirmCancelRemainingDialog, confirmCancelRemaining] = useConfirmDialog(
    'Are you sure you want to cancel this student and remaining students?',
  );

  React.useEffect(() => {
    if (!permissions.isTS) {
      Toastify.error('You do NOT have permission to access TIERED SERVICES function.');
      Navigator.navigate(DefaultRoute.getLocalDefaultRoute());
    }
  }, []);

  React.useEffect(() => {
    if (isEmpty(selectedProvider) || isEmpty(selectedStudents)) {
      Navigator.navigate(PATHS.tieredService);
    }
  }, [selectedProvider, selectedStudents]);

  React.useEffect(() => {
    if (selectedStudents) {
      const nextIndex = selectedStudents.findIndex(
        (s, index) => index !== currentStudentIndex && !completedStudentIds.includes(s.studentId),
      );

      if (
        (currentStudentIndex > 0 && currentStudentIndex > selectedStudents.length - 1) ||
        currentStudentIndex === -1
      ) {
        const lastCompletedIndex = selectedStudents.findIndex(
          s => s.studentId === completedStudentIds[completedStudentIds.length - 1],
        );
        setCurrentStudentIndex(nextIndex !== -1 ? nextIndex : lastCompletedIndex);
      } else {
        setNextStudentIndex(nextIndex);
      }

      let remain = selectedStudents.length - completedStudentIds.length;
      remain = nextIndex !== -1 ? remain : remain - 1;

      remain = remain > -1 ? remain : 0;

      setRemaining(remain);
      setSubmitActionType(remain > 0 ? 'save_next' : 'submit_clone');
    }
  }, [currentStudentIndex, nextStudentIndex, selectedStudents, completedStudentIds]);

  React.useEffect(() => {
    if (selectedStudents?.length && selectedStudents[currentStudentIndex]?.studentId) {
      const progress = formProgress[selectedStudents[currentStudentIndex].studentId];
      setFormInitialValue(progress);
    }
  }, [selectedStudents, currentStudentIndex, formProgress]);

  React.useEffect(() => {
    if (selectedProvider) {
      setFormInitialValue({
        provider: selectedProvider.userId,
        providerHUserId: isEmpty(selectedProvider.hUserId) ? providerHUserId : selectedProvider.hUserId,
        providerFullName: getProviderWithPositionLabel(selectedProvider),
      });
    }
  }, [selectedProvider]);

  const resetForm = () => {
    formRef.current.resetForm();
  };

  const getClickCount = () => {
    return localStorage.getItem("clickCount");
  };

  const scrollToTop = (nameElement: string) => {
    scroller.scrollTo(`${nameElement}`, {
      smooth: true,
      duration: 500,
      offset: -90,
    });
  };

  const setClickCount = (val: number) => {
    const curr = parseInt(getClickCount());
    var count = (curr) + (val);
    localStorage.setItem("clickCount", String(count));
  };

  const handleCancelOneStudent = () => {
    confirmCancelOneStudent(() => {
      cancelAddingService();

      Navigator.navigate(PATHS.tieredService);
    });
  };

  const handleCancelAll = () => {
    confirmCancelAll(() => {
      cancelAddingService();
      if (submissions?.length) {
        Navigator.navigate(PATHS.submissionHistory);
      } else {
        Navigator.navigate(PATHS.tieredService);
      }
    });
  };
  const handleCancelRemaining = () => {
    if (selectedStudents.length > 1) {
      confirmCancelRemaining(() => {
        cancelRemainingStudents();
        setShowCancelDialog(false);
        handleSubmitWhenCancelRemaining();
      });
    } else {
      handleCancelAll();
    }
  };

  const closeAddStudentDialog = () => setShowAddTieredServiceDialog(false);
  const handleOnCreateTieredService = (_provider: ProviderName, students: EditableStudentName[]) => {
    setSelectedStudents(students);
    closeAddStudentDialog();
  };

  const handleSaveForm = (formValues: TieredServiceFormValue, completed: boolean = false) => {
    saveFormProgress({
      studentId: studentToCopy?.studentId || selectedStudents[currentStudentIndex].studentId,
      formValues,
      completed,
    });
  };

  const getAllStudentsPayloadsForSubmitWhenCancel = () => {
    const formLastStudentCompleted = formRef.current.isValid && !formRef.current.touched;
    const formValues = {
      ...formProgress,
      [selectedStudents[currentStudentIndex].studentId]: formRef.current.values,
    };
    const payloads = formLastStudentCompleted
      ? Object.keys(formValues).map(studentId =>
          toTieredServiceSubmitPayload(studentId, selectedProvider, selectedStudents, formValues[studentId]),
        )
      : // handle cancel remaining students
        Object.keys(formProgress).map(studentId =>
          toTieredServiceSubmitPayload(studentId, selectedProvider, selectedStudents, formProgress[studentId]),
        );

    return payloads;
  };

  const getAllStudentsPayloads = () => {
    const formValues = {
      ...formProgress,
      [selectedStudents[currentStudentIndex].studentId]: formRef.current.values,
    };

    const payloads = Object.keys(formValues).map(studentId =>
      toTieredServiceSubmitPayload(studentId, selectedProvider, selectedStudents, formValues[studentId]),
    );

    return payloads;
  };

  const getLastStudentPayload = () => {
    const formValues = formRef.current.values;
    const payload = toTieredServiceSubmitPayload(
      studentToCopy?.studentId,
      selectedProvider,
      [studentToCopy],
      formValues,
    );
    return [payload];
  };

  const handleSubmitWhenCancelRemaining = () => {
    localStorage.setItem("submitCloneButton", "false");
    const payloads = getAllStudentsPayloadsForSubmitWhenCancel();
    if (!isEmpty(payloads)) {
      createTieredServices({
        payloads: getAllStudentsPayloadsForSubmitWhenCancel(),
        final: true,
      });
    }

    if (studentToCopy) {
      setStudentToCopy(null);
    }
  };

  const handleSubmit = () => {
    localStorage.setItem("submitCloneButton", "false");
    handleSaveForm(formRef.current.values, true);
    const hasSubmittedAllStudents = !!submissions?.length;
    createTieredServices({
      payloads: hasSubmittedAllStudents ? getLastStudentPayload() : getAllStudentsPayloads(),
      final: true,
    });

    if (studentToCopy) {
      setStudentToCopy(null);
    }
  };

  const handleSubmitAndClone = () => {
    localStorage.setItem("submitCloneButton", "true");
    // set student to clone
    if (!studentToCopy) {
      setStudentToCopy(selectedStudents[currentStudentIndex]);
    }

    const hasSubmittedAllStudents = !!submissions?.length;
    if (hasSubmittedAllStudents) {
      // submit only last student payload this time
      createTieredServices({
        payloads: getLastStudentPayload(),
        final: false,
      });
    } else {
      // then submit all
      createTieredServices({
        payloads: getAllStudentsPayloads(),
        final: false,
      });
    }
  };

  const handleNext = (formValues: TieredServiceFormValue) => {
    handleSaveForm(formValues, true);

    if(localStorage.getItem("isCompleted") === "true") setClickCount(-1);
    
    if(parseInt(getClickCount()) === 0) localStorage.setItem("isCompleted", "false");
    if (submitActionType === 'save_next') {
      resetForm();
      setCurrentStudentIndex(nextStudentIndex);
      window.scrollTo(0, 0);
    } else {
      handleSubmitAndClone();
    }
  };

  const shouldDisableSubmitButton = (isFormValid: boolean, isFormDirty: boolean) => {
    if (submitting || loading) {
      return true;
    }

    if(isCloning && !isFormDirty) return false;

    if (remaining === 0) return !(isFormValid && isFormDirty);
    return !selectedStudents?.length || selectedStudents.length !== completedStudentIds.length;
  };

  const shouldDisableNextButton = (isFormValid: boolean, isFormDirty: boolean) => {
    if (submitting || loading) {
      return true;
    }
    
    if (isCloning && isFormValid) { 
      return false; }

    if (isFormValid && !isFormDirty) {
      return false;
    }
    if(isFormValid) {
      return false;
    }

    else return !(isFormValid && isFormDirty);
  };

  const handleCompletedStudentSelected = (id: number) => {
    setCompletedStudentListAnchor(null);
    confirmCancelCompletedStudent(() => {
      onYes();
    });

    const onYes = () => {
      setCurrentStudentIndex(selectedStudents.findIndex(s => s.studentId === id));
      clearCompletedStudentTS({ studentId: id });
      localStorage.setItem("isCompleted", "true");
      setClickCount(1);
      window.scrollTo(0, 0);
      resetForm();
    };
  };

  const isCloning = !!studentToCopy;

  return (
    <>
      <TieredServiceFormik 
        initialValues={formInitialValue}
        onSubmit={handleNext} 
        innerRef={formRef}
        isInitialValid={ (isCloning || localStorage.getItem("isCompleted") === "true") ? true : false}>
        {(formProps: FormikProps<TieredServiceFormValue>) => {
          // console.log('formProps values: ', formProps.values);
          // console.log('formProps errors: ', formProps.errors);

          return (
            <PageContentContainer loading={loading} contentClassName={`${clsPrefix}-page-container`}>
              <StickyPageHeader className={`${clsPrefix}-sticky-header`}>
                <h3 className={`${clsPrefix}-header`}>{isCloning ? 'Copy' : 'Add'} Tiered Service</h3>
                <Grid container className={`${clsPrefix}-header-status`}>
                  <Grid item xs={12} md={8} className={`${clsPrefix}-header-status-left`}>
                    {!!selectedStudents?.length && (
                      <span className={`${clsPrefix}-header-status-text`}>
                        # Students Remaining: <b>{remaining > 0 ? remaining - 1 : 0}</b> {/* hotfix: ECSSS-23237 */}
                        {isMobile && <br />}
                        {nextStudentIndex !== -1 && (
                          <>
                            {' | '}Next Student:{' '}
                            <b>
                              {getFullName(
                                selectedStudents[nextStudentIndex]?.firstName,
                                selectedStudents[nextStudentIndex]?.lastName,
                              )}
                            </b>
                          </>
                        )}
                      </span>
                    )}
                    {!isCloning && (
                      <Button
                        className={`${clsPrefix}-header-status-button`}
                        variant="text"
                        startIcon={<FaUsers />}
                        color="secondary"
                        onClick={() => setShowAddTieredServiceDialog(true)}>
                        ADD/REMOVE STUDENT
                      </Button>
                    )}
                  </Grid>
                  {!!completedStudentIds?.length && localStorage.getItem("submitCloneButton") === "false" &&(
                    <Grid item xs={12} md={4} className={`${clsPrefix}-header-status-right`}>
                      <Button
                        variant="text"
                        endIcon={<FaCaretDown />}
                        color="secondary"
                        onClick={({ currentTarget }) => setCompletedStudentListAnchor(currentTarget)}>
                        READY TO SUBMIT
                      </Button>
                      <Popover
                        open={!!completedStudentListAnchor}
                        anchorEl={completedStudentListAnchor}
                        onClose={() => setCompletedStudentListAnchor(null)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        classes={{
                          paper: `${clsPrefix}-header-status-done-list`,
                        }}>
                        {selectedStudents?.length &&
                          completedStudentIds.map(id => {
                            const s = selectedStudents.find(s => s.studentId === id);
                            const fullName = getFullName(s?.firstName, s?.lastName) || '';
                            return (
                              <MenuItem
                                key={id}
                                onClick={() => handleCompletedStudentSelected(id as any)}
                                selected={id === selectedStudents[currentStudentIndex]?.studentId}>
                                {fullName}
                              </MenuItem>
                            );
                          })}
                      </Popover>
                    </Grid>
                  )}
                </Grid>
              </StickyPageHeader>
              <StudentInfoBanner
                student={studentToCopy || (currentStudentIndex !== -1 && selectedStudents[currentStudentIndex])}
              />
              <ConnectedTieredServiceForm
                {...formProps}
                studentId={studentToCopy?.studentId || selectedStudents[currentStudentIndex]?.studentId}
                key={studentToCopy?.studentId || selectedStudents[currentStudentIndex]?.studentId || 0}
              />
              <PageContentFooter>
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => {
                    selectedStudents.length > 1 ? setShowCancelDialog(true) : handleCancelOneStudent();
                  }}
                  disabled={submitting}>
                  CANCEL
                </Button>
                {( selectedStudents?.length === 1 && (!!selectedStudents?.length || !!studentToCopy)) && (
                  <Button
                    type="submit"
                    variant="outlined"
                    color="secondary"
                    disabled={shouldDisableNextButton(formProps.isValid, formProps.dirty)}
                    onClick={() => formProps.handleSubmit()}>
                    {submitActionType === 'save_next' ? 'NEXT' : 'SUBMIT & CLONE'}
                  </Button>
                )}

                {( selectedStudents?.length > 1 && submitActionType !== 'submit_clone' && (!!selectedStudents?.length || !!studentToCopy)) && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    type="submit"
                    onClick={() => {
                    formProps.handleSubmit();
                    scrollToTop(clsPrefix);
                    }}
                    disabled={shouldDisableNextButton(formProps.isValid, formProps.dirty)}>
                    {submitActionType === 'save_next' ? 'NEXT' : '' }
                  </Button>
                    )}

                <Button
                  variant="contained"
                  color="secondary"
                  disabled={shouldDisableSubmitButton(formProps.isValid, formProps.dirty)}
                  onClick={handleSubmit}>
                  SUBMIT
                </Button>
              </PageContentFooter>
            </PageContentContainer>
          );
        }}
      </TieredServiceFormik>

      {showAddTieredServiceDialog && (
        <AddTieredServiceDialog
          skipSelectProvider={!!selectedProvider}
          onCreate={handleOnCreateTieredService}
          onClose={closeAddStudentDialog}
        />
      )}
      <CancelDialog
        open={showCancelDialog}
        onClose={() => setShowCancelDialog(false)}
        onCancelAll={handleCancelAll}
        onCancelRemaining={handleCancelRemaining}
      />

      <ConfirmCancelCompletedDialog />
      {<ConfirmCancelOneStudentDialog />}
      <ConfirmCancelAllDialog />
      <ConfirmCancelRemainingDialog />
    </>
  );
};

const mapState = (state: IRootState) => {
  const {
    completedStudentIds,
    loading,
    searchStudents: students,
    progress,
    providers,
    submissions,
  } = state.tieredServices.addTieredService;

  return {
    permissions: selectUserPermissions(state),
    loading,
    completedStudentIds,
    selectedStudents: students.selected,
    formProgress: progress,
    selectedProvider: providers.selected,
    providerHUserId: providers.providerHUserId?.data[0]?.provider_h_user_id,
    submitting: loading,
    submissions: submissions.records,
    isFinalSubmission: submissions.final,
  };
};

const mapDispatch = {
  setSelectedStudents,
  saveFormProgress: saveAddTieredServiceFormProgress,
  cancelAddingService,
  cancelRemainingStudents: cancelRemainingStudentsAddingService,
  createTieredServices: createTieredServiceLogsAsync.request,
  clearCompletedStudentTS: clearCompletedStudentTS,
};

export default connect(mapState, mapDispatch)(AddTieredService);
