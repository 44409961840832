import { Button } from '@material-ui/core';
import { FormikErrors, FormikProps } from 'formik';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PATHS } from 'src/appConfig/paths';
import { connect } from 'react-redux';
import { Element } from 'react-scroll';
import { useConfirmDialog } from 'src/components/common/ConfirmDialog';
import PageContentContainer, { PageContentFooter } from 'src/components/PageContentContainer';
import StickyPageHeader from 'src/components/StickyPageHeader';
import { selectUserPermissions } from 'src/redux/authRedux/selectors';
import { IRootState } from 'src/redux/rootReducer';
import {
  cancelAddingSession,
  createSessionLogsAsync,
  getAbsentReasonOptionsSLAsync,
  getContactTypesOptionsSLAsync,
  getSessionTypesOptionsSLAsync,
  getDeliveryTypesOptionsSLAsync,
  getOutcomeOptionsSLAsync,
  getPracticeElementOptionsSLAsync,
  getReferredByOptionsSLAsync,
  getSLHeaderAsync,
  getTargetOptionsSLAsync,
  getBehaviorCategoryOptionsSLAsync,
  getBehaviorMeasureOptionsSLAsync,
  saveAddLogFormProgress,
  searchStudentsAsync,
  setAddSessionTemplateFirstInfo,
  setSelectedProviderAddSession,
  setSessionDateAddSession,
  updateSessionLogsAsync,
  getConcernTreatmentOptionsSLAsync,
  getDirectNonDirectOptionsSLAsync,
  getFocusAreaAsPlanOptionsSLAsync,
} from 'src/redux/sessionLogsRedux/actions';
import { AddSessionLogType } from 'src/redux/sessionLogsRedux/types';
import { DefaultRoute, Navigator, Toastify } from 'src/services';
import { isEmpty } from 'src/validations';
import { AddSessionLogFormSchema, formValuesToPayload, isCrisisOrConsultation, isObservation } from '../AddSessionLog/helper';
import AddSessionLogFormik, { ConnectedAddSessionLogForm } from '../components/SessionLogForm';
import SkeletonForm from '../components/SkeletonForm';
import SLStudentInfoBanner from '../components/StudentInfoBanner';
import { convertLogDetailsToFormValue, formInitialEdit, formValuesEditToPayload } from './helper';
import './styles.scss';

const clsPrefix = 'add-session-logs';

const AddSessionLog: React.FC<Props> = ({
  permissions,
  logDetails,
  mode,
  updateSessionLog,
  cancelAddingSession,
  loading,
  createSessionLogs,
  contact,
  sessionTypes,
  deliveryMode,
  directNonDirect,
  outcomes,
  practices,
  targets,
  concernTreatments,
  focusAreaAsPlan,
  progressMeasures,
  behaviorMeasures,
  absentReason,
  referredBy,
  getTargetOptions,
  getConcernTreatmentOptions,
  getFocusAreaAsPlanOptions,
  getPracticeOptions,
  getOutcomeOptions,
  getContactOptions,
  getSessionTypeOptions,
  getDeliveryModeOptions,
  getDirectNonDirectOptions,
  getReferredByOptions,
  getAbsentReasonOptions,
  getBehaviorCategoryOptions,
  getBehaviorMeasureOptions,
  studentInfo,
  getSLHeader,
  clearSLHeader,
  studentInfoLoading,
}) => {
  const [ConfirmDialog, confirm] = useConfirmDialog(
    'You have unsaved information. Are you sure you want to leave this page?',
  );

  const formRef = React.useRef<FormikProps<AddSessionLogType>>(null);

  // cancels session log if user navigates away from edit or clone
  // (i.e. if they click on TS or Scheduler in the nav bar)
  const history = useHistory();
  React.useEffect(() => {
    history.listen(({ pathname }) => {
      if(mode === 'edit' || mode === 'clone') {
        if(pathname === PATHS.tieredService || pathname === PATHS.scheduler || pathname === PATHS.sessionLogs) {
          cancelAddingSession();
        }
      }
    });
  }, [PATHS]);

  React.useEffect(() => {
    if (!permissions.isSL) {
      Toastify.error('You do NOT have permission to access SESSION LOGS function.');
      Navigator.navigate(DefaultRoute.getLocalDefaultRoute());
      cancelAddingSession();
    }
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (isEmpty(targets.data)) {
      getTargetOptions();
    }
    if (isEmpty(concernTreatments.data)) {
      getConcernTreatmentOptions();
    }
    if (isEmpty(focusAreaAsPlan.data)) {
      getFocusAreaAsPlanOptions();
    }
    if (isEmpty(practices.data)) {
      getPracticeOptions();
    }
    if (isEmpty(outcomes.data)) {
      getOutcomeOptions();
    }
    if (isEmpty(contact.data)) {
      getContactOptions();
    }
    if (isEmpty(sessionTypes.data)) {
      getSessionTypeOptions();
    }
    if (isEmpty(progressMeasures.data)) {
      getBehaviorCategoryOptions();
    }
    if (isEmpty(behaviorMeasures.data)) {
      getBehaviorMeasureOptions();
    }
    if (isEmpty(deliveryMode.data)) {
      getDeliveryModeOptions();
    }
    if (isEmpty(directNonDirect.data)) {
      getDirectNonDirectOptions();
    }
    if (isEmpty(referredBy.data)) {
      getReferredByOptions();
    }
    if (isEmpty(absentReason.data)) {
      getAbsentReasonOptions();
    }
  }, []);

  const [showSkeleton, setShowSkeleton] = React.useState<boolean>(true);
  React.useEffect(() => {
    setTimeout(() => {
      setShowSkeleton(false);
    }, 1000);
  }, []);

  const handleCancel = () => {
    confirm(() => {
      cancelAddingSession();
    });
  };

  React.useEffect(() => {
    clearSLHeader();
    handleHeader();
  }, []);

  const handleHeader = () => {
    if (logDetails) {
      getSLHeader({
        sessionId: logDetails.sessionId,
        supportId: logDetails.supportId,
        supportType: logDetails.supportType,
      });
    }
  };

  const [formValue, setFormValue] = React.useState<AddSessionLogType>(formInitialEdit);

  React.useEffect(() => {
    isEmpty(logDetails) ? Navigator.goBack() : setFormValue(convertLogDetailsToFormValue(logDetails));
  }, [logDetails]);

  const handleSubmitAndClone = () => {
    const formValues = formRef.current.values;
    const payload = formValuesToPayload(formValues);
    createSessionLogs({
      payloads: [payload],
      final: false,
    });
  };

  const shouldDisableSubmitButton = (isFormValid: boolean, loading: boolean) => {

    if (formRef.current?.values?.licenseAttestUserId === null) {
      if(Object.keys(formRef.current.errors).length === 0) {
        localStorage.setItem('ProviderSignatureError', "true");
      }
      else {
        localStorage.setItem('ProviderSignatureError', "false");
      } 
      return true;
    }
    if (formRef.current?.values?.licenseAttestUserId) {
      localStorage.setItem('ProviderSignatureError', "false");
    }

    return (!isFormValid || loading)
  };

  const handleSubmit = (formProps: AddSessionLogType) => {
    if (mode === 'clone') {
      const payload = formValuesToPayload(formProps);
      createSessionLogs({
        payloads: [payload],
        final: true,
      });
    } else if (mode === 'edit') {
      const payload = formValuesEditToPayload(formProps);
      updateSessionLog({
        sessionId: logDetails.sessionId,
        payload: payload,
      });
    }
  };
  const title = `${mode === 'clone' ? 'Copy' : 'Edit'} Session Log`;
  if (!logDetails) {
    return null;
  }

  const validate = (values: AddSessionLogType): FormikErrors<AddSessionLogType> => {
    let errors: FormikErrors<AddSessionLogType> = {
      targets: [],
      concernTreatments: [],
      focusAreaAsPlan: [],
      practiceElements: [],
      outcomes: [],
    };

    const sessionDate = values.sessionDate;

    const startDate = studentInfo[0]?.supportStartDate;
    const endDate = studentInfo[0]?.supportEndDate;
    const today = moment();
    let targets = values.targets;
    let concernTreatments = values.concernTreatments;
    let focusAreaAsPlan = values.focusAreaAsPlan;
    let practiceElements = values.practiceElements;
    let outcomes = values.outcomes;
    let primaryMethodOfContact = values.primaryMethodOfContact;

    if (!moment(sessionDate).isValid()) {
      errors.sessionDate = 'Please enter a valid date (MM/DD/YYYY)';
    } else {
      if (moment(sessionDate).isAfter(moment(endDate).format('MM/DD/YYYY'))) {
        errors.sessionDate = `Session Date must be on or within the (Projected) Start Date and (Projected) End Date`;
      } else if (moment(sessionDate).isBefore(moment(startDate).format('MM/DD/YYYY'))) {
        errors.sessionDate = `Session Date must be on or within the (Projected) Start Date and (Projected) End Date`;
      }
      if (moment(sessionDate).isAfter(today)) {
        errors.sessionDate = `Session Date cannot be in the future`;
      }
    }

    if (!values.studentNotAvailable) {
      let targetErrors = (errors.targets as Array<unknown>);
      let concernTreatmentErrors = (errors.concernTreatments as Array<unknown>);
      let focusAreaAsPlanErrors = (errors.focusAreaAsPlan as Array<unknown>);
      let practiceElementsErrors = (errors.practiceElements as Array<unknown>);
      let outcomeErrors = (errors.outcomes as Array<unknown>);
      for (let i = 0; i < targets?.length; i++) {
        if(!targets[i]?.id) {
          targetErrors.push({ id: `Targeted Concern is required` }); 
        }
        else if(targets[i].needDescription && !targets[i].description) {
          targetErrors.push({
            id: null,
            description:`Targeted Concern Description is required`,
          }); 
        }

        // Jira#ECSSS-24275
        if (!isObservation(primaryMethodOfContact)) {
          if(!concernTreatments[i]?.id) {
            concernTreatmentErrors.push({ id: `Focus Area is required` }); 
          }
          else if(concernTreatments[i].needDescription && !concernTreatments[i].description) {
            concernTreatmentErrors.push({
              id: null,
              description:`Focus Area Description is required`,
            }); 
          }

          if(!focusAreaAsPlan[i]?.focusAreaAsPlan) {
            focusAreaAsPlanErrors.push({ focusAreaAsPlan: `Focus Area As Plan is required` }); 
          }

          if(!practiceElements[i]?.id) {
            practiceElementsErrors.push({ id: `Practice Element is required` }); 
          }
          else if(practiceElements[i].needDescription && !practiceElements[i].description) {
            practiceElementsErrors.push({
              id: null,
              description:`Practice Element Description is required`,
            }); 
          }

          if (!outcomes?.[i] && !isCrisisOrConsultation(primaryMethodOfContact, values.sessionType)) {
            outcomeErrors.push(`Outcome is required`);
          }
        }
      }
      !errors.targets?.length && delete errors.targets;
      !errors.concernTreatments?.length && delete errors.concernTreatments;
      !errors.focusAreaAsPlan?.length && delete errors.focusAreaAsPlan;
      !errors.practiceElements?.length && delete errors.practiceElements;
      !errors.outcomes?.length && delete errors.outcomes;
    } else {
      delete errors.targets;
      delete errors.concernTreatments;
      delete errors.focusAreaAsPlan;
      delete errors.practiceElements;
      delete errors.outcomes;
    }
    return errors;
  };

  const selectOptionsEmpty =
    isEmpty(targets.data) ||
    isEmpty(focusAreaAsPlan.data) ||
    isEmpty(outcomes.data) ||
    isEmpty(contact.data) ||
    isEmpty(sessionTypes.data) ||
    isEmpty(referredBy.data) ||
    isEmpty(absentReason.data) ||
    isEmpty(practices.data) ||
    isEmpty(deliveryMode.data) ||
    isEmpty(directNonDirect.data) ||
    showSkeleton;

  return (
    <>
      {selectOptionsEmpty ? (
        <SkeletonForm />
      ) : (
        <AddSessionLogFormik
          initialValues={formValue}
          validationSchema={AddSessionLogFormSchema}
          onSubmit={handleSubmit}
          innerRef={formRef}
          validate={validate}
          enableReinitialize>
          {(formProps: FormikProps<AddSessionLogType>) => {
             // console.log('formProps values: ', formProps.values);
            // console.log('formProps error: ', formProps.errors);

            return (
              <Element name={`${clsPrefix}`}>
                <PageContentContainer className={`${clsPrefix}-page-container`} loading={loading}>
                  <StickyPageHeader>
                    <div className={`${clsPrefix}-header-container`}>
                      <h3>{title}</h3>
                    </div>
                  </StickyPageHeader>
                  {logDetails && (
                    <SLStudentInfoBanner
                      student={{
                        studentId: studentInfo[0]?.studentId,
                        studentExtId: studentInfo[0]?.studentExtId,
                        lastName: studentInfo[0]?.studentLastName,
                        firstName: studentInfo[0]?.studentFirstName,
                        schoolCode: studentInfo[0]?.schoolCode,
                        schoolName: studentInfo[0]?.studentSchoolName,
                        grade: studentInfo[0]?.grade,
                        birthDate: studentInfo[0]?.birthDate,
                        gender: studentInfo[0]?.gender,
                        supportName: studentInfo[0]?.supportTypeName,
                        supportType: studentInfo[0]?.supportType || logDetails.supportType,
                        esy: studentInfo[0]?.serviceEsy,
                        frequency: studentInfo[0]?.frequency,
                        startDate: studentInfo[0]?.supportStartDate,
                        endDate: studentInfo[0]?.supportEndDate,
                        primaryTargetArea: studentInfo[0]?.targetArea,
                        supportActiveFlag: studentInfo[0]?.supportActiveFlag,
                        providerId: logDetails?.providerId,
                      }}
                      loading={studentInfoLoading}
                    />
                  )}
                  <ConnectedAddSessionLogForm
                    {...formProps}
                    studentId={logDetails.studentId}
                    startDate={studentInfo[0]?.supportStartDate}
                    endDate={studentInfo[0]?.supportEndDate}
                  />
                  <PageContentFooter>
                    <Button variant="text" color="secondary" onClick={handleCancel}>
                      CANCEL
                    </Button>
                    {mode === 'clone' && (
                      <Button
                        variant="outlined"
                        color="secondary"
                        type="submit"
                        onClick={handleSubmitAndClone}
                        disabled={shouldDisableSubmitButton(formProps.isValid, loading)}>
                        SUBMIT & CLONE
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      disabled={shouldDisableSubmitButton(formProps.isValid, loading)}
                      onClick={() => formProps.handleSubmit()}>
                      SUBMIT
                    </Button>
                  </PageContentFooter>
                </PageContentContainer>
              </Element>
            );
          }}
        </AddSessionLogFormik>
      )}

      <ConfirmDialog />
    </>
  );
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
const mapStateToProps = (state: IRootState) => {
  const { editAndClone, submissions, header } = state.sessionLogs.addSession;
  const { absentReason, contact, sessionTypes, deliveryMode, directNonDirect, outcomes, practices, referredBy, targets, concernTreatments, focusAreaAsPlan, progressMeasures, behaviorMeasures } =
    state.sessionLogs.addSession.search;

  return {
    permissions: selectUserPermissions(state),
    logDetails: state.sessionLogs.searchLogs.selectedLog.data,
    mode: state.sessionLogs.searchLogs.selectedLog.mode,
    loading:
      editAndClone.loading ||
      submissions.loading ||
      absentReason.loading ||
      contact.loading ||
      sessionTypes.loading ||
      deliveryMode.loading ||
      directNonDirect.loading ||
      outcomes.loading ||
      practices.loading ||
      referredBy.loading ||
      targets.loading,
    contact,
    sessionTypes,
    deliveryMode,
    directNonDirect,
    outcomes,
    practices,
    targets,
    concernTreatments,
    focusAreaAsPlan,
    progressMeasures,
    behaviorMeasures,
    absentReason,
    referredBy,
    studentInfo: header.data,
    studentInfoLoading: header.loading,
  };
};

const mapDispatchToProps = {
  cancelAddingSession: cancelAddingSession,
  saveFormProgress: saveAddLogFormProgress,
  createSessionLogs: createSessionLogsAsync.request,
  setSelectedProviderAddSession: setSelectedProviderAddSession,
  setSessionDateAddSession: setSessionDateAddSession,
  setAddSessionTemplateFirstInfo: setAddSessionTemplateFirstInfo,
  searchStudents: searchStudentsAsync.request,
  updateSessionLog: updateSessionLogsAsync.request,

  getTargetOptions: getTargetOptionsSLAsync.request,
  getConcernTreatmentOptions: getConcernTreatmentOptionsSLAsync.request,
  getFocusAreaAsPlanOptions: getFocusAreaAsPlanOptionsSLAsync.request,
  getBehaviorCategoryOptions: getBehaviorCategoryOptionsSLAsync.request,
  getBehaviorMeasureOptions: getBehaviorMeasureOptionsSLAsync.request,
  getPracticeOptions: getPracticeElementOptionsSLAsync.request,
  getOutcomeOptions: getOutcomeOptionsSLAsync.request,
  getContactOptions: getContactTypesOptionsSLAsync.request,
  getSessionTypeOptions: getSessionTypesOptionsSLAsync.request,
  getDeliveryModeOptions: getDeliveryTypesOptionsSLAsync.request,
  getDirectNonDirectOptions: getDirectNonDirectOptionsSLAsync.request,
  getReferredByOptions: getReferredByOptionsSLAsync.request,
  getAbsentReasonOptions: getAbsentReasonOptionsSLAsync.request,

  getSLHeader: getSLHeaderAsync.request,
  clearSLHeader: getSLHeaderAsync.cancel,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSessionLog);
